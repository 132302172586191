import React from 'react'

import { getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class BoxGridSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        fill={getDiscHex(discType, color)}
        xmlns="http://www.w3.org/2000/svg"
        width="21.308"
        height="20.704"
        viewBox="0 0 21.308 20.704"
      >
        <g transform="translate(-11.209 -12.308)">
          <rect
            width="8.637"
            height="8.637"
            rx="1"
            transform="translate(11.209 24.376)"
          />
          <rect
            width="8.637"
            height="8.637"
            rx="1"
            transform="translate(23.88 24.376)"
          />
          <rect
            width="8.637"
            height="8.637"
            rx="1"
            transform="translate(11.209 12.308)"
          />
          <rect
            width="8.637"
            height="8.637"
            rx="1"
            transform="translate(23.88 12.308)"
          />
        </g>
      </svg>
    )
  }
}
