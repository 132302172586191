import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookDogSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookDog', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.14 88.719"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-732.24 -1502.842)">
          <g transform="translate(730.319 411.789)">
            <path
              className={a}
              d="M96.912,1103.27a1.791,1.791,0,0,0-1.773-1.539H87.355c-2.2-6.339-6.9-9.678-13.656-9.678-4.5,0-9.571,1.447-12.539,6.947-.018.032-.041.06-.057.094s-.026.064-.041.1c-1.511,2.894-2.454,6.878-2.454,12.322,0,6.777-4.422,8.86-12.9,12.247-13.021,5.2-31.336,18.974-31.336,45.86-2.314-.052-4.383-.716-5.394-3.495-1.313-3.6.277-7.721.06-9.789a2.257,2.257,0,0,0-3.922-1.391c-1.261,1.24-1.882,5.773-2.012,6.752-.04.3-.922,7.318,3.168,11.988,2.29,2.616,5.6,3.942,9.856,3.942H72.521a1.806,1.806,0,0,0,.432-.062c.017,0,.033,0,.05-.007.033-.009.061-.026.093-.037a1.741,1.741,0,0,0,.178-.071c.052-.024.1-.052.148-.08s.1-.062.147-.1.091-.072.135-.111.082-.077.12-.119a1.54,1.54,0,0,0,.109-.132,1.576,1.576,0,0,0,.1-.143c.029-.048.055-.1.08-.148s.049-.105.07-.16.037-.111.052-.168.027-.108.036-.163a1.81,1.81,0,0,0,.02-.195c0-.033.009-.064.009-.1s-.005-.042-.006-.063a1.711,1.711,0,0,0-.019-.188,1.815,1.815,0,0,0-.032-.179c-.006-.021-.006-.043-.012-.064-.017-.058-.041-.1-.058-.162-.007-.017-.012-.036-.02-.053a11.488,11.488,0,0,0-6.836-7.468l3.23-15.428a8.3,8.3,0,0,0,4.852-2.6c5.475-5.816,4.642-19.566,4.286-25.438-.051-.835-.091-1.467-.091-1.827,0-5.207,2.639-7.738,8.068-7.738a8.87,8.87,0,0,0,7.053-2.852C97.608,1108.44,96.942,1103.479,96.912,1103.27Z"
            />
            <path className={a} d="M57.75,1177.626v-32.465l4.615-6.092" />
            <path
              className={a}
              d="M72.149,1105.843c0,2.739-5.907,9.229-5.907,9.229s-7.431-4.717-6.669-11.957"
            />
            <path
              className={a}
              d="M25.374,1154.944s13.179.4,13.179,14.042c0,0,10.342,1.788,11.447,8.64"
            />
          </g>
          <g className={b} transform="translate(732.319 412.789)">
            <path
              className={c}
              d="M96.912,1103.27a1.791,1.791,0,0,0-1.773-1.539H87.355c-2.2-6.339-6.9-9.678-13.656-9.678-4.5,0-9.571,1.447-12.539,6.947-.018.032-.041.06-.057.094s-.026.064-.041.1c-1.511,2.894-2.454,6.878-2.454,12.322,0,6.777-4.422,8.86-12.9,12.247-13.021,5.2-31.336,18.974-31.336,45.86-2.314-.052-4.383-.716-5.394-3.495-1.313-3.6.277-7.721.06-9.789a2.257,2.257,0,0,0-3.922-1.391c-1.261,1.24-1.882,5.773-2.012,6.752-.04.3-.922,7.318,3.168,11.988,2.29,2.616,5.6,3.942,9.856,3.942H72.521a1.806,1.806,0,0,0,.432-.062c.017,0,.033,0,.05-.007.033-.009.061-.026.093-.037a1.741,1.741,0,0,0,.178-.071c.052-.024.1-.052.148-.08s.1-.062.147-.1.091-.072.135-.111.082-.077.12-.119a1.54,1.54,0,0,0,.109-.132,1.576,1.576,0,0,0,.1-.143c.029-.048.055-.1.08-.148s.049-.105.07-.16.037-.111.052-.168.027-.108.036-.163a1.81,1.81,0,0,0,.02-.195c0-.033.009-.064.009-.1s-.005-.042-.006-.063a1.711,1.711,0,0,0-.019-.188,1.815,1.815,0,0,0-.032-.179c-.006-.021-.006-.043-.012-.064-.017-.058-.041-.1-.058-.162-.007-.017-.012-.036-.02-.053a11.488,11.488,0,0,0-6.836-7.468l3.23-15.428a8.3,8.3,0,0,0,4.852-2.6c5.475-5.816,4.642-19.566,4.286-25.438-.051-.835-.091-1.467-.091-1.827,0-5.207,2.639-7.738,8.068-7.738a8.87,8.87,0,0,0,7.053-2.852C97.608,1108.44,96.942,1103.479,96.912,1103.27Z"
            />
            <path className={c} d="M57.75,1177.626v-32.465l4.615-6.092" />
            <path
              className={c}
              d="M72.149,1105.843c0,2.739-5.907,9.229-5.907,9.229s-7.431-4.717-6.669-11.957"
            />
            <path
              className={c}
              d="M25.374,1154.944s13.179.4,13.179,14.042c0,0,10.342,1.788,11.447,8.64"
            />
          </g>
        </g>
      </svg>
    )
  }
}
