import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class DiceSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('DiceSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 112.595 133.437', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}.${a},.${b},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-width:2px;}.${a},.${b}{stroke-linecap:round;stroke-linejoin:round;}.${b},.${c}{fill:none;}`}</style>
        </defs>
        <g transform="translate(-17.778 -13.169)">
          <path
            className={a}
            d="M-6599.941-8321.036v0l-55.281-33.418v-62.5l55.314,28.974v66.969Z"
            transform="translate(6676 8466.621)"
          />
          <path
            className={b}
            d="M18.778,34.11v62.5l55.315,33.439V63.082Z"
            transform="translate(0 13.555)"
          />
          <path
            className={b}
            d="M107,31.45V98.42L51.688,131.858V64.889Z"
            transform="translate(22.371 11.747)"
          />
          <path
            className={b}
            d="M74.092,14.169,129.373,43.2,74.058,76.636,18.778,47.664Z"
          />
          <ellipse
            className={c}
            cx="5"
            cy="8.627"
            rx="5"
            ry="8.627"
            transform="matrix(0.848, -0.53, 0.53, 0.848, 23.643, 96.519)"
          />
          <ellipse
            className={c}
            cx="5"
            cy="8.627"
            rx="5"
            ry="8.627"
            transform="matrix(0.848, -0.53, 0.53, 0.848, 47.563, 81.57)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(79.758 88.217) rotate(-60)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(79.758 105.093) rotate(-60)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(79.758 121.969) rotate(-60)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(109.994 71.342) rotate(-60)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(35.784 40.87) rotate(-2.2)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(65.73 40.87) rotate(-2.2)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(95.675 40.87) rotate(-2.2)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(109.994 88.217) rotate(-60)"
          />
          <ellipse
            className={c}
            cx="8.149"
            cy="4.849"
            rx="8.149"
            ry="4.849"
            transform="translate(109.994 105.093) rotate(-60)"
          />
        </g>
      </svg>
    )
  }
}
