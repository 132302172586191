import { getBasicClasses, getDiscHex } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType: string | undefined
  color: string | undefined
}

const FourSquaresSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 26 26', discType, color)}>
      <path
        d="M9.46704 2.49866H1.96704V9.99866H9.46704V2.49866Z"
        stroke={getDiscHex(discType)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8003 2.49866H15.3003V9.99866H22.8003V2.49866Z"
        stroke={getDiscHex(discType)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8003 15.832H15.3003V23.332H22.8003V15.832Z"
        stroke={getDiscHex(discType)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46704 15.832H1.96704V23.332H9.46704V15.832Z"
        stroke={getDiscHex(discType)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FourSquaresSVG
