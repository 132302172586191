import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class DiscSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('DiscSVG', discType, color)

    return (
      <svg width={'100%'} height={'100%'} viewBox="0 0 30 30">
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-width:2px;}`}</style>
        </defs>
        <g id="Group_3962" data-name="Group 3962">
          <g id="Ellipse_324" data-name="Ellipse 324">
            <circle className={a} cx="14.72" cy="14.97" r="14" />
            <circle className={b} cx="14.72" cy="14.97" r="12.88" />
          </g>
          <g id="Ellipse_325" data-name="Ellipse 325">
            <circle className={a} cx="14.72" cy="14.97" r="3.92" />
            <circle className={b} cx="14.72" cy="14.97" r="2.8" />
          </g>
          <line
            id="Line_376"
            data-name="Line 376"
            className={b}
            x1="14.72"
            y1="11.61"
            x2="14.72"
            y2="1.53"
          />
          <line
            id="Line_377"
            data-name="Line 377"
            className={b}
            x1="14.72"
            y1="28.41"
            x2="14.72"
            y2="18.33"
          />
          <line
            id="Line_378"
            data-name="Line 378"
            className={b}
            x1="18.08"
            y1="14.97"
            x2="28.16"
            y2="14.97"
          />
          <line
            id="Line_379"
            data-name="Line 379"
            className={b}
            x1="1.28"
            y1="14.97"
            x2="11.36"
            y2="14.97"
          />
        </g>
      </svg>
    )
  }
}
