import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class RoseSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 29.417 29.799', discType, color)}>
        <g transform="translate(-0.306 -4458.581)">
          <path d="M17.79,4462.978c2.249,1.234,7.239,4.9,6.571,6.062-1.464,2.559-6.961-1.31-9.036-3.478a8.146,8.146,0,0,1-1.615-2.124,2.874,2.874,0,0,1-.027-2.582,2.819,2.819,0,0,1,2.371-1.273,6.871,6.871,0,0,1,2.724.611,26.387,26.387,0,0,1,7.864,4.849,6.025,6.025,0,0,1,.916,8.094c-1.684,2.262-7.21,3.7-9.639,3.03-4.7-1.294-8.64-6.48-7.416-10.247,1.323-4.075,3.18-5.064,3.18-5.064" />
          <line y1="2.888" x2="1.766" transform="translate(16.023 4462.978)" />
          <path d="M24.2,4469.27" />
          <path d="M17.919,4476.167a13.387,13.387,0,0,0,6.442-7.127" />
          <path d="M10,4476.167c0,1.789-2.16,3.24-2.16,3.24s-2.16-1.451-2.16-3.24,2.16-4.32,2.16-4.32S10,4474.377,10,4476.167Z" />
          <path d="M9.286,4480.684c2.484-.161,5.436,2.03,5.436,2.03s-2.585,2.489-5.069,2.65a4.71,4.71,0,0,1-3.926-2.067A4.553,4.553,0,0,1,9.286,4480.684Z" />
          <line x1="11.88" y2="12.96" transform="translate(1.719 4474.007)" />
          <line y1="5.4" transform="translate(2.799 4480.487)" />
        </g>
      </svg>
    )
  }
}
