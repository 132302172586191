import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookPulseSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookPulse', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 135.687 122.1', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-117.954 -3255.897)">
          <g transform="translate(118.954 3256.897)">
            <g transform="translate(0)">
              <path
                className={a}
                d="M6280.657,754.283h34.231l7.327-17.232,13.208,45.053,12.878-63.417,14.3,50.047,6.671-14.437h39.759"
                transform="translate(-6280.657 -688.317)"
              />
              <path
                className={a}
                d="M6373.509,697.865c-12.257,0-22.965,9.59-28.761,19.444-5.8-9.854-16.5-19.444-28.761-19.444a33.352,33.352,0,0,0-33.352,33.352c0,40.366,62.113,82.362,62.113,82.362s62.114-41.512,62.114-82.362A33.353,33.353,0,0,0,6373.509,697.865Z"
                transform="translate(-6279.752 -697.865)"
              />
            </g>
            <g className={b} transform="translate(9.46 4.385)">
              <path
                className={c}
                d="M6373.509,697.865c-12.257,0-22.965,9.59-28.761,19.444-5.8-9.854-16.5-19.444-28.761-19.444a33.352,33.352,0,0,0-33.352,33.352c0,40.366,62.113,82.362,62.113,82.362s62.114-41.512,62.114-82.362A33.353,33.353,0,0,0,6373.509,697.865Z"
                transform="translate(-6282.635 -697.865)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
