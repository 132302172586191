import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class HeartWithOutlineSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('HeartWithOutlineSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.5px;}`}</style>
        </defs>
        <path
          className={a}
          d="M67.78,93.41a1,1,0,0,1-.56-.17,117.86,117.86,0,0,1-15.39-12.9C41.52,70,36.29,60.48,36.29,52A17.39,17.39,0,0,1,53.66,34.62c6.07,0,11.11,4.26,14.12,8.68,3-4.42,8-8.68,14.12-8.68A17.39,17.39,0,0,1,99.27,52c0,8.59-5.23,18.16-15.54,28.44A116.27,116.27,0,0,1,68.34,93.24,1,1,0,0,1,67.78,93.41ZM53.66,36.62A15.39,15.39,0,0,0,38.29,52c0,17.6,25.38,36.29,29.49,39.21C71.9,88.31,97.27,69.79,97.27,52A15.39,15.39,0,0,0,81.9,36.62c-5.86,0-10.8,4.87-13.26,9.05a1,1,0,0,1-1.72,0C64.46,41.49,59.52,36.62,53.66,36.62Z"
        />
        <path
          className={a}
          d="M32.22,23.69h0a1,1,0,0,1-.86-.5,23.1,23.1,0,0,0-1.62-2.37,1,1,0,0,1,1.58-1.23q.46.6.9,1.23.44-.63.9-1.23a1,1,0,0,1,1.58,1.23,22.06,22.06,0,0,0-1.62,2.37A1,1,0,0,1,32.22,23.69Z"
        />
        <path
          className={a}
          d="M25.07,65.48a1,1,0,0,1-.64-.23c-1.56-1.28-3.1-2.62-4.56-4a1,1,0,0,1,1.35-1.48c1.44,1.31,3,2.62,4.49,3.89a1,1,0,0,1,.13,1.41A1,1,0,0,1,25.07,65.48ZM16.24,57.4a1,1,0,0,1-.72-.3c-1.46-1.49-2.83-3-4.08-4.47a1,1,0,0,1,.11-1.41,1,1,0,0,1,1.41.12c1.22,1.44,2.57,2.91,4,4.36a1,1,0,0,1-.71,1.7ZM8.52,48.27a1,1,0,0,1-.82-.43,48.86,48.86,0,0,1-3.22-5.18,1,1,0,0,1,.4-1.35,1,1,0,0,1,1.36.41,47.71,47.71,0,0,0,3.1,5,1,1,0,0,1-.24,1.39A1,1,0,0,1,8.52,48.27ZM3,37.71A1,1,0,0,1,2,37,25.08,25.08,0,0,1,.78,30.94a1,1,0,0,1,.93-1.07,1,1,0,0,1,1.07.93,23,23,0,0,0,1.14,5.6,1,1,0,0,1-.64,1.26A.84.84,0,0,1,3,37.71ZM2.38,26a.9.9,0,0,1-.28,0,1,1,0,0,1-.68-1.24A17.3,17.3,0,0,1,4.25,19a1,1,0,1,1,1.59,1.21,15.3,15.3,0,0,0-2.5,5A1,1,0,0,1,2.38,26ZM26.26,17a1,1,0,0,1-.58-.19,14.22,14.22,0,0,0-5-2.4,1,1,0,0,1,.46-1.94,16.11,16.11,0,0,1,5.72,2.72A1,1,0,0,1,26.26,17ZM9.44,16.62a1,1,0,0,1-.85-.47,1,1,0,0,1,.31-1.37,17.39,17.39,0,0,1,5.88-2.32,1,1,0,1,1,.38,2,15.29,15.29,0,0,0-5.2,2A1,1,0,0,1,9.44,16.62Z"
        />
        <path
          className={a}
          d="M32.22,70.94a1,1,0,0,1-.56-.18s-.95-.64-2.47-1.77a1,1,0,1,1,1.19-1.61c.8.6,1.43,1.05,1.84,1.34.42-.29,1.05-.74,1.85-1.33a1,1,0,0,1,1.4.21,1,1,0,0,1-.21,1.4c-1.53,1.13-2.45,1.74-2.49,1.77A1,1,0,0,1,32.22,70.94Z"
        />
        <path
          className={a}
          d="M39.38,65.52a1,1,0,0,1-.77-.36,1,1,0,0,1,.14-1.41c1.54-1.27,3.06-2.57,4.49-3.88a1,1,0,0,1,1.42.07,1,1,0,0,1-.07,1.41c-1.46,1.33-3,2.66-4.57,3.95A1,1,0,0,1,39.38,65.52Zm8.85-8a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41c1.43-1.46,2.77-2.93,4-4.37A1,1,0,0,1,53,52.7c-1.25,1.47-2.63,3-4.09,4.47A1.06,1.06,0,0,1,48.23,57.47ZM56,48.33a.93.93,0,0,1-.57-.19,1,1,0,0,1-.25-1.39,46,46,0,0,0,3.09-5,1,1,0,0,1,1.77.94,50,50,0,0,1-3.22,5.19A1,1,0,0,1,56,48.33Zm5.53-10.57a1.12,1.12,0,0,1-.31,0,1,1,0,0,1-.64-1.26,23.08,23.08,0,0,0,1.13-5.61,1,1,0,0,1,1.07-.93A1,1,0,0,1,63.66,31a25.51,25.51,0,0,1-1.23,6.1A1,1,0,0,1,61.48,37.76ZM62.07,26a1,1,0,0,1-1-.73,15.11,15.11,0,0,0-2.5-5,1,1,0,0,1,.2-1.41,1,1,0,0,1,1.4.2A17.13,17.13,0,0,1,63,24.71,1,1,0,0,1,62.35,26,1.35,1.35,0,0,1,62.07,26ZM38.2,17a1,1,0,0,1-.81-.41,1,1,0,0,1,.22-1.4,16.34,16.34,0,0,1,5.72-2.71,1,1,0,1,1,.47,1.95,14.12,14.12,0,0,0-5,2.38A1,1,0,0,1,38.2,17ZM55,16.64a1,1,0,0,1-.53-.15,15.13,15.13,0,0,0-5.19-2.06,1,1,0,0,1-.79-1.17,1,1,0,0,1,1.17-.79,17.13,17.13,0,0,1,5.87,2.32A1,1,0,0,1,55,16.64Z"
        />
      </svg>
    )
  }
}
