import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class SimpleHandTextBubbleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 28.476 28.136', discType, color)}>
        <g transform="translate(-1.305 -4501.967)">
          <line y2="6.12" transform="translate(2.719 4521.475)" />
          <path d="M2.719,4521.326s4.195-4.38,9.974-.131l4.622-.086s1.165-.138,1.165,1.333a3.787,3.787,0,0,1-.9,2.474l-5.384.34" />
          <path d="M2.719,4528.4a22.016,22.016,0,0,1,7.931.1c3.691.89,4.706.71,6.069.467,3.52-.628,11.511-6.728,11.511-6.728s1-1.725.139-2.768a1.417,1.417,0,0,0-1.895-.371l-7.873,3.788" />
          <path d="M21.812,4513.5h-.938l-2.758,4.1-2.886-4.1H7.777a2.24,2.24,0,0,1-2.376-2.072v-6.388a2.239,2.239,0,0,1,2.376-2.071H21.812a2.24,2.24,0,0,1,2.377,2.071h0v6.388A2.241,2.241,0,0,1,21.812,4513.5Z" />
        </g>
      </svg>
    )
  }
}
