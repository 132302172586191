import { getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const TrashcanDetailed: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  const { a } = getSVGClasses('TrashcanDetailed', discType, color)

  return (
    <svg {...getBasicClasses('0 0 18 20', discType, color)}>
      <path
        d="M2.40002 3.40002V16.952C2.40002 16.952 2.40002 17.568 2.71169 18.184C3.02336 18.8 3.99873 18.8 3.99873 18.8H13.3084C13.3084 18.8 14.2434 18.8 14.555 18.184C14.8667 17.568 14.8667 17.26 14.8667 17.26V3.40002"
        className={a}
      />
      <path
        d="M5.64841 1.66151L6.1724 2.90002H11.0943L11.6183 1.66151C11.7577 1.33184 11.5157 0.966691 11.1578 0.966691H6.1089C5.75094 0.966691 5.50894 1.33184 5.64841 1.66151Z"
        className={a}
      />
      <line
        x1="0.700012"
        y1="2.90002"
        x2="16.5667"
        y2="2.90002"
        className={a}
        strokeLinecap="round"
      />
      <line
        x1="5.83334"
        y1="6.83337"
        x2="5.83334"
        y2="15.3667"
        className={a}
        strokeLinecap="round"
      />
      <line
        x1="8.76669"
        y1="6.83337"
        x2="8.76669"
        y2="15.3667"
        className={a}
        strokeLinecap="round"
      />
      <line
        x1="11.7"
        y1="6.83337"
        x2="11.7"
        y2="15.3667"
        className={a}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default TrashcanDetailed
