import React from 'react'

export default class PersonQuestionMark extends React.Component {
  render() {
    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.6 27.1002V24.6002C24.6 21.9002 22.4 19.7002 19.7 19.7002H9.89999C7.19999 19.7002 5 21.9002 5 24.6002V27.1002"
          stroke="#55AEF6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8001 15.4C18.5004 15.4 21.5001 12.4003 21.5001 8.7C21.5001 4.99969 18.5004 2 14.8001 2C11.0998 2 8.1001 4.99969 8.1001 8.7C8.1001 12.4003 11.0998 15.4 14.8001 15.4Z"
          stroke="#55AEF6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6001 9.3999L14.4001 12.1999L18.2001 6.3999"
          stroke="#55AEF6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}
