import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookWhistleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookWhistle', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 93.663 95.259"
      >
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1837.474 -875.859)">
          <path
            className={a}
            d="M1728.233,568.662c-1.643,14.382-11.735,26.211-26.211,26.211a26.211,26.211,0,0,1-26.211-26.211c0-11.28,7.731-19.554,17.122-24.592,5.426-2.91,46.559-22.645,46.559-22.645,5.478,5.661,11.531,16.313,11.531,16.313s-13.9,9.068-27.416,17.649C1723.607,555.387,1729.085,561.2,1728.233,568.662Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1687.315,590.361c-4-1.188-8.234-3.536-11.5-5.036-9.444-4.331-16.451-13.307-16.451-24.333,0-11.28,7.73-19.554,17.121-24.592,5.426-2.91,46.56-22.645,46.56-22.645,8.965,4.018,16.451,7.67,16.451,7.67"
            transform="translate(179.114 373.638)"
          />
          <line
            className={a}
            x2="16.829"
            y2="6.988"
            transform="translate(1878.541 899.27)"
          />
          <line
            className={a}
            x2="16.829"
            y2="6.988"
            transform="translate(1867.963 903.904)"
          />
          <line
            className={a}
            x1="5.783"
            y1="14.24"
            transform="translate(1863.378 879.436)"
          />
          <line
            className={a}
            y1="15.011"
            x2="3.298"
            transform="translate(1878.541 877.051)"
          />
          <line
            className={a}
            x1="15.006"
            y1="6.231"
            transform="translate(1848.098 891.475)"
          />
          <path
            className={b}
            d="M2170.716,537.738s-6.053-10.652-11.531-16.313h0s-7.485-3.652-16.451-7.67c0,0-9.658,5.074-23.615,11.876h0l16.83,6.988-10.579,4.634-17.247-7.262c-5.942,2.9-10.328,5.54-11.948,6.409-9.391,5.038-17.122,13.312-17.122,24.592,0,11.026,7.007,20,16.451,24.333,3.27,1.5,7.5,3.848,11.5,5.035h0a26.092,26.092,0,0,0,14.706,4.513c14.476,0,24.568-11.829,26.211-26.211.852-7.458-4.626-13.275-4.626-13.275C2156.812,546.806,2170.716,537.738,2170.716,537.738Z"
            transform="translate(-241.053 376.245)"
          />
        </g>
      </svg>
    )
  }
}
