import React from 'react'

const GroupWithWavingSVG = () => {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9314 11.1731C16.4668 11.1731 17.7115 9.92845 17.7115 8.39308C17.7115 6.8577 16.4668 5.61304 14.9314 5.61304C13.3961 5.61304 12.1514 6.8577 12.1514 8.39308C12.1514 9.92845 13.3961 11.1731 14.9314 11.1731Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9051 8.30142C22.3552 7.90427 21.5608 8.02647 21.1637 8.57636L18.2004 12.6395H17.1006H12.8236H12.4875C11.2961 12.6395 10.3491 13.6171 10.3796 14.8085L10.5018 19.3605C10.5934 20.1548 11.3572 20.7658 12.3042 20.8574L12.732 31H16.9479L17.9254 17.2831L23.2106 10.0428C23.5772 9.49286 23.455 8.69856 22.9051 8.30142Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.26367 4.45209L10.3492 6.59058"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2638 4.45209L19.1782 6.59058"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9316 3.47454V1"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7217 6.95732C22.7828 6.74347 22.8439 6.52962 22.9661 6.31577C23.3632 5.64367 24.0964 5.18542 24.9213 5.18542C26.1738 5.18542 27.182 6.19357 27.182 7.44612C27.182 8.69866 26.1738 9.70681 24.9213 9.70681C24.7685 9.70681 24.6158 9.67626 24.463 9.67626"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2251 13.9532L21.2862 16.3666C21.3778 17.0081 21.9888 17.5275 22.7526 17.5886L23.0886 25.8676H26.5408L27.1517 17.5886C27.9155 17.4969 28.5265 17.0081 28.5876 16.3666L28.6792 12.6701C28.7098 11.6925 27.9155 10.9287 26.9684 10.9287H26.6935H24.1273"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.72802 9.70681C5.97657 9.70681 6.98869 8.69466 6.98869 7.44612C6.98869 6.19757 5.97657 5.18542 4.72802 5.18542C3.47948 5.18542 2.46729 6.19757 2.46729 7.44612C2.46729 8.69466 3.47948 9.70681 4.72802 9.70681Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.46936 10.8982H2.98663H2.71171C1.73411 10.8982 0.970335 11.6925 1.00089 12.6395L1.09255 16.3361C1.1842 16.9776 1.79521 17.497 2.55896 17.5581L2.89504 25.8371H6.34712L6.95812 17.5581C7.72186 17.4664 8.33292 16.9776 8.39402 16.3361L8.48561 12.6395C8.51616 11.6619 7.72191 10.8982 6.77486 10.8982H6.46936Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GroupWithWavingSVG
