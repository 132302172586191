import React from 'react'

import {
  getDiscHex,
  getSVGClasses,
  getPlaybookBasicClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookSignSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookSign', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 136.129 126.094', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-106.112 -3389.431)">
          <g transform="translate(107.111 3390.431)">
            <g transform="translate(0)">
              <line
                className={a}
                y1="13.103"
                transform="translate(59.837 44.664)"
              />
              <path
                className={a}
                d="M4592.845,2200.118v37.3h-10.066v-37.3"
                transform="translate(-4522.941 -2113.328)"
              />
              <line
                className={a}
                y2="13.103"
                transform="translate(69.904 44.664)"
              />
              <path
                className={a}
                d="M4582.778,2150.991V2135.35h10.066v15.641"
                transform="translate(-4522.941 -2135.35)"
              />
              <path
                className={a}
                d="M4645.763,2176.045h-95.635v-29.023h95.635l17.146,14.511Z"
                transform="translate(-4534.042 -2131.381)"
              />
              <path
                className={a}
                d="M4555.27,2178.459H4650.9v29.023H4555.27l-17.146-14.512Z"
                transform="translate(-4538.123 -2120.692)"
              />
            </g>
            <g className={b} transform="translate(5.264 20.905)">
              <path
                className={c}
                d="M4645.763,2176.045h-95.635v-29.023h95.635l17.146,14.511Z"
                transform="translate(-4534.042 -2147.022)"
              />
              <path
                className={c}
                d="M4555.27,2178.459H4650.9v29.023H4555.27l-17.146-14.512Z"
                transform="translate(-4538.123 -2136.333)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
