import React from 'react'

const MagnifyingGlassWithProfile = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4 22.8C18.1438 22.8 22.8 18.1439 22.8 12.4001C22.8 6.65638 18.1438 2 12.4 2C6.65623 2 2 6.65638 2 12.4001C2 18.1439 6.65623 22.8 12.4 22.8Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 28L20 20"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 20.5498V19.0498C18.5 17.3498 17.2 16.0498 15.5 16.0498H9.5C7.8 16.0498 6.5 17.3498 6.5 19.0498V20.5498"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5998 13.3499C14.4223 13.3499 15.8998 11.8726 15.8998 10.05C15.8998 8.22751 14.4223 6.75 12.5998 6.75C10.7773 6.75 9.2998 8.22751 9.2998 10.05C9.2998 11.8726 10.7773 13.3499 12.5998 13.3499Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MagnifyingGlassWithProfile
