import { getBasicClasses, getDiscHex } from '@dashboard/lib/svg'
import React from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const CircularArrowsSVG: React.FC<OwnProps> = ({
  discType,
  color,
}: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 30 30', discType, color)}>
      <path
        d="M13.4 28.0998L8.5 23.1998L13.4 18.2998"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1 18.2002C25.1 20.9002 22.9 23.1002 20.2 23.1002H9"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1 17.5999C26.8121 17.5999 28.2 16.212 28.2 14.4999C28.2 12.7878 26.8121 11.3999 25.1 11.3999C23.3879 11.3999 22 12.7878 22 14.4999C22 16.212 23.3879 17.5999 25.1 17.5999Z"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7998 1L20.6998 5.90001L15.7998 10.8"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.09961 10.7999C4.09961 8.0999 6.29961 5.8999 8.99961 5.8999H20.0996"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1 17.5999C5.81208 17.5999 7.2 16.212 7.2 14.4999C7.2 12.7878 5.81208 11.3999 4.1 11.3999C2.38792 11.3999 1 12.7878 1 14.4999C1 16.212 2.38792 17.5999 4.1 17.5999Z"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CircularArrowsSVG
