import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookWeightSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookWeight', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 135.283 142.113', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-106.586 -67.586)">
          <g transform="translate(108 69)">
            <path
              className={a}
              d="M2.27,0h7.38a2.27,2.27,0,0,1,2.27,2.27V9.038a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2.27A2.27,2.27,0,0,1,2.27,0Z"
              transform="translate(113.153 12.428) rotate(45)"
            />
            <path
              className={a}
              d="M2.27,0h7.38a2.27,2.27,0,0,1,2.27,2.27V9.038a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2.27A2.27,2.27,0,0,1,2.27,0Z"
              transform="translate(21.308 121.129) rotate(-135)"
            />
            <rect
              className={a}
              width="64.083"
              height="13.725"
              rx="1.781"
              transform="translate(87.14 0) rotate(45)"
            />
            <rect
              className={a}
              width="75.596"
              height="13.725"
              rx="1.781"
              transform="translate(73.18 5.82) rotate(45)"
            />
            <rect
              className={a}
              width="64.083"
              height="13.725"
              rx="1.781"
              transform="translate(45.314 132.454) rotate(-135)"
            />
            <rect
              className={a}
              width="75.596"
              height="13.725"
              rx="1.781"
              transform="translate(59.274 126.635) rotate(-135)"
            />
            <path
              className={a}
              d="M3561.078,1415.071l4.7,4.7,3.564,3.563-47.673,47.672-8.267-8.267Z"
              transform="translate(-3474.15 -1376.094)"
            />
          </g>
          <g className={b} transform="translate(108 75.83)">
            <rect
              className={c}
              width="64.083"
              height="13.725"
              rx="1.781"
              transform="translate(87.14 0) rotate(45)"
            />
            <rect
              className={c}
              width="75.596"
              height="13.725"
              rx="1.781"
              transform="translate(73.18 5.82) rotate(45)"
            />
            <rect
              className={c}
              width="64.083"
              height="13.725"
              rx="1.781"
              transform="translate(45.314 132.454) rotate(-135)"
            />
            <rect
              className={c}
              width="75.596"
              height="13.725"
              rx="1.781"
              transform="translate(59.274 126.635) rotate(-135)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
