import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookMountainSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses(
      'renderPlaybookMountain',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 101.359 88.421"
      >
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${a},.${b},.${d}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${a},.${d}{fill-rule:evenodd;}.${c}{opacity:0.1;}.${d}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1577.511 -1123.972)">
          <path
            className={a}
            d="M1578.012,828.678l-32.042-64.743-37.078,64.743Z"
            transform="translate(69.619 380.926)"
          />
          <path
            className={a}
            d="M1565.933,803.5l12.278-21.439a2.247,2.247,0,0,1,3.958.118l23.593,46.5h-27.75"
            transform="translate(69.619 380.926)"
          />
          <path
            className={a}
            d="M1535.018,783.059l5.474,4.152a3.644,3.644,0,0,0,4.425-.014l3.217-2.471a3.642,3.642,0,0,1,4.375-.052l5.765,4.219"
            transform="translate(69.619 380.926)"
          />
          <path
            className={a}
            d="M1571.267,794.184l3.143,1.372a5.126,5.126,0,0,0,4.41-.148l7.876-4.085"
            transform="translate(69.619 380.926)"
          />
          <line
            className={b}
            y2="19.89"
            transform="translate(1615.382 1124.972)"
          />
          <rect
            className={b}
            width="12.961"
            height="8.251"
            transform="translate(1615.382 1124.972)"
          />
          <g className={c} transform="translate(490.581 365.772)">
            <path
              className={d}
              d="M1139.189,804.5c-.473.1-4.021-1.775-6.226-2.834a3.485,3.485,0,0,0-3.155.073l-4.344,2.323a3.47,3.47,0,0,1-3.479-.1c-2.021-1.266-4.779-3.439-5.569-3.736l-26,45.393h69.121Z"
            />
            <path
              className={d}
              d="M1168.3,808.184c-1.653,1.43-7.475,5.066-10.286,5.066-2.981,0-3.537-.546-5.215-2.123l-5.334,9.314,12.08,25.18h27.749Z"
            />
          </g>
        </g>
      </svg>
    )
  }
}
