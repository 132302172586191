import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class BrainSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 69.89 60.169', discType, color)}>
        <g transform="translate(-5825.5 -920.769)">
          <g transform="translate(5826.5 921.769)">
            <path
              d="M5894.39,944.425a8.577,8.577,0,0,0-5.052-7.807,10.482,10.482,0,0,0-9.414-8.8,7.922,7.922,0,0,0-7.119-4.439h0c-.382,0-.765.076-1.147.076h-.077a7.154,7.154,0,0,0-1.225.306,8.78,8.78,0,0,0-5.434-1.99,7.855,7.855,0,0,0-4.515,1.378,11.074,11.074,0,0,0-4.516-.919,10.806,10.806,0,0,0-8.19,3.674,8.8,8.8,0,0,0-1.99-.23,7.829,7.829,0,0,0-6.735,3.75h-.689c-.154,0-.306.077-.459.077s-.229.076-.383.076a1.078,1.078,0,0,0-.458.153c-.154,0-.229.076-.383.076-.153.076-.306.076-.383.153-.153.076-.306.076-.382.153s-.23.076-.306.153l-.46.23h0a9.283,9.283,0,0,0-4.745,7.5v.077a12.4,12.4,0,0,0,8.572,21.354,20.258,20.258,0,0,0,2.144-.153,12.2,12.2,0,0,0,2.908.536c1.99,4.9,5.971,7.5,11.4,7.5,5.2,0,11.481,5.281,11.481,11.481v1.147h8.726l-.995-7.118a12.469,12.469,0,0,0,13.317-9.337,15.275,15.275,0,0,0,.383-3.138,9.262,9.262,0,0,0,6.123-8.648,8.549,8.549,0,0,0-.765-3.674A9.778,9.778,0,0,0,5894.39,944.425Z"
              transform="translate(-5826.5 -921.769)"
            />
            <path
              d="M5851.709,954.717s-6.449,4.079-4.256,10.722"
              transform="translate(-5830.003 -927.399)"
            />
            <path
              d="M5836.118,955.212s-1.745-3.261.793-4.646c0,0-6.878-3.4-5.819-9.134"
              transform="translate(-5827.266 -925.129)"
            />
            <path
              d="M5840.431,941.067a9.282,9.282,0,0,0,10.156-1.772"
              transform="translate(-5828.88 -924.764)"
            />
            <path
              d="M5851.261,933.692s-.168,4.983,5.545,5.93"
              transform="translate(-5830.73 -923.806)"
            />
            <path
              d="M5865.815,966.386s6.961,7.819,17.019.985"
              transform="translate(-5833.218 -929.393)"
            />
            <path
              d="M5863.783,947.835s8.463,9.343,20.5,1.008"
              transform="translate(-5832.871 -926.223)"
            />
            <path
              d="M5872.739,956.98a4.489,4.489,0,0,0-3.092-5.2"
              transform="translate(-5833.873 -926.897)"
            />
            <path
              d="M5871.929,938.734s-.5-4.486-5.749-4.583"
              transform="translate(-5833.281 -923.885)"
            />
            <path
              d="M5858.5,960.832a12.26,12.26,0,0,0-2.224-12.174"
              transform="translate(-5831.589 -926.364)"
            />
            <path
              d="M5893.633,944.628s-.494-8.423-10.639-8.04"
              transform="translate(-5836.154 -924.299)"
            />
            <path
              d="M5898.751,966.6s-.75-8.591-10.9-8.207"
              transform="translate(-5836.985 -928.025)"
            />
            <path
              d="M5873.485,929.934s.907-4.721,4.9-5.765"
              transform="translate(-5834.529 -922.179)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
