import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class RookSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('RookSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.5px;}`}</style>
        </defs>
        <path
          className={a}
          d="M80.83,98.9H17.66a1,1,0,0,1-1-1V90a1,1,0,0,1,1-1H80.83a1,1,0,0,1,1,1V97.9A1,1,0,0,1,80.83,98.9Zm-62.17-2H79.83V91H18.66Z"
        />
        <path
          className={a}
          d="M72.5,29.25h-46a1,1,0,0,1-1-.71l-2.39-7.87a1,1,0,0,1,.15-.89,1,1,0,0,1,.8-.4h50.8a1,1,0,0,1,.8.4,1,1,0,0,1,.16.89l-2.4,7.87A1,1,0,0,1,72.5,29.25Zm-45.27-2H71.75l1.79-5.87H25.44Z"
        />
        <path
          className={a}
          d="M76.3,91H22.19a1,1,0,0,1-1-1v-.6a8.28,8.28,0,0,1,8.27-8.27H69a8.27,8.27,0,0,1,8.26,8.27V90A1,1,0,0,1,76.3,91ZM23.2,89H75.29A6.27,6.27,0,0,0,69,83.16H29.46A6.28,6.28,0,0,0,23.2,89Z"
        />
        <path
          className={a}
          d="M70.11,82.72a1,1,0,0,1-1-1L66.47,29.25H32.86L30.22,81.77l-2-.1L30.91,28.2a1,1,0,0,1,1-.95H67.42a1,1,0,0,1,1,.95l2.69,53.47a1,1,0,0,1-1,1Z"
        />
        <path
          className={a}
          d="M74.89,21.38h-.13a1,1,0,0,1-.86-1.12l2.25-17.6H66V9.8a1,1,0,0,1-1,1H57a1,1,0,0,1-1-1V2.65H44.77V9.8a1,1,0,0,1-1,1h-8a1,1,0,0,1-1-1V2.65H23.16l1.93,17.62a1,1,0,0,1-2,.22L21.05,1.76A1,1,0,0,1,22,.65H35.78a1,1,0,0,1,1,1V8.8h6V1.65a1,1,0,0,1,1-1H57a1,1,0,0,1,1,1V8.8h6V1.65a1,1,0,0,1,1-1H77.29a1,1,0,0,1,1,1.12l-2.4,18.74A1,1,0,0,1,74.89,21.38Z"
        />
      </svg>
    )
  }
}
