import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookBranchSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookBranch', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 96.348 87.823"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1834.83 -511.56)">
          <g transform="translate(179.114 373.638)">
            <path
              className={a}
              d="M1661.023,224.458c2.7-7.2,7.839-17.6,18.078-25.241,15.98-11.93,31.212-7.331,42.977-19.442,4.443-4.573,9.655-12.829,10.233-28.651"
            />
            <path
              className={a}
              d="M1727.7,156.456c3.006,4.9,1,10.952,1,10.952s-6.441-1.185-9.307-5.859-.979-10.919-.979-10.919S1724.974,152.008,1727.7,156.456Z"
            />
            <path
              className={a}
              d="M1742.843,159.7c-5.027,2.791-10.985.526-10.985.526s1.462-6.384,6.255-9.045,10.951-.507,10.951-.507S1747.405,157.17,1742.843,159.7Z"
            />
            <path
              className={a}
              d="M1735.439,145.259c-.043,3.689-3.2,6.289-3.2,6.289s-3.093-2.844-3.053-6.362,3.2-6.264,3.2-6.264S1735.477,141.911,1735.439,145.259Z"
            />
            <path
              className={a}
              d="M1718.073,172.033c1.643,6.494-2.685,12.529-2.685,12.529s-6.754-3.552-8.321-9.744c-1.571-6.209,2.7-12.484,2.7-12.484S1716.581,166.14,1718.073,172.033Z"
            />
            <path
              className={a}
              d="M1699.823,180.615c-1.722,8.191-9.892,12.586-9.892,12.586s-5.63-7.694-3.988-15.5c1.646-7.831,9.881-12.531,9.881-12.531S1701.386,173.182,1699.823,180.615Z"
            />
            <path
              className={a}
              d="M1673.883,194.338c.463,10.12-7.78,17.745-7.78,17.745s-8.928-7.311-9.369-16.96c-.443-9.675,7.783-17.676,7.783-17.676S1673.462,185.155,1673.883,194.338Z"
            />
            <path
              className={a}
              d="M1685.766,217.9c-9.749-2.754-14.385-12.982-14.385-12.982s9.755-6.164,19.05-3.539c9.321,2.632,14.318,12.962,14.318,12.962S1694.613,220.393,1685.766,217.9Z"
            />
            <path
              className={a}
              d="M1708.09,204.509c-7.382-3.945-9.316-13.019-9.316-13.019s8.962-3.252,16,.509c7.058,3.772,9.265,12.993,9.265,12.993S1714.789,208.089,1708.09,204.509Z"
            />
            <path
              className={a}
              d="M1733.142,186.729c-6.684-.447-11.083-6.429-11.083-6.429s5.466-5.324,11.84-4.9c6.39.426,11.037,6.425,11.037,6.425S1739.208,187.133,1733.142,186.729Z"
            />
            <path className={a} d="M1664.531,188.935" />
          </g>
          <g className={b} transform="translate(181.114 374.638)">
            <path
              className={c}
              d="M1727.7,156.456c3.006,4.9,1,10.952,1,10.952s-6.441-1.185-9.307-5.859-.979-10.919-.979-10.919S1724.974,152.008,1727.7,156.456Z"
            />
            <path
              className={c}
              d="M1742.843,159.7c-5.027,2.791-10.985.526-10.985.526s1.462-6.384,6.255-9.045,10.951-.507,10.951-.507S1747.405,157.17,1742.843,159.7Z"
            />
            <path
              className={c}
              d="M1735.439,145.259c-.043,3.689-3.2,6.289-3.2,6.289s-3.093-2.844-3.053-6.362,3.2-6.264,3.2-6.264S1735.477,141.911,1735.439,145.259Z"
            />
            <path
              className={c}
              d="M1718.073,172.033c1.643,6.494-2.685,12.529-2.685,12.529s-6.754-3.552-8.321-9.744c-1.571-6.209,2.7-12.484,2.7-12.484S1716.581,166.14,1718.073,172.033Z"
            />
            <path
              className={c}
              d="M1699.823,180.615c-1.722,8.191-9.892,12.586-9.892,12.586s-5.63-7.694-3.988-15.5c1.646-7.831,9.881-12.531,9.881-12.531S1701.386,173.182,1699.823,180.615Z"
            />
            <path
              className={c}
              d="M1673.883,194.338c.463,10.12-7.78,17.745-7.78,17.745s-8.928-7.311-9.369-16.96c-.443-9.675,7.783-17.676,7.783-17.676S1673.462,185.155,1673.883,194.338Z"
            />
            <path
              className={c}
              d="M1685.766,217.9c-9.749-2.754-14.385-12.982-14.385-12.982s9.755-6.164,19.05-3.539c9.321,2.632,14.318,12.962,14.318,12.962S1694.613,220.393,1685.766,217.9Z"
            />
            <path
              className={c}
              d="M1708.09,204.509c-7.382-3.945-9.316-13.019-9.316-13.019s8.962-3.252,16,.509c7.058,3.772,9.265,12.993,9.265,12.993S1714.789,208.089,1708.09,204.509Z"
            />
            <path
              className={c}
              d="M1733.142,186.729c-6.684-.447-11.083-6.429-11.083-6.429s5.466-5.324,11.84-4.9c6.39.426,11.037,6.425,11.037,6.425S1739.208,187.133,1733.142,186.729Z"
            />
            <path className={c} d="M1664.531,188.935" />
          </g>
        </g>
      </svg>
    )
  }
}
