import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookFlowerSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookFlower', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 99.727 70.016"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1833.641 -1494.383)">
          <g transform="translate(179.114 373.638)">
            <path
              className={a}
              d="M1690.212,1136.387a77.629,77.629,0,0,1,12.678-14.642,62.957,62.957,0,0,1,12.4,14.642"
            />
            <path
              className={a}
              d="M1739.382,1178.889c-12.978,12.978-36.332,10.665-36.332,10.665s-2.313-23.354,10.666-36.332,36.332-10.666,36.332-10.666S1752.361,1165.91,1739.382,1178.889Z"
            />
            <path
              className={a}
              d="M1703.268,1174.289c-.271-5.991.443-22.839,4.179-28.8,7.956-12.686,26.5-14.812,26.5-14.812s2.386,4.312,1.944,13.118"
              transform="translate(-0.326)"
            />
            <path
              className={a}
              d="M1702.538,1169.584c.268-5.719-.159-19.077-3.858-24.766-7.877-12.11-26.237-14.14-26.237-14.14s-2.362,4.165-1.925,12.571"
            />
            <path
              className={a}
              d="M1666.4,1178.889q.816.816,1.686,1.554c6.31,5.366,14.5,7.685,21.328,8.643a62.173,62.173,0,0,0,13.318.468s2.313-23.354-10.665-36.332-36.333-10.666-36.333-10.666S1653.42,1165.91,1666.4,1178.889Z"
            />
          </g>
          <g className={b} transform="translate(182.114 373.638)">
            <path
              className={c}
              d="M1683.411,1155.244c5.207-18.637,19.479-33.5,19.479-33.5a86.257,86.257,0,0,1,17.616,33.5"
            />
            <path
              className={c}
              d="M1739.382,1178.889c-12.978,12.978-36.332,10.665-36.332,10.665s-2.313-23.354,10.666-36.332,36.332-10.666,36.332-10.666S1752.361,1165.91,1739.382,1178.889Z"
            />
            <path
              className={c}
              d="M1703.268,1172.31c0-.006,0,2.295,0,2.306-.071-1.536-.248.164.045-7.126.28-7.527,1.384-18.506,4.092-22.672,7.877-12.11,26.237-14.14,26.237-14.14s2.362,4.116,1.925,12.523"
            />
            <path
              className={c}
              d="M1702.538,1169.584c.268-5.719-.159-19.077-3.858-24.766-7.877-12.11-26.237-14.14-26.237-14.14s-2.362,4.165-1.925,12.571"
            />
            <path
              className={c}
              d="M1666.4,1178.889q.816.816,1.686,1.554c6.31,5.366,14.5,7.685,21.328,8.643a62.173,62.173,0,0,0,13.318.468s2.313-23.354-10.665-36.332-36.333-10.666-36.333-10.666S1653.42,1165.91,1666.4,1178.889Z"
            />
          </g>
        </g>
      </svg>
    )
  }
}
