import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookArrowHeartSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookArrowHeart',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.734 95.283"
      >
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${a},.${b},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill-rule:evenodd;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1577.511 -1479.312)">
          <g transform="translate(69.619 380.926)">
            <path
              className={a}
              d="M1571.677,1115.937c-6.208,0-11.63,4.856-14.565,9.846-2.935-4.99-8.357-9.846-14.565-9.846a16.889,16.889,0,0,0-16.889,16.889c0,20.441,31.454,41.708,31.454,41.708s31.454-21.022,31.454-41.708A16.888,16.888,0,0,0,1571.677,1115.937Z"
            />
            <path
              className={b}
              d="M1540.721,1160.937l-7.343,7.669.1,9.051-14.688,15.013-1.2-8.76-8.693-.923,13.837-14.142,10.65-.239"
            />
            <path
              className={b}
              d="M1583.635,1120.633l9.321-9.741-6.114-5.683,18.784-5.822-5.424,18.189-7.246-6.684"
            />
          </g>
          <path
            className={c}
            d="M1571.677,1115.937c-6.208,0-11.63,4.856-14.565,9.846-2.935-4.99-8.357-9.846-14.565-9.846a16.889,16.889,0,0,0-16.889,16.889c0,20.441,31.454,41.708,31.454,41.708s31.454-21.022,31.454-41.708A16.888,16.888,0,0,0,1571.677,1115.937Z"
            transform="translate(69.619 380.926)"
          />
        </g>
      </svg>
    )
  }
}
