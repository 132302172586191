import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookGavelSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookGavel', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 102.756 96.629"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1576 -641.353)">
          <g transform="translate(69.619 380.926)">
            <path
              className={a}
              d="M5.16,0H26.5A4.977,4.977,0,0,1,31.48,4.977V8.352a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5.16A5.16,5.16,0,0,1,5.16,0Z"
              transform="translate(1515.161 338.352)"
            />
            <path
              className={a}
              d="M8.352,0H37.4a8.352,8.352,0,0,1,8.352,8.352v0a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A8.352,8.352,0,0,1,8.352,0Z"
              transform="translate(1507.381 346.704)"
            />
            <rect
              className={a}
              width="40.554"
              height="26.06"
              transform="translate(1523.393 300.02) rotate(-45)"
            />
            <rect
              className={a}
              width="8.519"
              height="35.9"
              rx="3.85"
              transform="translate(1548.59 267.865) rotate(-45)"
            />
            <rect
              className={a}
              width="8.519"
              height="35.9"
              rx="3.85"
              transform="translate(1513.89 302.565) rotate(-45)"
            />
            <path
              className={a}
              d="M0,0H14.306a0,0,0,0,1,0,0V56.813a7.153,7.153,0,0,1-7.153,7.153h0A7.153,7.153,0,0,1,0,56.813V0A0,0,0,0,1,0,0Z"
              transform="translate(1552.378 308.912) rotate(-45)"
            />
          </g>
          <g className={b} transform="translate(71.619 381.926)">
            <path
              className={c}
              d="M5.16,0H26.5A4.977,4.977,0,0,1,31.48,4.977V8.352a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5.16A5.16,5.16,0,0,1,5.16,0Z"
              transform="translate(1515.161 338.352)"
            />
            <path
              className={c}
              d="M8.352,0H37.4a8.352,8.352,0,0,1,8.352,8.352v0a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A8.352,8.352,0,0,1,8.352,0Z"
              transform="translate(1507.381 346.704)"
            />
            <rect
              className={c}
              width="40.554"
              height="26.06"
              transform="translate(1523.393 300.02) rotate(-45)"
            />
            <rect
              className={c}
              width="8.519"
              height="35.9"
              rx="3.85"
              transform="translate(1548.59 267.865) rotate(-45)"
            />
            <rect
              className={c}
              width="8.519"
              height="35.9"
              rx="3.85"
              transform="translate(1513.89 302.565) rotate(-45)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
