import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookBooksAppleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses(
      'renderPlaybookBooksApple',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.718 90.166"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${d}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c},.${d}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1574 -758.6)">
          <g transform="translate(564 -3441)">
            <g transform="translate(-497.376 3821.092)">
              <path
                className={a}
                d="M1527.147,429.345h-5.576a3.842,3.842,0,0,1-3.842-3.842v-8.071a3.842,3.842,0,0,1,3.842-3.841h79.575"
              />
              <line
                className={a}
                x1="63.047"
                transform="translate(1538.099 429.345)"
              />
              <path className={a} d="M1598.073,429.345s-4.194-9.243,0-15.754" />
              <path className={a} d="M1587.512,445.1s-4.194-9.243,0-15.754" />
              <path className={a} d="M1599.668,460.853s-4.195-9.243,0-15.754" />
              <path
                className={a}
                d="M1538.1,433.862l-5.476-3.129-5.476,3.129V421.2H1538.1Z"
              />
              <path
                className={a}
                d="M1552.892,466.969l-5.476-3.129-5.476,3.129V454.31h10.952Z"
              />
              <line
                className={a}
                x2="48.788"
                transform="translate(1541.94 421.203)"
              />
              <line
                className={a}
                x2="53.694"
                transform="translate(1538.099 429.345)"
              />
              <path
                className={a}
                d="M1591.793,445.1h-79.575a3.842,3.842,0,0,1-3.842-3.842v-8.071a3.842,3.842,0,0,1,3.842-3.841h14.929"
              />
              <line
                className={a}
                x2="48.788"
                transform="translate(1532.586 438.556)"
              />
              <line
                className={a}
                x2="9.699"
                transform="translate(1515.518 438.556)"
              />
              <path
                className={a}
                d="M1541.332,460.853h-15.813a3.842,3.842,0,0,1-3.842-3.842V448.94a3.842,3.842,0,0,1,3.842-3.841h79.575"
              />
              <line
                className={a}
                x1="52.202"
                transform="translate(1552.892 460.853)"
              />
              <line
                className={a}
                x2="16.857"
                transform="translate(1574.271 454.31)"
              />
              <line
                className={a}
                x2="37.514"
                transform="translate(1528.819 454.31)"
              />
              <path
                className={a}
                d="M1555.191,388.238s-7.575-4.654-12.338,4.2c-1.081,2.009-1.441,4.808.264,8.619,1.356,3.029,2.679,6.275,6.351,9.432,1.147.986,3.613,2.355,6.05-.07,0,0,2.847,2.81,5.138.07a40.116,40.116,0,0,0,6.35-9.432c1.706-3.811,1.3-6.584.265-8.619C1562.45,382.921,1555.191,388.238,1555.191,388.238Z"
              />
              <path
                className={a}
                d="M1550.989,379.908a11.782,11.782,0,0,1,4.2,8.33"
              />
              <path
                className={a}
                d="M1559.747,385.38c-1.263,1.96-4.315,2.242-4.315,2.242s-1.005-2.9.257-4.856,4.316-2.242,4.316-2.242S1561.01,383.42,1559.747,385.38Z"
              />
            </g>
            <g className={b} transform="translate(-1035.802 3838.63)">
              <path
                className={c}
                d="M2095.054,370.7s-7.575-4.653-12.339,4.2c-1.081,2.01-1.441,4.808.265,8.619,1.355,3.029,2.679,6.276,6.35,9.433,1.147.986,3.613,2.355,6.05-.07,0,0,2.848,2.81,5.138.07a40.125,40.125,0,0,0,6.351-9.433c1.705-3.811,1.3-6.583.264-8.619C2102.312,365.383,2095.054,370.7,2095.054,370.7Z"
              />
              <path
                className={c}
                d="M2090.851,362.37a11.784,11.784,0,0,1,4.2,8.329"
              />
              <path
                className={c}
                d="M2099.609,367.842c-1.263,1.96-4.315,2.242-4.315,2.242s-1-2.9.258-4.856,4.315-2.242,4.315-2.242S2100.872,365.882,2099.609,367.842Z"
              />
            </g>
            <g className={b} transform="translate(-497.147 3821.797)">
              <path
                className={d}
                d="M1538.1,433.862l-5.476-3.129-5.476,3.129V421.2H1538.1Z"
              />
              <path
                className={d}
                d="M1552.892,466.969l-5.476-3.129-5.476,3.129V454.31h10.952Z"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
