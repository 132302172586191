import React from 'react'

import { getBasicClasses, getSVGClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class WalletSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses('MoneyWalletSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 97.702 127.458', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c},.${d}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b},.${d}{opacity:0.1;}.${c},.${d}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-5938.684 -453.688)">
          <path
            className={a}
            d="M5945.1,501.9h83.382a6.9,6.9,0,0,1,6.9,6.9v50.091a6.9,6.9,0,0,1-6.9,6.9h-81.9a6.9,6.9,0,0,1-6.9-6.9V496.478"
            transform="translate(0 14.355)"
          />
          <path
            className={a}
            d="M5945.1,503.282a5.419,5.419,0,0,1-5.418-5.418h0a5.419,5.419,0,0,1,5.418-5.418h9.2"
            transform="translate(0 12.97)"
          />
          <circle
            className={a}
            cx="9.107"
            cy="9.107"
            r="9.107"
            transform="translate(5998.926 539.053)"
          />
          <line
            className={a}
            x1="17.8"
            transform="translate(6016.292 543.361)"
          />
          <line
            className={a}
            x1="17.8"
            transform="translate(6016.292 553.598)"
          />
          <g transform="translate(5955.808 454.689)">
            <path
              className={a}
              d="M6015.171,516.186v-61.5h-63.486v61.563"
              transform="translate(-5951.686 -454.689)"
            />
            <path
              className={a}
              d="M6008.236,514.685V469c-5.044-.289-9.874-3.567-10.254-9.824h-30.86c-.254,6.912-5.836,10.294-11.326,10.152v45.38"
              transform="translate(-5950.273 -453.146)"
            />
            <path
              className={a}
              d="M5996.492,506.651a18.018,18.018,0,1,0-33.123,0"
              transform="translate(-5948.172 -445.466)"
            />
            <circle
              className={a}
              cx="4.437"
              cy="4.437"
              r="4.437"
              transform="translate(27.322 14.016)"
            />
          </g>
          <path
            className={a}
            d="M5999.939,492.445h2.158a5.689,5.689,0,0,1,5.688,5.69v5.147"
            transform="translate(20.698 12.97)"
          />
          <g className={b} transform="translate(5957.808 454.688)">
            <path
              className={c}
              d="M6015.171,516.186v-61.5h-63.486v61.563"
              transform="translate(-5951.686 -454.689)"
            />
            <path
              className={c}
              d="M6008.236,514.685V469c-5.044-.289-9.874-3.567-10.254-9.824h-30.86c-.254,6.912-5.836,10.294-11.326,10.152v45.38"
              transform="translate(-5950.273 -453.146)"
            />
            <path
              className={c}
              d="M5996.492,506.651a18.018,18.018,0,1,0-33.123,0"
              transform="translate(-5948.172 -445.466)"
            />
            <circle
              className={c}
              cx="4.437"
              cy="4.437"
              r="4.437"
              transform="translate(27.322 14.016)"
            />
          </g>
          <circle
            className={d}
            cx="9.107"
            cy="9.107"
            r="9.107"
            transform="translate(6000.926 539.053)"
          />
        </g>
      </svg>
    )
  }
}
