import React from 'react'

import {
  getPlaybookBasicClasses,
  getSVGClasses,
  getDiscHex,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookSpeechSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookSpeech', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 136.371 100.452', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b},.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}.${c}{opacity:0.106;}`}</style>
        </defs>
        <g transform="translate(-94.525 -752.441)">
          <g transform="translate(95.525 753.441)">
            <g transform="translate(0)">
              <path
                className={a}
                d="M2780.053,1465.443h9.631l8.74,18.394,9.375-18.394h42.014a11.812,11.812,0,0,0,11.812-11.812v-36.42a11.812,11.812,0,0,0-11.812-11.812h-69.76a11.812,11.812,0,0,0-11.812,11.812v36.42A11.813,11.813,0,0,0,2780.053,1465.443Z"
                transform="translate(-2768.241 -1405.399)"
              />
              <path
                className={a}
                d="M2842.771,1433.036h32.067a8.92,8.92,0,0,1,8.92,8.92v27.5a8.92,8.92,0,0,1-8.92,8.92h-7.272l-6.6,13.889-7.081-13.889h-31.727a8.92,8.92,0,0,1-8.92-8.92v-15.6"
                transform="translate(-2749.386 -1393.818)"
              />
              <line
                className={a}
                x2="59.946"
                transform="translate(17.037 20.015)"
              />
              <line
                className={a}
                x2="59.946"
                transform="translate(17.037 39.219)"
              />
              <path
                className={b}
                d="M6.24,1.854c2.491,0,2.78.247,2.78,2.738a4.51,4.51,0,1,1-9.02,0C0,2.1,3.749,1.854,6.24,1.854Z"
                transform="translate(78.998 57.403)"
              />
              <ellipse
                className={b}
                cx="4.51"
                cy="4.51"
                rx="4.51"
                ry="4.51"
                transform="translate(93.384 57.484)"
              />
              <ellipse
                className={b}
                cx="4.51"
                cy="4.51"
                rx="4.51"
                ry="4.51"
                transform="translate(107.771 57.484)"
              />
            </g>
            <path
              className={c}
              d="M2780.053,1465.443h9.631l8.74,18.394,9.375-18.394h42.014a11.812,11.812,0,0,0,11.812-11.812v-36.42a11.812,11.812,0,0,0-11.812-11.812h-69.76a11.812,11.812,0,0,0-11.812,11.812v36.42A11.813,11.813,0,0,0,2780.053,1465.443Z"
              transform="translate(-2764.767 -1402.918)"
            />
            <path
              className={c}
              d="M2813.237,1453.862v15.6a8.92,8.92,0,0,0,8.92,8.92h31.727l7.081,13.889,6.6-13.889h7.272a8.92,8.92,0,0,0,8.92-8.92v-27.5a8.92,8.92,0,0,0-8.92-8.92h-32.067s1.363,12.3-2.717,18.565S2813.237,1453.862,2813.237,1453.862Z"
              transform="translate(-2749.736 -1394.106)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
