import { getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const EyeBlindSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  const { a } = getSVGClasses('TrashcanDetailed', discType, color)

  return (
    <svg {...getBasicClasses('0 0 23 22', discType, color)}>
      <path
        d="M11.6333 4.40002C5.8494 4.40003 2.3401 8.94928 1.31525 10.4939C1.11096 10.8018 1.12396 11.1903 1.34686 11.485C2.45529 12.9505 6.16255 17.2334 11.6333 17.2334C17.1041 17.2334 20.8114 12.9505 21.9198 11.485C22.1427 11.1903 22.1557 10.8018 21.9514 10.4939C20.9266 8.94927 17.4173 4.40002 11.6333 4.40002Z"
        className={a}
      />
      <path
        d="M15.1667 10.6333C15.1667 12.5847 13.5847 14.1666 11.6333 14.1666C9.68193 14.1666 8.10001 12.5847 8.10001 10.6333C8.10001 8.6819 9.68193 7.09998 11.6333 7.09998C13.5847 7.09998 15.1667 8.6819 15.1667 10.6333Z"
        className={a}
      />
      <line
        x1="17.9483"
        y1="2.90233"
        x2="4.63568"
        y2="19.7183"
        className={a}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default EyeBlindSVG
