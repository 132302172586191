import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookLetterSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookLetter', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 137.022 132.676', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-124.866 -893)">
          <g transform="translate(126.192 894)">
            <g transform="translate(0)">
              <path
                className={a}
                d="M2300.153,1192.049l-63.83,38.313L2190.9,1202.3l-16.585-10.247v76.625h125.84Z"
                transform="translate(-2174.313 -1143.116)"
              />
              <path
                className={a}
                d="M2189.178,1213.556v-59.849h89.05v59.637"
                transform="translate(-2170.208 -1153.707)"
              />
              <line
                className={a}
                x2="19.021"
                transform="translate(70.15 11.062)"
              />
              <line
                className={a}
                x2="54.321"
                transform="translate(34.85 31.282)"
              />
              <line
                className={a}
                x2="54.391"
                transform="translate(34.78 51.503)"
              />
              <line
                className={a}
                x1="17.819"
                y1="8.171"
                transform="translate(108.02 40.761)"
              />
              <line
                className={a}
                y1="8.699"
                x2="18.971"
                transform="translate(0 40.233)"
              />
            </g>
            <g className={b} transform="translate(8.531 5.119)">
              <path
                className={c}
                d="M2300.153,1192.049l-63.83,38.313L2190.9,1202.3l-16.585-10.247v76.625h125.84Z"
                transform="translate(-2174.313 -1143.116)"
              />
              <path
                className={c}
                d="M2189.178,1244.8v-91.1h89.05v90.772"
                transform="translate(-2170.208 -1153.707)"
              />
              <line
                className={a}
                x2="19.021"
                transform="translate(70.15 11.062)"
              />
              <line
                className={a}
                x2="54.321"
                transform="translate(34.85 31.282)"
              />
              <line
                className={a}
                x2="54.391"
                transform="translate(34.78 51.503)"
              />
              <line
                className={a}
                x1="17.819"
                y1="8.171"
                transform="translate(108.02 40.761)"
              />
              <line
                className={a}
                y1="8.699"
                x2="18.971"
                transform="translate(0 40.233)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
