import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class HandsTogetherSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderHandsTogether', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 131.991 129.133', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-114 -6305.61)">
          <g transform="translate(-3142.134 4678.039)">
            <path
              className={a}
              d="M3257.134,1714.329v15.942H3296.4l13.091-17.738-27.389-22.918S3265.107,1709.745,3257.134,1714.329Z"
              transform="translate(0 25.433)"
            />
            <path
              className={a}
              d="M3277.887,1718.76s6.474-7.3,8.069-14.873,15.745-71.149,15.745-71.149,2.642-7.705,7.1-2.159a19.324,19.324,0,0,1,4.06,12.124s.741,19.922,0,26.507c-.513,4.567-.367,28.841.01,32.486.435,4.213.649,12.755,0,16.94-.526,3.379-2.6,8.171-9.576,9.766s-7.277,5.529-7.277,5.529Z"
              transform="translate(8.646)"
            />
            <path
              className={a}
              d="M3291.572,1678.506s6.512-40.7,10-42.925c3.835-2.448,6.41,6.668,6.41,6.668"
              transform="translate(14.348 2.746)"
            />
            <path
              className={a}
              d="M3294.788,1679.67s5.553-34.126,7.776-35.544c2.441-1.558,4.081,4.245,4.081,4.245"
              transform="translate(15.688 6.369)"
            />
            <path
              className={a}
              d="M3319.571,1678.506s-6.512-40.7-10-42.925c-3.835-2.448-6.411,6.668-6.411,6.668"
              transform="translate(19.175 2.746)"
            />
            <path
              className={a}
              d="M3315.015,1679.67s-5.554-34.126-7.776-35.544c-2.441-1.558-4.082,4.245-4.082,4.245"
              transform="translate(19.175 6.369)"
            />
            <path
              className={a}
              d="M3364.292,1714.329v15.942h-39.261l-13.091-17.738,27.388-22.918S3356.32,1709.745,3364.292,1714.329Z"
              transform="translate(22.834 25.433)"
            />
            <path
              className={a}
              d="M3338.551,1718.76s-6.474-7.3-8.068-14.873-15.744-71.149-15.744-71.149-2.644-7.705-7.1-2.159a19.335,19.335,0,0,0-4.06,12.124s-.74,19.922,0,26.507c.513,4.567.367,28.841-.008,32.486-.435,4.213-.65,12.755,0,16.94.524,3.379,2.6,8.171,9.575,9.766s7.277,5.529,7.277,5.529Z"
              transform="translate(19.175)"
            />
          </g>
          <g className={b} transform="translate(-3139.134 4679.039)">
            <path
              className={c}
              d="M3277.887,1718.76s6.474-7.3,8.069-14.873,15.745-71.149,15.745-71.149,2.642-7.705,7.1-2.159a19.324,19.324,0,0,1,4.06,12.124s.741,19.922,0,26.507c-.513,4.567-.367,28.841.01,32.486.435,4.213.649,12.755,0,16.94-.526,3.379-2.6,8.171-9.576,9.766s-7.277,5.529-7.277,5.529Z"
              transform="translate(8.646)"
            />
            <path
              className={c}
              d="M3291.572,1678.506s6.512-40.7,10-42.925c3.835-2.448,6.41,6.668,6.41,6.668"
              transform="translate(14.348 2.746)"
            />
            <path
              className={c}
              d="M3294.788,1679.67s5.553-34.126,7.776-35.544c2.441-1.558,4.081,4.245,4.081,4.245"
              transform="translate(15.688 6.369)"
            />
            <path
              className={c}
              d="M3319.571,1678.506s-6.512-40.7-10-42.925c-3.835-2.448-6.411,6.668-6.411,6.668"
              transform="translate(19.175 2.746)"
            />
            <path
              className={c}
              d="M3315.015,1679.67s-5.554-34.126-7.776-35.544c-2.441-1.558-4.082,4.245-4.082,4.245"
              transform="translate(19.175 6.369)"
            />
            <path
              className={c}
              d="M3338.551,1718.76s-6.474-7.3-8.068-14.873-15.744-71.149-15.744-71.149-2.644-7.705-7.1-2.159a19.335,19.335,0,0,0-4.06,12.124s-.74,19.922,0,26.507c.513,4.567.367,28.841-.008,32.486-.435,4.213-.65,12.755,0,16.94.524,3.379,2.6,8.171,9.575,9.766s7.277,5.529,7.277,5.529Z"
              transform="translate(19.175)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
