import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookProfileSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookRelationship',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 123.896 139.088', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke-width:1.929px;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linejoin:round;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};stroke-width:2px;}`}</style>
        </defs>
        <g transform="translate(-125.035 -592.029)">
          <g transform="translate(126 593)">
            <g transform="translate(0)">
              <path
                className={a}
                d="M2663.78,1154.307s3.507-25.408-6.586-35.791a57.657,57.657,0,0,0-23.188-10.188l-1.009,1.009a41.057,41.057,0,0,1-57.99-1.009,58.652,58.652,0,0,0-23.19,10.188c-6.018,7.938-4.75,15.647-6.018,35.211C2545.8,1153.727,2591.35,1182.545,2663.78,1154.307Z"
                transform="translate(-2545.8 -1032.992)"
              />
              <path
                className={a}
                d="M2619.69,1101.4c0,16.306-10.43,40.329-30.317,40.329s-30.283-24.023-30.283-40.329a30.317,30.317,0,0,1,60.53,0"
                transform="translate(-2530.858 -1072.863)"
              />
            </g>
          </g>
          <g className={b} transform="translate(129.341 596.341)">
            <g transform="translate(0)">
              <path
                className={c}
                d="M2663.78,1154.307s3.507-25.408-6.586-35.791a57.657,57.657,0,0,0-23.188-10.188l-1.009,1.009a41.057,41.057,0,0,1-57.99-1.009,58.652,58.652,0,0,0-23.19,10.188c-6.018,7.938-4.75,15.647-6.018,35.211C2545.8,1153.727,2591.35,1182.545,2663.78,1154.307Z"
                transform="translate(-2545.8 -1032.992)"
              />
              <path
                className={c}
                d="M2619.69,1101.4c0,16.306-10.43,40.329-30.317,40.329s-30.283-24.023-30.283-40.329a30.317,30.317,0,0,1,60.53,0"
                transform="translate(-2530.858 -1072.863)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
