import React from 'react'

import { getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ThinPrintSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="99.053"
        height="97.688"
        viewBox="0 0 99.053 97.688"
        fill="none"
        stroke={getDiscHex(discType, color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      >
        <g transform="translate(-3494.68 -812.935)">
          <path
            d="M3560.6,842.059h12.991c2.829,0,5.122,1.874,5.122,4.186v44.5c0,2.312-2.293,4.186-5.122,4.186h-10.4"
            transform="translate(14.024 6.076)"
          />
          <path
            d="M3512.977,894.928H3500.8c-2.83,0-5.124-1.874-5.124-4.186v-44.5c0-2.312,2.293-4.186,5.124-4.186h13.548"
            transform="translate(0 6.076)"
          />
          <path
            d="M3575.57,896.4H3508.9l3.648-21.251h59.369Z"
            transform="translate(2.857 13.224)"
          />
          <path
            d="M3571.088,848.135h-59.055v-34.2h52.347l6.708,5.8Z"
            transform="translate(3.533 0)"
          />
          <line x2="73.773" transform="translate(3506.799 888.372)" />
          <line x2="12.26" transform="translate(3503.307 856.827)" />
        </g>
      </svg>
    )
  }
}
