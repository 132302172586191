import React from 'react'

import {
  getPlaybookBasicClasses,
  getSVGClasses,
  getDiscHex,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class MagnifyingGlassInfoSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d, e, f } = getSVGClasses(
      'renderMagnifyingGlassInfo',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 129.773 130.151', discType, color)}>
        <defs>
          <style>{`.${a},.${b},.${c}{fill:none;}.${a},.${b},.${c},.${d},.${f}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-width:2px;}.${a},.${f}{stroke-miterlimit:10;}.${b},.${c},.${d}{stroke-linejoin:round;}.${c}{stroke-linecap:round;}.${d},.${f}{fill:${getDiscHex(
            discType,
            color,
          )};}.${d},.${e}{opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-423 -6811.248)">
          <g transform="translate(424 6812.248)">
            <circle
              className={a}
              cx="49.131"
              cy="49.131"
              r="49.131"
              transform="translate(0 0)"
            />
            <path
              className={b}
              d="M2483.5,1089.762l34.505,33.8a10.93,10.93,0,0,1,0,15.18h0a10.928,10.928,0,0,1-15.178,0l-35.149-34.432"
              transform="translate(-2393.297 -1013.655)"
            />
            <line
              className={c}
              x2="22.548"
              y2="22.548"
              transform="translate(90.871 92.058)"
            />
            <g transform="translate(41.475 12.338)">
              <path
                className={a}
                d="M2456.5,1048.636a6.369,6.369,0,0,1-.543,2.6,6.869,6.869,0,0,1-1.486,2.149,7.242,7.242,0,0,1-2.2,1.463,6.613,6.613,0,0,1-2.668.544,6.169,6.169,0,0,1-2.572-.544,6.769,6.769,0,0,1-2.1-1.463,7.23,7.23,0,0,1-1.441-2.149,6.349,6.349,0,0,1-.543-2.6,6.463,6.463,0,0,1,.543-2.644,7.25,7.25,0,0,1,1.441-2.149,6.793,6.793,0,0,1,2.1-1.462,6.186,6.186,0,0,1,2.572-.544,6.632,6.632,0,0,1,2.668.544,7.268,7.268,0,0,1,2.2,1.462,6.887,6.887,0,0,1,1.486,2.149A6.484,6.484,0,0,1,2456.5,1048.636Zm-1.7,14.541v48.154h-10.15v-48.154Z"
                transform="translate(-2442.948 -1041.837)"
              />
            </g>
          </g>
          <path
            className={d}
            d="M2483.5,1089.762l34.505,33.8a10.93,10.93,0,0,1,0,15.18h0a10.928,10.928,0,0,1-15.178,0l-35.149-34.432"
            transform="translate(-1969.297 5798.593)"
          />
          <g className={e} transform="translate(465.475 6824.586)">
            <path
              className={f}
              d="M2456.5,1048.636a6.369,6.369,0,0,1-.543,2.6,6.869,6.869,0,0,1-1.486,2.149,7.242,7.242,0,0,1-2.2,1.463,6.613,6.613,0,0,1-2.668.544,6.169,6.169,0,0,1-2.572-.544,6.769,6.769,0,0,1-2.1-1.463,7.23,7.23,0,0,1-1.441-2.149,6.349,6.349,0,0,1-.543-2.6,6.463,6.463,0,0,1,.543-2.644,7.25,7.25,0,0,1,1.441-2.149,6.793,6.793,0,0,1,2.1-1.462,6.186,6.186,0,0,1,2.572-.544,6.632,6.632,0,0,1,2.668.544,7.268,7.268,0,0,1,2.2,1.462,6.887,6.887,0,0,1,1.486,2.149A6.484,6.484,0,0,1,2456.5,1048.636Zm-1.7,14.541v48.154h-10.15v-48.154Z"
              transform="translate(-2442.948 -1041.837)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
