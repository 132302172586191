import React from 'react'

import { getBasicClasses, getSVGClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class BouquetSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('BouquetSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 137.235 132.739', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-6161.598 -686.739)">
          <path
            className={a}
            d="M6210.814,707.361l52.53,52.53-71.022,29-2.865,16.289-21.012-21.012,15.414-3.74Z"
            transform="translate(2.582 9.021)"
          />
          <line
            className={a}
            x2="8.116"
            y2="8.116"
            transform="translate(6187.514 788.668)"
          />
          <g transform="translate(6221.681 690.765)">
            <path
              className={a}
              d="M6215.535,702.2s-7.455.549-10.731-2.727a5.66,5.66,0,1,1,8-8C6216.082,694.745,6215.535,702.2,6215.535,702.2Z"
              transform="translate(-6203.145 -689.811)"
            />
            <path
              className={a}
              d="M6211.817,702.373s-.548-7.455,2.728-10.731a5.66,5.66,0,0,1,8,8C6219.271,702.921,6211.817,702.373,6211.817,702.373Z"
              transform="translate(-6199.175 -689.732)"
            />
            <path
              className={a}
              d="M6211.99,698.655s7.456-.547,10.731,2.728a5.66,5.66,0,0,1-8,8C6211.442,706.111,6211.99,698.655,6211.99,698.655Z"
              transform="translate(-6199.095 -685.762)"
            />
            <path
              className={a}
              d="M6215.708,698.482s.549,7.456-2.727,10.732a5.66,5.66,0,0,1-8-8C6208.252,697.934,6215.708,698.482,6215.708,698.482Z"
              transform="translate(-6203.066 -685.841)"
            />
          </g>
          <g transform="translate(6271.641 712.716)">
            <path
              className={a}
              d="M6249.842,717.944s-7.472-.282-10.364-3.9a5.66,5.66,0,0,1,8.843-7.068C6251.212,710.594,6249.842,717.944,6249.842,717.944Z"
              transform="translate(-6236.971 -704.85)"
            />
            <path
              className={a}
              d="M6246.34,717.665s.283-7.471,3.9-10.363a5.659,5.659,0,0,1,7.065,8.842C6253.689,719.036,6246.34,717.665,6246.34,717.665Z"
              transform="translate(-6233.247 -704.291)"
            />
            <path
              className={a}
              d="M6246.6,714.2s7.471.282,10.362,3.9a5.659,5.659,0,1,1-8.841,7.067C6245.225,721.55,6246.6,714.2,6246.6,714.2Z"
              transform="translate(-6233.279 -700.55)"
            />
            <path
              className={a}
              d="M6250.464,714.113s-.281,7.469-3.9,10.363a5.66,5.66,0,0,1-7.068-8.842C6243.114,712.741,6250.464,714.113,6250.464,714.113Z"
              transform="translate(-6237.37 -700.74)"
            />
          </g>
          <g transform="translate(6261.759 743.517)">
            <path
              className={a}
              d="M6243.118,738.216s-7.442.693-10.781-2.518a5.66,5.66,0,0,1,7.848-8.158C6243.522,730.751,6243.118,738.216,6243.118,738.216Z"
              transform="translate(-6230.601 -725.946)"
            />
            <path
              className={a}
              d="M6239.374,738.469s-.693-7.443,2.518-10.783a5.66,5.66,0,0,1,8.158,7.848C6246.838,738.872,6239.374,738.469,6239.374,738.469Z"
              transform="translate(-6226.599 -725.95)"
            />
            <path
              className={a}
              d="M6239.534,734.716s7.444-.693,10.783,2.518a5.659,5.659,0,1,1-7.847,8.157C6239.131,742.179,6239.534,734.716,6239.534,734.716Z"
              transform="translate(-6226.501 -721.949)"
            />
            <path
              className={a}
              d="M6243.213,734.531s.693,7.443-2.52,10.782a5.659,5.659,0,0,1-8.157-7.846C6235.747,734.126,6243.213,734.531,6243.213,734.531Z"
              transform="translate(-6230.437 -722.012)"
            />
          </g>
          <g transform="translate(6236.93 715.585)">
            <path
              className={a}
              d="M6225.182,711.95c3.359,1.895,10.792,7.485,9.769,9.229-2.248,3.82-10.44-2.095-13.52-5.389a12.233,12.233,0,0,1-2.389-3.222,4.314,4.314,0,0,1,.006-3.88,4.231,4.231,0,0,1,3.586-1.871,10.337,10.337,0,0,1,4.083.966,39.7,39.7,0,0,1,11.732,7.429,9.055,9.055,0,0,1,1.232,12.182c-2.571,3.369-6.112,6.6-9.751,5.554-7.042-2.028-19.14-11.257-15.753-16.707,3.4-5.469,4.871-7.553,4.871-7.553"
              transform="translate(-6213.591 -706.815)"
            />
            <line
              className={a}
              y1="4.308"
              x2="2.707"
              transform="translate(8.885 5.135)"
            />
            <path
              className={a}
              d="M6228.053,716.889"
              transform="translate(-6206.943 -702.183)"
            />
            <path
              className={a}
              d="M6221.169,726.089a15.858,15.858,0,0,0,10.3-9.434"
              transform="translate(-6210.11 -702.291)"
            />
          </g>
          <g transform="translate(6249.871 687.738)">
            <path
              className={a}
              d="M6233.973,692.847c3.381,1.854,10.879,7.357,9.876,9.11-2.2,3.848-10.464-1.969-13.581-5.227a12.2,12.2,0,0,1-2.428-3.192,4.312,4.312,0,0,1-.04-3.88,4.24,4.24,0,0,1,3.564-1.915,10.33,10.33,0,0,1,4.092.919,39.688,39.688,0,0,1,11.821,7.287,9.055,9.055,0,0,1,1.376,12.167c-2.531,3.4-6.032,6.675-9.683,5.67-7.065-1.944-19.273-11.03-15.952-16.518,3.334-5.509,4.781-7.61,4.781-7.61"
              transform="translate(-6222.458 -687.738)"
            />
            <line
              className={a}
              y1="4.34"
              x2="2.655"
              transform="translate(8.86 5.108)"
            />
            <path
              className={a}
              d="M6236.944,697.716"
              transform="translate(-6215.798 -683.151)"
            />
            <path
              className={a}
              d="M6230.134,707.037a15.861,15.861,0,0,0,10.186-9.558"
              transform="translate(-6218.929 -683.26)"
            />
          </g>
          <line
            className={a}
            x1="6.23"
            y2="12.273"
            transform="translate(6224.307 715.018)"
          />
          <line
            className={a}
            x1="3.584"
            y2="4.183"
            transform="translate(6240.48 737.229)"
          />
          <line
            className={a}
            x1="21.285"
            y2="17.693"
            transform="translate(6249.749 734.524)"
          />
          <line
            className={a}
            x1="10.29"
            y2="2.149"
            transform="translate(6254.689 755.524)"
          />
          <line
            className={a}
            x1="4.657"
            y2="6.565"
            transform="translate(6252.95 709.329)"
          />
          <line
            className={a}
            x1="2.992"
            y2="3.989"
            transform="translate(6235.756 733.345)"
          />
          <line
            className={a}
            y1="3.91"
            x2="11.803"
            transform="translate(6169.552 803.516)"
          />
          <line
            className={a}
            y1="13.411"
            x2="8.588"
            transform="translate(6173.938 804.685)"
          />
          <line
            className={a}
            y1="9.026"
            x2="10.926"
            transform="translate(6170.43 803.516)"
          />
          <line
            className={a}
            x1="15.403"
            y2="4.257"
            transform="translate(6162.828 800.391)"
          />
          <path
            className={b}
            d="M6210.814,707.361l52.53,52.53-71.022,29-2.865,16.289-21.012-21.012,15.414-3.74Z"
            transform="translate(4.234 11.499)"
          />
        </g>
      </svg>
    )
  }
}
