import React from 'react'

import { getBasicClasses, getSVGClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class HandTextBubbleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('HandTextBubbleSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 136.8 136.862', discType, color)}>
        <defs>
          <style>{`.${a},.${b}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill-rule:evenodd;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-89 -8039.255)">
          <g transform="translate(-4923.111 7350.061)">
            <rect
              className={a}
              width="16.724"
              height="50.588"
              transform="translate(5029.835 824.056) rotate(180)"
            />
            <path
              className="b"
              d="M5024.409,756.181s19.048-15.2,45.29-.454l20.987-.3s5.288-.477,5.288,4.626c0,5.329-4.1,5.114-4.1,5.114l-33.525,1.181"
              transform="translate(5.426 28.383)"
            />
            <path
              className="b"
              d="M5024.409,789.458s19.25-3.736,36.011.474,24.517,1.107,24.517,1.107l55.308-35.445s4.543-3.431.632-8.364c-3.937-4.962-8.606-1.753-8.606-1.753l-35.748,17.912"
              transform="translate(5.426 26.063)"
            />
            <path
              className={a}
              d="M5037.3,750.578h9.686l8.788,18.5,9.43-18.5h42.25a11.88,11.88,0,0,0,11.881-11.879V702.072a11.879,11.879,0,0,0-11.881-11.878H5037.3a11.879,11.879,0,0,0-11.881,11.878V738.7A11.88,11.88,0,0,0,5037.3,750.578Z"
              transform="translate(5.914 0)"
            />
            <line
              className={a}
              x2="60.285"
              transform="translate(5048.472 710.322)"
            />
            <line
              className={a}
              x2="60.285"
              transform="translate(5048.472 729.634)"
            />
          </g>
          <rect
            className={c}
            width="16.724"
            height="50.588"
            transform="translate(108.724 8176.117) rotate(180)"
          />
          <path
            className={c}
            d="M5037.3,750.578h9.686l8.788,18.5,9.43-18.5h42.25a11.88,11.88,0,0,0,11.881-11.879V702.072a11.879,11.879,0,0,0-11.881-11.878H5037.3a11.879,11.879,0,0,0-11.881,11.878V738.7A11.88,11.88,0,0,0,5037.3,750.578Z"
            transform="translate(-4915.197 7352.061)"
          />
        </g>
      </svg>
    )
  }
}
