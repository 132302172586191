import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookHeartHandshakeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses(
      'renderPlaybookHeartHandshake',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.629 89.9"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2.411px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1834.043 -636.571)">
          <g transform="translate(179.114 373.638)">
            <path
              className={a}
              d="M1710.622,289.824c9.032,8.074,18.038,16.178,27.1,24.222,1.8,1.6,2.91,3.347,2.4,5.819-.85,4.142-5.375,5.379-8.95,2.249-8.185-7.166-16.255-14.463-24.372-21.706-1.014-.906-2.008-1.836-3.044-2.715"
            />
            <path
              className={a}
              d="M1730.433,321.332c1.8,1.6,2.91,3.347,2.4,5.819-.85,4.142-5.375,5.379-8.95,2.249-8.185-7.166-16.255-14.462-24.372-21.706-1.014-.906-2.008-1.836-3.045-2.715"
            />
            <path
              className={a}
              d="M1722.925,328.84c1.8,1.6,2.91,3.346,2.4,5.819-.85,4.142-5.245,6.626-10.614.829"
            />
            <path
              className={a}
              d="M1668.692,317.545c-4.994-4.859-12.537-16.741-12.557-24.366a28.705,28.705,0,0,1,41.136-25.951"
            />
            <path
              className={a}
              d="M1702.785,283.774c-3.665,4.2-7.515,8.234-11.308,12.318a22.683,22.683,0,0,1-5.523,4.66,6.673,6.673,0,0,1-5.176.735c-3.318-.934-3.758-2.546-1.419-5.027,7.285-7.732,14.7-15.348,21.826-23.219,7.4-8.166,16.571-10.1,26.712-7.577,20.014,4.979,28.106,29.008,15.48,45.3-.569.734-1.234,1.394-2.3,2.593"
            />
            <rect
              className={a}
              width="11.887"
              height="22.598"
              rx="4.681"
              transform="matrix(-0.755, -0.656, 0.656, -0.755, 1701.473, 351.131)"
            />
            <rect
              className={a}
              width="11.887"
              height="25.633"
              rx="4.681"
              transform="matrix(-0.755, -0.656, 0.656, -0.755, 1691.018, 345.43)"
            />
            <rect
              className={a}
              width="11.887"
              height="25.633"
              rx="4.681"
              transform="translate(1682.075 337.991) rotate(-138.984)"
            />
            <rect
              className={a}
              width="11.887"
              height="20.754"
              rx="4.681"
              transform="matrix(-0.755, -0.656, 0.656, -0.755, 1673.76, 329.83)"
            />
          </g>
          <path
            className={b}
            d="M2097.59,261.571c-9.043-2.25-17.314-.941-24.245,5.157l-6.381-3.593a28.705,28.705,0,0,0-41.136,25.951c.02,7.625,7.563,19.506,12.557,24.365l4.819-5.864c.025.079.05.158.076.237l-5.724,6.58a4.682,4.682,0,0,0,.46,6.6l1.905,1.657a4.667,4.667,0,0,0,5.616.373,4.665,4.665,0,0,0,.794,6.131l1.905,1.657a4.68,4.68,0,0,0,5.937.167,4.669,4.669,0,0,0,1.1,5.615l1.905,1.657a4.682,4.682,0,0,0,6.6-.46l.718-.827a4.667,4.667,0,0,0,1.227,5.331l1.905,1.657a4.68,4.68,0,0,0,6.6-.46l8.687-9.987a4.632,4.632,0,0,0,1.036-2.148c.28-.094.56-.2.84-.3l-.394.319c5.369,5.8,9.764,3.313,10.614-.829a5.022,5.022,0,0,0-.636-3.779c.253-.139.508-.276.759-.418,3.22,1.649,6.656.244,7.385-3.311a5.454,5.454,0,0,0,.092-1.7q1-.8,1.912-1.626a4.713,4.713,0,0,0,5.282-3.963,5.157,5.157,0,0,0-.06-2.487,8.7,8.7,0,0,0,1.01-3.826c1.072-1.2,1.736-1.859,2.305-2.593C2125.7,290.579,2117.6,266.549,2097.59,261.571Z"
            transform="translate(-187.828 379.73)"
          />
        </g>
      </svg>
    )
  }
}
