import React from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const renderC = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M18 25.4062L7.5 34.8777C9.18946 36.7339 11.2605 38.2366 13.5892 39.2619L19.2739 26.3112C18.7898 26.0967 18.3573 25.7872 18 25.4062Z"
        fill="#2F5FA5"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderCd = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M7.5 34.8777L18 25.4062C17.6616 25.0453 17.3907 24.6203 17.2075 24.1514L3.84906 28.806C4.6598 31.0738 5.91116 33.1321 7.5 34.8777Z"
        fill="#6756B2"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderCD = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M16.9399 22.7252H2.7998C2.7998 24.8588 3.16978 26.906 3.84906 28.806L17.2075 24.1514C17.0347 23.7094 16.9399 23.2284 16.9399 22.7252Z"
        fill="#93359B"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderCs = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.24 12.7201C22.24 18.4197 17.6196 23.0401 11.92 23.0401M22.24 12.7201C22.24 7.02056 17.6196 2.40002 11.92 2.40002M22.24 12.7201H14.1601M11.92 23.0401C6.22042 23.0401 1.60001 18.4197 1.60001 12.7201M11.92 23.0401L11.9201 14.9602M1.60001 12.7201C1.60001 7.02056 6.22042 2.40002 11.92 2.40002M1.60001 12.7201H9.68008M11.92 2.40002L11.9201 10.4801M14.1601 12.7201C14.1601 13.9573 13.1572 14.9602 11.9201 14.9602M14.1601 12.7201C14.1601 11.483 13.1572 10.4801 11.9201 10.4801M11.9201 14.9602C10.683 14.9602 9.68008 13.9573 9.68008 12.7201M9.68008 12.7201C9.68008 11.483 10.683 10.4801 11.9201 10.4801"
        stroke="#6C6C6C"
      />
      <path
        d="M11.92 23.0401L11.9201 14.9602C11.5975 14.9602 11.2909 14.892 11.0138 14.7692L7.76535 22.1697C9.03649 22.7293 10.442 23.0401 11.92 23.0401Z"
        fill="#007FB6"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderD = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M18 20.0443L8.07923 9.96503C6.37711 11.6699 5.01393 13.7132 4.09938 15.9854L17.2075 21.2991C17.3907 20.8302 17.6616 20.4052 18 20.0443Z"
        fill="#DB3C48"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderDc = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M2.7998 22.7252H16.9399C16.9399 22.2221 17.0347 21.7411 17.2075 21.2991L4.09938 15.9854C3.26115 18.068 2.7998 20.3427 2.7998 22.7252Z"
        fill="#C62E85"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderDi = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M8.07923 9.96503L18 20.0443C18.3573 19.6633 18.7898 19.3538 19.2739 19.1393L14 6.01345C11.7668 6.93112 9.75814 8.28341 8.07923 9.96503Z"
        fill="#E5561F"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderDI = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M20.86 18.8052L20.8598 4.66504C18.4314 4.66504 16.115 5.14432 14 6.01345L19.2739 19.1393C19.7588 18.9245 20.2955 18.8052 20.86 18.8052Z"
        fill="#F48119"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderI = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M23.7199 20.0443L33.6404 9.96503C32.0651 8.38718 30.1994 7.09928 28.1304 6.18828L22.446 19.1393C22.9301 19.3538 23.3626 19.6633 23.7199 20.0443Z"
        fill="#E5BE00"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderId = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M20.8598 4.66504L20.86 18.8052C21.4245 18.8052 21.9611 18.9245 22.446 19.1393L28.1304 6.18828C25.9059 5.20881 23.4464 4.66504 20.8598 4.66504Z"
        fill="#FFB727"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderIs = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M33.6404 9.96503L23.7199 20.0443C24.0583 20.4052 24.3292 20.8302 24.5124 21.2991L37.6202 15.9854C36.7057 13.7132 35.3425 11.6699 33.6404 9.96503Z"
        fill="#D6D605"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderIS = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M24.7799 22.7252H38.9198C38.9198 20.3427 38.4584 18.068 37.6202 15.9854L24.5124 21.2991C24.6852 21.7411 24.7799 22.2221 24.7799 22.7252Z"
        fill="#AFD80A"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderS = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M23.7199 25.4062L33.6404 35.4853C35.3425 33.7805 36.7057 31.7372 37.6202 29.465L24.5124 24.1514C24.3292 24.6203 24.0583 25.0453 23.7199 25.4062Z"
        fill="#11B21B"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderSc = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M33.6404 35.4853L23.7199 25.4062C23.3626 25.7872 22.9301 26.0967 22.446 26.3112L28.1304 39.2619C30.1994 38.351 32.0651 37.0631 33.6404 35.4853Z"
        fill="#51B48E"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderSC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.24 12.7201C22.24 18.4197 17.6196 23.0401 11.92 23.0401M22.24 12.7201C22.24 7.02056 17.6196 2.40002 11.92 2.40002M22.24 12.7201H14.1601M11.92 23.0401C6.22042 23.0401 1.60001 18.4197 1.60001 12.7201M11.92 23.0401L11.9201 14.9602M1.60001 12.7201C1.60001 7.02056 6.22042 2.40002 11.92 2.40002M1.60001 12.7201H9.68008M11.92 2.40002L11.9201 10.4801M14.1601 12.7201C14.1601 13.9573 13.1572 14.9602 11.9201 14.9602M14.1601 12.7201C14.1601 11.483 13.1572 10.4801 11.9201 10.4801M11.9201 14.9602C10.683 14.9602 9.68008 13.9573 9.68008 12.7201M9.68008 12.7201C9.68008 11.483 10.683 10.4801 11.9201 10.4801"
        stroke="#6C6C6C"
      />
      <path
        d="M11.9201 14.9602L11.92 23.0401C13.398 23.0401 14.8035 22.7293 16.0747 22.1697L12.8264 14.7692C12.5493 14.892 12.2427 14.9602 11.9201 14.9602Z"
        fill="#00B6BC"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const renderSi = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9198 22.7252C38.9198 32.6995 30.8341 40.7851 20.8598 40.7851M38.9198 22.7252C38.9198 12.751 30.8341 4.66504 20.8598 4.66504M38.9198 22.7252H24.7799M20.8598 40.7851C10.8855 40.7851 2.7998 32.6995 2.7998 22.7252M20.8598 40.7851L20.86 26.6453M2.7998 22.7252C2.7998 12.751 10.8855 4.66504 20.8598 4.66504M2.7998 22.7252H16.9399M20.8598 4.66504L20.86 18.8052M24.7799 22.7252C24.7799 24.8902 23.0249 26.6453 20.86 26.6453M24.7799 22.7252C24.7799 20.5603 23.0249 18.8052 20.86 18.8052M20.86 26.6453C18.695 26.6453 16.9399 24.8902 16.9399 22.7252M16.9399 22.7252C16.9399 20.5603 18.695 18.8052 20.86 18.8052"
        stroke="#6C6C6C"
      />
      <path
        d="M38.9198 22.7252H24.7799C24.7799 23.2284 24.6852 23.7094 24.5124 24.1514L37.6202 29.465C38.4584 27.3825 38.9198 25.1078 38.9198 22.7252Z"
        fill="#88C100"
        fillOpacity="0.35"
      />
    </svg>
  )
}

const svgMap = new Map()
svgMap.set('DI', renderDI)
svgMap.set('Id', renderId)
svgMap.set('I', renderI)
svgMap.set('Is', renderIs)
svgMap.set('IS', renderIS)
svgMap.set('Si', renderSi)
svgMap.set('S', renderS)
svgMap.set('Sc', renderSc)
svgMap.set('SC', renderSC)
svgMap.set('Cs', renderCs)
svgMap.set('C', renderC)
svgMap.set('Cd', renderCd)
svgMap.set('CD', renderCD)
svgMap.set('Dc', renderDc)
svgMap.set('D', renderD)
svgMap.set('Di', renderDi)

export default class MonochromeDiscSVG extends React.Component<OwnProps> {
  render() {
    const { discType } = this.props
    return svgMap.get(discType || 'D')()
  }
}
