import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookFeedbackSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses(
      'renderPlaybookFeedback',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 133.353 135.621', discType, color)}>
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${a},.${b},.${d}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linejoin:round;}.${a},.${d}{stroke-width:1.929px;}.${b}{stroke-linecap:round;stroke-width:2px;}.${c}{opacity:0.1;}.${d}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-124.035 -1643.282)">
          <g transform="translate(125 1644.25)">
            <path
              className={a}
              d="M2845.309,1086.645s1.625-11.768-3.049-16.578a26.7,26.7,0,0,0-10.741-4.718l-.468.467a19.018,19.018,0,0,1-26.86-.467,27.169,27.169,0,0,0-10.742,4.718c-2.787,3.677-2.2,7.248-2.787,16.309C2790.662,1086.376,2811.76,1099.725,2845.309,1086.645Z"
              transform="translate(-2790.662 -1030.453)"
            />
            <path
              className={a}
              d="M2827.914,1054.067c0,7.552-4.831,18.681-14.043,18.681s-14.027-11.129-14.027-18.681a14.043,14.043,0,0,1,28.037,0"
              transform="translate(-2786.767 -1040.847)"
            />
            <path
              className={b}
              d="M2831.991,1049.978h42.282V1091.9"
              transform="translate(-2773.13 -1036.973)"
            />
            <path
              className={b}
              d="M2883.239,1069.055l-16.613,16.613-16.612-16.613"
              transform="translate(-2765.484 -1028.88)"
            />
            <path
              className={b}
              d="M2847.153,1141.884h-38.525v-41.925"
              transform="translate(-2783.041 -1015.771)"
            />
            <path
              className={b}
              d="M2796.964,1115.266l16.613-16.613,16.613,16.613"
              transform="translate(-2787.989 -1016.324)"
            />
            <g transform="translate(73.682 68.912)">
              <path
                className={a}
                d="M2897.046,1135.032s1.624-11.77-3.051-16.579a26.72,26.72,0,0,0-10.74-4.718l-.467.467a19.02,19.02,0,0,1-26.862-.467,27.18,27.18,0,0,0-10.741,4.718c-2.787,3.677-2.2,7.249-2.787,16.31C2842.4,1134.763,2863.5,1148.112,2897.046,1135.032Z"
                transform="translate(-2842.397 -1078.839)"
              />
              <path
                className={a}
                d="M2879.648,1102.453c0,7.553-4.831,18.68-14.043,18.68s-14.025-11.127-14.025-18.68a14.042,14.042,0,0,1,28.035,0"
                transform="translate(-2838.502 -1089.233)"
              />
            </g>
          </g>
          <g className={c} transform="translate(127.811 1647.061)">
            <path
              className={d}
              d="M2845.309,1086.645s1.625-11.768-3.049-16.578a26.7,26.7,0,0,0-10.741-4.718l-.468.467a19.018,19.018,0,0,1-26.86-.467,27.169,27.169,0,0,0-10.742,4.718c-2.787,3.677-2.2,7.248-2.787,16.309C2790.662,1086.376,2811.76,1099.725,2845.309,1086.645Z"
              transform="translate(-2790.662 -1030.453)"
            />
            <path
              className={d}
              d="M2827.914,1054.067c0,7.552-4.831,18.681-14.043,18.681s-14.027-11.129-14.027-18.681a14.043,14.043,0,0,1,28.037,0"
              transform="translate(-2786.767 -1040.847)"
            />
          </g>
          <g className={c} transform="translate(201.493 1715.973)">
            <path
              className={d}
              d="M2897.046,1135.032s1.624-11.77-3.051-16.579a26.72,26.72,0,0,0-10.74-4.718l-.467.467a19.02,19.02,0,0,1-26.862-.467,27.18,27.18,0,0,0-10.741,4.718c-2.787,3.677-2.2,7.249-2.787,16.31C2842.4,1134.763,2863.5,1148.112,2897.046,1135.032Z"
              transform="translate(-2842.397 -1078.839)"
            />
            <path
              className={d}
              d="M2879.648,1102.453c0,7.553-4.831,18.68-14.043,18.68s-14.025-11.127-14.025-18.68a14.042,14.042,0,0,1,28.035,0"
              transform="translate(-2838.502 -1089.233)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
