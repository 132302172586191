import React from 'react'

import { getDiscHex, getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class LargeDISCSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        {...getBasicClasses('0 0 150 151', discType, color)}
        fill="none"
        stroke={getDiscHex(discType, color)}
      >
        <path
          d="M130.473 30.956L120.862 21.3453L125.668 16.5399C127.063 15.1448 129.233 15.1448 130.628 16.5399L135.279 21.1902C136.674 22.5853 136.674 24.7555 135.279 26.1506L130.473 30.956Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6551 120.552L31.2659 130.163L26.4604 134.969C25.0653 136.364 22.8952 136.364 21.5001 134.969L16.8498 130.318C15.4547 128.923 15.4547 126.753 16.8498 125.358L21.6551 120.552Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.139 60.4081L89.5502 12.8196C88.4651 11.7345 88.4651 10.0294 89.5502 8.94433L96.6807 1.81381C97.7657 0.72873 99.4709 0.72873 100.556 1.81381L148.144 49.4023C149.229 50.4874 149.229 52.1925 148.144 53.2776L141.014 60.4081C139.929 61.4932 138.224 61.4932 137.139 60.4081Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M130.628 76.3742L73.8941 19.6401C72.809 18.555 72.809 16.8499 73.8941 15.7648L81.0246 8.63429C82.1096 7.54921 83.8148 7.54921 84.8998 8.63429L141.634 65.3684C142.719 66.4535 142.719 68.1586 141.634 69.2437L134.503 76.3742C133.418 77.4593 131.713 77.4593 130.628 76.3742Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8196 89.8602L60.408 137.449C61.4931 138.534 61.4931 140.239 60.408 141.324L53.2775 148.455C52.1924 149.54 50.4873 149.54 49.4022 148.455L1.81381 100.866C0.72873 99.7809 0.72873 98.0758 1.81381 96.9907L8.94429 89.8602C10.0294 88.7751 11.7345 88.7751 12.8196 89.8602Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.33 73.8941L76.0641 130.628C77.1492 131.713 77.1492 133.418 76.0641 134.504L68.9336 141.634C67.8485 142.719 66.1434 142.719 65.0583 141.634L8.32422 85.0549C7.23914 83.9698 7.23914 82.2647 8.32422 81.1796L15.4547 74.0491C16.5398 72.964 18.2449 72.964 19.33 73.8941Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M98.5408 44.2869L103.811 49.5573L107.841 53.5876L53.7427 107.687L44.442 98.3859L98.5408 44.2869Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}
