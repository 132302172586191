import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class MuscleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('MuscleSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 107.091 131.858', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-96.62 -8843.06)">
          <g transform="translate(85.417 8840.732)">
            <path
              className={a}
              d="M13.785,83.792s29.386-25.245,69.634,3.923c0,0-.549-18.319,3.625-29.964,2.35-6.557,3.088-19.3,3.844-27.359,0,0-21.872-7.561-10.243-18.7"
              transform="translate(0.38 2.641)"
            />
            <path
              className={a}
              d="M12.583,124.832s24.645,15.6,49.064,1.357c0,0,21.7,3.823,37.081-2.713,2.9-1.23,9.408,1.613,13.266-4.114,3.037-4.508,4.221-18.514,5.049-25.054,2.261-17.862-8.065-40.86-8.781-53.215,0,0-2.079-13.782-.1-25.851a6.653,6.653,0,0,0-3.667-7.08A19.238,19.238,0,0,1,100.764,5.9c-3.392-2.712-12.437-4.069-16.281,0s-5.2,8.367,1.357,11.531S92.4,12.909,92.4,12.909"
              transform="translate(0 0)"
            />
          </g>
          <path
            className={b}
            d="M191.453,20.246c-6.559-3.166-5.2-7.465-1.357-11.537s12.895-2.713,16.289,0a19.248,19.248,0,0,0,3.736,2.263,6.656,6.656,0,0,1,3.669,7.083c-1.984,12.076.1,25.864.1,25.864.716,12.361,11.047,35.37,8.786,53.241-.828,6.543-2.012,20.556-5.052,25.066-3.86,5.73-10.375,2.885-13.272,4.117-15.386,6.538-37.1,2.713-37.1,2.713-24.432,14.252-49.089-1.357-49.089-1.357l1.583-38.418s29.4-25.257,69.668,3.925c0,0-.549-18.328,3.627-29.978,2.351-6.561,3.09-19.309,3.846-27.373,0,0-21.883-7.565-10.248-18.71"
            transform="translate(-19.368 8839.86)"
          />
        </g>
      </svg>
    )
  }
}
