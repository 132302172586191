import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookLeaderSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookLeader', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 115.682 134.937', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.936px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-134.031 -2387.032)">
          <g transform="translate(135 2388)">
            <path
              className={a}
              d="M7280.039,942.49A11.388,11.388,0,1,1,7268.65,931.1,11.387,11.387,0,0,1,7280.039,942.49Z"
              transform="translate(-7211.377 -910.736)"
            />
            <circle
              className={a}
              cx="9.277"
              cy="9.277"
              r="9.277"
              transform="translate(6.075 18.579)"
            />
            <circle
              className={a}
              cx="9.277"
              cy="9.277"
              r="9.277"
              transform="translate(89.114 18.579)"
            />
            <path
              className={a}
              d="M7245.426,947.3h-15.474a7.03,7.03,0,0,0-7.028,7.2l.372,15.22c.354,2.67,2.891,4.714,6.022,5.01l1.43,33.9h14.12l2.446-33.9c3.095-.325,5.589-2.357,5.943-5l.37-15.22a7.031,7.031,0,0,0-7.028-7.2Z"
              transform="translate(-7222.922 -905.29)"
            />
            <path
              className={a}
              d="M7307.57,947.3H7292.1a7.029,7.029,0,0,0-7.027,7.2l.37,15.22c.354,2.67,2.891,4.714,6.023,5.01l1.429,33.9h14.119l2.448-33.9c3.094-.325,5.589-2.357,5.942-5l.37-15.22a7.029,7.029,0,0,0-7.028-7.2Z"
              transform="translate(-7202.028 -905.29)"
            />
            <path
              className={a}
              d="M7280.52,952h-20.7a9.406,9.406,0,0,0-9.4,9.634l.5,20.358c.476,3.572,3.867,6.306,8.057,6.7l1.913,45.344h18.885l3.275-45.352c4.138-.433,7.476-3.152,7.948-6.695l.5-20.358a9.4,9.4,0,0,0-9.4-9.634Z"
              transform="translate(-7213.678 -903.71)"
            />
            <line
              className={a}
              x2="12.59"
              y2="8.832"
              transform="translate(25.392 14.135)"
            />
            <line
              className={a}
              x1="12.59"
              y2="8.832"
              transform="translate(74.241 14.135)"
            />
            <line className={a} y1="10.192" transform="translate(56.832 0)" />
          </g>
          <g className={b} transform="translate(137.672 2409.251)">
            <path
              className={c}
              d="M7280.039,942.49A11.388,11.388,0,1,1,7268.65,931.1,11.387,11.387,0,0,1,7280.039,942.49Z"
              transform="translate(-7211.377 -929.315)"
            />
            <circle
              className={c}
              cx="9.277"
              cy="9.277"
              r="9.277"
              transform="translate(6.075 0)"
            />
            <path
              className={c}
              d="M7245.426,947.3h-15.474a7.03,7.03,0,0,0-7.028,7.2l.372,15.22c.354,2.67,2.891,4.714,6.022,5.01l1.43,33.9h14.12l2.446-33.9c3.095-.325,5.589-2.357,5.943-5l.37-15.22a7.031,7.031,0,0,0-7.028-7.2Z"
              transform="translate(-7222.922 -923.869)"
            />
            <path
              className={c}
              d="M7280.52,952h-20.7a9.406,9.406,0,0,0-9.4,9.634l.5,20.358c.476,3.572,3.867,6.306,8.057,6.7l1.913,45.344h18.885l3.275-45.352c4.138-.433,7.476-3.152,7.948-6.695l.5-20.358a9.4,9.4,0,0,0-9.4-9.634Z"
              transform="translate(-7213.678 -922.289)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
