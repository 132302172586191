import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class BarChartWithLineChartSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('BarChartWithLineChartSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.5px;}`}</style>
        </defs>
        <path
          className={a}
          d="M96.54,92.34h-90a1,1,0,0,1-1-1V6.63a1,1,0,0,1,2,0V90.34h89a1,1,0,0,1,0,2Z"
        />
        <path
          className={a}
          d="M32.93,91.46a1,1,0,0,1-1-1v-64H21.2v64a1,1,0,0,1-2,0v-65a1,1,0,0,1,1-1H32.93a1,1,0,0,1,1,1v65A1,1,0,0,1,32.93,91.46Z"
        />
        <path
          className={a}
          d="M59.69,91.46a1,1,0,0,1-1-1V44.15H48V90.46a1,1,0,0,1-2,0V43.15a1,1,0,0,1,1-1H59.69a1,1,0,0,1,1,1V90.46A1,1,0,0,1,59.69,91.46Z"
        />
        <path
          className={a}
          d="M86.45,91.46a1,1,0,0,1-1-1V31.92H74.72V90.46a1,1,0,1,1-2,0V30.92a1,1,0,0,1,1-1H86.45a1,1,0,0,1,1,1V90.46A1,1,0,0,1,86.45,91.46Z"
        />
        <path
          className={a}
          d="M26.57,14.94a4.1,4.1,0,1,1,4.1-4.1A4.11,4.11,0,0,1,26.57,14.94Zm0-6.2a2.1,2.1,0,1,0,2.1,2.1A2.1,2.1,0,0,0,26.57,8.74Z"
        />
        <path
          className={a}
          d="M52.88,35.68a4.1,4.1,0,1,1,4.1-4.1A4.11,4.11,0,0,1,52.88,35.68Zm0-6.2a2.1,2.1,0,1,0,2.1,2.1A2.1,2.1,0,0,0,52.88,29.48Z"
        />
        <path
          className={a}
          d="M80.08,20.46a4.1,4.1,0,1,1,4.1-4.1A4.11,4.11,0,0,1,80.08,20.46Zm0-6.2a2.1,2.1,0,1,0,2.1,2.1A2.11,2.11,0,0,0,80.08,14.26Z"
        />
        <path
          className={a}
          d="M49.78,32.58a1,1,0,0,1-.69-.28L29,13.07a1,1,0,0,1,1.39-1.45L50.47,30.86a1,1,0,0,1-.69,1.72Z"
        />
        <path
          className={a}
          d="M56,32.58a1,1,0,0,1-.84-.46,1,1,0,0,1,.3-1.38l21.35-13.8a1,1,0,1,1,1.08,1.68L56.53,32.42A1,1,0,0,1,56,32.58Z"
        />
      </svg>
    )
  }
}
