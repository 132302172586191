import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class SimpleStarTicketSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 29.643 19.48', discType, color)}>
        <g transform="translate(-0.076 -4544.487)">
          <path d="M28.657,4557.976a3.274,3.274,0,1,1-.046-6.5h.046c.021,0,.041,0,.062,0v-5.989H1.076v5.984c2.643,0,3.63,1.428,3.643,3.224s-1,3.28-3.6,3.28H1.076v4.992H28.718v-5C28.7,4557.972,28.678,4557.976,28.657,4557.976Z" />
          <path d="M16.9,4548.8l1.731,3.509,3.872.562-2.8,2.732.662,3.855-3.464-1.82-3.463,1.82.662-3.855-2.8-2.732,3.872-.562Z" />
          <line y2="17" transform="translate(7.719 4545.967)" />
        </g>
      </svg>
    )
  }
}
