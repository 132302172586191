import React from 'react'

import { getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class LongArrowSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 91 24', discType, color)}>
        <path
          d="M90.0607 13.0607C90.6464 12.4749 90.6464 11.5251 90.0607 10.9393L80.5147 1.3934C79.9289 0.807611 78.9792 0.807611 78.3934 1.3934C77.8076 1.97919 77.8076 2.92893 78.3934 3.51472L86.8787 12L78.3934 20.4853C77.8076 21.0711 77.8076 22.0208 78.3934 22.6066C78.9792 23.1924 79.9289 23.1924 80.5147 22.6066L90.0607 13.0607ZM0 13.5H89V10.5H0V13.5Z"
          fill={getDiscHex(discType, color)}
        />
      </svg>
    )
  }
}
