import React, { FC } from 'react'
import classNameBind from 'classnames/bind'

import css from './styles.module.scss'

const classNames = classNameBind.bind(css)

interface OwnProps {
  htmlFor?: string
  text?: string
  subtitle?: string
  subtitleUnder?: boolean
  error?: string | null
  light?: boolean
  titleError?: boolean
  optional?: boolean
  children?: React.ReactNode,
  className?: string
}

const Label: FC<OwnProps> = ({
  children,
  htmlFor,
  text,
  subtitle,
  subtitleUnder,
  error,
  light,
  titleError,
  optional,
  className,
}: OwnProps) => {
  // Partials
  const renderTitle = () => {
    if (!text) return null

    return (
      <div className={classNames('title', { titleError })}>
        {text}
        {children}
        {renderOptionalTag()}
      </div>
    )
  }

  const renderOptionalTag = () => {
    if (!optional) {
      return null
    }

    return <div className={classNames('optionalTag')}>Optional</div>
  }

  const renderSubtitle = () => {
    return (
      <div className={classNames('subtitle', { subtitleUnder })}>
        {subtitle}
      </div>
    )
  }

  const renderError = () => {
    if (!error) return null

    return <span className={classNames('error')}>{error}</span>
  }

  return (
    <label
      htmlFor={htmlFor}
      className={classNames('container', { light }, className)}
    >
      <div className={classNames('title-container', { subtitleUnder })}>
        {renderTitle()}
        {renderSubtitle()}
      </div>
      {renderError()}
    </label>
  )
}

export default Label
