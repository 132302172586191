import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookBlindSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookBlind', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 139.524 134.933', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-111 -189.586)">
          <g transform="translate(112 191)">
            <g transform="translate(0 33.596)">
              <path
                className={a}
                d="M4205.625,857.7s-30.786-36.372-68.763-36.372S4068.1,857.7,4068.1,857.7s30.786,36.949,68.761,36.949S4205.625,857.7,4205.625,857.7Z"
                transform="translate(-4068.101 -821.326)"
              />
              <path
                className={a}
                d="M4149.544,854.669a25.855,25.855,0,1,0-25.856,25.856A25.835,25.835,0,0,0,4149.544,854.669Z"
                transform="translate(-4054.927 -818.008)"
              />
              <path
                className={a}
                d="M4205.625,857.7s-30.786-36.372-68.763-36.372S4068.1,857.7,4068.1,857.7s30.786,36.949,68.761,36.949S4205.625,857.7,4205.625,857.7Z"
                transform="translate(-4068.101 -821.326)"
              />
            </g>
            <line
              className={a}
              x1="132.105"
              y2="132.105"
              transform="translate(3.976 0)"
            />
            <path
              className={b}
              d="M4149.544,854.669a25.855,25.855,0,1,0-25.856,25.856A25.835,25.835,0,0,0,4149.544,854.669Z"
              transform="translate(-4052.04 -781.526)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
