import React from 'react'
import classNamesBind from 'classnames/bind'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  discType?: string
}

export default class ThumbsUp extends React.Component<OwnProps> {
  render() {
    const { discType } = this.props

    return (
      <svg
        className={classNames('container')}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-testid="thumbs-up-icon"
      >
        <path
          className={classNames(`${discType}`)}
          d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"
        ></path>
      </svg>
    )
  }
}
