import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class SimpleGiftSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('SimpleGiftSVG', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 22.87 30.087', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}</style>
        </defs>
        <g transform="translate(-3.946 -4585.88)">
          <rect
            className={a}
            width="20.87"
            height="23"
            transform="translate(4.946 4591.967)"
          />
          <line
            className={a}
            y2="22.568"
            transform="translate(12.987 4592.398)"
          />
          <line
            className={a}
            y2="22.568"
            transform="translate(18.17 4592.398)"
          />
          <path className={a} d="M7.821,4589.235" />
          <path
            className={a}
            d="M14.719,4591.967a11.339,11.339,0,0,0-5.523,4.017c-1.477,1.983-1.8,5.65-1.8,5.65"
          />
          <path
            className={a}
            d="M15.4,4591.967a16.11,16.11,0,0,1,5.524,4.017c1.8,1.983,1.8,5.65,1.8,5.65"
          />
          <path
            className={a}
            d="M15.345,4590.655c-.466,1-2.657.967-4.023.331s-2.095-1.962-1.63-2.963,1.951-1.3,3.317-.66S15.811,4589.654,15.345,4590.655Z"
          />
          <path
            className={a}
            d="M16.641,4590.655c.466,1,2.658.967,4.023.331s2.1-1.962,1.63-2.963-1.951-1.3-3.316-.66S16.176,4589.654,16.641,4590.655Z"
          />
        </g>
      </svg>
    )
  }
}
