import React from 'react'

const CubeSVG = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1998 12.6984V6.29836C16.1995 6.01778 16.1255 5.74221 15.985 5.49929C15.8446 5.25637 15.6428 5.05465 15.3998 4.91436L9.7998 1.71436C9.55657 1.57393 9.28066 1.5 8.9998 1.5C8.71895 1.5 8.44304 1.57393 8.1998 1.71436L2.5998 4.91436C2.35681 5.05465 2.15499 5.25637 2.01457 5.49929C1.87416 5.74221 1.80009 6.01778 1.7998 6.29836V12.6984C1.80009 12.9789 1.87416 13.2545 2.01457 13.4974C2.15499 13.7403 2.35681 13.9421 2.5998 14.0824L8.1998 17.2824C8.44304 17.4228 8.71895 17.4967 8.9998 17.4967C9.28066 17.4967 9.55657 17.4228 9.7998 17.2824L15.3998 14.0824C15.6428 13.9421 15.8446 13.7403 15.985 13.4974C16.1255 13.2545 16.1995 12.9789 16.1998 12.6984Z"
        stroke="#55aef6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.7998 6.2998L8.7838 10.3398L15.7678 6.2998"
        stroke="#55aef6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17.4998V10.2998"
        stroke="#55aef6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CubeSVG
