import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookCommunicateSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d, e, f, g, h, i } = getSVGClasses(
      'PlaybookCommunicateSVG',
      discType,
      color,
    )

    return (
      <svg width="100%" height="100%" viewBox="0 0 233.489 217.835">
        <defs>
          <style>{`.${a},.${d},.${e}{fill:none;}.${a},.${b},.${d},.${e},.${h},.${i}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-width:3px;}.${b},.${h},.${i}{fill:${getDiscHex(
            discType,
            color,
          )};}.${b}{clip-path:url(#a);}.${d},.${h}{stroke-linecap:round;stroke-linejoin:round;}.${e},.${i}{stroke-miterlimit:10;}.${f}{opacity:0.1;}.${g}{clip-path:url(#b);}`}</style>
          <clipPath id="a">
            <circle className={a} cx="60.246" cy="60.246" r="60.246" />
          </clipPath>
          <clipPath id="b">
            <circle className={b} cx="60.246" cy="60.246" r="60.246" />
          </clipPath>
        </defs>
        <g transform="translate(-1254.175 -5689)">
          <g transform="translate(-1246.882 1833.617)">
            <g transform="translate(2556.054 3942.59)">
              <g className={c}>
                <path
                  className={d}
                  d="M2605.749,3970.716h-64.778c-6.346,0-9.414-9.231-7.634-15.322a43.347,43.347,0,0,1,83.193,0C2618.31,3961.484,2612.1,3970.716,2605.749,3970.716Z"
                  transform="translate(-2514.688 -3847.078)"
                />
                <circle
                  className={e}
                  cx="22.042"
                  cy="22.042"
                  r="22.042"
                  transform="translate(38.204 21.051)"
                />
              </g>
            </g>
            <circle
              className={d}
              cx="57.493"
              cy="57.493"
              r="57.493"
              transform="translate(2558.807 3950.233)"
            />
            <path
              className={d}
              d="M2642.96,3906.773a85.775,85.775,0,0,0-116.971.454"
              transform="translate(31.583 37.982)"
            />
            <path
              className={d}
              d="M2674.129,3901.729a115.97,115.97,0,0,0-156.1,0"
              transform="translate(20.218 20.219)"
            />
            <path
              className={d}
              d="M2728.72,3900.872a167.045,167.045,0,0,0-224.838,0"
              transform="translate(0 0)"
            />
          </g>
          <g className={f} transform="translate(-1243.882 1839.617)">
            <g transform="translate(2556.054 3942.59)">
              <g className={g}>
                <path
                  className={h}
                  d="M2605.749,3970.716h-64.778c-6.346,0-9.414-9.231-7.634-15.322a43.347,43.347,0,0,1,83.193,0C2618.31,3961.484,2612.1,3970.716,2605.749,3970.716Z"
                  transform="translate(-2514.688 -3847.078)"
                />
                <circle
                  className={i}
                  cx="22.042"
                  cy="22.042"
                  r="22.042"
                  transform="translate(38.204 21.051)"
                />
              </g>
            </g>
            <circle
              className={h}
              cx="57.493"
              cy="57.493"
              r="57.493"
              transform="translate(2558.807 3950.233)"
            />
            <path
              className={d}
              d="M2642.96,3906.773a85.775,85.775,0,0,0-116.971.454"
              transform="translate(31.583 37.982)"
            />
            <path
              className={d}
              d="M2674.129,3901.729a115.97,115.97,0,0,0-156.1,0"
              transform="translate(20.218 20.219)"
            />
            <path
              className={d}
              d="M2728.72,3900.872a167.045,167.045,0,0,0-224.838,0"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
