import { getBasicClasses, getDiscHex } from '@dashboard/lib/svg'
import React from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const TrophyWithProfileSVG: React.FC<OwnProps> = ({
  discType,
  color,
}: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 98 96', discType, color)}>
      <g>
        <path
          d="M16.796 1V34.4C16.796 38.546 17.6126 42.6513 19.1992 46.4817C20.7858 50.3121 23.1113 53.7924 26.043 56.7241C28.9746 59.6557 32.455 61.9812 36.2853 63.5678C40.1157 65.1544 44.2211 65.971 48.367 65.971C52.513 65.971 56.6183 65.1544 60.4487 63.5678C64.2791 61.9812 67.7594 59.6557 70.6911 56.7241C73.6227 53.7924 75.9482 50.3121 77.5348 46.4817C79.1214 42.6513 79.938 38.546 79.938 34.4V1H16.796Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.643 64.543C53.98 73.884 57.643 83.225 57.643 83.225H38.961C38.961 83.225 43.626 73.884 38.961 64.543"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.266 93.632H21.911L26.811 83.225H69.363L74.266 93.632Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.193 12.25H95.622C95.622 31.729 85.574 47.521 77.053 47.521"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.377 18.792H87.897C87.897 29.429 84.382 35.566 80.212 38.053"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.429 12.25H1C1 31.729 11.048 47.516 19.569 47.516"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.85 19.256H8.33002C8.33002 29.893 11.845 36.029 16.015 38.517"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.255 33.484C52.9296 33.484 56.719 29.6946 56.719 25.02C56.719 20.3455 52.9296 16.556 48.255 16.556C43.5805 16.556 39.791 20.3455 39.791 25.02C39.791 29.6946 43.5805 33.484 48.255 33.484Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.297 45.116C35.7453 42.8049 37.7447 40.8896 40.1159 39.542C42.4871 38.1944 45.1558 37.4566 47.8824 37.395C50.6091 37.3333 53.3084 37.9497 55.7381 39.1887C58.1678 40.4278 60.2518 42.2507 61.803 44.494"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.09 50.43C59.4367 50.43 68.635 41.2317 68.635 29.885C68.635 18.5383 59.4367 9.33997 48.09 9.33997C36.7433 9.33997 27.545 18.5383 27.545 29.885C27.545 41.2317 36.7433 50.43 48.09 50.43Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.15"
          d="M50.3669 67.9688C46.1453 67.9769 41.9655 67.1331 38.0778 65.4878C34.3264 63.9028 30.9187 61.6046 28.0431 58.7207C25.1593 55.8452 22.861 52.4375 21.2761 48.686C19.631 44.7983 18.7872 40.6185 18.7951 36.397V2.99707H81.9382V36.397C81.9463 40.6185 81.1025 44.7983 79.4572 48.686C77.8723 52.4375 75.574 55.8452 72.6901 58.7207C69.8145 61.6044 66.4063 63.9027 62.655 65.4878C58.7676 67.133 54.5881 67.9768 50.3669 67.9688ZM50.091 11.3398C47.3428 11.3342 44.6218 11.8837 42.091 12.9551C39.6499 13.9855 37.4321 15.4797 35.5602 17.355C33.685 19.227 32.1904 21.4447 31.1598 23.8857C30.0885 26.4165 29.5394 29.1375 29.5451 31.8857C29.5394 34.6339 30.0885 37.355 31.1598 39.8857C32.1903 42.3269 33.6849 44.5451 35.5602 46.417C37.4322 48.2922 39.6499 49.7864 42.091 50.8169C44.6218 51.888 47.3428 52.4376 50.091 52.4321C52.8392 52.4376 55.5602 51.888 58.091 50.8169C60.532 49.7863 62.7502 48.2922 64.6222 46.417C66.4976 44.5451 67.9917 42.3269 69.0221 39.8857C70.0935 37.355 70.6426 34.6339 70.6369 31.8857C70.6426 29.1375 70.0935 26.4165 69.0221 23.8857C67.9916 21.4447 66.4974 19.227 64.6222 17.355C62.7502 15.4798 60.532 13.9856 58.091 12.9551C55.5602 11.8837 52.8392 11.3341 50.091 11.3398Z"
          fill={getDiscHex(discType, color)}
        />
        <g opacity="0.15">
          <path
            opacity="0.15"
            d="M59.6429 66.543C55.9799 75.884 59.6429 85.225 59.6429 85.225H40.9609C40.9609 85.225 45.6259 75.884 40.9609 66.543"
            fill={getDiscHex(discType, color)}
          />
          <path
            opacity="0.15"
            d="M76.266 95.632H23.9109L28.8109 85.225H71.363L76.266 95.632Z"
            fill={getDiscHex(discType, color)}
          />
          <path
            opacity="0.15"
            d="M79.054 49.522L81.3948 14.1558L97.6218 14.25C97.6283 18.8792 97.0576 23.4912 95.9221 27.979C94.9541 31.8909 93.4794 35.6597 91.5359 39.1899C87.9079 45.6589 83.242 49.521 79.054 49.522ZM82.3767 20.793L82.2122 40.0552C84.6502 38.4747 86.5385 36.1775 87.6179 33.48C89.2418 29.4527 90.0171 25.1336 89.8958 20.793H82.3767Z"
            fill={getDiscHex(discType, color)}
          />
          <path
            opacity="0.15"
            d="M21.5679 49.5229C17.3799 49.5229 12.7139 45.6599 9.08892 39.1919C7.14542 35.6616 5.67122 31.8929 4.70318 27.981C3.56791 23.4931 2.99664 18.8812 3.00298 14.252H19.3648L21.5709 49.5229H21.5679ZM10.3301 21.2559C10.2086 25.5966 10.9843 29.9158 12.6089 33.9429C13.6888 36.6399 15.5771 38.9368 18.0142 40.5181L17.8492 21.2559H10.3301Z"
            fill={getDiscHex(discType, color)}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="97.623" height="95.632" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TrophyWithProfileSVG
