import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class MaximizeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 24 36.854', discType, color)}>
        <g transform="translate(47.781 2917.033)">
          <circle
            cx="11"
            cy="11"
            r="11"
            transform="translate(-46.781 -2916.033)"
          />
          <path
            d="M-30.914-2901.618l-.179,14.64a62.161,62.161,0,0,0-5.955-4.487s-6.2,3.424-7.058,4.382c.067-5.531-.24-10.42-.172-15.95"
            transform="translate(1.112 5.798)"
          />
          <path
            d="M-43.281-2906.7l4.024,4.024,6.1-8.362"
            transform="translate(1.561 2.23)"
          />
        </g>
      </svg>
    )
  }
}
