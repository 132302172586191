import React from 'react'

const ComparisonReportSVG = () => {
  return (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1861 31H1V1H19.0126L24.1861 6.14196V31Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1864 6.14196H19.0444V1L24.1864 6.14196Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.07886 26.1419C6.5841 26.1419 6.99373 25.7323 6.99373 25.2271C6.99373 24.7218 6.5841 24.3123 6.07886 24.3123C5.57361 24.3123 5.16406 24.7218 5.16406 25.2271C5.16406 25.7323 5.57361 26.1419 6.07886 26.1419Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.15479 28.2554C4.56488 27.6245 5.25884 27.1829 6.07903 27.1829C6.86767 27.1829 7.53013 27.5614 7.97177 28.1608"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.04712 28.9812C7.61513 28.9812 8.88624 27.7101 8.88624 26.1421C8.88624 24.5741 7.61513 23.303 6.04712 23.303C4.47912 23.303 3.20801 24.5741 3.20801 26.1421C3.20801 27.7101 4.47912 28.9812 6.04712 28.9812Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2962 26.1419C19.8015 26.1419 20.211 25.7323 20.211 25.2271C20.211 24.7218 19.8015 24.3123 19.2962 24.3123C18.791 24.3123 18.3813 24.7218 18.3813 25.2271C18.3813 25.7323 18.791 26.1419 19.2962 26.1419Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3408 28.2554C17.7509 27.6245 18.4449 27.1829 19.2651 27.1829C20.0537 27.1829 20.7162 27.5614 21.1578 28.1608"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2649 28.9812C20.8329 28.9812 22.104 27.7101 22.104 26.1421C22.104 24.5741 20.8329 23.303 19.2649 23.303C17.6969 23.303 16.4258 24.5741 16.4258 26.1421C16.4258 27.7101 17.6969 28.9812 19.2649 28.9812Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7476 24.7539H15.164"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7476 27.7192H15.164"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5992 20.5582H3.77588V3.77588"
        stroke="#2CA7E4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.01243 20.4006V8.50781H6.48877V20.4006"
        stroke="#2CA7E4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3122 20.4008V6.58374H11.7886V20.4008"
        stroke="#2CA7E4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.612 20.4007V11.8833H17.0884V20.4007"
        stroke="#2CA7E4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ComparisonReportSVG
