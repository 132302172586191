import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class GiftSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses('GiftSVG', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 114.015 138.016', discType, color)}>
        <defs>
          <style>{`.${a},.${d}{fill:none;}.${a}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-101.248 -8197.824)">
          <g transform="translate(-5037.347 7510.623)">
            <path
              className={a}
              d="M5224.174,715.437h20.145V810.27H5143.176V715.437h15.97"
              transform="translate(1.855 13.947)"
            />
            <line
              className={a}
              x1="25.117"
              transform="translate(5184.001 729.384)"
            />
            <path
              className={a}
              d="M5161,721.291H5139.6V704.141H5251.61v17.149h-25.581"
              transform="translate(0 8.094)"
            />
            <line
              className={a}
              x1="8.072"
              transform="translate(5175.93 729.384)"
            />
            <line
              className={a}
              y2="94.833"
              transform="translate(5184.001 729.385)"
            />
            <line
              className={a}
              y2="94.833"
              transform="translate(5209.119 729.385)"
            />
            <path
              className={a}
              d="M7.242,0h7.506a7.242,7.242,0,0,1,7.242,7.242V21.989a0,0,0,0,1,0,0H7.242A7.242,7.242,0,0,1,0,14.747V7.242A7.242,7.242,0,0,1,7.242,0Z"
              transform="translate(5173.007 688.524)"
            />
            <path
              className={a}
              d="M5152.352,695.766"
              transform="translate(6.611 3.753)"
            />
            <path
              className={a}
              d="M5164.22,690.194a10.971,10.971,0,0,1,3.971,8.46v10.994"
              transform="translate(12.76 0.865)"
            />
            <path
              className={a}
              d="M5191.93,688.524c-4.345,1.96-6.121,7.593-6.121,10.995v10.994"
              transform="translate(23.948 0)"
            />
            <path
              className={a}
              d="M7.242,0H21.989a0,0,0,0,1,0,0V14.747a7.242,7.242,0,0,1-7.242,7.242H7.242A7.242,7.242,0,0,1,0,14.747V7.242A7.242,7.242,0,0,1,7.242,0Z"
              transform="translate(5217.589 710.513) rotate(-180)"
            />
            <path
              className={a}
              d="M5189.948,710.513h11a10.994,10.994,0,0,0,10.994-10.994h0a11,11,0,0,0-10.994-10.995h-17.216"
              transform="translate(22.869 0)"
            />
            <path
              className={a}
              d="M5171.894,710.513H5160.9a10.994,10.994,0,0,1-11-10.994h0a10.994,10.994,0,0,1,11-10.995h17.216"
              transform="translate(5.342 0)"
            />
            <path
              className={a}
              d="M5161.261,751.692l-4.205-7.535-8.354,2.16s4.408-17.051,6.723-22.8a74.7,74.7,0,0,1,11.477-19.722l14.874.076s-8.823,12.7-11.806,20.441C5167.293,731.262,5161.261,751.692,5161.261,751.692Z"
              transform="translate(4.72 7.912)"
            />
            <path
              className={a}
              d="M5200.248,751.692l4.2-7.535,8.355,2.16s-4.41-17.051-6.723-22.8a74.7,74.7,0,0,0-11.477-19.722l-14.875.076s8.821,12.7,11.8,20.441C5194.216,731.262,5200.248,751.692,5200.248,751.692Z"
              transform="translate(20.801 7.912)"
            />
          </g>
          <g className={b} transform="translate(-5037.347 7510.623)">
            <path
              className={c}
              d="M5163.8,715.437h5.13V810.27h-25.756V715.437h4.066"
              transform="translate(40.826 13.947)"
            />
            <line
              className={d}
              x1="25.117"
              transform="translate(5184.001 729.384)"
            />
            <line
              className={d}
              x1="8.072"
              transform="translate(5175.93 729.384)"
            />
            <line
              className={d}
              y2="94.833"
              transform="translate(5184.001 729.385)"
            />
            <line
              className={d}
              y2="94.833"
              transform="translate(5209.119 729.385)"
            />
            <path
              className={c}
              d="M5152.352,695.766"
              transform="translate(6.611 3.753)"
            />
            <path
              className={d}
              d="M5191.93,688.524c-4.345,1.96-6.121,7.593-6.121,10.995v10.994"
              transform="translate(23.948 0)"
            />
            <path
              className={c}
              d="M5195.238,699.519l-3.806,11.174c14.642.537,20.5-5.1,20.5-11.174h0a11,11,0,0,0-10.994-10.995h-9.511"
              transform="translate(22.869 0)"
            />
            <path
              className={c}
              d="M5208.131,699.519l3.806,11.174c-14.642.537-20.5-5.1-20.5-11.174h0a10.994,10.994,0,0,1,10.994-10.995h9.511"
              transform="translate(-36.131)"
            />
            <path
              className={c}
              d="M5161.261,751.692l-4.205-7.535-8.354,2.16s4.408-17.051,6.723-22.8a74.7,74.7,0,0,1,11.477-19.722l14.874.076s-8.823,12.7-11.806,20.441C5167.293,731.262,5161.261,751.692,5161.261,751.692Z"
              transform="translate(4.72 7.912)"
            />
            <path
              className={c}
              d="M5200.248,751.692l4.2-7.535,8.355,2.16s-4.41-17.051-6.723-22.8a74.7,74.7,0,0,0-11.477-19.722l-14.875.076s8.821,12.7,11.8,20.441C5194.216,731.262,5200.248,751.692,5200.248,751.692Z"
              transform="translate(20.801 7.912)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
