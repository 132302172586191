import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookTrophySVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookTrophy', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 96.622 94.632"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-732.008 -416.873)">
          <g transform="translate(730.319 411.789)">
            <path
              className={a}
              d="M18.485,6.084v33.4a31.571,31.571,0,1,0,63.142,0V6.084Z"
            />
            <path
              className={a}
              d="M59.332,69.627c-3.663,9.341,0,18.682,0,18.682H40.65s4.665-9.341,0-18.682"
            />
            <path className={a} d="M75.955,98.716H23.6l4.9-10.407H71.052Z" />
            <path
              className={a}
              d="M81.882,17.334H97.311c0,19.479-10.048,35.271-18.569,35.271"
            />
            <path
              className={a}
              d="M82.066,23.876h7.52c0,10.637-3.515,16.774-7.685,19.261"
            />
            <path
              className={a}
              d="M18.118,17.334H2.689C2.689,36.813,12.737,52.6,21.258,52.6"
            />
            <path
              className={a}
              d="M17.539,24.34h-7.52c0,10.637,3.515,16.773,7.685,19.261"
            />
          </g>
          <path
            className={a}
            d="M50.756,15.813l5.749,11.648a.78.78,0,0,0,.587.427l12.854,1.867a.781.781,0,0,1,.433,1.332l-9.3,9.066a.783.783,0,0,0-.225.691l2.2,12.8a.781.781,0,0,1-1.133.823l-11.5-6.044a.777.777,0,0,0-.726,0L38.2,54.469a.78.78,0,0,1-1.132-.823l2.195-12.8a.78.78,0,0,0-.224-.691l-9.3-9.066a.781.781,0,0,1,.432-1.332L43.02,27.888a.783.783,0,0,0,.588-.427l5.748-11.648A.781.781,0,0,1,50.756,15.813Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className={b}
            d="M50.756,15.813l5.749,11.648a.78.78,0,0,0,.587.427l12.854,1.867a.781.781,0,0,1,.433,1.332l-9.3,9.066a.783.783,0,0,0-.225.691l2.2,12.8a.781.781,0,0,1-1.133.823l-11.5-6.044a.777.777,0,0,0-.726,0L38.2,54.469a.78.78,0,0,1-1.132-.823l2.195-12.8a.78.78,0,0,0-.224-.691l-9.3-9.066a.781.781,0,0,1,.432-1.332L43.02,27.888a.783.783,0,0,0,.588-.427l5.748-11.648A.781.781,0,0,1,50.756,15.813Z"
            transform="translate(731.319 412.789)"
          />
        </g>
      </svg>
    )
  }
}
