import React from 'react'
import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class FilterSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 20 19', discType, color)}>
        <path
          d="M11.227 16.3781L8.12778 17.3927C7.96615 17.4456 7.8 17.3252 7.8 17.1551V10.3608C7.8 10.0985 7.69697 9.84673 7.51312 9.65971L1.50837 3.55157C0.57585 2.60298 1.24786 1 2.57805 1H17.2415C18.5967 1 19.2571 2.65473 18.2743 3.58782L11.8829 9.65581C11.684 9.84462 11.5714 10.1068 11.5714 10.381V15.9029C11.5714 16.1191 11.4325 16.3108 11.227 16.3781Z"
          strokeWidth="1.5"
        />
      </svg>
    )
  }
}
