import React from 'react'

import { getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class CrystalLogoSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 209.781 52.1"
        fill={getDiscHex(discType, color)}
      >
        <g transform="translate(-492.639 -165)">
          <path d="M519.089,200.059a1.374,1.374,0,0,1,1.039.445l2.938,3.136a13.974,13.974,0,0,1-5.4,4.2A18.774,18.774,0,0,1,510,209.271a18.275,18.275,0,0,1-7.237-1.37,15.666,15.666,0,0,1-5.458-3.816,16.964,16.964,0,0,1-3.458-5.8,22.7,22.7,0,0,1-.63-12.411,17.857,17.857,0,0,1,1.692-4.433,17.136,17.136,0,0,1,2.68-3.656A16.131,16.131,0,0,1,501.135,175a17.028,17.028,0,0,1,4.31-1.754,19.774,19.774,0,0,1,4.976-.6,19.493,19.493,0,0,1,3.742.346,18.139,18.139,0,0,1,3.3.963,15.135,15.135,0,0,1,2.827,1.495,15.645,15.645,0,0,1,2.334,1.914l-2.493,3.408a2.343,2.343,0,0,1-.569.568,1.584,1.584,0,0,1-.938.247,2.093,2.093,0,0,1-1.2-.42q-.606-.42-1.482-.939a10.786,10.786,0,0,0-2.2-.938,11.242,11.242,0,0,0-3.347-.42,10.249,10.249,0,0,0-4.124.815,8.949,8.949,0,0,0-3.223,2.358,10.885,10.885,0,0,0-2.087,3.792,16.3,16.3,0,0,0-.741,5.112,15.336,15.336,0,0,0,.8,5.15,11.287,11.287,0,0,0,2.2,3.816,9.244,9.244,0,0,0,3.26,2.358,9.961,9.961,0,0,0,3.988.8,19.069,19.069,0,0,0,2.285-.124,9.4,9.4,0,0,0,1.9-.419,8.443,8.443,0,0,0,1.655-.766,10.273,10.273,0,0,0,1.568-1.185,3.007,3.007,0,0,1,.568-.371A1.411,1.411,0,0,1,519.089,200.059Z" />
          <path d="M532.649,187.611a11.408,11.408,0,0,1,2.791-3.433,5.768,5.768,0,0,1,3.705-1.259,4.569,4.569,0,0,1,2.816.815l-.445,5.038a1.135,1.135,0,0,1-.383.679,1.185,1.185,0,0,1-.7.185,8.114,8.114,0,0,1-1.136-.111,9.37,9.37,0,0,0-1.383-.111,4.736,4.736,0,0,0-1.668.272,4.2,4.2,0,0,0-1.3.765,5.5,5.5,0,0,0-1.024,1.21,11.433,11.433,0,0,0-.853,1.631v15.584h-6.816V183.413h4.025a2.108,2.108,0,0,1,1.432.37,2.331,2.331,0,0,1,.569,1.285Z" />
          <path d="M570.462,183.413l-13.732,32.3a2.706,2.706,0,0,1-.778,1.038,2.415,2.415,0,0,1-1.445.345h-5.064l4.817-10.274-10.225-23.413h5.977a1.918,1.918,0,0,1,1.26.37,2.2,2.2,0,0,1,.642.864l4.84,12.176q.273.717.482,1.445t.408,1.445q.444-1.458,1.012-2.914l4.495-12.152a1.8,1.8,0,0,1,.716-.889,2.011,2.011,0,0,1,1.136-.345Z" />
          <path d="M588.418,188.649a1.991,1.991,0,0,1-.556.6,1.407,1.407,0,0,1-.777.186,2.341,2.341,0,0,1-1.013-.247c-.346-.165-.733-.341-1.161-.531a12.625,12.625,0,0,0-1.47-.532,6.732,6.732,0,0,0-1.914-.246,4.238,4.238,0,0,0-2.568.679,2.123,2.123,0,0,0-.914,1.79,1.745,1.745,0,0,0,.519,1.285,4.553,4.553,0,0,0,1.37.9,16.848,16.848,0,0,0,1.939.716q1.086.333,2.235.728a21.284,21.284,0,0,1,2.236.914,7.93,7.93,0,0,1,1.938,1.3,5.963,5.963,0,0,1,1.371,1.878,6.178,6.178,0,0,1,.518,2.655,8.522,8.522,0,0,1-.678,3.42,7.481,7.481,0,0,1-1.989,2.705,9.368,9.368,0,0,1-3.26,1.778,14.992,14.992,0,0,1-9.533-.26,15.243,15.243,0,0,1-2.174-1,9.7,9.7,0,0,1-1.753-1.234l1.58-2.544a1.844,1.844,0,0,1,1.729-.939,2.176,2.176,0,0,1,1.136.309q.519.309,1.161.667a9.81,9.81,0,0,0,1.519.667,6.645,6.645,0,0,0,2.186.308,5.794,5.794,0,0,0,1.7-.222,3.221,3.221,0,0,0,1.161-.6,2.422,2.422,0,0,0,.654-.864,2.483,2.483,0,0,0,.21-1,1.859,1.859,0,0,0-.531-1.371,4.791,4.791,0,0,0-1.383-.926,13.8,13.8,0,0,0-1.963-.716q-1.112-.321-2.26-.716a18.946,18.946,0,0,1-2.247-.939,7.645,7.645,0,0,1-1.952-1.37,6.369,6.369,0,0,1-1.383-2.038,7.3,7.3,0,0,1-.531-2.939,7.427,7.427,0,0,1,.618-3,7.063,7.063,0,0,1,1.852-2.507,9.169,9.169,0,0,1,3.074-1.728,12.9,12.9,0,0,1,4.261-.643,13.209,13.209,0,0,1,4.94.889,10.717,10.717,0,0,1,3.655,2.322Z" />
          <path d="M603.235,209.271a8.6,8.6,0,0,1-3.062-.506,5.942,5.942,0,0,1-3.63-3.717,9.009,9.009,0,0,1-.47-3.013V188.3h-2.4a1.257,1.257,0,0,1-1.309-1.383v-2.668l4.075-.741,1.383-6.594a1.377,1.377,0,0,1,1.507-1.111h3.556v7.755h6.569V188.3H602.89V201.59a2.571,2.571,0,0,0,.506,1.68,1.763,1.763,0,0,0,1.445.617,2.776,2.776,0,0,0,.84-.111,3.41,3.41,0,0,0,.593-.247c.164-.09.316-.173.457-.247a.911.911,0,0,1,.432-.111.86.86,0,0,1,.494.135,1.534,1.534,0,0,1,.4.457l2.05,3.261a10,10,0,0,1-3.186,1.679A12.019,12.019,0,0,1,603.235,209.271Z" />
          <path d="M612.522,186.944a15.252,15.252,0,0,1,4.964-3.025,16.88,16.88,0,0,1,5.878-1,10.525,10.525,0,0,1,4.063.741,8.681,8.681,0,0,1,3.038,2.062,9.039,9.039,0,0,1,1.914,3.148,11.591,11.591,0,0,1,.667,4v16h-3.112a3.169,3.169,0,0,1-1.47-.272,2.006,2.006,0,0,1-.827-1.136l-.543-1.63q-.963.84-1.865,1.482a10.916,10.916,0,0,1-1.865,1.074,9.682,9.682,0,0,1-2.062.655,12.46,12.46,0,0,1-2.458.222,9.981,9.981,0,0,1-3.062-.444,6.659,6.659,0,0,1-2.383-1.322,5.81,5.81,0,0,1-1.544-2.186,7.806,7.806,0,0,1-.543-3.013,6.165,6.165,0,0,1,.716-2.815,6.924,6.924,0,0,1,2.457-2.557,15.6,15.6,0,0,1,4.643-1.9,32.5,32.5,0,0,1,7.274-.852v-1.309a5.152,5.152,0,0,0-1.013-3.52,3.72,3.72,0,0,0-2.914-1.124,7.092,7.092,0,0,0-2.346.334,11.545,11.545,0,0,0-1.643.716q-.7.384-1.321.716a2.961,2.961,0,0,1-1.432.334,1.949,1.949,0,0,1-1.2-.358,3.283,3.283,0,0,1-.8-.852Zm13.88,11.287a31.868,31.868,0,0,0-4.149.433,10.006,10.006,0,0,0-2.63.8,3.445,3.445,0,0,0-1.383,1.124,2.834,2.834,0,0,0,.419,3.47,3.8,3.8,0,0,0,2.309.617,6.687,6.687,0,0,0,2.952-.6,8.909,8.909,0,0,0,2.482-1.889Z" />
          <path d="M645.246,172.051v36.825H638.43V172.051Z" />
          <path d="M692.441,168.653A22.163,22.163,0,0,0,663.8,202.016l-4.84,7.354,21.341-.046h0a22.159,22.159,0,0,0,12.136-40.67Zm-12.184,5.583,4.155,4.258-2.514,4.28-1.62-2.194-1.644,2.225-2.532-4.311Zm2.985,13.588.385,1.474-1.523.417-.552.152-.333.45-.941,1.274-.941-1.274-.333-.45-.553-.152-1.523-.417.385-1.474.1-.382-.1-.381-.385-1.474,1.523-.418.553-.151.333-.45.941-1.274.941,1.274.333.45.552.151,1.523.418-.385,1.474-.1.381Zm-8.886-8.369,2.633,4.483-2.032.557.518,1.982H671.09l-2.635-5.7Zm-5.9,14.4,2.516-5.444h4.5l-.518,1.982,1.967.539-2.5,4.258Zm11.8,6.539-4.107-4.209,2.442-4.159,1.686,2.282,1.662-2.251,2.425,4.128Zm5.834-5.2-2.495-4.248,2-.549-.517-1.982h4.463l2.515,5.444Zm3.334-8.71h-4.344l.517-1.982-2.067-.567,2.627-4.473,5.9,1.32Z" />
        </g>
      </svg>
    )
  }
}
