import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookHeadIdeaSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookHeadIdea', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 75.497 100.259"
      >
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${a},.${b},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${a}{fill-rule:evenodd;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1588.767 -513.976)">
          <g transform="translate(69.619 380.926)">
            <path
              className={a}
              d="M1566.349,166.26a13.59,13.59,0,1,0-23.219,9.585,13.752,13.752,0,0,1,3.936,9.724v1.516a2.884,2.884,0,0,0,2.884,2.883h5.62a2.884,2.884,0,0,0,2.884-2.883v-1.514a14.361,14.361,0,0,1,4.118-9.913A13.533,13.533,0,0,0,1566.349,166.26Z"
            />
            <line
              className={b}
              x2="11.388"
              transform="translate(1547.066 185.716)"
            />
            <line
              className={b}
              x2="11.388"
              transform="translate(1547.066 182.347)"
            />
            <path
              className={b}
              d="M1555.594,190.822a2.675,2.675,0,0,1-5.289,0"
            />
            <line
              className={b}
              x2="5.341"
              y2="0.032"
              transform="translate(1531.471 163.952)"
            />
            <line
              className={b}
              x2="5.341"
              y2="0.032"
              transform="translate(1569.476 163.952)"
            />
            <line
              className={b}
              y1="2.826"
              x2="4.532"
              transform="translate(1533.232 174.653)"
            />
            <line
              className={b}
              y1="2.826"
              x2="4.532"
              transform="translate(1566.757 150.498)"
            />
            <line
              className={b}
              y1="5.101"
              x2="1.582"
              transform="translate(1557.426 145.216)"
            />
            <line
              className={b}
              x1="2.009"
              y1="4.948"
              transform="translate(1546.512 145.292)"
            />
            <line
              className={b}
              x1="4.232"
              y1="3.258"
              transform="translate(1568.05 174.437)"
            />
            <line
              className={b}
              x1="2.874"
              y1="4.502"
              transform="translate(1562.797 181.333)"
            />
            <line
              className={b}
              y1="4.502"
              x2="2.874"
              transform="translate(1538.748 181.333)"
            />
            <line
              className={b}
              x1="4.625"
              y1="2.67"
              transform="translate(1534.327 150.848)"
            />
          </g>
          <path
            className={b}
            d="M1533.217,223.285l35.463,6.023c-.155-4.538-.358-16.165,1.487-18.827,2.08-3,5.66-3.1,8.82-3.189,2.772-.078,5.391-.152,6.756-2.02,1.661-2.274.747-6.022-.06-9.329-.811-3.328-1.578-6.472.523-7.932.971-.675,2.872.049,4.75-.713,5.483-2.227,1.285-8.117-1.442-12.292-2.818-4.313-4.59-11.874-4.912-14.806A27.235,27.235,0,0,0,1562.82,135c-10.778-2.523-22.928.435-28.442,4.75-17.849,13.968-18.94,35.49-3.071,60.6a1.064,1.064,0,0,1,.163.493Z"
            transform="translate(69.619 380.926)"
          />
          <path
            className={c}
            d="M-3616.469-2498.763h0l-35.462-6.023-1.747-22.439a1.08,1.08,0,0,0-.163-.493c-15.869-25.112-14.778-46.635,3.071-60.6a25.716,25.716,0,0,1,8.517-4.046A41.788,41.788,0,0,1-3630.7-2594a36.77,36.77,0,0,1,8.365.929,27.939,27.939,0,0,1,19.419,15.5,27.782,27.782,0,0,1,2.364,9.7c.335,3.055,2.119,10.534,4.912,14.807l.377.575c1.959,2.982,4.4,6.692,3.582,9.31a3.963,3.963,0,0,1-2.516,2.407,6.767,6.767,0,0,1-2.617.389h-.347a3.153,3.153,0,0,0-1.787.324c-2.1,1.459-1.334,4.6-.524,7.927.808,3.309,1.724,7.058.062,9.334-1.367,1.87-3.984,1.942-6.756,2.02-3.16.089-6.741.191-8.821,3.189-.8,1.148-1.3,4.079-1.493,8.71-.163,3.873-.066,8,.007,10.116Zm-13.922-80.638c-.3,0-.594.01-.887.028a13.558,13.558,0,0,0-8.631,3.885,13.531,13.531,0,0,0-4.025,8.563,13.552,13.552,0,0,0,.825,5.905,13.5,13.5,0,0,0,3.09,4.794,13.291,13.291,0,0,1,2.928,4.465,14.072,14.072,0,0,1,1.007,5.259v1.516a2.887,2.887,0,0,0,2.885,2.884h5.62a2.887,2.887,0,0,0,2.885-2.884v-1.513a14.335,14.335,0,0,1,4.118-9.913,13.514,13.514,0,0,0,3.777-9.4,13.5,13.5,0,0,0-3.98-9.61A13.5,13.5,0,0,0-3630.392-2579.4Z"
            transform="translate(5254.769 3111.998)"
          />
        </g>
      </svg>
    )
  }
}
