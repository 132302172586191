import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookDeviceLocationMarkerSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookDeviceLocationMarker',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 261.863 230.363"
      >
        <defs>
          <style>
            {`
      .${a} {
        fill: none;
        stroke: ${getDiscHex(discType, color)};
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 4px;
      }

      .${b} {
        opacity: 0.1;
      }

      .${c} {
        fill: ${getDiscHex(discType, color)};
      }`}
          </style>
        </defs>
        <g
          id="Group_3952"
          data-name="Group 3952"
          transform="translate(-1232.259 -7997.143)"
        >
          <g
            id="Group_3947"
            data-name="Group 3947"
            transform="translate(-3780.145 7595.238)"
          >
            <g id="Group_3951" data-name="Group 3951">
              <g
                id="Group_3946"
                data-name="Group 3946"
                transform="translate(5015.145 423.475)"
              >
                <g
                  id="Group_3944"
                  data-name="Group 3944"
                  transform="translate(0 143.69)"
                >
                  <line
                    id="Line_324"
                    data-name="Line 324"
                    className={a}
                    y1="45.335"
                    x2="26.972"
                  />
                  <line
                    id="Line_325"
                    data-name="Line 325"
                    className={a}
                    x2="24.52"
                    y2="45.335"
                    transform="translate(230.002)"
                  />
                  <path
                    id="Path_2658"
                    data-name="Path 2658"
                    className={a}
                    d="M5099.138,472.2"
                    transform="translate(-4881.291 -458.324)"
                  />
                  <path
                    id="Path_2659"
                    data-name="Path 2659"
                    className={a}
                    d="M5032.4,472.2"
                    transform="translate(-4987.647 -458.324)"
                  />
                </g>
                <g
                  id="Group_3945"
                  data-name="Group 3945"
                  transform="translate(26.972)"
                >
                  <path
                    id="Path_2660"
                    data-name="Path 2660"
                    className={a}
                    d="M5064.669,555.14h-39.124V411.451h147.7"
                    transform="translate(-5025.545 -411.451)"
                  />
                  <path
                    id="Path_2661"
                    data-name="Path 2661"
                    className={a}
                    d="M5188.866,453.415v34.851h-138.4"
                    transform="translate(-4985.836 -344.576)"
                  />
                  <line
                    id="Line_326"
                    data-name="Line 326"
                    className={a}
                    x2="10.887"
                    transform="translate(94.275 14.683)"
                  />
                </g>
              </g>
              <path
                id="Path_2662"
                data-name="Path 2662"
                className={a}
                d="M5064.931,433.172c-18.137.044-32.3,15.541-31.668,33.665.986,28.2,23.576,61.479,30.038,70.448a2.144,2.144,0,0,0,3.462.023c6.655-8.964,30.031-42.561,30.031-72.354A31.782,31.782,0,0,0,5064.931,433.172Z"
                transform="translate(28.841 46.64)"
              />
              <circle
                id="Ellipse_301"
                data-name="Ellipse 301"
                className={a}
                cx="12.568"
                cy="12.568"
                r="12.568"
                transform="translate(5081.291 501.102)"
              />
              <rect
                id="Rectangle_587"
                data-name="Rectangle 587"
                className={a}
                width="69.391"
                height="128.409"
                rx="6.548"
                transform="translate(5189.818 403.905)"
              />
              <circle
                id="Ellipse_302"
                data-name="Ellipse 302"
                className={a}
                cx="5.088"
                cy="5.088"
                r="5.088"
                transform="translate(5219.425 515.328)"
              />
              <path
                id="Path_2663"
                data-name="Path 2663"
                className={a}
                d="M5133.513,404.905v1.5a7.91,7.91,0,0,1-7.828,7.991h-30.978a7.91,7.91,0,0,1-7.827-7.991v-1.5"
                transform="translate(114.317 1.594)"
              />
              <path
                id="Path_2664"
                data-name="Path 2664"
                className={a}
                d="M5269.667,484.331H5015.146V500.1h254.521Z"
                transform="translate(-0.001 128.169)"
              />
            </g>
          </g>
          <g
            id="Group_3949"
            data-name="Group 3949"
            className={b}
            transform="translate(-3909.54 7595.416)"
          >
            <g
              id="Group_3948"
              data-name="Group 3948"
              transform="translate(5135.54 427.461)"
            >
              <path
                id="Path_2665"
                data-name="Path 2665"
                className={c}
                d="M5187.463,441.381a12.557,12.557,0,1,0,12.556,12.556A12.557,12.557,0,0,0,5187.463,441.381Z"
                transform="translate(-5096.215 -363.816)"
              />
              <path
                id="Path_2666"
                data-name="Path 2666"
                className={c}
                d="M5388.161,555.025l-6.252-143.574H5184.97l-.3,143.574-26.948,45.3V616.08h254.942V600.323ZM5237.529,571.8a2.142,2.142,0,0,1-3.459-.023c-6.456-8.962-29.027-42.216-30.013-70.392-.631-18.11,13.521-33.594,31.643-33.638a31.756,31.756,0,0,1,31.836,31.757C5267.536,529.268,5244.181,562.839,5237.529,571.8Z"
                transform="translate(-5144.54 -411.451)"
              />
            </g>
            <rect
              id="Rectangle_588"
              data-name="Rectangle 588"
              className={c}
              width="69.336"
              height="128.307"
              rx="6.548"
              transform="translate(5323.67 406.905)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
