import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class HandThumbsDownSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderHandThumbsDown', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 122.561 134.281', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-127 -6540.996)">
          <g transform="translate(128 6542)">
            <path
              className={a}
              d="M3038.3,1504.773h48.436s6.584-.324,16.406,9.5"
              transform="translate(-3006.238 -1504.772)"
            />
            <path
              className={a}
              d="M3026.224,1516.311"
              transform="translate(-3011.784 -1499.472)"
            />
            <path
              className={a}
              d="M3096.345,1551.22c-14.417,11.711-21.026,35.205-19,50.3,1.343,9.989-3.837,12.735-10.146,12.735-9.363,0-19-12.52-15.112-49.648"
              transform="translate(-3000.308 -1483.436)"
            />
            <path
              className={a}
              d="M3102.9,1505.73h-16.48c-1.83,0-3.313,1.887-3.313,4.215v64.762c0,2.328,1.483,4.213,3.313,4.213h16.48c1.83,0,3.313-1.885,3.313-4.213v-64.762C3106.215,1507.617,3104.732,1505.73,3102.9,1505.73Z"
              transform="translate(-2985.654 -1504.332)"
            />
            <path
              className={a}
              d="M3050.191,1553.087"
              transform="translate(-3000.775 -1482.578)"
            />
            <path
              className={a}
              d="M3034.909,1545.69h-9.245c-5.963,0-9.336,4.832-9.336,10.795h0c0,5.961,3.372,10.795,9.336,10.795h42.449"
              transform="translate(-3016.329 -1485.976)"
            />
            <path
              className={a}
              d="M3052.191,1538.293"
              transform="translate(-2999.856 -1489.374)"
            />
            <path
              className={a}
              d="M3047.373,1530.9h-19.708c-5.963,0-9.335,4.833-9.335,10.795h0c0,5.962,3.372,10.795,9.335,10.795h19.708"
              transform="translate(-3015.41 -1492.772)"
            />
            <path
              className={a}
              d="M3056.649,1523.5"
              transform="translate(-2997.808 -1496.17)"
            />
            <path
              className={a}
              d="M3046.647,1516.1h-14.527c-5.961,0-9.334,4.833-9.334,10.795h0c0,5.961,3.373,10.795,9.334,10.795h4.791"
              transform="translate(-3013.363 -1499.567)"
            />
            <path
              className={a}
              d="M3056.649,1510.9"
              transform="translate(-2997.808 -1501.956)"
            />
            <path
              className={a}
              d="M3060.515,1504.772h-21.354c-4.941,0-10.407,3.171-10.407,8.267,0,4.566,2.547,8.268,7.488,8.268"
              transform="translate(-3010.621 -1504.772)"
            />
          </g>
          <path
            className={b}
            d="M51.739,81.591,50.616,81.3H9.335C3.373,81.3,0,76.471,0,70.509s3.373-10.8,9.335-10.8h2.919c-5.965,0-9.335-4.832-9.335-10.794s3.369-10.794,9.335-10.794h6.505c-5.962,0-9.335-4.835-9.335-10.794S12.8,16.536,18.759,16.536h5.6l.015-.083c-4.125-.572-6.245-4.019-6.245-8.184C18.133,3.171,23.6,0,28.541,0H49.892l0,0h30.6s6.535-.322,16.3,9.386l.041.014v.026L96.9,9.5l-.072-.01-.9,58.327.107-.032-.108.088-.006.421-.934.374C81.3,80.642,75.061,103.359,77.04,118.081c1.344,9.989-3.835,12.736-10.144,12.736C57.569,130.817,47.97,118.388,51.739,81.591Z"
            transform="translate(135.297 6543.459)"
          />
        </g>
      </svg>
    )
  }
}
