import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class LeadershipSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 77.412 88.415', discType, color)}>
        <g transform="translate(0.968 0.968)">
          <path
            d="M7272.376,938.659a7.557,7.557,0,1,1-7.557-7.556A7.556,7.556,0,0,1,7272.376,938.659Z"
            transform="translate(-7226.814 -917.588)"
          />
          <circle
            cx="6.156"
            cy="6.156"
            r="6.156"
            transform="translate(4.031 12.328)"
          />
          <circle
            cx="6.156"
            cy="6.156"
            r="6.156"
            transform="translate(59.131 12.328)"
          />
          <path
            d="M7237.855,947.3h-10.269a4.664,4.664,0,0,0-4.663,4.779l.246,10.1a4.206,4.206,0,0,0,4,3.324l.949,22.494h9.368l1.624-22.5a4.2,4.2,0,0,0,3.943-3.321l.246-10.1a4.665,4.665,0,0,0-4.664-4.779Z"
            transform="translate(-7222.922 -919.424)"
          />
          <path
            d="M7300,947.3H7289.73a4.664,4.664,0,0,0-4.663,4.779l.246,10.1a4.208,4.208,0,0,0,4,3.324l.949,22.494h9.368l1.625-22.5a4.2,4.2,0,0,0,3.943-3.321l.246-10.1a4.664,4.664,0,0,0-4.664-4.779Z"
            transform="translate(-7229.965 -919.424)"
          />
          <path
            d="M7270.391,952h-13.735a6.241,6.241,0,0,0-6.239,6.393l.33,13.508c.315,2.37,2.566,4.184,5.346,4.448l1.269,30.088h12.531l2.173-30.093c2.745-.287,4.96-2.092,5.274-4.442l.329-13.508a6.24,6.24,0,0,0-6.238-6.393Z"
            transform="translate(-7226.038 -919.957)"
          />
          <line x2="8.354" y2="5.861" transform="translate(16.849 9.38)" />
          <line x1="8.354" y2="5.861" transform="translate(49.263 9.38)" />
          <line y1="6.763" transform="translate(37.711)" />
        </g>
      </svg>
    )
  }
}
