import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookGroupThinkSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookGroupThink', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 87.061 100.47"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.936px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1840.47 -382.032)">
          <path
            className={a}
            d="M1713.711,33.125a8.523,8.523,0,1,1-8.523-8.521A8.523,8.523,0,0,1,1713.711,33.125Z"
            transform="translate(179.114 373.638)"
          />
          <circle
            className={a}
            cx="6.943"
            cy="6.943"
            r="6.943"
            transform="translate(1845.986 396.904)"
          />
          <circle
            className={a}
            cx="6.943"
            cy="6.943"
            r="6.943"
            transform="translate(1908.129 396.904)"
          />
          <path
            className={a}
            d="M1679.167,40.8h-11.581a5.262,5.262,0,0,0-5.26,5.39l.278,11.389a4.746,4.746,0,0,0,4.508,3.75l1.069,25.369h10.566l1.832-25.373a4.737,4.737,0,0,0,4.447-3.746l.278-11.389a5.262,5.262,0,0,0-5.26-5.39Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1741.31,40.8h-11.581a5.261,5.261,0,0,0-5.26,5.39l.277,11.389a4.749,4.749,0,0,0,4.509,3.75l1.069,25.369h10.566l1.832-25.373a4.737,4.737,0,0,0,4.447-3.746l.278-11.389a5.262,5.262,0,0,0-5.26-5.39Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1712.347,45.5h-15.491a7.037,7.037,0,0,0-7.036,7.209l.371,15.236c.356,2.673,2.9,4.719,6.03,5.016l1.431,33.934h14.133l2.451-33.94c3.1-.325,5.594-2.36,5.948-5.01l.372-15.236a7.038,7.038,0,0,0-7.036-7.209Z"
            transform="translate(179.114 373.638)"
          />
          <line
            className={a}
            x2="9.422"
            y2="6.61"
            transform="translate(1860.442 393.578)"
          />
          <line
            className={a}
            x1="9.422"
            y2="6.61"
            transform="translate(1896.999 393.578)"
          />
          <line className={a} y1="7.628" transform="translate(1883.97 383)" />
          <path
            className={b}
            d="M1713.711,33.125a8.523,8.523,0,1,1-8.523-8.521A8.523,8.523,0,0,1,1713.711,33.125Z"
            transform="translate(181.114 374.638)"
          />
          <circle
            className={b}
            cx="6.943"
            cy="6.943"
            r="6.943"
            transform="translate(1847.985 397.904)"
          />
          <path
            className={b}
            d="M1679.167,40.8h-11.581a5.262,5.262,0,0,0-5.26,5.39l.278,11.389a4.746,4.746,0,0,0,4.508,3.75l1.069,25.369h10.566l1.832-25.373a4.737,4.737,0,0,0,4.447-3.746l.278-11.389a5.262,5.262,0,0,0-5.26-5.39Z"
            transform="translate(181.114 374.638)"
          />
          <path
            className={b}
            d="M1712.347,45.5h-15.491a7.037,7.037,0,0,0-7.036,7.209l.371,15.236c.356,2.673,2.9,4.719,6.03,5.016l1.431,33.934h14.133l2.451-33.94c3.1-.325,5.594-2.36,5.948-5.01l.372-15.236a7.038,7.038,0,0,0-7.036-7.209Z"
            transform="translate(181.114 374.638)"
          />
        </g>
      </svg>
    )
  }
}
