import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class HandshakeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 98.99 63.65', discType, color)}>
        <g transform="translate(-3133.96 -1173.607)">
          <rect
            width="10.217"
            height="34.466"
            transform="translate(3153.271 1175.766) rotate(31.359)"
          />
          <rect
            width="10.217"
            height="34.466"
            transform="translate(3231.575 1204.413) rotate(148.641)"
          />
          <path d="M3160.261,1184.515l4.573,2.309s4.482.462,6.859,1.848" />
          <rect
            width="7.639"
            height="18.002"
            rx="3.82"
            transform="matrix(0.813, 0.583, -0.583, 0.813, 3165.92, 1208.603)"
          />
          <rect
            width="7.639"
            height="18.002"
            rx="3.82"
            transform="translate(3171.521 1214.203) rotate(35.654)"
          />
          <path d="M3206.015,1216.907a4.727,4.727,0,0,1,1.778,6.009h0c-1.006,1.744-3.556,2.156-5.694.922l-4.341-2.635" />
          <path d="M3187.254,1215.138l10.5,6.065c2.139,1.235,3.017,3.717,1.963,5.543h0c-1.055,1.827-3.644,2.307-5.782,1.073l-2.985-1.745" />
          <path d="M3180.745,1220.179l10.5,6.065a3.759,3.759,0,0,1,1.143,5.715c-2.041,2.593-7.485,1.29-9.623.055l-3.394-1.755" />
          <rect
            width="7.639"
            height="13.142"
            rx="3.82"
            transform="matrix(0.813, 0.583, -0.583, 0.813, 3176.234, 1220.731)"
          />
          <rect
            width="7.639"
            height="11.492"
            rx="3.82"
            transform="translate(3156.92 1207.896) rotate(35.654)"
          />
          <path d="M3205.621,1184.515a12.736,12.736,0,0,1-6.861,0c-4.229-.991-12.864-4.938-22.738-.521a73.747,73.747,0,0,0-10.156,14.755s4.295,3.181,10.156-1.127l5.445-5.422h1.967l28.2,17.733c2.138,1.235,2.167,4.624,1.112,6.451h0c-1.055,1.827-3.643,2.307-5.782,1.072l-10.5-6.065" />
          <line y1="4.866" x2="6.102" transform="translate(3213.428 1206.08)" />
          <line
            x2="6.015"
            y2="3.676"
            transform="translate(3146.318 1208.513)"
          />
        </g>
      </svg>
    )
  }
}
