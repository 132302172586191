import { getBasicClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const CustomContentBlindSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 23 15', discType, color)}>
      <path
        d="M11.6714 1.06036C5.86456 1.06037 2.34221 5.6294 1.3152 7.17788C1.11097 7.48582 1.12395 7.87426 1.34681 8.16901C2.45762 9.63816 6.17892 13.9397 11.6714 13.9397C17.1639 13.9397 20.8852 9.63816 21.996 8.169C22.2189 7.87426 22.2319 7.48582 22.0277 7.17788C21.0006 5.6294 17.4783 1.06036 11.6714 1.06036Z"
        stroke="#6C6C6C"
      />
      <path
        d="M15.2192 7.31603C15.2192 9.27542 13.6308 10.8638 11.6714 10.8638C9.71205 10.8638 8.12366 9.27542 8.12366 7.31603C8.12366 5.35665 9.71205 3.76825 11.6714 3.76825C13.6308 3.76825 15.2192 5.35665 15.2192 7.31603Z"
        stroke="#6C6C6C"
      />
      <path
        d="M18.2279 1.06036L5.5 13.7884"
        stroke="#6C6C6C"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CustomContentBlindSVG
