import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PaperWithRulerSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('PaperWithRulerSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.5px;}`}</style>
        </defs>
        <path
          className={a}
          d="M54,99a1,1,0,0,1-.32-.06L33.44,92.07a1,1,0,0,1-.63-1.27L62.3,3.43a1,1,0,0,1,.5-.58,1.05,1.05,0,0,1,.76-.05L83.78,9.63a1,1,0,0,1,.63,1.26L54.92,98.27a1,1,0,0,1-.5.57A1,1,0,0,1,54,99ZM35,90.49l18.33,6.19L82.2,11.2,63.87,5Z"
        />
        <path
          className={a}
          d="M65.78,30.59a1.12,1.12,0,0,1-.31-.05L56.16,27.4a1,1,0,0,1,.64-1.9l9.3,3.14a1,1,0,0,1-.32,1.95Z"
        />
        <path
          className={a}
          d="M58.42,52.41a1.19,1.19,0,0,1-.32,0l-9.3-3.14a1,1,0,1,1,.64-1.9l9.3,3.14a1,1,0,0,1,.63,1.27A1,1,0,0,1,58.42,52.41Z"
        />
        <path
          className={a}
          d="M51.05,74.23a1.12,1.12,0,0,1-.31,0L41.43,71a1,1,0,0,1,.64-1.9l9.3,3.14a1,1,0,0,1-.32,2Z"
        />
        <path
          className={a}
          d="M57.74,40a1.25,1.25,0,0,1-.32-.05l-4.94-1.67a1,1,0,0,1,.64-1.9l4.94,1.67a1,1,0,0,1-.32,2Z"
        />
        <path
          className={a}
          d="M50.38,61.85a1.13,1.13,0,0,1-.32-.05l-4.94-1.67a1,1,0,1,1,.64-1.9L50.7,59.9a1,1,0,0,1-.32,2Z"
        />
        <path
          className={a}
          d="M43,83.67a1.25,1.25,0,0,1-.32,0L37.75,82a1,1,0,0,1,.64-1.9l4.94,1.67a1,1,0,0,1-.32,2Z"
        />
        <path
          className={a}
          d="M65.11,18.21a1.13,1.13,0,0,1-.32-.05l-5-1.67a1,1,0,0,1,.65-1.9l4.94,1.67a1,1,0,0,1-.32,1.95Z"
        />
        <path
          className={a}
          d="M58.61,17.76H19.13a1,1,0,0,1,0-2H58.61a1,1,0,0,1,0,2Z"
        />
        <path
          className={a}
          d="M91.45,85H58.7a1,1,0,0,1,0-2H90.45V17.76H81.38a1,1,0,1,1,0-2H91.45a1,1,0,0,1,1,1V84A1,1,0,0,1,91.45,85Z"
        />
        <path
          className={a}
          d="M35.71,85H18a1,1,0,0,1,0-2H35.71a1,1,0,0,1,0,2Z"
        />
        <path
          className={a}
          d="M8.67,77a1,1,0,0,1-1-1V16.76a1,1,0,1,1,2,0V76A1,1,0,0,1,8.67,77Z"
        />
        <path
          className={a}
          d="M8.72,77a1,1,0,0,1-1.05-1V75a1,1,0,0,1,2,0,8.12,8.12,0,0,0,0,.86,1,1,0,0,1-.25.77A1,1,0,0,1,8.72,77Z"
        />
        <path
          className={a}
          d="M8.67,76a1,1,0,0,1-1-1V12.93A11.48,11.48,0,0,1,19.13,1.46a1,1,0,0,1,1,1V65.28a1,1,0,0,1-1.07,1l-.64,0A8.76,8.76,0,0,0,9.67,75,1,1,0,0,1,8.67,76ZM18.13,3.52a9.47,9.47,0,0,0-8.46,9.41V68.75a10.78,10.78,0,0,1,8.46-4.51Z"
        />
        <path
          className={a}
          d="M18,85a10.34,10.34,0,0,1,0-20.67,1,1,0,0,1,0,2A8.34,8.34,0,0,0,18,83a1,1,0,0,1,0,2Z"
        />
      </svg>
    )
  }
}
