import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class HollowPriceTicketSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderHollowPriceTicket',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 131.748 126.776', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-147.005 -4775)">
          <g transform="translate(148 4776)">
            <path
              className={a}
              d="M6766.292,1342.533l-50.716,50.716-68.625-68.626,3.163-46.622,47.554-4.094Z"
              transform="translate(-6637.245 -1273.907)"
            />
            <circle
              className={a}
              cx="4.225"
              cy="4.225"
              r="4.225"
              transform="translate(22.075 13.432)"
            />
            <path
              className={a}
              d="M6664.941,1286.736c-6.594-1.676-23.065,6.23-24.8,22.58-4.624,43.641,68.386,45.68,30.314,84.125"
              transform="translate(-6639.933 -1269.079)"
            />
            <circle
              className={a}
              cx="23.662"
              cy="23.662"
              r="23.662"
              transform="translate(39.299 28.794)"
            />
          </g>
          <g className={b} transform="translate(159.412 4775.999)">
            <path
              className={c}
              d="M68.626,119.343,0,50.717,3.163,4.094,50.716,0l68.626,68.626L68.626,119.343h0Zm-15.37-90.55a23.6,23.6,0,1,0,9.211,1.86A23.5,23.5,0,0,0,53.257,28.794ZM16.6,13.433a4.226,4.226,0,1,0,4.225,4.226A4.23,4.23,0,0,0,16.6,13.433Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
