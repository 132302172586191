import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class DetailedMonitorSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderDetailedMonitor', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 132.753 113.767', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;isolation:isolate;}`}</style>
        </defs>
        <g transform="translate(-147 -4998)">
          <g transform="translate(148 4999)">
            <g transform="translate(0 0)">
              <rect className={a} width="127.34" height="90.122" />
              <line
                className={a}
                x2="6.829"
                transform="translate(59.128 5.956)"
              />
              <g transform="translate(0 81.196)">
                <rect className={a} width="127.34" height="8.927" />
              </g>
              <g transform="translate(35.751 103.498)">
                <path
                  className={a}
                  d="M47.233,122.284H98.967a4.338,4.338,0,0,1,4.338,4.338v3.931H42.9v-3.931A4.338,4.338,0,0,1,47.233,122.284Z"
                  transform="translate(-42.895 -122.284)"
                />
              </g>
              <g transform="translate(51.197 90.707)">
                <path
                  className={a}
                  d="M89.3,120.087H61L65.934,107.3H84.362Z"
                  transform="translate(-60.995 -107.295)"
                />
              </g>
            </g>
            <rect
              className={b}
              width="127.34"
              height="79.882"
              transform="translate(3.413 3.414)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
