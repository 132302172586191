import React from 'react'

import { getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PuzzleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="372.124"
        height="253.171"
        viewBox="0 0 372.124 253.171"
      >
        <g
          id="Group_3387"
          data-name="Group 3387"
          transform="translate(-5.171 -8.011)"
        >
          <g
            id="Group_3384"
            data-name="Group 3384"
            transform="translate(182.791 8.011)"
            opacity="0.571"
          >
            <path
              id="Path_1746"
              data-name="Path 1746"
              d="M72.977,136.594l-.138-30.267,1.425-.842a4.34,4.34,0,0,1,4.369-.026l.318.183a5.489,5.489,0,0,1,1.607,1.438,11.448,11.448,0,1,0-.026-13.911,6.186,6.186,0,0,1-1.748,1.591l-.214.127a4.34,4.34,0,0,1-4.369.026l-1.427-.821-.519-54.3a3.267,3.267,0,0,1,3.267-3.267H98.98l.98-1.679a4.345,4.345,0,0,0,0-4.371l-.124-.214a6.178,6.178,0,0,0-1.581-1.755,11.446,11.446,0,1,1,13.911.056,5.5,5.5,0,0,0-1.448,1.6l-.185.315a4.345,4.345,0,0,0,0,4.371l.98,1.679h56.193c1.046,0,1.872.985,1.872,3.267l.522,95.714-42.121,36.065Z"
              transform="translate(-72.254 -8.011)"
              fill={getDiscHex(discType, color)}
            />
          </g>
          <path
            id="Path_1747"
            data-name="Path 1747"
            d="M113.275,168.857s.36,2.153,1.4,2.153h55.259l.98,1.679a4.345,4.345,0,0,1,0,4.371l-.185.315a5.5,5.5,0,0,1-1.448,1.6,11.448,11.448,0,1,0,13.911.056,6.134,6.134,0,0,1-1.578-1.755l-.127-.214a4.345,4.345,0,0,1,0-4.371l.98-1.679h23.737a3.266,3.266,0,0,0,3.267-3.267V138.794a13.615,13.615,0,1,1,.005-16.064c0-15.767-.005-46.516-.005-46.516L161.144,45.429,113.275,74.746Z"
            transform="translate(167.816 61.656)"
            fill={getDiscHex(discType, color)}
            opacity="0.571"
          />
          <path
            id="Path_1748"
            data-name="Path 1748"
            d="M207.835,73.387A12.989,12.989,0,0,1,218.3,78.6a3.269,3.269,0,0,0,5.873-1.941V40.7a3.267,3.267,0,0,0-3.267-3.267H192a19.35,19.35,0,0,0,2.756-9.807,19.612,19.612,0,1,0-39.224,0,19.35,19.35,0,0,0,2.756,9.807H129.382a1.82,1.82,0,0,0-1.869,1.586V76.657a3.271,3.271,0,0,1-5.875,1.941,13.1,13.1,0,1,0,0,15.68,3.27,3.27,0,0,1,5.875,1.991v35.957c0,2.285.826,3.27,1.869,3.27H184.64l.98,1.679a4.34,4.34,0,0,1,0,4.369l-.185.318a5.486,5.486,0,0,1-1.448,1.6,11.45,11.45,0,1,0,13.911.058,6.2,6.2,0,0,1-1.578-1.755l-.127-.217a4.34,4.34,0,0,1,0-4.369l.98-1.679h23.737a3.268,3.268,0,0,0,3.267-3.27V96.269a3.268,3.268,0,0,0-5.873-1.991,13.072,13.072,0,1,1-10.469-20.891Z"
            transform="translate(153.113 0)"
            fill={getDiscHex(discType, color)}
          />
          <path
            id="Path_1749"
            data-name="Path 1749"
            d="M205.786,78.066a11.4,11.4,0,0,0-9.047,4.456,6.18,6.18,0,0,1-1.758,1.578l-.214.127a4.34,4.34,0,0,1-4.369,0l-1.681-.98V59.51a3.266,3.266,0,0,0-3.267-3.267H149.492a3.269,3.269,0,0,0-1.994,5.873,13.048,13.048,0,1,1-15.68,0,3.268,3.268,0,0,0-1.941-5.873H93.921a3.266,3.266,0,0,0-3.267,3.267V88.419a19.35,19.35,0,0,0-9.807-2.756,19.612,19.612,0,1,0,0,39.224,19.35,19.35,0,0,0,9.807-2.756v28.908a1.82,1.82,0,0,0,1.583,1.869h37.807c.056-1.372.008-2.7-.09-4.475-7.9-7.265-4.912-24.155,8.936-24.155,14.351,0,17.025,18.084,8.073,24.876.1,1.268.1,2.4.114,3.755h38.371c2.282,0,3.267-.826,3.267-1.869V95.779l1.681-.98a4.34,4.34,0,0,1,4.369,0l.315.185a5.5,5.5,0,0,1,1.6,1.448,11.444,11.444,0,1,0,9.106-18.367Z"
            transform="translate(92.375 79.475)"
            fill={getDiscHex(discType, color)}
          />
          <g
            id="Group_3385"
            data-name="Group 3385"
            transform="translate(5.171 45.322)"
          >
            <path
              id="Path_1750"
              data-name="Path 1750"
              d="M121.382,22.189,86.365,30.368a3.27,3.27,0,0,0-.6,6.172,13.051,13.051,0,1,1-15.267,3.567,3.269,3.269,0,0,0-3.228-5.277L32.253,43.008a3.268,3.268,0,0,0-2.439,3.927L40.493,92.651l-1.485,1.4A5.814,5.814,0,0,1,33.3,95.386l-.469-.143a7.357,7.357,0,0,1-2.529-1.4,15.352,15.352,0,1,0,4.168,18.185,8.284,8.284,0,0,1,1.811-2.6l.244-.23a5.812,5.812,0,0,1,5.706-1.332l1.951.6,6.447,27.6a3.27,3.27,0,0,0,3.927,2.441l28.1-6.564a19.362,19.362,0,0,0-.405,10.165,19.614,19.614,0,0,0,38.2-8.923,19.374,19.374,0,0,0-4.917-8.92l28.151-6.577a3.268,3.268,0,0,0,2.439-3.927l-5.658-24.232,1.2-1.136a4.333,4.333,0,0,1,4.255-1l.35.106a5.536,5.536,0,0,1,1.888,1.046,11.447,11.447,0,1,0-3.116-13.559,6.2,6.2,0,0,1-1.35,1.941l-.18.169a4.34,4.34,0,0,1-4.255,1l-1.573-.485L125.308,24.627A3.27,3.27,0,0,0,121.382,22.189Z"
              transform="translate(-5.171 -22.102)"
              fill={getDiscHex(discType, color)}
            />
          </g>
          <path
            id="Path_1751"
            data-name="Path 1751"
            d="M109.236,18.762"
            transform="translate(171.474 17.715)"
            fill={getDiscHex(discType, color)}
          />
        </g>
      </svg>
    )
  }
}
