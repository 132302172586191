import { getBasicClasses, getDiscHex } from '@dashboard/lib/svg'
import React from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const DetailedCloudWithLightningSVG: React.FC<OwnProps> = ({
  discType,
  color,
}: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 102 110', discType, color)}>
      <path
        d="M53.572 33.3708L36.981 78.9709L55.802 76.2259L50.519 105.326L74.9531 60.3159L57.1919 62.6259L53.572 33.3708Z"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.447 64.2568V75.9768"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.762 100.936V108.107"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.772 86.8298V97.1118"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.635 82.7769V90.8839"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.856 64.2568V72.3638"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0459 93.0588V101.166"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.967 56.3459C96.445 56.3459 100.967 46.6458 100.967 38.2718C100.965 34.6522 99.6678 31.1527 97.3101 28.4063C94.9523 25.6599 91.6897 23.8478 88.1121 23.2978C86.8427 16.8881 83.3462 11.1343 78.2417 7.05515C73.1372 2.97596 66.754 0.834543 60.2222 1.00998C53.6903 1.18543 47.4314 3.66639 42.5532 8.01371C37.6751 12.361 34.4926 18.2943 33.5691 24.7629C29.9552 23.3383 26.0478 22.8188 22.1873 23.2496C18.3267 23.6804 14.6299 25.0485 11.4189 27.2346C8.20797 29.4207 5.58009 32.3586 3.76416 35.7926C1.94823 39.2265 0.999311 43.0523 1 46.9369C1 57.5369 13.5091 57.1658 39.6421 56.6198"
        stroke={getDiscHex(discType, color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.15"
        d="M55.572 35.3708L38.981 80.9709L57.802 78.2259L52.519 107.326L76.9531 62.3159L59.1919 64.6259L55.572 35.3708Z"
        fill={getDiscHex(discType, color)}
      />
    </svg>
  )
}

export default DetailedCloudWithLightningSVG
