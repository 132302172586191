import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookTwoFingersSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d, e } = getSVGClasses(
      'renderPlaybookTwoFingers',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 59.789 100.979"
      >
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${a},.${b},.${d},.${e}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${a},.${d}{fill-rule:evenodd;}.${c}{opacity:0.1;}.${d},.${e}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1589.47 -1235.25)">
          <path
            className={a}
            d="M1530.987,902.975V865.546a5.759,5.759,0,0,1,5.76-5.759h0a5.759,5.759,0,0,1,5.759,5.759v39.115"
            transform="translate(69.619 380.926)"
          />
          <path
            className={a}
            d="M1542.506,900.2V861.084a5.76,5.76,0,0,1,5.76-5.76h0a5.76,5.76,0,0,1,5.76,5.76V900.2"
            transform="translate(69.619 380.926)"
          />
          <rect
            className={b}
            width="11.519"
            height="32.341"
            rx="5.203"
            transform="translate(1623.644 1263.15)"
          />
          <rect
            className={b}
            width="11.519"
            height="32.341"
            rx="5.203"
            transform="translate(1635.731 1270.295)"
          />
          <path
            className={a}
            d="M1577.632,915.951s-.178,21.443-3.784,28.109c-6.649,12.289-44.26,12.862-49.528-1.542-4.789-13.1-3.327-44.464-3.273-56.563a2.833,2.833,0,0,1,1.5-2.569c1.972-.955,3.843.878,5.312,3.21a20.771,20.771,0,0,1,3.076,10.957l.13,20.321"
            transform="translate(69.619 380.926)"
          />
          <g className={c} transform="translate(1 1)">
            <path
              className={d}
              d="M1530.987,924V868.35c0-4.73,2.579-8.563,5.76-8.563h0c3.181,0,5.759,3.833,5.759,8.563v58.158"
              transform="translate(69.619 380.926)"
            />
            <path
              className={d}
              d="M1542.506,926.508V864.461c0-5.046,2.579-9.137,5.76-9.137h0c3.181,0,5.76,4.091,5.76,9.137v62.047"
              transform="translate(69.619 380.926)"
            />
            <rect
              className={e}
              width="11.519"
              height="41.341"
              rx="5.203"
              transform="translate(1623.644 1263.15)"
            />
            <rect
              className={e}
              width="11.519"
              height="32.341"
              rx="5.203"
              transform="translate(1635.731 1270.295)"
            />
            <path
              className={d}
              d="M1577.632,915.951s-.178,21.443-3.784,28.109c-6.649,12.289-44.26,12.862-49.528-1.542-4.789-13.1-3.327-44.464-3.273-56.563a2.833,2.833,0,0,1,1.5-2.569c1.972-.955,3.843.878,5.312,3.21a20.771,20.771,0,0,1,3.076,10.957l.13,20.321"
              transform="translate(69.619 380.926)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
