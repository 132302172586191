import React from 'react'

import {
  getPlaybookBasicClasses,
  getDiscHex,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookBriefcaseSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('PlaybookBriefcaseSVG', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 136.726 96.884', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill: ${getDiscHex(
            discType,
            color,
          )}}`}</style>
        </defs>
        <g transform="translate(1.297 1)">
          <g transform="translate(0 0)">
            <rect
              className={a}
              width="130.631"
              height="80.792"
              transform="translate(0 10.593)"
            />
            <line
              className={a}
              y1="21.153"
              x2="53.216"
              transform="translate(77.415 29.809)"
            />
            <line
              className={a}
              x2="53.205"
              y2="21.009"
              transform="translate(0 29.98)"
            />
            <path
              className={a}
              d="M3633.479,1179.314h0a10.593,10.593,0,0,1,10.592-10.593h23.592a10.593,10.593,0,0,1,10.593,10.593h0"
              transform="translate(-3591.586 -1168.721)"
            />
            <rect
              className={a}
              width="24.221"
              height="11.69"
              transform="translate(53.205 47.086)"
            />
          </g>
          <g className={b} transform="translate(3.499 3.498)">
            <rect
              className={c}
              width="130.631"
              height="80.792"
              transform="translate(0 10.593)"
            />
            <line
              className={a}
              y1="21.153"
              x2="53.216"
              transform="translate(77.415 29.809)"
            />
            <line
              className={a}
              x2="53.205"
              y2="21.009"
              transform="translate(0 29.98)"
            />
            <path
              className={a}
              d="M3633.479,1179.314h0a10.593,10.593,0,0,1,10.592-10.593h23.592a10.593,10.593,0,0,1,10.593,10.593h0"
              transform="translate(-3591.586 -1168.721)"
            />
            <rect
              className={c}
              width="24.221"
              height="11.69"
              transform="translate(53.205 47.086)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
