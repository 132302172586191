import { getBasicClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const CheckmarkBigSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 19 18', discType, color)}>
      <path
        d="M1.90698 11.0034L7.95237 16.1851L17.625 1.67621"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckmarkBigSVG
