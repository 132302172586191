import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookTieSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookTie', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 53.874 134.75', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}.${b}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.936px;}`}</style>
        </defs>
        <g transform="translate(-1406.603 -536.438)">
          <path
            className={a}
            d="M7160.056,817.717h-16.42l-6.091-20.089h27.727Z"
            transform="translate(-5718.856 -259.987)"
          />
          <path
            className={a}
            d="M7140.253,830.857l22.52-10.738,4.371,19.257-32.323,15.413Z"
            transform="translate(-5719.81 -252.113)"
          />
          <path
            className={a}
            d="M7172.568,849.009l-40.3,19.216-3.384,11.746,6.65,8.039,42.428-20.231Z"
            transform="translate(-5721.887 -242)"
          />
          <path
            className={a}
            d="M7143.189,891.936l21.87-10.429-14.506,19.333Z"
            transform="translate(-5716.88 -230.622)"
          />
          <g transform="translate(1407.571 537.406)">
            <path
              className={b}
              d="M7163.148,812.508l17.678,80.383L7155.61,925.2l-26.721-32.306,17.812-80.383"
              transform="translate(-7128.889 -792.384)"
            />
            <path
              className={b}
              d="M7160.1,817.753h-16.449l-6.1-20.124h27.776Z"
              transform="translate(-7125.836 -797.629)"
            />
            <path
              className={b}
              d="M7140.266,830.877l22.559-10.757,4.379,19.291-32.38,15.44Z"
              transform="translate(-7126.797 -789.701)"
            />
            <path
              className={b}
              d="M7173.555,848.689l-42.074,19.751-2.592,11.7,6.662,8.053,42.5-20.267Z"
              transform="translate(-7128.889 -779.63)"
            />
            <path
              className={b}
              d="M7143.191,891.955l21.909-10.447-14.531,19.368Z"
              transform="translate(-7123.848 -768.062)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
