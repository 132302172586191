import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ShieldSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('ShieldSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.5px;}`}</style>
        </defs>
        <path
          className={a}
          d="M47.17,97.82a1,1,0,0,1-.66-.24L27,80.59c-2.72-2.37-5.14-4.09-7.27-5.62C13.44,70.5,9.65,67.79,9.65,56.63V12.7a1,1,0,0,1,1.09-1l3,.27A47.77,47.77,0,0,0,44.29,4.18l2.32-1.55a1,1,0,0,1,1.08,0l4.46,2.7a47.66,47.66,0,0,0,31.4,6.4,1,1,0,0,1,.79.23,1,1,0,0,1,.35.76V56.63C84.69,67.79,80.9,70.5,74.63,75c-2.13,1.53-4.55,3.25-7.27,5.62h0l-19.53,17A1,1,0,0,1,47.17,97.82Zm-35.52-84V56.63c0,10.13,3.08,12.33,9.22,16.72a89.46,89.46,0,0,1,7.42,5.73L47.17,95.5,66.05,79.08a89.46,89.46,0,0,1,7.42-5.73c6.14-4.39,9.22-6.59,9.22-16.72V13.84A49.66,49.66,0,0,1,51.12,7L47.19,4.65,45.4,5.84A49.63,49.63,0,0,1,13.6,14Zm55.06,66Z"
        />
        <path
          className={a}
          d="M47.17,89.05a1,1,0,0,1-.66-.24L30.65,75a73.21,73.21,0,0,0-5.89-4.55c-5.14-3.67-8.25-5.89-8.25-15V19.74a1,1,0,0,1,.33-.74,1,1,0,0,1,.76-.26l2.47.22a38.58,38.58,0,0,0,24.66-6.3l1.88-1.26a1,1,0,0,1,1.08,0l3.62,2.19a38.5,38.5,0,0,0,25.37,5.17,1,1,0,0,1,1.15,1V55.41c0,9.16-3.11,11.38-8.25,15A73.21,73.21,0,0,0,63.69,75L47.83,88.81A1,1,0,0,1,47.17,89.05ZM18.51,20.83V55.41c0,8.13,2.48,9.9,7.41,13.42a73.22,73.22,0,0,1,6,4.67L47.17,86.73,62.38,73.5h0a73.22,73.22,0,0,1,6-4.67c4.93-3.52,7.41-5.29,7.41-13.42V20.87a40.5,40.5,0,0,1-25.55-5.58l-3.09-1.87-1.35.9A40.54,40.54,0,0,1,19.89,21Z"
        />
      </svg>
    )
  }
}
