import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookRelationshipSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookRelationship',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 136.044 110.093', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-120.96 -2529)">
          <g transform="translate(122 2530)">
            <circle
              className={a}
              cx="21.218"
              cy="21.218"
              r="21.218"
              transform="translate(18.319 0)"
            />
            <path
              className={a}
              d="M2955.63,1480.675h-61.011a9,9,0,0,1-8.67-11.467,40.823,40.823,0,0,1,78.351,0A9,9,0,0,1,2955.63,1480.675Z"
              transform="translate(-2885.588 -1388.239)"
            />
            <circle
              className={a}
              cx="17.765"
              cy="17.765"
              r="17.765"
              transform="translate(80.289 30.698)"
            />
            <path
              className={a}
              d="M2946.262,1461.671a34.179,34.179,0,0,1,52.5,21.062q.082.377.155.755a5.5,5.5,0,0,1-5.406,6.515h-56.3a5.46,5.46,0,0,1-5.416-6.4l.011-.063a34,34,0,0,1,2.086-6.866"
              transform="translate(-2867.312 -1381.91)"
            />
          </g>
          <g className={b} transform="translate(125.302 2530)">
            <circle
              className={c}
              cx="21.218"
              cy="21.218"
              r="21.218"
              transform="translate(18.319 0)"
            />
            <path
              className={c}
              d="M2955.63,1480.675h-61.011a9,9,0,0,1-8.67-11.467,40.823,40.823,0,0,1,78.351,0A9,9,0,0,1,2955.63,1480.675Z"
              transform="translate(-2885.588 -1388.239)"
            />
            <circle
              className={c}
              cx="17.765"
              cy="17.765"
              r="17.765"
              transform="translate(80.289 30.698)"
            />
            <path
              className={c}
              d="M2946.262,1461.671a34.179,34.179,0,0,1,52.5,21.062q.082.377.155.755a5.5,5.5,0,0,1-5.406,6.515h-56.3a5.46,5.46,0,0,1-5.416-6.4l.011-.063a34,34,0,0,1,2.086-6.866"
              transform="translate(-2867.312 -1381.91)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
