import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookClipboardCheckListSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookClipboardChecklist',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 115.384 167.61"
      >
        <defs>
          <style>
            {`.${a} {
    fill: none;
    stroke: ${getDiscHex(discType, color)};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }

  .${b}, .${c} {
    fill: ${getDiscHex(discType, color)};
  }

  .${c} {
    opacity: 0.1;
  }`}
          </style>
        </defs>
        <g
          id="Group_3994"
          data-name="Group 3994"
          transform="translate(-172 -5013.075)"
        >
          <g
            id="Group_3963"
            data-name="Group 3963"
            transform="translate(173 5014.075)"
          >
            <path
              id="Path_2763"
              data-name="Path 2763"
              className={a}
              d="M6267.422,463H6283.4V607.151H6172.706V463h21.671"
              transform="translate(-6172.706 -443.311)"
            />
            <path
              id="Path_2764"
              data-name="Path 2764"
              className={a}
              d="M6230.552,460.419a9.038,9.038,0,1,0-18.077,0h-26.112v17.919h70.3V460.419Z"
              transform="translate(-6163.221 -451.38)"
            />
            <ellipse
              id="Ellipse_340"
              data-name="Ellipse 340"
              className={b}
              cx="2.733"
              cy="2.733"
              rx="2.733"
              ry="2.733"
              transform="translate(55.346 6.967)"
            />
            <g
              id="Group_3987"
              data-name="Group 3987"
              transform="translate(16.697 38.396)"
            >
              <line
                id="Line_424"
                data-name="Line 424"
                className={a}
                x2="49.543"
                transform="translate(28.956 20.679)"
              />
              <line
                id="Line_425"
                data-name="Line 425"
                className={a}
                x2="49.543"
                transform="translate(28.956 50.233)"
              />
              <line
                id="Line_426"
                data-name="Line 426"
                className={a}
                x2="49.543"
                transform="translate(28.956 79.787)"
              />
              <line
                id="Line_427"
                data-name="Line 427"
                className={a}
                x2="49.543"
                transform="translate(28.956 109.341)"
              />
              <path
                id="Path_2765"
                data-name="Path 2765"
                className={a}
                d="M6198.546,485.531v7.127H6182.56V476.672h8.156"
                transform="translate(-6182.56 -472.208)"
              />
              <path
                id="Path_2766"
                data-name="Path 2766"
                className={a}
                d="M6185.268,484.586l3.566,3.473,10.4-14.021"
                transform="translate(-6180.678 -474.038)"
              />
              <path
                id="Path_2767"
                data-name="Path 2767"
                className={a}
                d="M6198.546,502.478V509.6H6182.56V493.618h8.156"
                transform="translate(-6182.56 -460.437)"
              />
              <path
                id="Path_2768"
                data-name="Path 2768"
                className={a}
                d="M6185.268,501.533l3.566,3.473,10.4-14.021"
                transform="translate(-6180.678 -462.266)"
              />
              <path
                id="Path_2769"
                data-name="Path 2769"
                className={a}
                d="M6198.546,520.538v7.127H6182.56V511.679h8.156"
                transform="translate(-6182.56 -447.892)"
              />
              <path
                id="Path_2770"
                data-name="Path 2770"
                className={a}
                d="M6185.268,519.593l3.566,3.473,10.4-14.021"
                transform="translate(-6180.678 -449.722)"
              />
              <path
                id="Path_2771"
                data-name="Path 2771"
                className={a}
                d="M6198.546,538.288v7.127H6182.56V529.429h8.156"
                transform="translate(-6182.56 -435.563)"
              />
              <path
                id="Path_2772"
                data-name="Path 2772"
                className={a}
                d="M6185.268,537.343l3.566,3.473,10.4-14.021"
                transform="translate(-6180.678 -437.392)"
              />
            </g>
          </g>
          <path
            id="Subtraction_4"
            data-name="Subtraction 4"
            className={c}
            d="M110.692,144.157H0V0H19.451V4.505h70.3V0h20.94V144.156Z"
            transform="translate(176.691 5036.528)"
          />
        </g>
      </svg>
    )
  }
}
