import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ComputerGraphSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 90.332 72.36', discType, color)}>
        <g transform="translate(-3253.615 -931.618)">
          <g transform="translate(3254.615 932.618)">
            <path
              d="M3303.365,946.108a15.828,15.828,0,1,1-15.828,15.828"
              transform="translate(-3257.903 -933.965)"
            />
            <path
              d="M3300.046,958.17h-15.828a15.827,15.827,0,0,1,15.828-15.828Z"
              transform="translate(-3257.572 -933.589)"
            />
            <path
              d="M3299.648,978.639l4.926-15.043,15.8-.86"
              transform="translate(-3259.113 -935.626)"
            />
            <line
              y1="10.594"
              x2="11.758"
              transform="translate(45.462 17.376)"
            />
            <rect width="70.462" height="49.868" transform="translate(9.361)" />
            <path
              d="M3342.947,1003.753h-88.332l9.361-15.734h70.461Z"
              transform="translate(-3254.615 -938.151)"
            />
            <line x2="60.073" transform="translate(15.531 54.685)" />
            <line x1="65.681" transform="translate(11.928 59.598)" />
            <rect
              width="88.332"
              height="4.758"
              transform="translate(0 65.601)"
            />
            <line x2="3.778" transform="translate(42.078 3.295)" />
          </g>
          <g transform="translate(3263.976 932.618)">
            <path
              d="M3303.365,946.108a15.828,15.828,0,1,1-15.828,15.828"
              transform="translate(-3267.264 -933.965)"
            />
            <line
              y1="10.594"
              x2="11.758"
              transform="translate(36.101 17.376)"
            />
            <rect width="70.462" height="49.868" />
            <line x2="60.073" transform="translate(6.17 54.685)" />
            <line x1="65.681" transform="translate(2.567 59.598)" />
            <line x2="3.778" transform="translate(32.718 3.295)" />
          </g>
        </g>
      </svg>
    )
  }
}
