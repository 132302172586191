import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookRibbonSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookRibbon', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 106.171 132.893', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-126.458 -2673.207)">
          <g transform="translate(127.508 2674.207)">
            <g transform="translate(0)">
              <circle
                className={a}
                cx="31.328"
                cy="31.328"
                r="31.328"
                transform="translate(18.631 18.934)"
              />
              <path
                className={a}
                d="M2461.581,3857.728h0a9.008,9.008,0,0,0,13.362,4.863h0a9.009,9.009,0,0,1,13.135,11.021h0a9.009,9.009,0,0,0,7.11,12.315h0a9.008,9.008,0,0,1,2.978,16.886h0a9.007,9.007,0,0,0-2.469,14h0a9.008,9.008,0,0,1-8.574,14.848h0a9.009,9.009,0,0,0-10.894,9.141h0a9.007,9.007,0,0,1-16.111,5.865h0a9.009,9.009,0,0,0-14.22,0h0a9.008,9.008,0,0,1-16.112-5.865h0a9.009,9.009,0,0,0-10.893-9.141h0a9.007,9.007,0,0,1-8.573-14.848h0a9.008,9.008,0,0,0-2.47-14h0a9.009,9.009,0,0,1,2.978-16.886h0a9.007,9.007,0,0,0,7.11-12.315h0a9.008,9.008,0,0,1,13.134-11.021h0a9.007,9.007,0,0,0,13.362-4.863h0A9.008,9.008,0,0,1,2461.581,3857.728Z"
                transform="translate(-2403.048 -3851.484)"
              />
              <path
                className={a}
                d="M2464.05,3914.167l13.8,36.607-13.624-4.943-6.968,12.707-13.8-36.607"
                transform="translate(-2388.584 -3829.048)"
              />
              <path
                className={a}
                d="M2445.339,3923.111l-16.126,35.645-6.137-13.129-13.913,4.058,16.126-35.645"
                transform="translate(-2400.859 -3829.093)"
              />
            </g>
            <g className={b} transform="translate(5.202 2.229)">
              <circle
                className={c}
                cx="31.328"
                cy="31.328"
                r="31.328"
                transform="translate(18.631 18.934)"
              />
              <path
                className={c}
                d="M2461.581,3857.728h0a9.008,9.008,0,0,0,13.362,4.863h0a9.009,9.009,0,0,1,13.135,11.021h0a9.009,9.009,0,0,0,7.11,12.315h0a9.008,9.008,0,0,1,2.978,16.886h0a9.007,9.007,0,0,0-2.469,14h0a9.008,9.008,0,0,1-8.574,14.848h0a9.009,9.009,0,0,0-10.894,9.141h0a9.007,9.007,0,0,1-16.111,5.865h0a9.009,9.009,0,0,0-14.22,0h0a9.008,9.008,0,0,1-16.112-5.865h0a9.009,9.009,0,0,0-10.893-9.141h0a9.007,9.007,0,0,1-8.573-14.848h0a9.008,9.008,0,0,0-2.47-14h0a9.009,9.009,0,0,1,2.978-16.886h0a9.007,9.007,0,0,0,7.11-12.315h0a9.008,9.008,0,0,1,13.134-11.021h0a9.007,9.007,0,0,0,13.362-4.863h0A9.008,9.008,0,0,1,2461.581,3857.728Z"
                transform="translate(-2403.048 -3851.484)"
              />
              <path
                className={c}
                d="M2464.05,3914.167l13.8,36.607-13.624-4.943-6.968,12.707-13.8-36.607"
                transform="translate(-2388.584 -3829.048)"
              />
              <path
                className={c}
                d="M2445.339,3923.111l-16.126,35.645-6.137-13.129-13.913,4.058,16.126-35.645"
                transform="translate(-2400.859 -3829.093)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
