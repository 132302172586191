import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ArrowGapSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    return (
      <svg {...getBasicClasses('0 0 41.405 17.967', discType, color)}>
        <g transform="translate(-0.27 -55.348)">
          <path
            d="M8.84,71.9,1.27,64.331,8.84,56.762"
            transform="translate(0 0)"
          />
          <line x1="16.057" transform="translate(1.87 64.241)" />
          <path
            d="M23.446,71.9l7.569-7.569-7.569-7.569"
            transform="translate(9.66 0)"
          />
          <line x2="16.057" transform="translate(24.018 64.241)" />
        </g>
      </svg>
    )
  }
}
