import React from 'react'

import { getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ApologizeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 114 106', discType, color)}>
        <path
          d="M68.1 17.2999C68.1 22.2999 64.9 29.7 58.8 29.7C52.7 29.7 49.5 22.2999 49.5 17.2999C49.8 12.1999 54.2 8.29995 59.3 8.59995C64.1 8.79995 67.8 12.5999 68.1 17.2999Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M23.9982 83.2436C23.9982 86.2644 22.0649 90.7353 18.3795 90.7353C14.6941 90.7353 12.7607 86.2644 12.7607 83.2436C12.942 80.1623 15.6003 77.8061 18.6816 77.9873C21.5816 78.1082 23.817 80.404 23.9982 83.2436Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M64.9982 83.2436C64.9982 86.2644 63.0649 90.7353 59.3795 90.7353C55.6941 90.7353 53.7607 86.2644 53.7607 83.2436C53.942 80.1623 56.6003 77.8061 59.6816 77.9873C62.5816 78.1082 64.817 80.404 64.9982 83.2436Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M103.998 83.2436C103.998 86.2644 102.065 90.7353 98.3795 90.7353C94.6941 90.7353 92.7607 86.2644 92.7607 83.2436C92.942 80.1623 95.6003 77.8061 98.6816 77.9873C101.582 78.1082 103.817 80.404 103.998 83.2436Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M59.0768 48.2111C52.3008 48.2111 46.2092 45.281 42 40.6186C42.1993 37.8806 42.5031 36.4603 42.7791 35.7038C42.9183 35.3223 43.0402 35.1392 43.1155 35.0471C43.1809 34.9671 43.2264 34.931 43.3315 34.8479L43.3798 34.8095C45.2297 33.4921 47.3129 32.5088 49.553 32.0006C54.7526 36.9879 63.0386 36.9787 68.2988 32C70.5485 32.51 72.64 33.4991 74.4959 34.8248C74.5047 34.831 74.5135 34.8371 74.5225 34.8431C74.5624 34.8697 74.5965 34.8921 74.6263 34.9116C74.7449 34.9894 74.794 35.0216 74.8649 35.0955C74.9431 35.177 75.0736 35.3448 75.2237 35.7199C75.5223 36.4666 75.8477 37.9013 76.0495 40.7332C71.8442 45.3289 65.7971 48.2111 59.0768 48.2111Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M18.5352 102C13.9581 102 9.84329 99.9563 7 96.7045C7.13464 94.7948 7.33983 93.8043 7.52628 93.2766C7.62031 93.0106 7.70263 92.8828 7.7535 92.8186C7.79767 92.7628 7.82844 92.7376 7.8994 92.6796L7.93205 92.6529C9.1816 91.734 10.5888 91.0482 12.1019 90.6938C15.6142 94.1722 21.2113 94.1658 24.7645 90.6934C26.2842 91.0491 27.697 91.739 28.9506 92.6635C28.9565 92.6679 28.9625 92.6722 28.9685 92.6763C28.9955 92.6949 29.0185 92.7105 29.0386 92.7241C29.1187 92.7783 29.152 92.8008 29.1998 92.8524C29.2526 92.9092 29.3408 93.0262 29.4422 93.2879C29.6439 93.8086 29.8637 94.8093 30 96.7844C27.1594 99.9898 23.0746 102 18.5352 102Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M59.5352 102C54.9581 102 50.8433 99.9563 48 96.7045C48.1346 94.7948 48.3398 93.8043 48.5263 93.2766C48.6203 93.0106 48.7026 92.8828 48.7535 92.8186C48.7977 92.7628 48.8284 92.7376 48.8994 92.6796L48.9321 92.6529C50.1816 91.734 51.5888 91.0482 53.1019 90.6938C56.6142 94.1722 62.2113 94.1658 65.7645 90.6934C67.2842 91.0491 68.697 91.739 69.9506 92.6635C69.9565 92.6679 69.9625 92.6722 69.9685 92.6763C69.9955 92.6949 70.0185 92.7105 70.0386 92.7241C70.1187 92.7783 70.152 92.8008 70.1998 92.8524C70.2526 92.9092 70.3408 93.0262 70.4422 93.2879C70.6439 93.8086 70.8637 94.8093 71 96.7844C68.1594 99.9898 64.0746 102 59.5352 102Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M98.5352 102C93.9581 102 89.8433 99.9563 87 96.7045C87.1346 94.7948 87.3398 93.8043 87.5263 93.2766C87.6203 93.0106 87.7026 92.8828 87.7535 92.8186C87.7977 92.7628 87.8284 92.7376 87.8994 92.6796L87.9321 92.6529C89.1816 91.734 90.5888 91.0482 92.1019 90.6938C95.6142 94.1722 101.211 94.1658 104.765 90.6934C106.284 91.0491 107.697 91.739 108.951 92.6635C108.957 92.6679 108.962 92.6722 108.969 92.6763C108.995 92.6949 109.019 92.7105 109.039 92.7241C109.119 92.7783 109.152 92.8008 109.2 92.8524C109.253 92.9092 109.341 93.0262 109.442 93.2879C109.644 93.8086 109.864 94.8093 110 96.7844C107.159 99.9898 103.075 102 98.5352 102Z"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.2"
        />
        <path
          d="M59 49C72.2548 49 83 38.2548 83 25C83 11.7452 72.2548 1 59 1C45.7452 1 35 11.7452 35 25C35 38.2548 45.7452 49 59 49Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 102C26.5081 102 33 95.5081 33 87.5C33 79.4919 26.5081 73 18.5 73C10.4919 73 4 79.4919 4 87.5C4 95.5081 10.4919 102 18.5 102Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.5 102C67.5081 102 74 95.5081 74 87.5C74 79.4919 67.5081 73 59.5 73C51.4919 73 45 79.4919 45 87.5C45 95.5081 51.4919 102 59.5 102Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M98.5 102C106.508 102 113 95.5081 113 87.5C113 79.4919 106.508 73 98.5 73C90.4919 73 84 79.4919 84 87.5C84 95.5081 90.4919 102 98.5 102Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.9004 40.7C41.3004 34.7 42.2004 34.1999 42.7004 33.7999C44.8004 32.2999 47.2004 31.2 49.8004 30.7C54.6004 35.7 62.6004 35.7999 67.6004 30.9999L67.9004 30.7C70.5004 31.2 72.9004 32.2999 75.0004 33.7999C75.6004 34.1999 76.6004 34.5999 77.0004 40.8999"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.1 16.2999C68.1 21.2999 64.9 28.7 58.8 28.7C52.7 28.7 49.5 21.2999 49.5 16.2999C49.8 11.1999 54.2 7.29995 59.3 7.59995C64.1 7.79995 67.8 11.5999 68.1 16.2999Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.56445 96.9853C7.80612 93.3603 8.34986 93.0582 8.65195 92.8165C9.9207 91.9103 11.3707 91.2457 12.9415 90.9436C15.8415 93.9644 20.6749 94.0248 23.6957 91.1248L23.877 90.9436C25.4478 91.2457 26.8978 91.9103 28.1665 92.8165C28.529 93.0582 29.1332 93.2999 29.3749 97.1061"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.9982 82.2436C23.9982 85.2644 22.0649 89.7353 18.3795 89.7353C14.6941 89.7353 12.7607 85.2644 12.7607 82.2436C12.942 79.1623 15.6003 76.8061 18.6816 76.9873C21.5816 77.1082 23.817 79.404 23.9982 82.2436Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.5645 96.9853C48.8061 93.3603 49.3499 93.0582 49.6519 92.8165C50.9207 91.9103 52.3707 91.2457 53.9415 90.9436C56.8415 93.9644 61.6749 94.0248 64.6957 91.1248L64.877 90.9436C66.4478 91.2457 67.8978 91.9103 69.1665 92.8165C69.529 93.0582 70.1332 93.2999 70.3749 97.1061"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.9982 82.2436C64.9982 85.2644 63.0649 89.7353 59.3795 89.7353C55.6941 89.7353 53.7607 85.2644 53.7607 82.2436C53.942 79.1623 56.6003 76.8061 59.6816 76.9873C62.5816 77.1082 64.817 79.404 64.9982 82.2436Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.5645 96.9853C87.8061 93.3603 88.3499 93.0582 88.6519 92.8165C89.9207 91.9103 91.3707 91.2457 92.9415 90.9436C95.8415 93.9644 100.675 94.0248 103.696 91.1248L103.877 90.9436C105.448 91.2457 106.898 91.9103 108.167 92.8165C108.529 93.0582 109.133 93.2999 109.375 97.1061"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M103.998 82.2436C103.998 85.2644 102.065 89.7353 98.3795 89.7353C94.6941 89.7353 92.7607 85.2644 92.7607 82.2436C92.942 79.1623 95.6003 76.8061 98.6816 76.9873C101.582 77.1082 103.817 79.404 103.998 82.2436Z"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 72V57H99V72"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.48 73.0004L59 49"
          stroke={getDiscHex(discType, color)}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}
