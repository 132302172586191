import { getBasicClasses } from '@dashboard/lib/svg'
import React from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const PodiumStarsSvg: React.FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 30 29', discType, color)}>
      <path
        d="M10.7126 22.1532H1.71265V27.0532H10.7126V22.1532Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7126 13.1532H10.7126V27.1532H19.7126V13.1532Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7126 17.2532H19.7126V27.1532H28.7126V17.2532Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4127 1.25317L16.7127 3.95317L19.7127 4.35317L17.6127 6.45317L18.1127 9.35317L15.5127 7.95317L12.9127 9.35317L13.4127 6.45317L11.3127 4.35317L14.3127 3.95317L15.4127 1.25317Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PodiumStarsSvg
