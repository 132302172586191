import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookWarningSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 29 29', discType, color)}>
        <g transform="translate(-0.719 -3358.467)">
          <circle
            cx="13.5"
            cy="13.5"
            r="13.5"
            transform="translate(1.719 3359.467)"
          />
          <line x2="27" transform="translate(1.719 3372.967)" />
          <path d="M15.219,3359.467a20.662,20.662,0,0,1,5.4,13.5,20.663,20.663,0,0,1-5.4,13.5,19.575,19.575,0,0,1,0-27Z" />
        </g>
      </svg>
    )
  }
}
