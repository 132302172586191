import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class LargeSunSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('LargeSunSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.5px;}`}</style>
        </defs>
        <path
          className={a}
          d="M50.36,83.39a32.3,32.3,0,1,1,32.3-32.3A32.34,32.34,0,0,1,50.36,83.39Zm0-62.6a30.3,30.3,0,1,0,30.3,30.3A30.33,30.33,0,0,0,50.36,20.79Z"
        />
        <path
          className={a}
          d="M50.36,12.61a1,1,0,0,1-1-1V3.5a1,1,0,0,1,2,0v8.11A1,1,0,0,1,50.36,12.61Z"
        />
        <path
          className={a}
          d="M50.36,97.09a1,1,0,0,1-1-1V88a1,1,0,0,1,2,0v8.1A1,1,0,0,1,50.36,97.09Z"
        />
        <path
          className={a}
          d="M96.65,50.8h-8.1a1,1,0,0,1,0-2h8.1a1,1,0,0,1,0,2Z"
        />
        <path
          className={a}
          d="M12.17,50.8H4.06a1,1,0,1,1,0-2h8.11a1,1,0,1,1,0,2Z"
        />
        <path
          className={a}
          d="M77.36,23.79a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41l5.73-5.73a1,1,0,1,1,1.41,1.41L78.07,23.5A1,1,0,0,1,77.36,23.79Z"
        />
        <path
          className={a}
          d="M17.62,83.53a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41l5.73-5.73a1,1,0,0,1,1.41,1.41l-5.73,5.73A1,1,0,0,1,17.62,83.53Z"
        />
        <path
          className={a}
          d="M83.09,83.53a1,1,0,0,1-.7-.29l-5.73-5.73a1,1,0,0,1,0-1.41,1,1,0,0,1,1.41,0l5.73,5.73a1,1,0,0,1,0,1.41A1,1,0,0,1,83.09,83.53Z"
        />
        <path
          className={a}
          d="M23.35,23.79a1,1,0,0,1-.7-.29l-5.73-5.73a1,1,0,0,1,0-1.41,1,1,0,0,1,1.41,0l5.73,5.73a1,1,0,0,1,0,1.41A1,1,0,0,1,23.35,23.79Z"
        />
      </svg>
    )
  }
}
