import React from 'react'

const GroupWithCheck = () => {
  return (
    <svg
      width="50"
      height="32"
      viewBox="0 0 50 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0378 7.76617C30.3363 8.41294 30.4358 9.00995 30.4358 9.80597C30.4358 12.8905 27.9482 15.3781 24.8637 15.3781C21.7791 15.3781 19.2915 12.8905 19.2915 9.80597C19.2915 6.72139 21.7791 4.23383 24.8637 4.23383C26.0079 4.23383 27.1025 4.58208 27.998 5.1791"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1072 12.1942C16.1072 15.2787 13.6196 17.7663 10.535 17.7663C7.45046 17.7663 4.96289 15.2787 4.96289 12.1942C4.96289 9.10959 7.45046 6.62203 10.535 6.62203C13.6196 6.57228 16.1072 9.10959 16.1072 12.1942Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2664 12.1942C44.2664 15.2787 41.7788 17.7663 38.6942 17.7663C35.6096 17.7663 33.1221 15.2787 33.1221 12.1942C33.1221 9.10959 35.6096 6.62203 38.6942 6.62203C41.729 6.57228 44.2664 9.10959 44.2664 12.1942Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.1671 3.48756L25.2119 12.4925L21.0825 9.75623L23.1721 7.71642L25.2119 9.85573L31.3313 1L34.1671 3.48756Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5749 28.0646H17.3013C15.8087 28.0646 14.7142 26.6218 15.1122 25.179C16.356 20.9502 18.8933 17.8158 24.9132 17.8158C30.1371 17.8158 33.4704 20.9004 34.7142 25.179C35.162 26.6218 34.0674 28.0646 32.5749 28.0646Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2217 21.9454C34.6147 21.1494 36.4058 20.7513 38.6446 20.7513C43.8685 20.7513 47.2018 23.8359 48.4456 28.1145C48.8436 29.5573 47.7491 31.0001 46.2565 31.0001H30.9829C29.4904 31.0001 28.3958 29.5573 28.7938 28.1145"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7342 28.0648C21.1322 29.5075 20.0376 30.9503 18.5451 30.9503H3.27145C1.77891 30.9503 0.684399 29.5075 1.08241 28.0648C2.32619 23.8359 4.86348 20.7016 10.8834 20.7016C12.8734 20.7016 14.565 21.1493 15.958 21.8956"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GroupWithCheck
