import React from 'react'

import {
  getDiscHex,
  getSVGClasses,
  getPlaybookBasicClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookCalendarSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookCalendar', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 136.371 124.413', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-114.665 -1039.81)">
          <g transform="translate(115.664 1040.81)">
            <g transform="translate(0 0)">
              <path
                className={a}
                d="M5928.6,1401.447h21.76v107.431H5823.1V1401.447h21.513"
                transform="translate(-5823.102 -1392.155)"
              />
              <line
                className={a}
                x2="65.746"
                transform="translate(30.592 9.293)"
              />
              <path
                className={a}
                d="M5843.224,1401.447H5823.1v25.018h127.26v-25.018H5928.6"
                transform="translate(-5823.102 -1392.155)"
              />
              <line
                className={a}
                x1="64.545"
                transform="translate(30.592 9.293)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(34.504 45.021)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(58.17 45.021)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(81.835 45.021)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(105.501 45.021)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(10.839 67.082)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(34.504 67.082)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(58.17 67.082)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(81.835 67.082)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(105.501 67.082)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(10.839 89.143)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(34.504 89.143)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(58.17 89.143)"
              />
              <rect
                className={a}
                width="13.302"
                height="13.302"
                transform="translate(81.835 89.143)"
              />
              <rect
                className={a}
                width="8.216"
                height="15.314"
                transform="translate(21.514 0)"
              />
              <rect
                className={a}
                width="8.216"
                height="15.314"
                transform="translate(96.338 0)"
              />
            </g>
            <g className={b} transform="translate(7.111 5.689)">
              <path
                className={c}
                d="M5928.6,1401.447h21.76v107.431H5823.1V1401.447h21.513"
                transform="translate(-5823.102 -1392.155)"
              />
              <line
                className={a}
                x2="65.746"
                transform="translate(30.592 9.293)"
              />
              <path
                className={c}
                d="M5843.224,1401.447H5823.1v25.018h127.26v-25.018H5928.6"
                transform="translate(-5823.102 -1392.155)"
              />
              <line
                className={a}
                x1="64.545"
                transform="translate(30.592 9.293)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(34.504 45.021)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(58.17 45.021)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(81.835 45.021)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(105.501 45.021)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(10.839 67.082)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(34.504 67.082)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(58.17 67.082)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(81.835 67.082)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(105.501 67.082)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(10.839 89.143)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(34.504 89.143)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(58.17 89.143)"
              />
              <rect
                className={c}
                width="13.302"
                height="13.302"
                transform="translate(81.835 89.143)"
              />
              <rect
                className={c}
                width="8.216"
                height="15.314"
                transform="translate(21.514 0)"
              />
              <rect
                className={c}
                width="8.216"
                height="15.314"
                transform="translate(96.338 0)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
