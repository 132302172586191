import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookGroupSparkleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookGroupSparkle',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 118.507 134.993', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1314 -2436)">
          <g transform="translate(1315 2437)">
            <path
              className={a}
              d="M7390.287,942.6a11.5,11.5,0,1,1-11.5-11.5A11.5,11.5,0,0,1,7390.287,942.6Z"
              transform="translate(-7320.952 -911.346)"
            />
            <circle
              className={a}
              cx="9.368"
              cy="9.368"
              r="9.368"
              transform="translate(6.135 17.951)"
            />
            <circle
              className={a}
              cx="9.368"
              cy="9.368"
              r="9.368"
              transform="translate(89.986 17.951)"
            />
            <path
              className={a}
              d="M7355.671,947.3h-15.626a7.1,7.1,0,0,0-7.1,7.273l.374,15.369c.359,2.7,2.919,4.76,6.083,5.059l1.444,34.231h14.257l2.472-34.237c3.124-.328,5.643-2.38,6-5.053l.374-15.369a7.1,7.1,0,0,0-7.1-7.273Z"
              transform="translate(-7332.947 -905.688)"
            />
            <path
              className={a}
              d="M7417.816,947.3h-15.627a7.1,7.1,0,0,0-7.1,7.273l.376,15.369c.358,2.7,2.919,4.76,6.083,5.059l1.444,34.231h14.256l2.474-34.237c3.123-.328,5.643-2.38,6-5.053l.373-15.369a7.1,7.1,0,0,0-7.1-7.273Z"
              transform="translate(-7311.239 -905.688)"
            />
            <path
              className={a}
              d="M7390.838,952h-20.9a9.5,9.5,0,0,0-9.495,9.729l.5,20.557c.48,3.607,3.9,6.368,8.137,6.768l1.93,45.788h19.071l3.306-45.8c4.179-.437,7.548-3.183,8.026-6.76l.5-20.557A9.5,9.5,0,0,0,7392.42,952Z"
              transform="translate(-7323.342 -904.046)"
            />
            <path
              className={a}
              d="M7371.777,933.18l-2.877.557a6.747,6.747,0,0,0-5.358,5.436l-.489,2.73-.665-2.989a6.749,6.749,0,0,0-5.2-5.138l-2.855-.6,2.721-.475a6.746,6.746,0,0,0,5.473-5.409l.53-2.84.437,2.619a6.746,6.746,0,0,0,5.313,5.5Z"
              transform="translate(-7325.477 -913.668)"
            />
            <path
              className={a}
              d="M7404.948,928.363l-3.926.761a9.207,9.207,0,0,0-7.311,7.417l-.667,3.725-.907-4.078a9.2,9.2,0,0,0-7.1-7.01l-3.9-.816,3.712-.646a9.2,9.2,0,0,0,7.467-7.379l.724-3.877.6,3.573a9.205,9.205,0,0,0,7.25,7.506Z"
              transform="translate(-7316.111 -916.461)"
            />
          </g>
          <g className={b} transform="translate(1316.649 2457.149)">
            <path
              className={c}
              d="M7390.287,942.6a11.5,11.5,0,1,1-11.5-11.5A11.5,11.5,0,0,1,7390.287,942.6Z"
              transform="translate(-7320.952 -929.297)"
            />
            <circle
              className={c}
              cx="9.368"
              cy="9.368"
              r="9.368"
              transform="translate(6.135)"
            />
            <circle
              className={c}
              cx="9.368"
              cy="9.368"
              r="9.368"
              transform="translate(89.986)"
            />
            <path
              className={c}
              d="M7355.671,947.3h-15.626a7.1,7.1,0,0,0-7.1,7.273l.374,15.369c.359,2.7,2.919,4.76,6.083,5.059l1.444,34.231h14.257l2.472-34.237c3.124-.328,5.643-2.38,6-5.053l.374-15.369a7.1,7.1,0,0,0-7.1-7.273Z"
              transform="translate(-7332.947 -923.64)"
            />
            <path
              className={c}
              d="M7417.816,947.3h-15.627a7.1,7.1,0,0,0-7.1,7.273l.376,15.369c.358,2.7,2.919,4.76,6.083,5.059l1.444,34.231h14.256l2.474-34.237c3.123-.328,5.643-2.38,6-5.053l.373-15.369a7.1,7.1,0,0,0-7.1-7.273Z"
              transform="translate(-7311.239 -923.64)"
            />
            <path
              className={c}
              d="M7390.838,952h-20.9a9.5,9.5,0,0,0-9.495,9.729l.5,20.557c.48,3.607,3.9,6.368,8.137,6.768l1.93,45.788h19.071l3.306-45.8c4.179-.437,7.548-3.183,8.026-6.76l.5-20.557A9.5,9.5,0,0,0,7392.42,952Z"
              transform="translate(-7323.342 -921.997)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
