import React from 'react'

import {
  getPlaybookBasicClasses,
  getSVGClasses,
  getDiscHex,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class MagnifyingGlassPersonSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d, e, f } = getSVGClasses(
      'MagnifyingGlassPersonSVG',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 131.098 131.48', discType, color)}>
        <defs>
          <style>{`.${a},.${b},.${c}{fill:none;}.${a},.${b},.${c},.${e},.${f}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-width:1.929px;}.${a},.${f}{stroke-miterlimit:10;}.${b},.${c},.${e}{stroke-linejoin:round;}.${c}{stroke-linecap:round;}.${d}{opacity:0.2;}.${e},.${f}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-119.493 -7091.036)">
          <g transform="translate(120.458 7092)">
            <circle className={a} cx="49.667" cy="49.667" r="49.667" />
            <path
              className={b}
              d="M73.881,59.117l34.883,34.169a11.05,11.05,0,0,1,0,15.345h0a11.05,11.05,0,0,1-15.345,0L57.887,73.823"
              transform="translate(17.307 17.822)"
            />
            <line
              className={c}
              x2="22.794"
              y2="22.794"
              transform="translate(91.863 93.064)"
            />
            <g transform="translate(12.261 13.639)">
              <path
                className={a}
                d="M11.1,68.267c.8-12.389,2.684-13.5,3.809-14.293A37.13,37.13,0,0,1,29.6,47.524a26,26,0,0,0,36.722.638l.638-.638a36.505,36.505,0,0,1,14.685,6.45c1.188.786,3.224,1.684,4.169,14.661"
                transform="translate(-11.102 0.181)"
              />
              <path
                className={a}
                d="M62.766,30.131c0,10.325-6.6,25.537-19.2,25.537S24.393,40.456,24.393,30.131a19.2,19.2,0,0,1,38.328,0"
                transform="translate(-6.515 -12.059)"
              />
            </g>
          </g>
          <g className={d} transform="translate(132.719 7105.639)">
            <path
              className={e}
              d="M73.881,59.117l34.883,34.169a11.05,11.05,0,0,1,0,15.345h0a11.05,11.05,0,0,1-15.345,0L57.887,73.823"
              transform="translate(5.045 4.183)"
            />
            <line
              className={c}
              x2="22.794"
              y2="22.794"
              transform="translate(79.602 79.425)"
            />
            <g transform="translate(0 0)">
              <path
                className={f}
                d="M11.1,68.267c.8-12.389,2.684-13.5,3.809-14.293A37.13,37.13,0,0,1,29.6,47.524a26,26,0,0,0,36.722.638l.638-.638a36.505,36.505,0,0,1,14.685,6.45c1.188.786,4.169,14.661,4.169,14.661S65.585,85,48.959,85.3,11.1,68.267,11.1,68.267Z"
                transform="translate(-11.102 0.181)"
              />
              <path
                className={f}
                d="M62.766,30.131c0,10.325-6.6,25.537-19.2,25.537S24.393,40.456,24.393,30.131a19.2,19.2,0,0,1,38.328,0"
                transform="translate(-6.515 -12.059)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
