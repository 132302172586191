import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookHandshakeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookHandshake', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 135.026 86.446', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-98.626 -1189.702)">
          <g transform="translate(100 1191.076)">
            <rect
              className={a}
              width="14.043"
              height="47.372"
              transform="translate(24.653 1.078) rotate(31.359)"
            />
            <rect
              className={a}
              width="14.043"
              height="47.372"
              transform="translate(132.278 40.452) rotate(148.641)"
            />
            <path
              className={a}
              d="M3160.261,1184.515l6.285,3.173s6.16.635,9.427,2.54"
              transform="translate(-3126 -1171.411)"
            />
            <rect
              className={a}
              width="10.5"
              height="24.742"
              rx="3.82"
              transform="matrix(0.813, 0.583, -0.583, 0.813, 42.039, 46.21)"
            />
            <rect
              className={a}
              width="10.5"
              height="24.742"
              rx="3.82"
              transform="translate(49.737 53.908) rotate(35.654)"
            />
            <path
              className={a}
              d="M3209.107,1216.907a6.5,6.5,0,0,1,2.443,8.259h0c-1.382,2.4-4.887,2.963-7.826,1.267l-5.966-3.622"
              transform="translate(-3111.96 -1159.282)"
            />
            <path
              className={a}
              d="M3187.254,1215.138l14.438,8.336c2.939,1.7,4.146,5.109,2.7,7.618h0c-1.45,2.511-5.009,3.171-7.947,1.475l-4.1-2.4"
              transform="translate(-3115.893 -1159.945)"
            />
            <path
              className={a}
              d="M3181.258,1220.179l14.438,8.336a5.167,5.167,0,0,1,1.571,7.855c-2.806,3.564-10.288,1.773-13.227.076l-4.665-2.412"
              transform="translate(-3118.843 -1158.057)"
            />
            <rect
              className={a}
              width="10.5"
              height="18.062"
              rx="3.82"
              transform="matrix(0.813, 0.583, -0.583, 0.813, 56.214, 62.88)"
            />
            <rect
              className={a}
              width="10.5"
              height="15.795"
              rx="3.82"
              transform="translate(29.669 45.24) rotate(35.654)"
            />
            <path
              className={a}
              d="M3220.507,1185.478s-3.618,1.362-9.43,0-17.681-6.787-31.252-.716c0,0-8.792,9.689-13.958,20.28,0,0,5.9,4.372,13.958-1.549l7.484-7.452h2.7l38.762,24.373c2.938,1.7,2.978,6.355,1.528,8.867h0c-1.45,2.511-5.007,3.171-7.947,1.473l-14.437-8.336"
              transform="translate(-3123.902 -1172.374)"
            />
            <line
              className={a}
              y1="6.688"
              x2="8.387"
              transform="translate(107.336 42.744)"
            />
            <line
              className={a}
              x2="8.267"
              y2="5.052"
              transform="translate(15.096 46.088)"
            />
          </g>
          <rect
            className={b}
            width="14.043"
            height="47.372"
            transform="translate(124.653 1192.154) rotate(31.359)"
          />
          <rect
            className={b}
            width="14.043"
            height="47.372"
            transform="translate(232.278 1231.528) rotate(148.641)"
          />
        </g>
      </svg>
    )
  }
}
