import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class MegaphoneSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('MegaphoneSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a} {fill:${getDiscHex(discType, color)};}`}</style>
        </defs>
        <path
          className={a}
          d="M77.2,38.62a1,1,0,0,1-.79-.38,1,1,0,0,1,.17-1.41l15.6-12.16a1,1,0,1,1,1.23,1.57L77.81,38.41A1,1,0,0,1,77.2,38.62Z"
        />
        <path
          className={a}
          d="M92.79,67.66a1,1,0,0,1-.61-.21L76.58,55.28a1,1,0,0,1,1.23-1.58l15.6,12.17a1,1,0,0,1,.17,1.4A1,1,0,0,1,92.79,67.66Z"
        />
        <path
          className={a}
          d="M96.77,46.73H76a1,1,0,0,1,0-2H96.77a1,1,0,0,1,0,2Z"
        />
        <path
          className={a}
          d="M65.51,77.47H57.29a1,1,0,0,1-1-1V17.64a1,1,0,0,1,1-1h8.22a1,1,0,0,1,1,1V76.47A1,1,0,0,1,65.51,77.47Zm-7.22-2h6.22V18.64H58.29Z"
        />
        <path
          className={a}
          d="M8.39,58.66h-6a1,1,0,0,1-1-1V39.59a1,1,0,0,1,1-1h6a1,1,0,0,1,0,2h-5V56.66h5a1,1,0,0,1,0,2Z"
        />
        <path
          className={a}
          d="M36.82,83.36h-14a1,1,0,0,1-1-.74L16.28,61.45a1,1,0,0,1,1.93-.51l5.37,20.42H35.52L30.63,62.54a1,1,0,1,1,1.94-.5l5.22,20.07a1,1,0,0,1-.18.86A1,1,0,0,1,36.82,83.36Z"
        />
        <path
          className={a}
          d="M57.29,74.8a1,1,0,0,1-.71-.29C43,61,12.59,61.1,12.3,61.1a.78.78,0,0,1-.3,0,5.41,5.41,0,0,1-3.86-5.21V40.52c0-2.41,1.54-5,4.11-5.26,2.23-.2,4.56-.22,7-.24,10.93-.11,23.32-.23,37.29-14.93a1,1,0,0,1,1.73.69v53a1,1,0,0,1-.62.93A1,1,0,0,1,57.29,74.8ZM12.45,59.1A107,107,0,0,1,29.7,60.71c11.65,2,20.58,5.68,26.59,10.81V23.22C42.4,36.8,29.63,36.92,19.29,37c-2.42,0-4.71,0-6.86.23-1.41.13-2.29,1.8-2.29,3.27V55.85A3.42,3.42,0,0,0,12.45,59.1Z"
        />
        <path
          className={a}
          d="M57.29,74.8a1,1,0,0,1-.71-.29C43,61,12.59,61.1,12.3,61.1a.78.78,0,0,1-.3,0,5.41,5.41,0,0,1-3.86-5.21V40.52c0-2.41,1.54-5,4.11-5.26,2.23-.2,4.56-.22,7-.24,10.93-.11,23.32-.23,37.29-14.93a1,1,0,0,1,1.73.69v53a1,1,0,0,1-.62.93A1,1,0,0,1,57.29,74.8ZM12.45,59.1A107,107,0,0,1,29.7,60.71c11.65,2,20.58,5.68,26.59,10.81V23.22C42.4,36.8,29.63,36.92,19.29,37c-2.42,0-4.71,0-6.86.23-1.41.13-2.29,1.8-2.29,3.27V55.85A3.42,3.42,0,0,0,12.45,59.1Z"
        />
      </svg>
    )
  }
}
