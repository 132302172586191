import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class HeadQuestionMarkSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('HeadQuestionMarkSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 106.155 131.309', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}.${b}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}</style>
        </defs>
        <g transform="translate(-15.574 -9.463)">
          <path
            className={a}
            d="M-6592.533-8484.228h0l-50.58-8.592-2.437-31.311a109.948,109.948,0,0,1-11.788-24.359,64.192,64.192,0,0,1-3.419-22.459,46.164,46.164,0,0,1,5.3-19.908,52.829,52.829,0,0,1,14.372-16.705,36.544,36.544,0,0,1,11.72-5.638,56.935,56.935,0,0,1,16.095-2.328,51.593,51.593,0,0,1,11.075,1.172,42.807,42.807,0,0,1,10.71,3.864,38.458,38.458,0,0,1,16.977,17.905c2.376,4.956,2.853,9.306,3.314,13.513v.006c.417,3.8.811,7.387,2.659,10.848,1.676,3.142,3.359,5.8,4.845,8.149l.005.008.005.009a58.307,58.307,0,0,1,4.582,8.117,22.093,22.093,0,0,1,1.827,9.026c0,1.827-3.461,1.861-6.8,1.895-2.385.023-4.636.046-5.578.7-2.008,1.4-1.133,4.981-.121,9.132,1.161,4.758,2.476,10.147-.158,13.752a7.377,7.377,0,0,1-4.455,2.771,28.8,28.8,0,0,1-5.762.539c-4.1.115-8.333.234-10.71,3.665-.757,1.093-1.689,4.5-1.833,14.552-.085,5.985.156,11.621.158,11.677Zm-26.452-37.665a5.594,5.594,0,0,0-5.588,5.588,5.594,5.594,0,0,0,5.588,5.588,5.6,5.6,0,0,0,5.59-5.588A5.6,5.6,0,0,0-6618.985-8521.893Zm4.506-60.429a13.466,13.466,0,0,1,9.583,3.97,13.463,13.463,0,0,1,3.97,9.584c0,5.294-2.847,9.109-8.462,11.341-2.542,1.01-7.048,2.214-14.077,2.214v25.943h10.144v-18.679h.009a22.421,22.421,0,0,0,8.783-1.774,22.527,22.527,0,0,0,7.181-4.841,22.532,22.532,0,0,0,4.841-7.18,22.444,22.444,0,0,0,1.775-8.793,22.445,22.445,0,0,0-1.775-8.794,22.526,22.526,0,0,0-4.841-7.18,22.527,22.527,0,0,0-7.181-4.841,22.455,22.455,0,0,0-8.792-1.775,22.581,22.581,0,0,0-18.731,9.96l6.333,6.821A13.555,13.555,0,0,1-6614.479-8582.321Z"
            transform="translate(6679 8625)"
          />
          <g transform="translate(1.378 3)">
            <path
              className={b}
              d="M30.068,96.581h0C9.457,63.964,9.691,34.589,34.42,15.238,46.936,5.444,85.021,0,99.329,29.839c4.47,9.32,2.081,16.744,5.824,23.757,5.6,10.491,11.009,15.054,10.982,24.674-.008,3.04-9.855.991-12.07,2.531-4.2,2.919,4.539,15.725-.272,22.309-4.48,6.129-15.848.229-20.4,6.8-2.589,3.734-1.632,25.573-1.632,25.573l-49.314-8.376Z"
              transform="translate(0 0)"
            />
            <path
              className={b}
              d="M53.974,85.037V66.359A22.589,22.589,0,1,0,35.243,31.14l6.334,6.821A13.556,13.556,0,0,1,66.371,45.54c0,12.169-15.054,13.554-22.54,13.554V85.037Z"
              transform="translate(8.326 5.694)"
            />
            <circle
              className={b}
              cx="5.588"
              cy="5.588"
              r="5.588"
              transform="translate(51.049 98.108)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
