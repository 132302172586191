import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class SmilingFrowningMasksSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('SmilingFrowningMasksSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 134.287 123.161', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}.${b}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}</style>
        </defs>
        <g transform="translate(-1.495 -5.878)">
          <path
            className={a}
            d="M-6624.571-9177.415a9.126,9.126,0,0,1-3.859-.862,174.924,174.924,0,0,1-15.74-14.693,92.448,92.448,0,0,1-10.821-13.57c-3.264-5.179-4.918-9.705-4.918-13.455v-45.089a109.506,109.506,0,0,0,11.325,4.1,106.071,106.071,0,0,0,11.39,2.817,70.3,70.3,0,0,0,12.752,1.28,71.262,71.262,0,0,0,12.948-1.28,99.6,99.6,0,0,0,11.276-2.817,96.716,96.716,0,0,0,10.987-4.1V-9220c0,3.75-1.655,8.277-4.919,13.455a92.433,92.433,0,0,1-10.822,13.57,174.892,174.892,0,0,1-15.739,14.692A9.124,9.124,0,0,1-6624.571-9177.415Zm-19.617-38.87a19.622,19.622,0,0,0,1.552,7.687,19.676,19.676,0,0,0,4.231,6.275,19.689,19.689,0,0,0,6.276,4.232,19.634,19.634,0,0,0,7.686,1.552,19.634,19.634,0,0,0,7.686-1.552,19.68,19.68,0,0,0,6.277-4.232,19.676,19.676,0,0,0,4.231-6.275,19.622,19.622,0,0,0,1.552-7.687h-39.492Z"
            transform="translate(6664.405 9272.962)"
          />
          <path
            className={b}
            d="M53.306,42.018a19.745,19.745,0,0,1-39.49,0h39.49Z"
            transform="translate(4.401 13.661)"
          />
          <path
            className={b}
            d="M58.088,79.037c2.215-6.988,8.839-12.611,19.744-12.611s16.6,5.784,19.745,12.611"
            transform="translate(21.612 23.149)"
          />
          <path
            className={b}
            d="M15.542,30.758a3.807,3.807,0,1,1,7.615,0"
            transform="translate(5.072 7.803)"
          />
          <path
            className={b}
            d="M59.366,54.34a7.287,7.287,0,0,1,9.845-3.046"
            transform="translate(22.109 16.939)"
          />
          <path
            className={b}
            d="M88.665,54.34a7.287,7.287,0,0,0-9.845-3.046"
            transform="translate(29.671 16.939)"
          />
          <path
            className={b}
            d="M34.273,30.758a3.807,3.807,0,1,1,7.615,0"
            transform="translate(12.354 7.803)"
          />
          <path
            className={b}
            d="M41.692,93.686a9.073,9.073,0,0,1-7.717,0S2.495,68.408,2.495,51.966V6.878s18.377,8.194,35.467,8.194c17.651,0,35.21-8.194,35.21-8.194V51.966C73.173,68.408,41.692,93.686,41.692,93.686Z"
            transform="translate(0)"
          />
          <path
            className={b}
            d="M62.452,36.266a80.351,80.351,0,0,0,19.874,2.886c17.651,0,35.209-8.194,35.209-8.194V76.046c0,16.441-31.479,41.719-31.479,41.719a9.073,9.073,0,0,1-7.717,0s-31.48-25.278-31.48-41.719"
            transform="translate(17.246 9.361)"
          />
        </g>
      </svg>
    )
  }
}
