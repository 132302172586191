import { getBasicClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const TrashcanBubbleSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 20 21', discType, color)}>
      <circle
        cx="10"
        cy="11"
        r="10"
        fill="white"
        fillOpacity="0.25"
        strokeOpacity="0.25"
      />
      <path
        d="M3 4.16663V17.8066C3 17.8066 3 18.4266 3.35 19.0466C3.7 19.6666 4.79534 19.6666 4.79534 19.6666H15.25C15.25 19.6666 16.3 19.6666 16.65 19.0466C17 18.4266 17 18.1166 17 18.1166V4.16663"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M6.65905 1.33327L7.15199 2.2666H12.918L13.411 1.33327L6.65905 1.33327Z"
        stroke="white"
        strokeWidth="2"
      />
      <line
        x1="2"
        y1="3.41663"
        x2="17.87"
        y2="3.41663"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="7.13333"
        y1="7.19995"
        x2="7.13333"
        y2="14.7333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="10"
        y1="7.19995"
        x2="10"
        y2="14.7333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="13"
        y1="7.19995"
        x2="13"
        y2="14.7333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default TrashcanBubbleSVG
