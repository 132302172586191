import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookGearCheckSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookGearCheck', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.019 98.019"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-732.26 -1868.693)">
          <path
            className={a}
            d="M96.96,1512.342v-14.857H83.715a34.4,34.4,0,0,0-4.675-11.155l9.4-9.4L77.938,1466.42l-9.44,9.44a34.38,34.38,0,0,0-11.119-4.6V1457.9H42.521v13.354a34.39,34.39,0,0,0-11.119,4.6l-9.44-9.44-10.506,10.506,9.4,9.4a34.4,34.4,0,0,0-4.675,11.155H2.941v14.857H16.11a34.359,34.359,0,0,0,4.64,11.266l-9.294,9.294,10.506,10.505,9.258-9.257a34.4,34.4,0,0,0,11.3,4.713v13.06H57.379v-13.06a34.4,34.4,0,0,0,11.3-4.713l9.257,9.257L88.444,1532.9l-9.293-9.294a34.381,34.381,0,0,0,4.64-11.266Z"
            transform="translate(730.319 411.789)"
          />
          <circle
            className={a}
            cx="22.395"
            cy="22.395"
            r="22.395"
            transform="translate(757.874 1894.459)"
          />
          <path
            className={a}
            d="M63.295,1494.247,49.3,1519.671l-13.028-8.479,2.8-4.429,8.076,4.575,10.708-19.947Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className={b}
            d="M-3610.562-2499.981h-14.857v-13.06a34.324,34.324,0,0,1-11.3-4.713l-9.258,9.257-10.506-10.5,9.294-9.294a34.292,34.292,0,0,1-4.639-11.267H-3665v-14.856h13.245a34.337,34.337,0,0,1,4.675-11.155l-9.405-9.4,10.506-10.506,9.44,9.44a34.337,34.337,0,0,1,11.119-4.6V-2594h14.857v13.354a34.319,34.319,0,0,1,11.119,4.6l9.44-9.44,10.506,10.506-9.4,9.4a34.335,34.335,0,0,1,4.675,11.155h13.245v14.856h-13.169a34.34,34.34,0,0,1-4.64,11.267l9.293,9.294L-3590-2508.5l-9.257-9.257a34.314,34.314,0,0,1-11.3,4.713v13.059Zm-7.428-69.253a22.255,22.255,0,0,0-8.717,1.76,22.323,22.323,0,0,0-7.119,4.8,22.326,22.326,0,0,0-4.8,7.119,22.255,22.255,0,0,0-1.76,8.717,22.253,22.253,0,0,0,1.76,8.717,22.321,22.321,0,0,0,4.8,7.119,22.319,22.319,0,0,0,7.119,4.8,22.25,22.25,0,0,0,8.717,1.76,22.248,22.248,0,0,0,8.717-1.76,22.321,22.321,0,0,0,7.119-4.8,22.321,22.321,0,0,0,4.8-7.119,22.248,22.248,0,0,0,1.76-8.717,22.25,22.25,0,0,0-1.76-8.717,22.326,22.326,0,0,0-4.8-7.119,22.321,22.321,0,0,0-7.119-4.8A22.252,22.252,0,0,0-3617.99-2569.234Z"
            transform="translate(4400.26 4465.693)"
          />
        </g>
      </svg>
    )
  }
}
