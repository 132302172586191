import React from 'react'

const PresentationWithPersonSVG = () => {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1519 6.83179C14.5322 6.83179 16.4561 8.75564 16.4561 11.136C16.4561 13.5164 14.5322 15.4402 12.1519 15.4402C9.77149 15.4402 7.84766 13.5164 7.84766 11.136"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3364 10.1902C8.95606 10.1902 7.03223 10.1902 7.03223 10.1902C7.03223 7.80984 8.95606 5.88599 11.3364 5.88599V10.1902Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8149 15.2118L12.1518 11.1033L16.4235 10.875"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1519 11.1034L15.3148 8.23389"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.445 3.53833H2.33691V17.0705H21.445V3.53833Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1519 6.83179C14.5322 6.83179 16.4561 8.75564 16.4561 11.136C16.4561 13.5164 14.5322 15.4402 12.1519 15.4402C9.77149 15.4402 7.84766 13.5164 7.84766 11.136"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1519 11.1034L15.3148 8.23389"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.445 3.53833H2.33691V17.0705H21.445V3.53833Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8366 2.10368H11.4019V1.38631C11.4019 0.995022 11.7279 0.668945 12.1192 0.668945C12.5105 0.668945 12.8366 0.995022 12.8366 1.38631V2.10368Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3691 18.5054H12.8038V22.6139C12.8038 23.0052 12.4778 23.3313 12.0865 23.3313C11.6952 23.3313 11.3691 23.0052 11.3691 22.6139V18.5054Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6519 18.4727H15.6735L17.5648 20.3639C17.8582 20.6574 17.8582 21.0813 17.5648 21.3747C17.2713 21.6682 16.8474 21.6682 16.5539 21.3747L13.6519 18.4727Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5212 18.4727L8.46695 18.5053L6.60829 20.3639C6.31482 20.6574 6.31482 21.0813 6.60829 21.3747C6.90175 21.6682 7.32569 21.6682 7.61916 21.3747L10.5212 18.4727Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4232 2.10376H1V3.5385H22.4232V2.10376Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4232 17.0706H1V18.5053H22.4232V17.0706Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1188 6.24504C28.3975 6.24504 29.434 5.20851 29.434 3.92989C29.434 2.65127 28.3975 1.61475 27.1188 1.61475C25.8402 1.61475 24.8037 2.65127 24.8037 3.92989C24.8037 5.20851 25.8402 6.24504 27.1188 6.24504Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9121 7.48413H25.3252H25.0317C24.0535 7.48413 23.2384 8.29932 23.271 9.27755L23.3688 13.0927C23.4666 13.7774 24.0861 14.2665 24.8687 14.3644L25.2274 22.875H28.7816L29.4012 14.3644C30.1838 14.2991 30.8033 13.7774 30.9011 13.0927L30.999 9.27755C31.0316 8.29932 30.2164 7.48413 29.2381 7.48413H28.9121Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PresentationWithPersonSVG
