import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookDollarSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookDollar', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 99.834 50.255"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-730.903 -1051.89)">
          <circle
            className={a}
            cx="13.41"
            cy="13.41"
            r="13.41"
            transform="translate(766.909 1063.095)"
          />
          <circle
            className={a}
            cx="3.302"
            cy="3.302"
            r="3.302"
            transform="translate(742.335 1073.203)"
          />
          <rect
            className={a}
            width="96.833"
            height="47.253"
            transform="translate(731.903 1052.89)"
          />
          <path
            className={a}
            d="M94.322,653.381c-5.353,0-7.986-4.11-7.918-8.17H13.385a7.586,7.586,0,0,1-7.313,7.463v22.458c5.146.184,7.663,4.247,7.557,8.242h73.01c-.105-4.089,2.531-8.259,7.914-8.259h.012V653.369C94.483,653.371,94.406,653.381,94.322,653.381Z"
            transform="translate(730.319 411.66)"
          />
          <circle
            className={a}
            cx="3.302"
            cy="3.302"
            r="3.302"
            transform="translate(810.41 1073.203)"
          />
          <path
            className={b}
            d="M-3568.166-2546.745H-3665V-2594h96.834v47.253Zm-48.418-37.05a13.322,13.322,0,0,0-9.482,3.928,13.324,13.324,0,0,0-3.927,9.482,13.324,13.324,0,0,0,3.927,9.483,13.322,13.322,0,0,0,9.482,3.928,13.323,13.323,0,0,0,9.483-3.928,13.323,13.323,0,0,0,3.928-9.483,13.323,13.323,0,0,0-3.928-9.482A13.322,13.322,0,0,0-3616.584-2583.8Z"
            transform="translate(4397.903 3647.89)"
          />
        </g>
      </svg>
    )
  }
}
