import React from 'react'

const LinedSpeechBubbleSVG = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.47226 24.1972C-1.08293 17.2745 0.835041 7.98427 7.75774 3.45904C14.6804 -1.06619 23.9706 0.821825 28.4959 7.74452C32.2419 13.4385 31.6725 20.9606 27.0874 26.0252L30.3539 31L15.9391 30.97C10.9344 30.9101 6.22936 28.3927 3.47226 24.1972Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.62695 10.8062H21.6332"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.62695 16.1705H24.69"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.62695 21.5048L22.9219 21.4449"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinedSpeechBubbleSVG
