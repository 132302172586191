import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookHeartFlowerSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d, e } = getSVGClasses(
      'renderPlaybookHeartFlower',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 76.542 98.297"
      >
        <defs>
          <style>{`.${a},.${b},.${c}{fill:none;}.${a},.${b},.${c},.${d},.${e}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linejoin:round;stroke-width:2px;}.${b},.${c},.${e}{stroke-linecap:round;}.${b}{fill-rule:evenodd;}.${d},.${e}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1846.811 -1108.623)">
          <path
            className={a}
            d="M1704.548,735.985c-3.383,0-6.339,2.647-7.939,5.367-1.6-2.72-4.556-5.367-7.939-5.367a9.207,9.207,0,0,0-9.207,9.207c0,11.143,17.146,22.736,17.146,22.736s17.146-11.46,17.146-22.736A9.207,9.207,0,0,0,1704.548,735.985Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={b}
            d="M1669.056,831.9c4.143-2.3,16.522-7.522,36.9-7.522s34.048,5.739,36.9,7.522"
            transform="translate(179.114 373.638)"
          />
          <path
            className={c}
            d="M1705.847,784.8s-1.505,2.854-.592,9.283c.6,4.22,3.744,9.98,3.744,17.484a26.235,26.235,0,0,1-3.152,12.813"
            transform="translate(179.114 373.638)"
          />
          <path
            className={c}
            d="M1725.236,779.741c-7.058,7.058-19.318,4.965-19.318,4.965s-3.339-11.015,4.965-19.318c6.915-6.916,20.736-6.383,20.736-6.383S1732.294,772.684,1725.236,779.741Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={c}
            d="M1694.885,784.887c8.966,4.387,10.962,16.664,10.962,16.664s-9.336,6.731-19.884,1.57c-8.785-4.3-12.764-17.545-12.764-17.545S1685.92,780.5,1694.885,784.887Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={c}
            d="M1685.123,791.26s11.808,9.33,20.724,10.291"
            transform="translate(179.114 373.638)"
          />
          <path
            className={c}
            d="M1705.918,784.706s9.119-12.932,16.529-17.048"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1705.255,794.085s-8.076-12.785-8.646-26.157"
            transform="translate(179.114 373.638)"
          />
          <path
            className={d}
            d="M1704.548,735.985c-3.383,0-6.339,2.647-7.939,5.367-1.6-2.72-4.556-5.367-7.939-5.367a9.207,9.207,0,0,0-9.207,9.207c0,11.143,17.146,22.736,17.146,22.736s17.146-11.46,17.146-22.736A9.207,9.207,0,0,0,1704.548,735.985Z"
            transform="translate(181.114 374.638)"
          />
          <path
            className={e}
            d="M1725.236,779.741c-7.058,7.058-19.318,4.965-19.318,4.965s-3.339-11.015,4.965-19.318c6.915-6.916,20.736-6.383,20.736-6.383S1732.294,772.684,1725.236,779.741Z"
            transform="translate(181.114 374.638)"
          />
          <path
            className={e}
            d="M1694.885,784.887c8.966,4.387,10.962,16.664,10.962,16.664s-9.336,6.731-19.884,1.57c-8.785-4.3-12.764-17.545-12.764-17.545S1685.92,780.5,1694.885,784.887Z"
            transform="translate(181.114 374.638)"
          />
        </g>
      </svg>
    )
  }
}
