import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookSmileSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookSmile', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 97.982 97.983"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1835.639 -1354.978)">
          <circle
            className={a}
            cx="46.991"
            cy="46.991"
            r="46.991"
            transform="translate(1836.639 1355.978)"
          />
          <circle
            className={a}
            cx="46.991"
            cy="46.991"
            r="46.991"
            transform="translate(1836.639 1355.978)"
          />
          <path
            className={a}
            d="M1729.286,1045.486a29.066,29.066,0,1,1-54.159-21.123Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1693.157,1001.943l8.052,10.466-13.79,1.656"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1729.286,1017.106l-13.189.647,7.673,11.577"
            transform="translate(179.114 373.638)"
          />
          <path
            className={b}
            d="M-3618.009-2500.017a46.7,46.7,0,0,1-18.291-3.693,46.834,46.834,0,0,1-14.937-10.071,46.834,46.834,0,0,1-10.071-14.937,46.7,46.7,0,0,1-3.693-18.291,46.7,46.7,0,0,1,3.693-18.292,46.834,46.834,0,0,1,10.071-14.937,46.839,46.839,0,0,1,14.937-10.071,46.7,46.7,0,0,1,18.291-3.693,46.7,46.7,0,0,1,18.292,3.693,46.835,46.835,0,0,1,14.937,10.071,46.837,46.837,0,0,1,10.071,14.937,46.7,46.7,0,0,1,3.693,18.292,46.7,46.7,0,0,1-3.693,18.291,46.837,46.837,0,0,1-10.071,14.937,46.84,46.84,0,0,1-14.937,10.071A46.7,46.7,0,0,1-3618.009-2500.017Zm-29.388-51.959h0a28.892,28.892,0,0,0-1.983,11.371,28.973,28.973,0,0,0,2.447,10.871,28.97,28.97,0,0,0,6.344,9.16,28.89,28.89,0,0,0,9.71,6.239,28.925,28.925,0,0,0,10.555,2,29,29,0,0,0,8.508-1.278,29.211,29.211,0,0,0,7.716-3.672,28.892,28.892,0,0,0,10.863-13.563l-54.159-21.123Z"
            transform="translate(5503.639 3951.978)"
          />
        </g>
      </svg>
    )
  }
}
