import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookWarningSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderGlobe', discType, color)
    const discHex = getDiscHex(discType, color)

    return (
      <svg width="100%" height="100%" viewBox="0 0 166.666 199.892">
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${a},.${b},.${c}{stroke:${discHex};stroke-linecap:round;stroke-linejoin:round;}.${a},.${c}{stroke-width:2px;}.${b}{stroke-width:1.936px;}.${c}{fill:${discHex};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-7677.031 -328.89)">
          <line
            className={a}
            y2="17.701"
            transform="translate(7767.425 495.648)"
          />
          <line
            className={a}
            y2="17.701"
            transform="translate(7760.425 495.648)"
          />
          <path
            className={a}
            d="M7789.8,436.782h-86.594c5.524-9.159,13.6-14.433,22.107-14.433H7767.7c8.5,0,16.582,5.274,22.107,14.433Z"
            transform="translate(20.917 91)"
          />
          <path
            className={a}
            d="M7801.473,351.851l7.1-7.1a81.8,81.8,0,0,1-115.676,115.677l6.869-6.87"
            transform="translate(10.169 10.17)"
          />
          <circle
            className={b}
            cx="72.284"
            cy="72.284"
            r="72.284"
            transform="translate(7685.773 337.632)"
          />
          <ellipse
            className={b}
            cx="72.284"
            cy="33.679"
            rx="72.284"
            ry="33.679"
            transform="matrix(0.438, -0.899, 0.899, 0.438, 7696.1, 460.121)"
          />
          <ellipse
            className={b}
            cx="33.679"
            cy="72.284"
            rx="33.679"
            ry="72.284"
            transform="matrix(0.438, -0.899, 0.899, 0.438, 7678.325, 408.5)"
          />
          <path
            className={c}
            d="M7789.8,436.782h-86.594c5.524-9.159,13.6-14.433,22.107-14.433H7767.7c8.5,0,16.582,5.274,22.107,14.433Z"
            transform="translate(20.917 91)"
          />
        </g>
      </svg>
    )
  }
}
