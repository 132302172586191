import React from 'react'

import { getDiscHex, getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class CommunicateSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        {...getBasicClasses('0 0 85.543 63.144', discType, color)}
        fill={getDiscHex(discType, color)}
        strokeWidth={0}
      >
        <path
          d="M2786.835,1576.921h-.012a.875.875,0,0,1-.779-.5l-5.212-10.97h-5.453a8.25,8.25,0,0,1-8.24-8.241V1534.5a8.25,8.25,0,0,1,8.24-8.241h43.5a8.25,8.25,0,0,1,8.241,8.241v22.711a8.25,8.25,0,0,1-8.241,8.241h-25.663l-5.6,10.992A.875.875,0,0,1,2786.835,1576.921Zm-11.456-48.913a6.5,6.5,0,0,0-6.491,6.491v22.711a6.5,6.5,0,0,0,6.491,6.492h6.006a.876.876,0,0,1,.79.5l4.688,9.865,5.04-9.887a.875.875,0,0,1,.78-.478h26.2a6.5,6.5,0,0,0,6.491-6.492V1534.5a6.5,6.5,0,0,0-6.491-6.491Z"
          transform="translate(-2767.139 -1526.259)"
        />
        <g transform="translate(39.816 24.457)">
          <path
            d="M2843.289,1592.9a.875.875,0,0,1-.779-.478l-4.171-8.183h-19.25a6.445,6.445,0,0,1-6.438-6.438v-9.727a.875.875,0,0,1,1.75,0v9.727a4.694,4.694,0,0,0,4.688,4.688h19.785a.874.874,0,0,1,.779.478l3.609,7.078,3.353-7.056a.875.875,0,0,1,.79-.5h4.536a4.693,4.693,0,0,0,4.688-4.688v-17.15a4.693,4.693,0,0,0-4.687-4.688h-20a.875.875,0,1,1,0-1.75h20a6.445,6.445,0,0,1,6.438,6.438v17.15a6.445,6.445,0,0,1-6.437,6.438h-3.983l-3.878,8.162a.878.878,0,0,1-.778.5Z"
            transform="translate(-2812.65 -1554.214)"
          />
        </g>
        <g transform="translate(10.624 12.482)">
          <path
            d="M2817.539,1542.276h-37.382a.875.875,0,0,1,0-1.75h37.382a.875.875,0,0,1,0,1.75Z"
            transform="translate(-2779.282 -1540.526)"
          />
        </g>
        <g transform="translate(10.624 24.457)">
          <path
            d="M2817.539,1555.964h-37.382a.875.875,0,0,1,0-1.75h37.382a.875.875,0,0,1,0,1.75Z"
            transform="translate(-2779.282 -1554.214)"
          />
        </g>
        <g transform="translate(50.137 37.598)">
          <path
            d="M2830.073,1571.171c0-1.553-1.259-1.936-2.812-1.936s-2.813.383-2.813,1.936a2.812,2.812,0,1,0,5.625,0Z"
            transform="translate(-2824.448 -1569.235)"
          />
        </g>
        <g transform="translate(59.109 36.722)">
          <circle cx="2.812" cy="2.812" r="2.812" />
        </g>
        <g transform="translate(68.08 36.722)">
          <circle cx="2.812" cy="2.812" r="2.812" />
        </g>
      </svg>
    )
  }
}
