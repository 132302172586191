import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookResumeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        {...getBasicClasses('0 0 100 100', discType, color)}
        strokeWidth={'1.6'}
      >
        <g id="Group_3751" data-name="Group 3751">
          <g id="Group_3748" data-name="Group 3748">
            <path
              id="Path_1423"
              data-name="Path 1423"
              d="M85.85,94.4H15.25V3H70.13L85.85,18.61Z"
            />
            <path
              id="Path_1424"
              data-name="Path 1424"
              d="M85.85,18.62H70.24V3Z"
            />
          </g>
          <line
            id="Line_238"
            data-name="Line 238"
            x1="24.01"
            y1="49.68"
            x2="32.63"
            y2="49.68"
          />
          <line
            id="Line_239"
            data-name="Line 239"
            x1="39.79"
            y1="49.68"
            x2="75.93"
            y2="49.68"
          />
          <line
            id="Line_240"
            data-name="Line 240"
            x1="24.01"
            y1="57.72"
            x2="75.93"
            y2="57.72"
          />
          <line
            id="Line_241"
            data-name="Line 241"
            x1="24.01"
            y1="72.89"
            x2="32.63"
            y2="72.89"
          />
          <line
            id="Line_242"
            data-name="Line 242"
            x1="39.79"
            y1="72.89"
            x2="75.93"
            y2="72.89"
          />
          <line
            id="Line_243"
            data-name="Line 243"
            x1="24.01"
            y1="80.94"
            x2="75.93"
            y2="80.94"
          />
          <g id="Group_3750" data-name="Group 3750">
            <circle
              id="Ellipse_55-2"
              data-name="Ellipse 55-2"
              cx="50.55"
              cy="26.46"
              r="14.16"
            />
            <g id="Group_3749" data-name="Group 3749">
              <path
                id="Path_1425"
                data-name="Path 1425"
                d="M39.88,35.7c.23-3.53.77-3.85,1.09-4.08a10.59,10.59,0,0,1,4.19-1.84A7.42,7.42,0,0,0,55.63,30l.18-.19A10.65,10.65,0,0,1,60,31.62c.34.23.92.48,1.18,4.18"
              />
              <path
                id="Path_1426"
                data-name="Path 1426"
                d="M55.68,21.1c0,2.82-1.8,7-5.23,7s-5.22-4.15-5.22-7a5.22,5.22,0,0,1,10.43,0"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
