import { getBasicClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const CustomContentEyeSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 23 15', discType, color)}>
      <path
        d="M11.6333 0.900024C5.8494 0.900027 2.3401 5.44928 1.31525 6.99394C1.11096 7.30184 1.12396 7.69028 1.34686 7.98499C2.45529 9.45052 6.16255 13.7334 11.6333 13.7334C17.1041 13.7334 20.8114 9.45052 21.9198 7.98499C22.1427 7.69028 22.1557 7.30184 21.9514 6.99394C20.9266 5.44927 17.4173 0.900021 11.6333 0.900024Z"
        stroke="#1E749F"
      />
      <path
        d="M15.1667 7.13331C15.1667 9.08472 13.5847 10.6666 11.6333 10.6666C9.68193 10.6666 8.10001 9.08472 8.10001 7.13331C8.10001 5.1819 9.68193 3.59998 11.6333 3.59998C13.5847 3.59998 15.1667 5.1819 15.1667 7.13331Z"
        stroke="#1E749F"
      />
    </svg>
  )
}

export default CustomContentEyeSVG
