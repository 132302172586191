import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class DetailedShoppingCartSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses(
      'renderDetailedShoppingCart',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 139 107.854', discType, color)}>
        <defs>
          <style>{`.${a},.${d}{fill:none;}.${a}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-118 -4585)">
          <g transform="translate(119 4586)">
            <path
              className={a}
              d="M5131.674,480.843h-79.157l-14.028-54.358h102.243Z"
              transform="translate(-5006.009 -409.24)"
            />
            <path
              className={a}
              d="M5048.627,431.868l-4.49-17.245h-27.989"
              transform="translate(-5016.147 -414.623)"
            />
            <line
              className={a}
              x2="88.453"
              transform="translate(40.245 44.559)"
            />
            <line
              className={a}
              x2="94.268"
              transform="translate(37.338 30.562)"
            />
            <line
              className={a}
              x2="82.638"
              transform="translate(44.607 59.258)"
            />
            <line
              className={a}
              y2="53.843"
              transform="translate(57.05 17.76)"
            />
            <line
              className={a}
              y2="53.843"
              transform="translate(78.12 17.76)"
            />
            <line
              className={a}
              y2="53.843"
              transform="translate(99.19 17.76)"
            />
            <line
              className={a}
              y2="53.843"
              transform="translate(120.261 17.76)"
            />
            <path
              className={a}
              d="M5130.089,480.475H5049.5a8.3,8.3,0,0,1-8.3-8.3h0a8.3,8.3,0,0,1,8.3-8.3h80.593"
              transform="translate(-5004.779 -392.272)"
            />
            <circle
              className={a}
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(47.125 88.203)"
            />
            <circle
              className={a}
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(104.23 88.203)"
            />
          </g>
          <g className={b} transform="translate(154.757 4604.884)">
            <path
              className={c}
              d="M5131.674,480.843h-79.157l-14.028-54.358h102.243Z"
              transform="translate(-5038.488 -426.485)"
            />
            <line
              className={d}
              x2="88.453"
              transform="translate(7.767 27.314)"
            />
            <line
              className={d}
              x2="94.268"
              transform="translate(4.859 13.317)"
            />
            <line
              className={d}
              x2="82.638"
              transform="translate(12.128 42.013)"
            />
            <line
              className={d}
              y2="53.843"
              transform="translate(24.099 0.515)"
            />
            <line
              className={d}
              y2="53.843"
              transform="translate(45.297 0.515)"
            />
            <line
              className={d}
              y2="53.843"
              transform="translate(66.495 0.515)"
            />
            <line
              className={d}
              y2="53.843"
              transform="translate(87.782 0.515)"
            />
            <circle
              className={c}
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(14.155 70.959)"
            />
            <circle
              className={c}
              cx="8.506"
              cy="8.506"
              r="8.506"
              transform="translate(71.751 70.959)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
