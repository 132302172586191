import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookPaintSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookPaint', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 92.705 98.456"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2.031px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-724.397 -901.418)">
          <g transform="translate(-290 -3036)">
            <g transform="translate(1009.319 3437.789)">
              <path
                className={a}
                d="M76.356,574.661C87.657,566.2,94.521,552.1,93.7,536.33c-1.166-22.462-18.664-34.536-41.128-35.657q-.227-.012-.453-.021a7.552,7.552,0,0,0-7.073,10.814c1.285,2.672,8.612,12.778-.65,18.255-8.95,5.293-16.622-1.645-21.976-3.635-15.164-5.636-16.58,14.6-16.275,20.921.008.159.016.317.026.475C7.486,569.854,26.616,581.83,49,582.9a42.175,42.175,0,0,0,22.434-5.068m-8.077-55.253c-8.238,0-8.225-12.783,0-12.783S71.582,522.577,63.357,522.577Z"
              />
              <path
                className={a}
                d="M89.692,594.415l-3.6,2.655L54.578,556.024l5.209-3.842Z"
              />
              <path
                className={a}
                d="M59.787,552.182l-5.209,3.842-6.55-7.192a15.873,15.873,0,0,1-4.053-9.045l-.257-2.467a1.016,1.016,0,0,1,1.409-1.039l2.281.974a15.871,15.871,0,0,1,7.443,6.544Z"
              />
              <line
                className={a}
                y1="3.318"
                x2="4.499"
                transform="translate(59.359 558.464)"
              />
              <path
                className={a}
                d="M70.713,550.27c-.911,2.462-.49,6.026,1.668,7.184,2.639,1.416,6.968-1.25,8.758-4.39,1.419-2.49,1.881-6.4,0-7.784C78.707,543.488,72.33,545.9,70.713,550.27Z"
              />
              <path
                className={a}
                d="M74.348,527.554c-1.442,1.882.647,7.879,4.248,8.495,3.338.572,6.928-3.658,7.018-7.572a7.8,7.8,0,0,0-2.216-5.356c-2.077-2.213-5.469-3.31-6.279-2.4-.843.945,1.638,3.464.553,5.171C76.889,527.124,75.178,526.471,74.348,527.554Z"
              />
              <path
                className={a}
                d="M30.891,562.542c-1.822,2.686-2.524,7.236,0,9.6,2.822,2.65,7.643.658,8.68.185,4.769-2.174,7.154-7.6,6.28-8.5-.808-.828-4.346,2.276-5.725,1.108-1.311-1.109,1.112-4.558-.186-5.91C38.559,557.593,33.34,558.93,30.891,562.542Z"
              />
              <path
                className={a}
                d="M18.084,548.495c3.3,3.191,9.959,4.538,13.376,1.88,2.423-1.884,3.116-5.705,1.459-7.1-1.324-1.119-3.147.16-7.3-.209-5.7-.507-8-3.43-9.485-2.507C14.579,541.519,15.325,545.822,18.084,548.495Z"
              />
            </g>
            <g className={b} transform="translate(1014.319 3440.789)">
              <path
                className={c}
                d="M-4344.274-2916.486h0c-.712,0-1.438-.017-2.158-.052a60.635,60.635,0,0,1-15.95-2.838,44.843,44.843,0,0,1-13.364-6.881,34.3,34.3,0,0,1-9.415-10.875,35.118,35.118,0,0,1-4.1-14.822q-.008-.135-.015-.271c0-.052-.005-.1-.008-.157l0-.047a42.029,42.029,0,0,1,.677-9.3c.834-4.406,2.29-7.723,4.327-9.86a8.516,8.516,0,0,1,6.384-2.741,14.159,14.159,0,0,1,4.887.98,35.577,35.577,0,0,1,3.935,1.888c3.284,1.733,7.005,3.7,11.187,3.7a13.182,13.182,0,0,0,6.854-1.951c3.513-2.078,5.032-5.079,4.514-8.92a23.924,23.924,0,0,0-3.2-8.107l0-.009-.033-.058c-.258-.461-.481-.859-.627-1.161a7.512,7.512,0,0,1,.419-7.293,7.459,7.459,0,0,1,6.34-3.527c.1,0,.208,0,.314.007s.239.009.452.02c11.487.573,21.36,3.971,28.551,9.826a33.194,33.194,0,0,1,8.818,10.941,37.377,37.377,0,0,1,3.759,14.89,47.7,47.7,0,0,1-.775,11.451,45.3,45.3,0,0,1-3.353,10.454,43.112,43.112,0,0,1-13.217,16.425l-.448.289-16.122-22.768-4.935-8.382a15.862,15.862,0,0,0-3.2-3.868,15.834,15.834,0,0,0-4.246-2.676l-2.281-.974a1.012,1.012,0,0,0-.4-.083,1.019,1.019,0,0,0-.755.339,1,1,0,0,0-.254.783l.258,2.468a15.854,15.854,0,0,0,1.3,4.846,15.833,15.833,0,0,0,2.75,4.2l6.55,7.192,16.776,21.849a41.368,41.368,0,0,1-9.552,3.753A42.989,42.989,0,0,1-4344.274-2916.486Zm12.2-73.155a5.883,5.883,0,0,0-4.629,2,6.723,6.723,0,0,0-1.544,4.4,6.711,6.711,0,0,0,1.542,4.391,5.888,5.888,0,0,0,4.631,2,5.882,5.882,0,0,0,4.629-2,6.716,6.716,0,0,0,1.544-4.4,6.711,6.711,0,0,0-1.542-4.391A5.888,5.888,0,0,0-4332.076-2989.641Z"
                transform="translate(4393.433 3499.435)"
              />
              <path
                className={c}
                d="M70.713,550.27c-.911,2.462-.49,6.026,1.668,7.184,2.639,1.416,6.968-1.25,8.758-4.39,1.419-2.49,1.881-6.4,0-7.784C78.707,543.488,72.33,545.9,70.713,550.27Z"
              />
              <path
                className={c}
                d="M74.348,527.554c-1.442,1.882.647,7.879,4.248,8.495,3.338.572,6.928-3.658,7.018-7.572a7.8,7.8,0,0,0-2.216-5.356c-2.077-2.213-5.469-3.31-6.279-2.4-.843.945,1.638,3.464.553,5.171C76.889,527.124,75.178,526.471,74.348,527.554Z"
              />
              <path
                className={c}
                d="M30.891,562.542c-1.822,2.686-2.524,7.236,0,9.6,2.822,2.65,7.643.658,8.68.185,4.769-2.174,7.154-7.6,6.28-8.5-.808-.828-4.346,2.276-5.725,1.108-1.311-1.109,1.112-4.558-.186-5.91C38.559,557.593,33.34,558.93,30.891,562.542Z"
              />
              <path
                className={c}
                d="M18.084,548.495c3.3,3.191,9.959,4.538,13.376,1.88,2.423-1.884,3.116-5.705,1.459-7.1-1.324-1.119-3.147.16-7.3-.209-5.7-.507-8-3.43-9.485-2.507C14.579,541.519,15.325,545.822,18.084,548.495Z"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
