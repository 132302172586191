import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class BoxGridSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('TimerSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 66.565 60.951', discType, color)}>
        <defs>
          <style>{`.${a},.${b}{fill:${getDiscHex(
            discType,
            color,
          )};}.${b}{opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-460 -480.379)">
          <g transform="translate(460 486.236)">
            <path
              className={a}
              d="M53.561,63.8H39.135a.712.712,0,0,1-.712-.712V44.047a.712.712,0,0,1,.712-.711h6.7L34.473,28.209,23.108,43.335h6.77a.712.712,0,0,1,.712.711h0V63.092a.712.712,0,0,1-.712.711H16.846a14.924,14.924,0,0,1-2.067-29.7v-.057a19.213,19.213,0,0,1,38.427-.084c.119,0,.237-.006.357-.006a14.924,14.924,0,1,1,0,29.848Z"
              transform="translate(-1.922 -14.787)"
            />
          </g>
          <path
            className={b}
            d="M47.881,5.667A30.445,30.445,0,0,0,8.543,51.5L1.893,61.6l29.316-.064v0A30.44,30.44,0,0,0,47.881,5.667Z"
            transform="translate(462.492 479.73)"
          />
        </g>
      </svg>
    )
  }
}
