import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookWaterSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookWater', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 55.7 96.63"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1853.149 -1228.837)">
          <ellipse
            className={a}
            cx="19.45"
            cy="3.156"
            rx="19.45"
            ry="3.156"
            transform="translate(1861.903 1272.23)"
          />
          <ellipse
            className={a}
            cx="26.85"
            cy="3.156"
            rx="26.85"
            ry="3.156"
            transform="translate(1854.149 1229.837)"
          />
          <path
            className={a}
            d="M1675.035,859.355l4.7,88.318c0,1.743,9.918,3.156,22.152,3.156s22.153-1.413,22.153-3.156l4.7-88.318"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1682.789,901.748l2.86,41.5c0,.819,7.294,1.483,16.292,1.483s16.292-.664,16.292-1.483l3.455-41.5"
            transform="translate(179.114 373.638)"
          />
          <path
            className={b}
            d="M-3662.14-2549.348l-2.854-41.412a.529.529,0,0,1-.007-.083c0-1.743,8.708-3.156,19.45-3.156s19.449,1.413,19.449,3.156h0v.014a.513.513,0,0,1,0,.058l-3.449,41.423c0,.82-7.294,1.484-16.292,1.484S-3662.14-2548.529-3662.14-2549.348Z"
            transform="translate(5526.903 3866.23)"
          />
        </g>
      </svg>
    )
  }
}
