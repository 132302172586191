import React from 'react'

import { getDiscHex, getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class TeamSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        {...getBasicClasses('0 0 94.301 56.36', discType, color)}
        fill={getDiscHex(discType, color)}
        strokeWidth={0}
      >
        <g transform="translate(35.28)">
          <path
            d="M3766.27,1544.132a11.671,11.671,0,1,1,11.671-11.671A11.684,11.684,0,0,1,3766.27,1544.132Zm0-21.342a9.671,9.671,0,1,0,9.671,9.671A9.681,9.681,0,0,0,3766.27,1522.79Z"
            transform="translate(-3754.599 -1520.79)"
          />
        </g>
        <g transform="translate(26.066 25.964)">
          <path
            d="M3781.612,1569.277h-30.684a5.5,5.5,0,0,1-4.438-2.222,5.436,5.436,0,0,1-.882-4.824,21.531,21.531,0,0,1,41.324,0,5.433,5.433,0,0,1-.882,4.824A5.5,5.5,0,0,1,3781.612,1569.277Zm-15.342-20.523a19.622,19.622,0,0,0-18.742,14.037,3.45,3.45,0,0,0,.562,3.065,3.518,3.518,0,0,0,2.838,1.421h30.684a3.518,3.518,0,0,0,2.838-1.421,3.45,3.45,0,0,0,.562-3.065A19.622,19.622,0,0,0,3766.27,1548.754Z"
            transform="translate(-3745.385 -1546.754)"
          />
        </g>
        <g transform="translate(66.445 15.439)">
          <path
            d="M3795.7,1556.1a9.934,9.934,0,1,1,9.934-9.934A9.945,9.945,0,0,1,3795.7,1556.1Zm0-17.868a7.934,7.934,0,1,0,7.934,7.934A7.943,7.943,0,0,0,3795.7,1538.229Z"
            transform="translate(-3785.765 -1536.229)"
          />
        </g>
        <g transform="translate(58.45 37.177)">
          <path
            d="M3809.852,1577.15h-28.311a3.775,3.775,0,0,1-2.918-1.373,3.661,3.661,0,0,1-.789-3.028,18.171,18.171,0,0,1,1.116-3.689,1,1,0,1,1,1.842.781,16.055,16.055,0,0,0-.988,3.249,1.671,1.671,0,0,0,.359,1.412,1.783,1.783,0,0,0,1.378.648h28.311a1.765,1.765,0,0,0,1.366-.645,1.739,1.739,0,0,0,.371-1.441l-.074-.359a16.264,16.264,0,0,0-15.816-12.738,16.1,16.1,0,0,0-9.048,2.764,1,1,0,1,1-1.119-1.659,18.191,18.191,0,0,1,27.938,11.21l.083.4a3.764,3.764,0,0,1-3.7,4.464Z"
            transform="translate(-3777.77 -1557.967)"
          />
        </g>
        <g transform="translate(7.987 15.439)">
          <path
            d="M3737.241,1556.1a9.934,9.934,0,1,1,9.934-9.934A9.945,9.945,0,0,1,3737.241,1556.1Zm0-17.868a7.934,7.934,0,1,0,7.934,7.934A7.943,7.943,0,0,0,3737.241,1538.229Z"
            transform="translate(-3727.307 -1536.229)"
          />
        </g>
        <g transform="translate(0 37.177)">
          <path
            d="M3751.4,1577.15h-28.312a3.763,3.763,0,0,1-3.7-4.465l.082-.4a18.191,18.191,0,0,1,27.938-11.211,1,1,0,0,1-1.119,1.659,16.094,16.094,0,0,0-9.048-2.764,16.264,16.264,0,0,0-15.816,12.739l-.073.357a1.739,1.739,0,0,0,.37,1.442,1.765,1.765,0,0,0,1.366.645H3751.4a1.786,1.786,0,0,0,1.378-.648,1.653,1.653,0,0,0,.364-1.382,16.288,16.288,0,0,0-.993-3.279,1,1,0,1,1,1.841-.781,18.022,18.022,0,0,1,1.11,3.655,3.682,3.682,0,0,1-.782,3.062A3.777,3.777,0,0,1,3751.4,1577.15Z"
            transform="translate(-3719.32 -1557.967)"
          />
        </g>
      </svg>
    )
  }
}
