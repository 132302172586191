import React from 'react'

import { getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ConversationSpeechBubblesSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        fill={getDiscHex(discType, color)}
        xmlns="http://www.w3.org/2000/svg"
        width="117.578"
        height="86.791"
        viewBox="0 0 117.578 86.791"
      >
        <path
          d="M2794.211,1595.894h-.016a1.2,1.2,0,0,1-1.07-.687l-7.164-15.078h-7.5a11.34,11.34,0,0,1-11.326-11.327v-31.215a11.34,11.34,0,0,1,11.326-11.327h59.792a11.34,11.34,0,0,1,11.328,11.327V1568.8a11.34,11.34,0,0,1-11.328,11.327h-35.273l-7.7,15.108A1.2,1.2,0,0,1,2794.211,1595.894Zm-15.746-67.23a8.931,8.931,0,0,0-8.921,8.922V1568.8a8.932,8.932,0,0,0,8.921,8.923h8.255a1.2,1.2,0,0,1,1.086.687l6.443,13.559,6.927-13.589a1.2,1.2,0,0,1,1.071-.656h36.01a8.932,8.932,0,0,0,8.923-8.923v-31.215a8.931,8.931,0,0,0-8.923-8.922Z"
          transform="translate(-2767.139 -1526.259)"
        />
        <g transform="translate(54.726 33.616)">
          <path
            d="M2854.762,1607.389a1.2,1.2,0,0,1-1.071-.656l-5.734-11.248H2821.5a8.859,8.859,0,0,1-8.85-8.849v-13.369a1.2,1.2,0,0,1,2.405,0v13.369a6.451,6.451,0,0,0,6.444,6.444h27.194a1.2,1.2,0,0,1,1.071.657l4.96,9.728,4.608-9.7a1.2,1.2,0,0,1,1.086-.687h6.235a6.45,6.45,0,0,0,6.443-6.444v-23.572a6.45,6.45,0,0,0-6.443-6.444h-27.487a1.2,1.2,0,1,1,0-2.405h27.487a8.859,8.859,0,0,1,8.848,8.849v23.572a8.859,8.859,0,0,1-8.848,8.849h-5.475l-5.331,11.218a1.206,1.206,0,0,1-1.069.687Z"
            transform="translate(-2812.65 -1554.214)"
          />
        </g>
        <g transform="translate(14.602 17.156)">
          <path
            d="M2831.866,1542.931h-51.381a1.2,1.2,0,0,1,0-2.405h51.381a1.2,1.2,0,0,1,0,2.405Z"
            transform="translate(-2779.282 -1540.526)"
          />
        </g>
        <g transform="translate(14.602 33.616)">
          <path
            d="M2831.866,1556.619h-51.381a1.2,1.2,0,0,1,0-2.405h51.381a1.2,1.2,0,0,1,0,2.405Z"
            transform="translate(-2779.282 -1554.214)"
          />
        </g>
        <g transform="translate(68.913 51.678)">
          <path
            d="M2832.179,1571.9c0-2.135-1.73-2.661-3.865-2.661s-3.866.527-3.866,2.661a3.865,3.865,0,1,0,7.731,0Z"
            transform="translate(-2824.448 -1569.235)"
          />
        </g>
        <g transform="translate(81.244 50.474)">
          <circle cx="3.866" cy="3.866" r="3.866" />
        </g>
        <g transform="translate(93.575 50.474)">
          <circle cx="3.866" cy="3.866" r="3.866" />
        </g>
      </svg>
    )
  }
}
