import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class SimpleIntroduceSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 29.265 19.154', discType, color)}>
        <g transform="translate(-0.453 -4261.586)">
          <rect
            width="27.265"
            height="17.154"
            transform="translate(1.453 4262.586)"
          />
          <line x2="27.265" transform="translate(1.453 4269.374)" />
          <line x2="16.508" transform="translate(6.843 4266.023)" />
        </g>
      </svg>
    )
  }
}
