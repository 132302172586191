import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ApologizeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('ApologizeSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 138.001 126.403', discType, color)}>
        <defs>
          <style>
            {`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
              discType,
              color,
            )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
              discType,
              color,
            )};}`}
          </style>
        </defs>
        <g transform="translate(-101.999 -6924.125)">
          <g transform="translate(-2947.595 4786.742)">
            <path
              className={a}
              d="M3054.9,2257c3.912-10.448,11.375-25.532,26.232-36.624,23.188-17.312,45.29-10.638,62.363-28.211,6.445-6.637,14.009-18.617,14.847-41.575"
              transform="translate(1.942 5.504)"
            />
            <path
              className={a}
              d="M3126.044,2158.545c4.362,7.113,1.451,15.892,1.451,15.892s-9.346-1.721-13.505-8.5c-4.169-6.8-1.421-15.842-1.421-15.842S3122.087,2152.09,3126.044,2158.545Z"
              transform="translate(27.552 5.281)"
            />
            <path
              className={a}
              d="M3141.676,2163.608c-7.294,4.05-15.94.765-15.94.765s2.121-9.263,9.076-13.126c6.974-3.871,15.89-.736,15.89-.736S3148.3,2159.932,3141.676,2163.608Z"
              transform="translate(33.892 4.929)"
            />
            <path
              className={a}
              d="M3132.137,2147.578c-.062,5.353-4.642,9.126-4.642,9.126s-4.488-4.127-4.43-9.23,4.642-9.091,4.642-9.091S3132.192,2142.72,3132.137,2147.578Z"
              transform="translate(32.687)"
            />
            <path
              className={a}
              d="M3117.071,2175.868c2.384,9.425-3.9,18.18-3.9,18.18s-9.8-5.153-12.073-14.138c-2.281-9.01,3.911-18.115,3.911-18.115S3114.906,2167.318,3117.071,2175.868Z"
              transform="translate(22.554 10.56)"
            />
            <path
              className={a}
              d="M3100.1,2187.043c-2.5,11.886-14.354,18.263-14.354,18.263s-8.169-11.164-5.786-22.5c2.388-11.363,14.338-18.181,14.338-18.181S3102.365,2176.258,3100.1,2187.043Z"
              transform="translate(13.047 11.838)"
            />
            <path
              className={a}
              d="M3075.5,2201.418c.673,14.685-11.289,25.75-11.289,25.75s-12.955-10.61-13.595-24.611c-.643-14.039,11.294-25.649,11.294-25.649S3074.893,2188.093,3075.5,2201.418Z"
              transform="translate(0 17.377)"
            />
            <path
              className={a}
              d="M3086.132,2225.1c-14.146-3.995-20.872-18.837-20.872-18.837s14.154-8.944,27.643-5.135c13.523,3.819,20.774,18.81,20.774,18.81S3098.969,2228.726,3086.132,2225.1Z"
              transform="translate(6.615 27.875)"
            />
            <path
              className={a}
              d="M3106.17,2210.431c-10.711-5.724-13.518-18.891-13.518-18.891s13-4.719,23.219.74c10.241,5.472,13.444,18.852,13.444,18.852S3115.891,2215.625,3106.17,2210.431Z"
              transform="translate(18.97 23.122)"
            />
            <path
              className={a}
              d="M3132.02,2191.31c-9.7-.647-16.083-9.329-16.083-9.329s7.932-7.726,17.181-7.107c9.272.618,16.016,9.323,16.016,9.323S3140.821,2191.9,3132.02,2191.31Z"
              transform="translate(29.473 16.443)"
            />
            <path
              className={a}
              d="M3058.409,2188.4"
              transform="translate(3.525 22.558)"
            />
          </g>
          <g className={b} transform="translate(-2945.595 4788.742)">
            <path
              className={c}
              d="M3126.044,2158.545c4.362,7.113,1.451,15.892,1.451,15.892s-9.346-1.721-13.505-8.5c-4.169-6.8-1.421-15.842-1.421-15.842S3122.087,2152.09,3126.044,2158.545Z"
              transform="translate(27.552 5.281)"
            />
            <path
              className={c}
              d="M3141.676,2163.608c-7.294,4.05-15.94.765-15.94.765s2.121-9.263,9.076-13.126c6.974-3.871,15.89-.736,15.89-.736S3148.3,2159.932,3141.676,2163.608Z"
              transform="translate(33.892 4.929)"
            />
            <path
              className={c}
              d="M3132.137,2147.578c-.062,5.353-4.642,9.126-4.642,9.126s-4.488-4.127-4.43-9.23,4.642-9.091,4.642-9.091S3132.192,2142.72,3132.137,2147.578Z"
              transform="translate(32.687)"
            />
            <path
              className={c}
              d="M3117.071,2175.868c2.384,9.425-3.9,18.18-3.9,18.18s-9.8-5.153-12.073-14.138c-2.281-9.01,3.911-18.115,3.911-18.115S3114.906,2167.318,3117.071,2175.868Z"
              transform="translate(22.554 10.56)"
            />
            <path
              className={c}
              d="M3100.1,2187.043c-2.5,11.886-14.354,18.263-14.354,18.263s-8.169-11.164-5.786-22.5c2.388-11.363,14.338-18.181,14.338-18.181S3102.365,2176.258,3100.1,2187.043Z"
              transform="translate(13.047 11.838)"
            />
            <path
              className={c}
              d="M3075.5,2201.418c.673,14.685-11.289,25.75-11.289,25.75s-12.955-10.61-13.595-24.611c-.643-14.039,11.294-25.649,11.294-25.649S3074.893,2188.093,3075.5,2201.418Z"
              transform="translate(0 17.377)"
            />
            <path
              className={c}
              d="M3086.132,2225.1c-14.146-3.995-20.872-18.837-20.872-18.837s14.154-8.944,27.643-5.135c13.523,3.819,20.774,18.81,20.774,18.81S3098.969,2228.726,3086.132,2225.1Z"
              transform="translate(6.615 27.875)"
            />
            <path
              className={c}
              d="M3106.17,2210.431c-10.711-5.724-13.518-18.891-13.518-18.891s13-4.719,23.219.74c10.241,5.472,13.444,18.852,13.444,18.852S3115.891,2215.625,3106.17,2210.431Z"
              transform="translate(18.97 23.122)"
            />
            <path
              className={c}
              d="M3132.02,2191.31c-9.7-.647-16.083-9.329-16.083-9.329s7.932-7.726,17.181-7.107c9.272.618,16.016,9.323,16.016,9.323S3140.821,2191.9,3132.02,2191.31Z"
              transform="translate(29.473 16.443)"
            />
            <path
              className={c}
              d="M3058.409,2188.4"
              transform="translate(3.525 22.558)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
