import React from 'react'

const FollowUpSVG = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.24268 2.86656C5.44268 2.36656 6.876 2.63323 7.84267 3.59989C9.14267 4.89989 9.14267 6.9999 7.84267 8.2999C6.54267 9.5999 4.44268 9.5999 3.14268 8.2999C2.17601 7.33323 1.94267 5.93322 2.37601 4.73322"
        stroke="#1E749F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70847 4.76667L3.8418 2.9L5.70847 1"
        stroke="#1E749F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FollowUpSVG
