import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class LargeCupSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('LargeCupSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a} {fill: ${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.25px;}`}</style>
        </defs>
        <path
          className={a}
          d="M50.06,69.65A32.61,32.61,0,0,1,17.49,37.08V3.68a1,1,0,0,1,1-1H81.63a1,1,0,0,1,1,1v33.4A32.61,32.61,0,0,1,50.06,69.65Zm-30.57-65v32.4a30.57,30.57,0,1,0,61.14,0V4.68Z"
        />
        <path
          className={a}
          d="M66.73,50.51a1,1,0,0,1-.67-.26A1,1,0,0,1,66,48.84a31.69,31.69,0,0,0,8-21.26V19.87a1,1,0,0,1,2,0v7.71a33.66,33.66,0,0,1-8.51,22.6A1,1,0,0,1,66.73,50.51Z"
        />
        <path
          className={a}
          d="M45.56,60.15a1,1,0,0,1,0-2,26.46,26.46,0,0,0,11.36-2.54,1,1,0,0,1,.85,1.82A28.55,28.55,0,0,1,45.56,60.15Z"
        />
        <path
          className={a}
          d="M59.33,86.91H40.65a1,1,0,0,1-.85-.47,1,1,0,0,1,0-1c0-.09,4.38-9,0-17.79a1,1,0,0,1,.45-1.34,1,1,0,0,1,1.34.45c3.79,7.58,1.77,15.06.62,18.13H58c-.9-3-2.52-10.47.45-18a1,1,0,0,1,1.86.73c-3.47,8.85,0,17.86,0,18a1,1,0,0,1-.1.93A1,1,0,0,1,59.33,86.91Z"
        />
        <path
          className={a}
          d="M76,97.32H23.6a1,1,0,0,1-.9-1.43l4.9-10.41a1,1,0,0,1,.91-.57H71.05a1,1,0,0,1,.91.57l4.9,10.41a1,1,0,0,1-.9,1.43Zm-50.78-2h49.2l-4-8.41H29.14Z"
        />
        <path
          className={a}
          d="M78.74,51.2a1,1,0,0,1,0-2C86.56,49.2,96,34.45,96.3,15.93H81.88a1,1,0,0,1,0-2H97.31a1,1,0,0,1,1,1C98.31,35.36,87.79,51.2,78.74,51.2Z"
        />
        <path
          className={a}
          d="M81.9,41.74a1,1,0,0,1-.86-.49,1,1,0,0,1,.35-1.37c4.4-2.63,7-8.94,7.19-17.4H82.07a1,1,0,0,1,0-2h7.52a1,1,0,0,1,1,1c0,9.68-3,17-8.18,20.12A1,1,0,0,1,81.9,41.74Z"
        />
        <path
          className={a}
          d="M21.26,51.2c-9,0-19.57-15.84-19.57-36.27a1,1,0,0,1,1-1H18.12a1,1,0,0,1,0,2H3.7C4,34.45,13.44,49.2,21.26,49.2a1,1,0,1,1,0,2Z"
        />
        <path
          className={a}
          d="M17.7,42.2a1.06,1.06,0,0,1-.51-.14C12,39,9,31.63,9,21.94a1,1,0,0,1,1-1h7.52a1,1,0,0,1,0,2H11c.19,8.46,2.78,14.78,7.19,17.4a1,1,0,0,1,.34,1.37A1,1,0,0,1,17.7,42.2Z"
        />
      </svg>
    )
  }
}
