import React from 'react'
import classNamesBind from 'classnames/bind'

import ReactiveOverlay, {
  OverlayDirection,
} from '@dashboard/components/ReactiveOverlay'
import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  text?: string
  icon?: string
  inline?: boolean
  light?: boolean
  smallMargin?: boolean
  smallText?: boolean
  blue?: boolean
  fixedPosition?: boolean
  direction?: OverlayDirection
  overlayGap?: number
  flex?: boolean
  fullWidth?: boolean
  disabled?: boolean
  children?: React.ReactNode
}

export default class TooltipWrapper extends React.Component<OwnProps> {
  render() {
    const { children, overlayGap, flex, fullWidth, disabled } = this.props
    return disabled ? (
      <>{children}</>
    ) : (
      <ReactiveOverlay
        withTransform
        overlayGap={overlayGap ?? 12}
        preferredDirection={this.getPreferredPosition()}
        overlayContent={this.renderTip()}
        flex={flex}
        fullWidth={fullWidth}
      >
        {children}
      </ReactiveOverlay>
    )
  }

  renderTip() {
    const { text, smallText, fixedPosition } = this.props

    if (text) {
      return (
        <div
          className={classNames('tip', { smallText, fixedPosition })}
          dangerouslySetInnerHTML={{ __html: text || '' }}
        />
      )
    }
  }

  // Functions
  getPreferredPosition(): OverlayDirection {
    const { direction } = this.props

    return direction || 'bottomRight'
  }
}
