import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class TimerSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses('TimerSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 120.209 138.115', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${d}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b},.${d}{fill:${getDiscHex(
            discType,
            color,
          )};}.${c}{opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-126.758 117.033)">
          <g transform="translate(127.758 -98.127)">
            <circle className={a} cx="58.105" cy="58.105" r="58.105" />
            <line
              className={a}
              y2="4.153"
              transform="translate(58.105 9.725)"
            />
            <line
              className={a}
              y2="4.153"
              transform="translate(58.105 102.331)"
            />
            <line
              className={a}
              x1="4.153"
              transform="translate(102.331 58.105)"
            />
            <line
              className={a}
              x1="4.153"
              transform="translate(9.725 58.105)"
            />
            <line
              className={a}
              x1="3.06"
              y2="2.808"
              transform="translate(90.693 25.398)"
            />
            <line
              className={a}
              x1="3.06"
              y2="2.808"
              transform="translate(22.456 88.004)"
            />
            <line
              className={a}
              x1="2.808"
              y1="3.06"
              transform="translate(88.004 90.693)"
            />
            <line
              className={a}
              x1="2.808"
              y1="3.06"
              transform="translate(25.398 22.456)"
            />
            <circle
              className={b}
              cx="4.258"
              cy="4.258"
              r="4.258"
              transform="translate(53.846 53.846)"
            />
            <line
              className={a}
              x1="29.899"
              y2="18.808"
              transform="translate(58.105 39.296)"
            />
          </g>
          <rect
            className={a}
            width="47.022"
            height="9.891"
            rx="2.782"
            transform="translate(161.889 -116.033)"
          />
          <line
            className={a}
            y2="8.015"
            transform="translate(179.472 -106.142)"
          />
          <line
            className={a}
            y2="8.015"
            transform="translate(191.288 -106.142)"
          />
          <line
            className={a}
            x1="5.667"
            y2="5.667"
            transform="translate(222.608 -91.236)"
          />
          <line
            className={a}
            x2="14.867"
            y2="14.867"
            transform="translate(221.278 -99.105)"
          />
          <g className={c} transform="translate(129.758 -96.127)">
            <circle className={d} cx="58.105" cy="58.105" r="58.105" />
            <line
              className={a}
              y2="4.153"
              transform="translate(58.105 9.725)"
            />
            <line
              className={a}
              y2="4.153"
              transform="translate(58.105 102.331)"
            />
            <line
              className={a}
              x1="4.153"
              transform="translate(102.331 58.105)"
            />
            <line
              className={a}
              x1="4.153"
              transform="translate(9.725 58.105)"
            />
            <line
              className={a}
              x1="3.06"
              y2="2.808"
              transform="translate(90.693 25.398)"
            />
            <line
              className={a}
              x1="3.06"
              y2="2.808"
              transform="translate(22.456 88.004)"
            />
            <line
              className={a}
              x1="2.808"
              y1="3.06"
              transform="translate(88.004 90.693)"
            />
            <line
              className={a}
              x1="2.808"
              y1="3.06"
              transform="translate(25.398 22.456)"
            />
            <circle
              className={b}
              cx="4.258"
              cy="4.258"
              r="4.258"
              transform="translate(53.846 53.846)"
            />
            <line
              className={a}
              x1="29.899"
              y2="18.808"
              transform="translate(58.105 39.296)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
