import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PricetagSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 77.469 75.147', discType, color)}>
        <g transform="translate(0.996 1)">
          <path
            d="M6716.747,1314.043l-29.661,29.661-40.135-40.135,1.85-27.267,27.812-2.394Z"
            transform="translate(-6641.274 -1273.908)"
          />
          <g transform="translate(29.138 22.994)">
            <path
              d="M6685.306,1316.226a4.122,4.122,0,0,0,4.226-1.094,3.875,3.875,0,0,0,.323-5.45c-1.416-1.415-3.808-.614-5.562.437-2.048,1.225-4.035,1.739-5.45.323a3.874,3.874,0,0,1,.323-5.45,4.165,4.165,0,0,1,3.99-1.169"
              transform="translate(-6676.323 -1302.596)"
            />
            <line x2="2.198" y2="2.198" transform="translate(12.872 12.872)" />
            <line x2="2.198" y2="2.198" />
          </g>
          <circle
            cx="2.471"
            cy="2.471"
            r="2.471"
            transform="translate(12.911 7.856)"
          />
          <path
            d="M6654.559,1286.645c-3.857-.98-13.49,3.644-14.5,13.206-2.7,25.523,39.995,26.715,17.729,49.2"
            transform="translate(-6639.933 -1276.318)"
          />
          <circle
            cx="13.839"
            cy="13.839"
            r="13.839"
            transform="translate(22.984 16.84)"
          />
        </g>
      </svg>
    )
  }
}
