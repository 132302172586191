import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class InterviewSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderInterview', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 107.016 137.373', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-119 -7264.665)">
          <g transform="translate(-6645.386 6461.594)">
            <g transform="translate(6784.559 871.107)">
              <path
                className={a}
                d="M6846.371,907.465s0-16.609-3.761-19.491a32.925,32.925,0,0,0-13.244-5.817l-.575.575a23.453,23.453,0,0,1-33.122-.575,33.493,33.493,0,0,0-13.244,5.817c-1.016.713-3.437,19.159-3.437,19.159Z"
                transform="translate(-6778.988 -839.127)"
              />
              <path
                className={a}
                d="M6825.038,867.93c0,9.313-5.958,23.034-17.314,23.034s-17.295-13.721-17.295-23.034a17.314,17.314,0,0,1,34.569,0"
                transform="translate(-6774.303 -851.63)"
              />
            </g>
            <g transform="translate(6797.036 804.073)">
              <path
                className={a}
                d="M6830.268,839.229s0-10.458-2.368-12.273a20.731,20.731,0,0,0-8.339-3.662l-.363.362a14.767,14.767,0,0,1-20.854-.362,21.1,21.1,0,0,0-8.339,3.662c-.641.45-2.164,12.064-2.164,12.064Z"
                transform="translate(-6787.84 -796.201)"
              />
              <path
                className={a}
                d="M6816.834,814.337c0,5.864-3.751,14.5-10.9,14.5s-10.89-8.639-10.89-14.5a10.9,10.9,0,0,1,21.768,0"
                transform="translate(-6784.89 -804.073)"
              />
            </g>
            <path
              className={a}
              d="M6786.049,916.335h-20.663l15.506-81.736h74l15.5,81.736h-19.037"
              transform="translate(0 12.502)"
            />
          </g>
          <g className={b} transform="translate(-6645.386 6461.594)">
            <g transform="translate(6784.559 871.107)">
              <path
                className={c}
                d="M6846.371,907.465s0-16.609-3.761-19.491a32.925,32.925,0,0,0-13.244-5.817l-.575.575a23.453,23.453,0,0,1-33.122-.575,33.493,33.493,0,0,0-13.244,5.817c-1.016.713-3.437,19.159-3.437,19.159Z"
                transform="translate(-6778.988 -839.127)"
              />
              <path
                className={c}
                d="M6825.038,867.93c0,9.313-5.958,23.034-17.314,23.034s-17.295-13.721-17.295-23.034a17.314,17.314,0,0,1,34.569,0"
                transform="translate(-6774.303 -851.63)"
              />
            </g>
            <g transform="translate(6797.036 804.073)">
              <path
                className={c}
                d="M6830.268,839.229s0-10.458-2.368-12.273a20.731,20.731,0,0,0-8.339-3.662l-.363.362a14.767,14.767,0,0,1-20.854-.362,21.1,21.1,0,0,0-8.339,3.662c-.641.45-2.164,12.064-2.164,12.064Z"
                transform="translate(-6787.84 -796.201)"
              />
              <path
                className={c}
                d="M6816.834,814.337c0,5.864-3.751,14.5-10.9,14.5s-10.89-8.639-10.89-14.5a10.9,10.9,0,0,1,21.768,0"
                transform="translate(-6784.89 -804.073)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
