import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class DetailedMirrorSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderDetailedMirror', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="190.744"
        height="211.039"
        viewBox="0 0 190.744 211.039"
      >
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )};}.${b}{fill:#fff;opacity:0.5;}`}</style>
        </defs>
        <g transform="translate(-585 -1855)">
          <g transform="translate(-7218.766 1399.883)">
            <g transform="translate(7819.121 455.117)">
              <path
                className={a}
                d="M7890.961,615.148a80.016,80.016,0,1,1,80.013-80.015A80.108,80.108,0,0,1,7890.961,615.148Zm0-151.475a71.459,71.459,0,1,0,71.456,71.46A71.541,71.541,0,0,0,7890.961,463.674Z"
                transform="translate(-7810.942 -455.117)"
              />
            </g>
            <g transform="translate(7803.766 532.995)">
              <path
                className={a}
                d="M7899.138,589.031a95.479,95.479,0,0,1-95.372-95.37,2.137,2.137,0,0,1,2.14-2.139h17.5a2.139,2.139,0,0,1,0,4.278h-15.333a91.093,91.093,0,0,0,182.136,0h-15.333a2.139,2.139,0,0,1,0-4.278h17.5a2.138,2.138,0,0,1,2.14,2.139A95.479,95.479,0,0,1,7899.138,589.031Z"
                transform="translate(-7803.766 -491.522)"
              />
            </g>
            <g transform="translate(7894.859 606.592)">
              <path
                className={a}
                d="M7850.627,572.507a4.279,4.279,0,0,1-4.278-4.278V530.2a4.279,4.279,0,0,1,8.557,0v38.024A4.28,4.28,0,0,1,7850.627,572.507Z"
                transform="translate(-7846.349 -525.926)"
              />
            </g>
            <g transform="translate(7851.633 646.755)">
              <path
                className={a}
                d="M7917.873,560.823h-90.73c5.788-9.6,14.252-15.122,23.163-15.122h44.4c8.909,0,17.374,5.526,23.164,15.122Z"
                transform="translate(-7825.003 -543.562)"
              />
              <path
                className={a}
                d="M7919.012,564.1h-90.73a2.142,2.142,0,0,1-1.834-3.245c6.195-10.266,15.306-16.155,25-16.155h44.4c9.69,0,18.8,5.889,24.994,16.155a2.14,2.14,0,0,1-1.831,3.245Zm-86.714-4.278H7915c-5.213-6.94-12.036-10.844-19.148-10.844h-44.4C7844.332,548.979,7837.508,552.883,7832.3,559.823Z"
                transform="translate(-7826.142 -544.701)"
              />
            </g>
            <g transform="translate(7892.251 536.192)">
              <path
                className={a}
                d="M7847.267,538.3a2.139,2.139,0,0,1-1.513-3.652l41-41a2.139,2.139,0,0,1,3.025,3.025l-41,41A2.135,2.135,0,0,1,7847.267,538.3Z"
                transform="translate(-7845.128 -493.017)"
              />
            </g>
            <g transform="translate(7942.362 517.066)">
              <path
                className={a}
                d="M7870.695,497.07a2.139,2.139,0,0,1-1.514-3.652l8.716-8.715a2.139,2.139,0,0,1,3.025,3.025l-8.716,8.715A2.13,2.13,0,0,1,7870.695,497.07Z"
                transform="translate(-7868.554 -484.076)"
              />
            </g>
            <g transform="translate(7897.308 528.302)">
              <path
                className={a}
                d="M7849.631,556.092a2.139,2.139,0,0,1-1.512-3.652l62.486-62.486a2.139,2.139,0,0,1,3.025,3.025l-62.486,62.486A2.138,2.138,0,0,1,7849.631,556.092Z"
                transform="translate(-7847.493 -489.328)"
              />
            </g>
          </g>
          <circle
            className={b}
            cx="70.5"
            cy="70.5"
            r="70.5"
            transform="translate(610 1864)"
          />
        </g>
      </svg>
    )
  }
}
