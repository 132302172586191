import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class SimpleApologizeSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 29.626 27.863', discType, color)}>
        <g transform="translate(-0.355 -4177.956)">
          <path d="M2.844,4204.532a16.8,16.8,0,0,1,5.511-7.694c4.871-3.637,9.515-2.235,13.1-5.927a15.094,15.094,0,0,0,3.04-3.889" />
          <path d="M28.412,4183.695a8.728,8.728,0,0,1-3.916,3.327s-.918-3.215.2-5.308a6.534,6.534,0,0,1,3.856-2.758S29.473,4181.7,28.412,4183.695Z" />
          <path d="M18.8,4188.456a7.49,7.49,0,0,1-3.515,4.472s-2-2.734-1.417-5.509,3.511-4.452,3.511-4.452S19.351,4185.815,18.8,4188.456Z" />
          <path d="M7.456,4193.455c.165,3.595-2.764,6.3-2.764,6.3s-3.172-2.6-3.329-6.026,2.766-6.281,2.766-6.281S7.307,4190.192,7.456,4193.455Z" />
          <path d="M13.023,4202.756c-3.4-.961-5.021-4.531-5.021-4.531s3.4-2.152,6.649-1.236,5,4.525,5,4.525S16.11,4203.628,13.023,4202.756Z" />
          <path d="M21.678,4197.354a7.356,7.356,0,0,1-4.667-3.073s2.511-2.183,5.276-1.84,4.647,3.07,4.647,3.07S24.309,4197.68,21.678,4197.354Z" />
          <path d="M3,4193.786" />
        </g>
      </svg>
    )
  }
}
