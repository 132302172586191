import React from 'react'

import { getBasicClasses, getSVGClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class StarTicketSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('StarTicketSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 137.779 72.442', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-87 -7910.008)">
          <g transform="translate(-5838.101 7549.523)">
            <path
              className={a}
              d="M6060.583,411.1a12.919,12.919,0,1,1,0-25.837c.1,0,.2.013.3.014V361.485H5926.1V385.26a12.919,12.919,0,1,1,0,25.837v19.832h134.78V411.081C6060.781,411.084,6060.683,411.1,6060.583,411.1Z"
              transform="translate(0)"
            />
            <line
              className={a}
              y2="52.797"
              transform="translate(5949.472 368.708)"
            />
            <line
              className={a}
              y2="52.797"
              transform="translate(6035.399 368.708)"
            />
            <path
              className={a}
              d="M5981.381,366.9l8.443,17.109,18.881,2.744-13.662,13.317,3.226,18.8-16.888-8.878-16.888,8.878,3.226-18.8-13.662-13.317,18.881-2.744Z"
              transform="translate(12.111 2.345)"
            />
          </g>
          <g className={b} transform="translate(-5836.101 7551.523)">
            <path
              className={c}
              d="M-762.12-7585.081H-896.9v-19.831a12.835,12.835,0,0,0,9.135-3.784,12.836,12.836,0,0,0,3.784-9.135,12.836,12.836,0,0,0-3.784-9.135,12.834,12.834,0,0,0-9.135-3.784v-23.774h134.78v23.79c-.046,0-.093,0-.137-.008s-.1-.008-.16-.008a12.838,12.838,0,0,0-9.136,3.784,12.836,12.836,0,0,0-3.785,9.135,12.834,12.834,0,0,0,3.785,9.135,12.837,12.837,0,0,0,9.136,3.784c.056,0,.114,0,.161-.008h.018l.118-.006v19.845Zm-67.388-61.687-8.445,17.109-18.88,2.743,13.662,13.319-3.225,18.8,16.888-8.879,16.888,8.879-3.227-18.8,13.662-13.319-18.88-2.743Z"
              transform="translate(6823 8016.008)"
            />
            <line
              className={a}
              y2="52.797"
              transform="translate(5949.472 368.708)"
            />
            <line
              className={a}
              y2="52.797"
              transform="translate(6035.399 368.708)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
