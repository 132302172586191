import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class EaselSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    return (
      <svg {...getBasicClasses('0 0 93.23 98.568', discType, color)}>
        <g transform="translate(-3.083 -2.169)">
          <path
            className="a"
            d="M51.509,29.35A18.289,18.289,0,1,1,33.22,47.639"
          />
          <path
            className="a"
            d="M48.057,43.723H29.768A18.289,18.289,0,0,1,48.057,25.434Z"
          />
          <path className="a" d="M45.817,65.021l5.692-17.382,18.262-.994" />
          <line
            className="a"
            y1="12.242"
            x2="13.586"
            transform="translate(51.509 35.398)"
          />
          <rect
            className="a"
            width="81.418"
            height="57.622"
            transform="translate(9.795 15.32)"
          />
          <path
            className="a"
            d="M51.509,29.35A18.289,18.289,0,1,1,33.22,47.639"
          />
          <line
            className="a"
            y1="12.242"
            x2="13.586"
            transform="translate(51.509 35.398)"
          />
          <rect
            className="a"
            width="81.418"
            height="57.622"
            transform="translate(9.795 15.32)"
          />
          <path
            className="a"
            d="M3.038,0h0A3.038,3.038,0,0,1,6.076,3.038V6.076a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3.038A3.038,3.038,0,0,1,3.038,0Z"
            transform="translate(48.471 3.169)"
          />
          <path
            className="a"
            d="M3.038,0h0A3.038,3.038,0,0,1,6.076,3.038V20.561a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3.038A3.038,3.038,0,0,1,3.038,0Z"
            transform="translate(54.271 99.737) rotate(180)"
          />
          <path
            className="a"
            d="M58.026,79.017h8.592l8.094,8.1a3.036,3.036,0,0,1,0,4.3h0a3.038,3.038,0,0,1-4.3,0Z"
          />
          <path
            className="a"
            d="M44.665,79.017l-8.751.159-7.936,7.936a3.036,3.036,0,0,0,0,4.3h0a3.038,3.038,0,0,0,4.3,0Z"
          />
          <rect
            className="a"
            width="91.23"
            height="6.076"
            transform="translate(4.083 9.244)"
          />
          <rect
            className="a"
            width="91.23"
            height="6.076"
            transform="translate(4.083 72.942)"
          />
        </g>
      </svg>
    )
  }
}
