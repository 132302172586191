import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookTreesSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookTrees', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 103.696 85.418"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-729.319 -654.485)">
          <g transform="translate(730.319 411.789)">
            <line
              className={a}
              x2="19.089"
              transform="translate(17.041 320.242)"
            />
            <path className={a} d="M26.585,290.959" />
            <line
              className={a}
              x1="7.224"
              y1="7.224"
              transform="translate(14.086 278.815)"
            />
            <path className={a} d="M26.506,278.441" />
            <line
              className={a}
              x1="4.364"
              y1="4.364"
              transform="translate(19.72 271.851)"
            />
            <path
              className={a}
              d="M39.34,254.38c-1.525-6.157-6.658-10.684-12.755-10.684-7.3,0-13.215,6.488-13.215,14.492a15.988,15.988,0,0,0,.209,2.5C5.764,262.97,0,270.765,0,280.056c0,11.086,8.195,20.073,18.305,20.073h7.9"
            />
          </g>
          <line
            className={a}
            y1="19.324"
            transform="translate(756.219 711.918)"
          />
          <line
            className={a}
            y1="19.324"
            transform="translate(803.116 711.918)"
          />
          <g transform="translate(730.319 411.789)">
            <line
              className={a}
              x2="19.089"
              transform="translate(38.727 327.114)"
            />
            <line
              className={a}
              y2="64.859"
              transform="translate(48.271 262.255)"
            />
            <line
              className={a}
              y1="12.144"
              x2="12.144"
              transform="translate(48.271 285.686)"
            />
            <line
              className={a}
              x1="12.144"
              y1="12.144"
              transform="translate(35.772 285.686)"
            />
            <line
              className={a}
              y1="6.687"
              x2="6.687"
              transform="translate(48.191 278.626)"
            />
            <line
              className={a}
              x1="6.591"
              y1="6.591"
              transform="translate(41.406 278.723)"
            />
            <path
              className={a}
              d="M61.332,267.158a15.757,15.757,0,0,0,.154-2.1c0-8-5.916-14.492-13.215-14.492s-13.215,6.489-13.215,14.492a15.857,15.857,0,0,0,.209,2.495c-7.815,2.288-13.579,10.082-13.579,19.373,0,11.086,8.195,20.073,18.3,20.073H58.261c10.109,0,18.305-8.987,18.305-20.073C76.566,276.991,69.976,268.763,61.332,267.158Z"
            />
          </g>
          <g transform="translate(730.319 411.789)">
            <line
              className={a}
              x1="19.089"
              transform="translate(62.567 320.242)"
            />
            <path className={a} d="M72.111,290.959" />
            <line
              className={a}
              y1="7.224"
              x2="7.224"
              transform="translate(77.386 278.815)"
            />
            <path className={a} d="M72.191,278.441" />
            <line
              className={a}
              y1="4.364"
              x2="4.364"
              transform="translate(74.612 271.851)"
            />
            <path
              className={a}
              d="M58.686,256.137c1.674-8.191,7.328-12.441,13.425-12.441,7.3,0,13.215,6.488,13.215,14.492a15.867,15.867,0,0,1-.209,2.5C92.932,262.97,98.7,270.765,98.7,280.056c0,11.086-8.195,20.073-18.3,20.073h-7.9"
            />
          </g>
          <g className={b} transform="translate(261 -4)">
            <g transform="translate(472.319 418.789)">
              <line
                className={a}
                y1="12.144"
                x2="12.144"
                transform="translate(48.271 285.686)"
              />
              <line
                className={a}
                x1="12.144"
                y1="12.144"
                transform="translate(35.772 285.686)"
              />
              <line
                className={a}
                y1="6.687"
                x2="6.687"
                transform="translate(48.191 278.626)"
              />
              <line
                className={a}
                x1="6.591"
                y1="6.591"
                transform="translate(41.406 278.723)"
              />
              <path
                className={c}
                d="M61.332,267.158a15.757,15.757,0,0,0,.154-2.1c0-8-5.916-14.492-13.215-14.492s-13.215,6.489-13.215,14.492a15.857,15.857,0,0,0,.209,2.495c-7.815,2.288-13.579,10.082-13.579,19.373,0,11.086,8.195,20.073,18.3,20.073H58.261c10.109,0,18.305-8.987,18.305-20.073C76.566,276.991,69.976,268.763,61.332,267.158Z"
              />
            </g>
            <g transform="translate(472.319 418.789)">
              <path className={c} d="M26.585,290.959" />
              <line
                className={a}
                x1="7.224"
                y1="7.224"
                transform="translate(14.086 278.815)"
              />
              <path className={c} d="M26.506,278.441" />
              <line
                className={a}
                x1="4.364"
                y1="4.364"
                transform="translate(19.72 271.851)"
              />
              <path
                className={c}
                d="M39.34,254.38c-1.525-6.157-6.658-10.684-12.755-10.684-7.3,0-13.215,6.488-13.215,14.492a15.988,15.988,0,0,0,.209,2.5C5.764,262.97,0,270.765,0,280.056c0,11.086,8.195,20.073,18.305,20.073h7.9"
              />
            </g>
            <g transform="translate(472.319 418.789)">
              <path className={c} d="M72.111,290.959" />
              <line
                className={a}
                y1="7.224"
                x2="7.224"
                transform="translate(77.386 278.815)"
              />
              <path className={c} d="M72.191,278.441" />
              <line
                className={a}
                y1="4.364"
                x2="4.364"
                transform="translate(74.612 271.851)"
              />
              <path
                className={c}
                d="M58.686,256.137c1.674-8.191,7.328-12.441,13.425-12.441,7.3,0,13.215,6.488,13.215,14.492a15.867,15.867,0,0,1-.209,2.5C92.932,262.97,98.7,270.765,98.7,280.056c0,11.086-8.195,20.073-18.3,20.073h-7.9"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
