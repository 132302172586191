import React from 'react'

const JournalSVG = () => {
  return (
    <svg
      width="27"
      height="32"
      viewBox="0 0 27 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3946 1.625H21.8552H4.61838L3.23682 1V30.0131L4.61838 31H26.3946V11.4934V1.625Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0131 12.7106H11.4604V16.6908H20.0131V12.7106Z"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.13156 7.57911C6.13156 8.237 4.98027 8.76332 3.5658 8.76332C2.15133 8.76332 1 8.237 1 7.57911C1 6.92121 1.78946 6.3949 3.20393 6.3949"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.13156 15.704C6.13156 16.3619 4.98027 16.8882 3.5658 16.8882C2.15133 16.8882 1 16.3619 1 15.704C1 15.0461 1.78946 14.5198 3.20393 14.5198"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.13156 23.8289C6.13156 24.4868 4.98027 25.0131 3.5658 25.0131C2.15133 25.0131 1 24.4868 1 23.8289C1 23.171 1.78946 22.6447 3.20393 22.6447"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3945 11.4935V1.62512"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.61865 1.62512V31.0001"
        stroke="#2CA7E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default JournalSVG
