import { getBasicClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const CheckmarkBubbleBigSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 21 21', discType, color)}>
      <circle
        cx="10"
        cy="11"
        r="10"
        fill={color}
        fillOpacity="0.13"
        strokeOpacity="0.13"
      />
      <path
        d="M3.75 10.6429L10 16L20 1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckmarkBubbleBigSVG
