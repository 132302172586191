import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ContractSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 74.814 78.712', discType, color)}>
        <g transform="translate(1.318 1.318)">
          <g transform="translate(16.572 4.003)">
            <path
              d="M7350.679,597.645v-9.474h43.475l12.449,12.366v60.025h-55.924V637.252"
              transform="translate(-7350.679 -588.172)"
            />
            <line x2="36.156" transform="translate(10.967 20.975)" />
            <line x2="36.156" transform="translate(10.967 33.472)" />
            <line x2="30.075" transform="translate(17.049 45.97)" />
            <line x2="23.496" transform="translate(23.628 63.38)" />
            <path
              d="M7416.858,600.538h-12.366V588.172Z"
              transform="translate(-7360.936 -588.172)"
            />
            <line x2="5.53" y2="5.529" transform="translate(23.628 53.468)" />
            <line x1="5.53" y2="5.529" transform="translate(23.628 53.468)" />
          </g>
          <g transform="translate(0 0)">
            <rect
              width="8.949"
              height="55.05"
              transform="translate(31.302 54.515) rotate(156.223)"
            />
            <path
              d="M7360.236,655.058l-1.1,3.692,8.911,7.072,1.294-11.567-3.405-1.706"
              transform="translate(-7335.719 -597.438)"
            />
            <line x1="3.027" y1="7.701" transform="translate(29.299 60.682)" />
            <path
              d="M0,0H6.135a0,0,0,0,1,0,0V2.655A2.476,2.476,0,0,1,3.659,5.131H2.476A2.476,2.476,0,0,1,0,2.655V0A0,0,0,0,1,0,0Z"
              transform="translate(7.683 4.696) rotate(156.223)"
            />
            <path
              d="M7333.641,602.8l-3.255,1.549,8.49,18.958"
              transform="translate(-7330.24 -587.957)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
