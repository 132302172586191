import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookScaleSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookScale', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.009 73.339"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1833 -1742.928)">
          <path
            className={a}
            d="M1698.666,1374.845a4.555,4.555,0,0,1,9.11,0"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1736.8,1375.791l-14.1,28.7h28.2l-14.1-28.7h-67.81l-14.1,28.7h28.2l-14.1-28.7"
            transform="translate(179.114 373.638)"
          />
          <line
            className={a}
            x2="54.735"
            transform="translate(1854.637 1815.267)"
          />
          <path
            className={a}
            d="M1750.894,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1683.084,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <rect
            className={a}
            width="9.11"
            height="65.516"
            transform="translate(1877.78 1749.751)"
          />
          <path
            className={b}
            d="M1750.894,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={b}
            d="M1683.084,1404.492a14.1,14.1,0,0,1-28.2,0Z"
            transform="translate(179.114 373.638)"
          />
          <rect
            className={b}
            width="9.11"
            height="65.516"
            transform="translate(1877.78 1749.751)"
          />
        </g>
      </svg>
    )
  }
}
