import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookPersonCenterSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookPersonCenter',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 93.591 90.224"
      >
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${a},.${b},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;}.${a},.${c}{stroke-width:1.936px;}.${b}{stroke-width:2.031px;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-731.578 -1388.58)">
          <circle
            className={a}
            cx="7.405"
            cy="7.405"
            r="7.405"
            transform="translate(771.265 1389.548)"
          />
          <path
            className={a}
            d="M54.058,996.459H41.706a5.611,5.611,0,0,0-5.609,5.748l.295,12.148c.285,2.131,2.31,3.764,4.809,4l1.141,27.057H53.61l1.954-27.062a5.05,5.05,0,0,0,4.743-3.994l.3-12.148a5.611,5.611,0,0,0-5.609-5.748Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className={a}
            d="M57.351,1037.5c7.595,1.337,12.865,4.262,12.865,7.654,0,4.656-9.922,8.43-22.162,8.43s-22.161-3.774-22.161-8.43c0-3.332,5.082-6.212,12.458-7.58"
            transform="translate(730.319 411.789)"
          />
          <path
            className={b}
            d="M60.535,1028.415c19.217,2.273,33.3,9.664,33.3,18.432,0,10.577-20.5,19.152-45.78,19.152s-45.78-8.575-45.78-19.152c0-9.077,15.093-16.679,35.352-18.653"
            transform="translate(730.319 411.789)"
          />
          <circle
            className={c}
            cx="7.405"
            cy="7.405"
            r="7.405"
            transform="translate(773.265 1390.548)"
          />
          <path
            className={c}
            d="M54.058,996.459H41.706a5.611,5.611,0,0,0-5.609,5.748l.295,12.148c.285,2.131,2.31,3.764,4.809,4l1.141,27.057H53.61l1.954-27.062a5.05,5.05,0,0,0,4.743-3.994l.3-12.148a5.611,5.611,0,0,0-5.609-5.748Z"
            transform="translate(732.319 412.789)"
          />
        </g>
      </svg>
    )
  }
}
