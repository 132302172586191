import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import classNameBind from 'classnames/bind'

import Label from '@dashboard/components/Label'
import Loader from '@crystal-eyes/components/elements/Loader/Loader'

import css from './styles.module.scss'

const classNames = classNameBind.bind(css)

interface OwnProps {
  error?: string | null
  label?: string
  leftSquare?: boolean
  noFlex?: boolean
  noMargin?: boolean
  placeholder?: string
  property?: string
  readOnly?: boolean
  required?: boolean
  short?: boolean
  subtitle?: string
  type?: string
  value: string
  noContainerMargin?: boolean
  loading?: boolean
  lightText?: boolean
  xSpace?: boolean
  borderLeft?: boolean
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onEnterPressed?: () => void
  optional?: boolean
  helpText?: string
}

const Input: FC<OwnProps> = ({
  onBlur,
  onFocus,
  onChange,
  placeholder,
  noMargin,
  property,
  required,
  type,
  value,
  readOnly,
  short,
  leftSquare,
  inputProps,
  borderLeft,
  loading,
  error,
  onEnterPressed,
  label,
  subtitle,
  lightText,
  noFlex,
  noContainerMargin,
  xSpace,
  optional,
  helpText,
}: OwnProps) => {
  const renderInput = () => {
    const hasError = !!error

    const propObject: DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > = {}
    if (onChange) propObject.onChange = onChange
    if (onBlur) propObject.onBlur = onBlur
    if (onFocus) propObject.onFocus = onFocus
    if (type) propObject.type = type || 'text'
    if (required) propObject.required = required
    if (typeof value === 'string') propObject.value = value
    if (readOnly) propObject.readOnly = readOnly
    if (placeholder) propObject.placeholder = placeholder
    if (onEnterPressed)
      propObject.onKeyDown = e => {
        if (e.key === 'Enter') onEnterPressed()
      }

    propObject.className = classNames('input', {
      'no-margin': noMargin,
      short,
      loading,
      leftSquare,
      borderLeft,
      hasError,
    })

    return (
      <input
        id={property}
        data-property={property}
        {...propObject}
        {...(inputProps || {})}
      />
    )
  }

  const renderLabel = () => {
    if (label) {
      return (
        <Label
          light={lightText}
          htmlFor={property}
          text={label}
          subtitle={subtitle}
          titleError={!!error}
          optional={optional}
        />
      )
    }

    if (error) {
      return <div className={classNames('error')}>{error}</div>
    }

    return null
  }

  const renderLoading = () => {
    if (!loading) return null

    return (
      <div className={classNames('loader')}>
        <Loader blue size={24} />
      </div>
    )
  }

  const BottomLabel = () => {
    if (error) {
      return <div className={classNames('bottomError')}>{error}</div>
    }

    return <div className={classNames('helpText')}>{helpText}</div>
  }

  return (
    <div
      className={classNames('container', {
        noFlex,
        noContainerMargin,
        xSpace,
      })}
    >
      {renderLabel()}
      {renderInput()}
      {renderLoading()}
      <BottomLabel />
    </div>
  )
}

export default Input
