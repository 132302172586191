import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookGroupSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookGroup', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 97.536 58.233"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1833.052 -1623.411)">
          <path
            className={a}
            d="M1718.31,1300.2h-30.683a4.525,4.525,0,0,1-4.361-5.766c2.492-8.528,7.6-14.757,19.7-14.757,10.549,0,17.211,6.229,19.7,14.757A4.524,4.524,0,0,1,1718.31,1300.2Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1713.517,1260.3c0,5.442-3.481,13.461-10.119,13.461s-10.107-8.019-10.107-13.461a9.757,9.757,0,0,1,10.113-9.525c5.13.016,10.09,3.238,10.09,9.525"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1719.626,1287.929a22.164,22.164,0,0,1,10.942-2.447c10.548,0,17.21,6.23,19.7,14.757a4.526,4.526,0,0,1-4.361,5.767h-30.683a4.525,4.525,0,0,1-4.36-5.767"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1741.116,1266.106c0,5.442-3.481,13.461-10.118,13.461s-10.108-8.019-10.108-13.461a9.757,9.757,0,0,1,10.113-9.525c5.131.016,10.09,3.238,10.09,9.525"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1694.545,1300.239a4.525,4.525,0,0,1-4.36,5.767H1659.5a4.526,4.526,0,0,1-4.361-5.767c2.492-8.527,7.6-14.757,19.7-14.757a21.155,21.155,0,0,1,10.213,2.421"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1685.392,1266.106c0,5.442-3.481,13.461-10.119,13.461s-10.107-8.019-10.107-13.461a9.757,9.757,0,0,1,10.113-9.525c5.13.016,10.09,3.238,10.09,9.525"
            transform="translate(179.114 373.638)"
          />
          <path
            className={b}
            d="M1718.31,1300.2h-30.683a4.525,4.525,0,0,1-4.361-5.766c2.492-8.528,7.6-14.757,19.7-14.757,10.549,0,17.211,6.229,19.7,14.757A4.524,4.524,0,0,1,1718.31,1300.2Z"
            transform="translate(181.114 374.638)"
          />
          <path
            className={b}
            d="M1713.517,1260.3c0,5.442-3.481,13.461-10.119,13.461s-10.107-8.019-10.107-13.461a9.757,9.757,0,0,1,10.113-9.525c5.13.016,10.09,3.238,10.09,9.525"
            transform="translate(181.114 374.638)"
          />
          <path
            className={b}
            d="M1694.545,1300.239a4.525,4.525,0,0,1-4.36,5.767H1659.5a4.526,4.526,0,0,1-4.361-5.767c2.492-8.527,7.6-14.757,19.7-14.757,12.492-.646,3.247,9.489,6.1,11.034"
            transform="translate(181.114 374.638)"
          />
          <path
            className={b}
            d="M1685.392,1266.106c0,5.442-3.481,13.461-10.119,13.461s-10.107-8.019-10.107-13.461a9.757,9.757,0,0,1,10.113-9.525c5.13.016,10.09,3.238,10.09,9.525"
            transform="translate(181.114 374.638)"
          />
        </g>
      </svg>
    )
  }
}
