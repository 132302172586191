import React from 'react'

const YellowCheckBubble = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14.7452" r="13.5" fill="#FFCB1B" fillOpacity="0.2" />
      <path
        d="M5.5625 14.2631L14 21.4952L27.5 1.24524"
        stroke="#F09025"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default YellowCheckBubble
