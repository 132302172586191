import React, { ChangeEvent } from 'react'
import classNamesBind from 'classnames/bind'

import TooltipWrapper from '@dashboard/components/TooltipWrapper'
import Input from '@dashboard/components/Input'
import IntroduceSVG from './components/IntroduceSVG'
import SimpleIntroduceSVG from './components/SimpleIntroduceSVG'
import ApologizeSVG from './components/ApologizeSVG'
import SimpleApologizeSVG from './components/SimpleApologizeSVG'
import GlobeSVG from './components/GlobeSVG'
import SmallGlobeSVG from './components/SmallGlobeSVG'
import CrystalLogoSVG from './components/CrystalLogoSVG'
import CheckSVG from './components/CheckSVG'
import CheckBubbleSVG from './components/CheckBubbleSVG'
import DiscSVG from './components/DiscSVG'
import StrengthsSVG from './components/StrengthsSVG'
import PuzzleSVG from './components/PuzzleSVG'
import BoxGridSVG from './components/BoxGridSVG'
import ThinPrintSVG from './components/ThinPrintSVG'
import LeadershipSVG from './components/LeadershipSVG'
import CommunicateSVG from './components/CommunicateSVG'
import TeamSVG from './components/TeamSVG'
import PricetagSVG from './components/PricetagSVG'
import BrainSVG from './components/BrainSVG'
import ComputerGraphSVG from './components/ComputerGraphSVG'
import HandshakeSVG from './components/HandshakeSVG'
import MagnifyingGlassPersonSVG from './components/MagnifyingGlassPersonSVG'
import ContractSVG from './components/ContractSVG'
import GraphClipboardSVG from './components/GraphClipboardSVG'
import ConversationSpeechBubblesSVG from './components/ConversationSpeechBubblesSVG'
import PlaybookSpeechSVG from './components/PlaybookSpeechSVG'
import PlaybookExplosionSVG from './components/PlaybookExplosionSVG'
import PlaybookDocumentSVG from './components/PlaybookDocumentSVG'
import PlaybookBriefcaseSVG from './components/PlaybookBriefcaseSVG'
import PlaybookChecklistSVG from './components/PlaybookChecklistSVG'
import PlaybookBranchSVG from './components/PlaybookBranchSVG'
import PlaybookRibbonSVG from './components/PlaybookRibbonSVG'
import PlaybookCommunicateSVG from './components/PlaybookCommunicateSVG'
import PlaybookSignSVG from './components/PlaybookSignSVG'
import PlaybookCalendarSVG from './components/PlaybookCalendarSVG'
import PlaybookPulseSVG from './components/PlaybookPulseSVG'
import PlaybookLetterSVG from './components/PlaybookLetterSVG'
import PlaybookChainSVG from './components/PlaybookChainSVG'
import PlaybookHandshakeSVG from './components/PlaybookHandshakeSVG'
import PlaybookFeedbackSVG from './components/PlaybookFeedbackSVG'
import PlaybookLeaderSVG from './components/PlaybookLeaderSVG'
import PlaybookProfileSVG from './components/PlaybookProfileSVG'
import PlaybookBooksAppleSVG from './components/PlaybookBooksAppleSVG'
import PlaybookRelationshipSVG from './components/PlaybookRelationshipSVG'
import PlaybookWeightSVG from './components/PlaybookWeightSVG'
import PlaybookBlindSVG from './components/PlaybookBlindSVG'
import PlaybookHandHeartSVG from './components/PlaybookHandHeartSVG'
import PlaybookRelationshipConversationSVG from './components/PlaybookRelationshipConversationSVG'
import PlaybookScaleSVG from './components/PlaybookScaleSVG'
import PlaybookGroupSVG from './components/PlaybookGroupSVG'
import PlaybookFlowerSVG from './components/PlaybookFlowerSVG'
import PlaybookSmileSVG from './components/PlaybookSmileSVG'
import PlaybookWaterSVG from './components/PlaybookWaterSVG'
import PlaybookHeartFlowerSVG from './components/PlaybookHeartFlowerSVG'
import PlaybookDiamondSVG from './components/PlaybookDiamondSVG'
import PlaybookWhistleSVG from './components/PlaybookWhistleSVG'
import PlaybookWarningSVG from './components/PlaybookWarningSVG'
import PlaybookGroupThinkSVG from './components/PlaybookGroupThinkSVG'
import PlaybookHeadIdeaSVG from './components/PlaybookHeadIdeaSVG'
import PlaybookGavelSVG from './components/PlaybookGavelSVG'
import PlaybookBrainSVG from './components/PlaybookBrainSVG'
import PlaybookLionSVG from './components/PlaybookLionSVG'
import PlaybookMountainSVG from './components/PlaybookMountainSVG'
import PlaybookTwoFingersSVG from './components/PlaybookTwoFingersSVG'
import PlaybookPartyHornSVG from './components/PlaybookPartyHornSVG'
import PlaybookArrowHeartSVG from './components/PlaybookArrowHeartSVG'
import PlaybookGearCheckSVG from './components/PlaybookGearCheckSVG'
import PlaybookLockSVG from './components/PlaybookLockSVG'
import PlaybookDogSVG from './components/PlaybookDogSVG'
import PlaybookPersonCenterSVG from './components/PlaybookPersonCenterSVG'
import PlaybookHeartHandshakeSVG from './components/PlaybookHeartHandshakeSVG'
import PlaybookPaintSVG from './components/PlaybookPaintSVG'
import PlaybookGroupSparkleSVG from './components/PlaybookGroupSparkleSVG'
import PlaybookTrophySVG from './components/PlaybookTrophySVG'
import PlaybookDollarSVG from './components/PlaybookDollarSVG'
import PlaybookTreesSVG from './components/PlaybookTreesSVG'
import PlaybookHire from './components/PlaybookHireSVG'
import PlaybookTieSVG from './components/PlaybookTieSVG'
import PlaybookManyToOneSVG from './components/PlaybookManyToOneSVG'
import PlaybookResumeSVG from './components/PlaybookResumeSVG'
import MagnifyingGlassInfoSVG from './components/MagnifyingGlassInfoSVG'
import ProfileWithArrowsSVG from './components/ProfileWithArrowsSVG'
import PlaybookDeviceLocationMarkerSVG from './components/PlaybookDeviceLocationMarkerSVG'
import DetailedShoppingCartSVG from './components/DetailedShoppingCartSVG'
import HollowPriceTicketSVG from './components/HollowPriceTicketSVG'
import DetailedMonitorSVG from './components/DetailedMonitorSVG'
import DetailedCoffeeCupSVG from './components/DetailedCoffeeCupSVG'
import PlaybookClipboardCheckListSVG from './components/PlaybookClipboardCheckListSVG'
import InterviewSVG from './components/InterviewSVG'
import HandsTogetherSVG from './components/HandsTogetherSVG'
import HandThumbsDownSVG from './components/HandThumbsDownSVG'
import DetailedMirrorSVG from './components/DetailedMirrorSVG'
import WalletSVG from './components/WalletSVG'
import SimpleWalletSVG from './components/SimpleWalletSVG'
import HandTextBubbleSVG from './components/HandTextBubbleSVG'
import StarTicketSVG from './components/StarTicketSVG'
import SimpleStarTicketSVG from './components/SimpleStarTicketSVG'
import SimpleHandTextBubbleSVG from './components/SimpleHandTextBubbleSVG'
import GiftSVG from './components/GiftSVG'
import SimpleGiftSVG from './components/SimpleGiftSVG'
import BouquetSVG from './components/BouquetSVG'
import RoseSVG from './components/RoseSVG'
import DiceSVG from './components/DiceSVG'
import HeadQuestionMarkSVG from './components/HeadQuestionMarkSVG'
import MuscleSVG from './components/MuscleSVG'
import TimerSVG from './components/TimerSVG'
import SmilingFrowningMasksSVG from './components/SmilingFrowningMasksSVG'
import ChessPieceSVG from './components/ChessPieceSVG'
import ArrowGapSVG from './components/ArrowGapSVG'
import EaselSVG from './components/EaselSVG'
import MaximizeSVG from './components/MaximizeSVG'
import RibbonCheckSVG from './components/RibbonCheckSVG'
import LightBulbSVG from './components/LightBulbSVG'
import SimpleLockSVG from './components/SimpleLockSVG'
import UploadCloudSVG from './components/UploadSVG'
import GraduationCapSVG from './components/GraduationCapSVG'
import CloseBubbleSVG from './components/CloseBubbleSVG'
import LargeDiscSVG from './components/LargeDiscSVG'
import SixteenPersonalitiesSVG from './components/SixteenPersonalitiesSVG'
import SparkleGroupSVG from './components/SparkleGroupSVG'
import WeightSVG from './components/WeightSVG'
import PodiumStarsSvg from './components/PodiumStarsSvg'
import QuestionMarkMagnifyingGlassSvg from './components/QuestionMarkMagnifyingGlassSvg'
import DetailedCloudWithLightningSVG from './components/DetailedCloudWithLightningSVG'
import TrophyWithProfileSVG from './components/TrophyWithProfileSVG'
import LanguageSVG from './components/LanguageSVG'
import FilterSVG from './components/FilterSVG'
import LongArrowSVG from './components/LongArrowSVG'
import ProfileTreeSVG from './components/ProfileTreeSVG'
import ProfilesGroupSVG from './components/ProfilesGroupSVG'
import CircularArrowsSVG from './components/CircularArrowsSVG'
import EmptyBubbleSVG from './components/EmptyBubbleSVG'
import WarningSVG from './components/WarningSVG'
import MedalSVG from './components/MedalSVG'
import LargeCupSVG from './components/LargeCupSVG'
import MegaphoneSVG from './components/MegaphoneSVG'
import LargeSunSVG from './components/LargeSunSVG'
import HeartWithOutlineSVG from './components/HeartWithOutlineSVG'
import ChartWithLinesSVG from './components/ChartWithLinesSVG'
import BarChartWithLineChartSVG from './components/BarChartWithLineChartSVG'
import RookSVG from './components/RookSVG'
import ShieldSVG from './components/ShieldSVG'
import PaperWithRulerSVG from './components/PaperWithRulerSVG'
import YellowCheckBubble from './components/YellowCheckBubble'
import GroupWithCheck from './components/GroupWithCheck'
import { getDiscHex, SVGProps } from '@dashboard/lib/svg'
import { tsErrorHelper } from '@dashboard/lib/playbooks'
import CheckmarkBigSVG from './components/CheckmarkBigSVG'
import CheckmarkBubbleBigSVG from './components/CheckmarkBubbleBigSVG'
import TrashcanDetailed from './components/TrashcanDetailedSVG'
import TrashcanThickSVG from './components/TrashcanThickSVG'
import TrashcanBubbleSVG from './components/TrashcanBubbleSVG'
import FourSquaresSVG from './components/FourSquaresSVG'
import ThumbsUp from '@dashboard/components/ColorIcon/components/ThumbsUp'
import ComparisonReportSVG from './components/ComparisonReportSVG'
import PresentationWithPersonSVG from './components/PresentationWithPerson'
import JournalSVG from './components/JournalSVG'
import LinedSpeechBubbleSVG from './components/LinedSpeechBubbleSVG'
import GroupWithWavingSVG from './components/GroupWithWavingSVG'
import MagnifyingGlassWithProfileSVG from './components/MagnifyingGlassWithProfileSVG'
import MonochromeDiscSVG from './components/MonochromeDiscSVG'
import FollowUpSVG from './components/FollowUpSVG'
import CubeSVG from './components/CubeSVG'
import PersonQuestionMark from './components/PersonQuestionMarkSVG'
import CustomContentEyeSVG from './components/CustomContentEyeSVG'
import CustomContentBlindSVG from './components/CustomContentBlindSVG'
import EyeBlindSVG from './components/EyeBlindSVG'
import { ColorIconName, COLOR_ICON_NAMES } from '@dashboard/lib/types'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

type Letters =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'

interface OwnProps {
  icon?: ColorIconName | null
  width?: number
  autoHeight?: boolean
  discType?: string
  getSamples?: boolean
  color?: string
}

interface State {
  query: string
}

export default class ColorIcon extends React.Component<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props)

    this.state = {
      query: '',
    }
  }

  render() {
    const { width, getSamples, icon, autoHeight } = this.props
    const { query } = this.state

    const style = width
      ? { width: `${width}px`, height: `${width}px` }
      : { width: '100%', height: '100%' }

    if (autoHeight) style.height = 'auto'

    if (getSamples) {
      const icons = COLOR_ICON_NAMES.filter(name => {
        return new RegExp(query, 'i').test(name)
      })

      return (
        <div>
          <Input
            onChange={this.handleSearchChange}
            value={query}
            type="search"
            placeholder="Filter icons"
          />
          <div className={classNames('array')}>
            {icons.map(name => {
              return (
                <TooltipWrapper key={name} text={name}>
                  <div
                    className={classNames('container', 'padded')}
                    style={style}
                  >
                    {this.renderIcon(name)}
                  </div>
                </TooltipWrapper>
              )
            })}
          </div>
        </div>
      )
    }

    return (
      <div className={classNames('container')} style={style}>
        {this.renderIcon(icon)}
      </div>
    )
  }

  // Render Partials
  renderIcon(icon: ColorIconName | null | undefined) {
    if (!icon) return null

    switch (icon) {
      case 'crystal-logo':
        return this.renderCrystalLogo()
      case 'check':
        return this.renderCheck()
      case 'globe':
        return this.renderGlobe()
      case 'checkBubble':
        return this.renderCheckBubble()
      case 'checkCircle':
        return this.renderCheckCircle()
      case 'checkBox':
        return this.renderCheckBox()
      case 'compass':
        return this.renderCompass()
      case 'profile':
        return this.renderProfile()
      case 'drains':
        return this.renderDrains()
      case 'mic':
        return this.renderMic()
      case 'calendar':
        return this.renderMeeting()
      case 'share-icon':
        return this.renderShareIcon()
      case 'profiles':
        return this.renderProfiles()
      case 'conflict':
        return this.renderConflict()
      case 'comparison':
        return this.renderComparison()
      case 'link':
        return this.renderLink()
      case 'blind':
        return this.renderBlind()
      case 'share':
        return this.renderShare()
      case 'convince':
        return this.renderConvince()
      case 'graph':
        return this.renderGraph()
      case 'email':
        return this.renderMail()
      case 'book-open':
        return this.renderBookOpen()
      case 'award':
        return this.renderMotivation()
      case 'target':
        return this.renderTarget()
      case 'print':
        return this.renderPrint()
      case 'enneagram':
        return this.renderEnneagram()
      case 'myers_briggs':
        return this.renderMyersBriggs()
      case 'disc':
        return this.renderDISC()
      case 'monochrome-disc':
        return this.renderMonochromeDISC()
      case 'speech_bubble':
        return this.renderSpeechBubble()
      case 'speech_bubble_thin':
        return this.renderSpeechBubbleThin()
      case 'upload':
        return this.renderUpload()
      case 'download':
        return this.renderDownload()
      case 'search':
        return this.renderSearch()
      case 'strengths':
        return this.renderStrengths()
      case 'book':
        return this.renderBook()
      case 'playbook':
        return this.renderPlaybook()
      case 'people':
        return this.renderPeople()
      case 'bubble':
        return this.renderBubble()
      case 'pencil':
        return this.renderPencil()
      case 'pencil_plain':
        return this.renderPencilPlain()
      case 'unlocked':
        return this.renderUnlocked()
      case 'lock-open':
        return this.renderLockOpen()
      case 'lock-open-bg':
        return this.renderLockOpenWithBG()
      case 'locked':
        return this.renderLocked()
      case 'lightning':
        return this.renderLightning()
      case 'clipboard':
        return this.renderClipboard()
      case 'star':
        return this.renderStar()
      case 'trash':
        return this.renderTrash()
      case 'arrow':
        return this.renderArrow()
      case 'ribbon':
        return this.renderRibbon()
      case 'puzzle':
        return this.renderPuzzle()
      case 'scale':
        return this.renderScale()
      case 'info':
        return this.renderInfo()
      case 'refresh':
        return this.renderRefresh()
      case 'small-globe':
        return this.renderSmallGlobe()
      case 'boxGrid':
        return this.renderBoxGrid()
      case 'hamburgerGrid':
        return this.renderHamburgerGrid()
      case 'gear':
        return this.renderSettings()
      case 'linkedInIcon':
        return this.renderLinkedInIcon()
      case 'google-icon':
        return this.renderGoogleIcon()
      case 'outlook-icon':
        return this.renderOutlookIcon()
      case 'twitter':
        return this.renderTwitter()
      case 'instagram':
        return this.renderInstagram()
      case 'more':
        return this.renderMore()
      case 'hamburgerMenu':
        return this.renderHamburgerMenu()
      case 'tie':
        return this.renderTie()
      case 'pulse':
        return this.renderPulse()
      case 'list_with_dots':
        return this.renderBreakdown()
      case 'horizontal_bars':
        return this.renderHorizontalBars()
      case 'thin_chain':
        return this.renderThinChain()
      case 'thin_print':
        return this.renderThinPrint()
      case 'thin_share':
        return this.renderThinShare()
      case 'tag':
        return this.renderTag()
      case 'close':
        return this.renderClose()
      case 'arrow-thin':
        return this.renderArrowThin()
      case 'leadership':
        return this.renderLeadership()
      case 'many-to-one':
        return this.renderManyToOne()
      case 'communicate':
        return this.renderCommunicate()
      case 'team':
        return this.renderTeam()
      case 'name':
        return this.renderName()
      case 'briefcase':
        return this.renderBriefcase()
      case 'pricetag':
        return this.renderPricetag()
      case 'explosion':
        return this.renderExplosion()
      case 'relationship-thin':
        return this.renderRelationshipThin()
      case 'signs':
        return this.renderSigns()
      case 'brain':
        return this.renderBrain()
      case 'person-thin':
        return this.renderPersonThin()
      case 'computer-graph':
        return this.renderComputerGraph()
      case 'handshake':
        return this.renderHandshake()
      case 'magnifying-glass-person':
        return this.renderMagnifyingGlassPerson()
      case 'magnifying-glass-person-thin':
        return this.renderMagnifyingGlassPersonThin()
      case 'magnifying-glass-with-profile':
        return this.renderMagnifyingGlassWithProfile()
      case 'contract':
        return this.renderContract()
      case 'home':
        return this.renderHome()
      case 'money_sign':
        return this.renderMoneySign()
      case 'sun':
        return this.renderSun()
      case 'shopping_cart':
        return this.renderShoppingCart()
      case 'thumbs_down':
        return this.renderThumbsDown()
      case 'thumbs_down_thin':
        return this.renderThumbsDownThin()
      case 'resume':
        return this.renderResume()
      case 'graph_clipboard':
        return this.renderGraphClipboard()
      case 'conversation_speech_bubbles':
        return this.renderConversationSpeechBubbles()
      case 'playbook-speech':
        return this.renderPlaybookSpeech()
      case 'playbook-explosion':
        return this.renderPlaybookExplosion()
      case 'playbook-document':
        return this.renderPlaybookDocument()
      case 'playbook-briefcase':
        return this.renderPlaybookBriefcase()
      case 'playbook-checklist':
        return this.renderPlaybookChecklist()
      case 'playbook-ribbon':
        return this.renderPlaybookRibbon()
      case 'playbook-communicate':
        return this.renderPlaybookCommunicate()
      case 'playbook-sign':
        return this.renderPlaybookSign()
      case 'playbook-calendar':
        return this.renderPlaybookCalendar()
      case 'playbook-pulse':
        return this.renderPlaybookPulse()
      case 'playbook-letter':
        return this.renderPlaybookLetter()
      case 'playbook-chain':
        return this.renderPlaybookChain()
      case 'playbook-handshake':
        return this.renderPlaybookHandshake()
      case 'playbook-lightning':
        return this.renderPlaybookLightning()
      case 'playbook-feedback':
        return this.renderPlaybookFeedback()
      case 'playbook-leader':
        return this.renderPlaybookLeader()
      case 'playbook-profile':
        return this.renderPlaybookProfile()
      case 'playbook-relationship':
        return this.renderPlaybookRelationship()
      case 'playbook-weight':
        return this.renderPlaybookWeight()
      case 'playbook-blind':
        return this.renderPlaybookBlind()
      case 'playbook-hand-heart':
        return this.renderPlaybookHandHeart()
      case 'playbook-relationship-conversation':
        return this.renderPlaybookRelationshipConversation()
      case 'playbook-scale':
        return this.renderPlaybookScale()
      case 'playbook-group':
        return this.renderPlaybookGroup()
      case 'playbook-flower':
        return this.renderPlaybookFlower()
      case 'playbook-smile':
        return this.renderPlaybookSmile()
      case 'playbook-water':
        return this.renderPlaybookWater()
      case 'playbook-heart-flower':
        return this.renderPlaybookHeartFlower()
      case 'playbook-diamond':
        return this.renderPlaybookDiamond()
      case 'playbook-whistle':
        return this.renderPlaybookWhistle()
      case 'playbook-warning':
        return this.renderPlaybookWarning()
      case 'playbook-branch':
        return this.renderPlaybookBranch()
      case 'playbook-group-think':
        return this.renderPlaybookGroupThink()
      case 'playbook-head-idea':
        return this.renderPlaybookHeadIdea()
      case 'playbook-gavel':
        return this.renderPlaybookGavel()
      case 'playbook-books-apple':
        return this.renderPlaybookBooksApple()
      case 'playbook-brain':
        return this.renderPlaybookBrain()
      case 'playbook-lion':
        return this.renderPlaybookLion()
      case 'playbook-mountain':
        return this.renderPlaybookMountain()
      case 'playbook-two-fingers':
        return this.renderPlaybookTwoFingers()
      case 'playbook-party-horn':
        return this.renderPlaybookPartyHorn()
      case 'playbook-arrow-heart':
        return this.renderPlaybookArrowHeart()
      case 'playbook-gear-check':
        return this.renderPlaybookGearCheck()
      case 'playbook-lock':
        return this.renderPlaybookLock()
      case 'playbook-dog':
        return this.renderPlaybookDog()
      case 'playbook-person-center':
        return this.renderPlaybookPersonCenter()
      case 'playbook-heart-handshake':
        return this.renderPlaybookHeartHandshake()
      case 'playbook-paint':
        return this.renderPlaybookPaint()
      case 'playbook-group-sparkle':
        return this.renderPlaybookGroupSparkle()
      case 'playbook-trophy':
        return this.renderPlaybookTrophy()
      case 'playbook-dollar':
        return this.renderPlaybookDollar()
      case 'playbook-trees':
        return this.renderPlaybookTrees()
      case 'playbook-tie':
        return this.renderPlaybookTie()
      case 'playbook-many-to-one':
        return this.renderPlaybookManyToOne()
      case 'playbook-resume':
        return this.renderPlaybookResume()
      case 'magnifying-glass-info':
        return this.renderMagnifyingGlassInfo()
      case 'profile-with-arrows':
        return this.renderProfileWithArrows()
      case 'playbook-device-location-marker':
        return this.renderPlaybookDeviceLocationMarker()
      case 'heart':
        return this.renderHeart()
      case 'arrow-split':
        return this.renderArrowSplit()
      case 'arrow-square':
        return this.renderArrowSquare()
      case 'hollow-check-bubble':
        return this.renderHollowCheckBubble()
      case 'box':
        return this.renderBox()
      case 'location-marker':
        return this.renderLocationMarker()
      case 'detailed_shopping_cart':
        return this.renderDetailedShoppingCart()
      case 'hollow-price-ticket':
        return this.renderHollowPriceTicket()
      case 'monitor':
        return this.renderMonitor()
      case 'detailed-monitor':
        return this.renderDetailedMonitor()
      case 'detailed-coffee-cup':
        return this.renderDetailedCoffeeCup()
      case 'coffee-cup':
        return this.renderCoffeeCup()
      case 'eye':
        return this.renderEye()
      case 'playbook-clipboard-checklist':
        return this.renderPlaybookClipboardChecklist()
      case 'playbook-hire':
        return this.renderPlaybookHire()
      case 'three-sixty-assessment':
        return this.renderThreeSixtyAssessment()
      case 'cloud_with_lightning':
        return this.renderBrainStorm()
      case 'collaborate':
        return this.renderCollaborate()
      case 'whistle':
        return this.renderWhistle()
      case 'group':
        return this.renderGroup()
      case 'magnifying-glass-person-small':
        return this.renderMagnifyingGlassPersonSmall()
      case 'signal':
        return this.renderSignal()
      case 'person-with-flag':
        return this.renderPersonWithFlag()
      case 'signature':
        return this.renderSignature()
      case 'phone':
        return this.renderPhone()
      case 'puzzle-piece':
        return this.renderPuzzlePiece()
      case 'wrench':
        return this.renderTool()
      case 'overlapping-boxes':
        return this.renderOverlappingBoxes()
      case 'bell':
        return this.renderBell()
      case 'interview':
        return this.renderInterview()
      case 'simple-magnifying-glass':
        return this.renderSimpleMagnifyingGlass()
      case 'table':
        return this.renderTable()
      case 'hands-together':
        return this.renderHandsTogether()
      case 'hand-thumbs-down':
        return this.renderHandThumbsDown()
      case 'classic-phone':
        return this.renderClassicPhone()
      case 'arrow-loop':
        return this.renderArrowLoop()
      case 'simple-classic-phone':
        return this.renderSimpleClassicPhone()
      case 'simple-hands-together':
        return this.renderSimpleHandsTogether()
      case 'simple-arrow-loop':
        return this.renderSimpleArrowLoop()
      case 'mirror':
        return this.renderMirror()
      case 'detailed-mirror':
        return this.renderDetailedMirror()
      case 'egg':
        return this.renderEgg()
      case 'arrow-in-gear':
        return this.renderArrowInGear()
      case 'introduce':
        return this.renderIntroduce()
      case 'simple-introduce':
        return this.renderSimpleIntroduce()
      case 'apologize':
        return this.renderApologize()
      case 'simple-apologize':
        return this.renderSimpleApologize()
      case 'wallet':
        return this.renderWallet()
      case 'simple-wallet':
        return this.renderSimpleWallet()
      case 'hand-text-bubble':
        return this.renderHandTextBubble()
      case 'star-ticket':
        return this.renderStarTicket()
      case 'simple-star-ticket':
        return this.renderSimpleStarTicket()
      case 'simple-hand-text-bubble':
        return this.renderSimpleHandTextBubble()
      case 'gift':
        return this.renderGift()
      case 'simple-gift':
        return this.renderSimpleGift()
      case 'bouquet':
        return this.renderBouquet()
      case 'rose':
        return this.renderRose()
      case 'dice':
        return this.renderDice()
      case 'head-question-mark':
        return this.renderHeadQuestionMark()
      case 'muscle':
        return this.renderMuscle()
      case 'timer':
        return this.renderTimer()
      case 'smiling-frowning-masks':
        return this.renderSmilingFrowningMasks()
      case 'chess-piece':
        return this.renderChessPiece()
      case 'arrow-gap':
        return this.renderArrowGap()
      case 'easel':
        return this.renderEasel()
      case 'maximize':
        return this.renderMaximize()
      case 'ribbon-check':
        return this.renderRibbonCheck()
      case 'light-bulb':
        return this.renderLighBulb()
      case 'simple-lock':
        return this.renderSimpleLock()
      case 'upload-cloud':
        return this.renderUploadCloud()
      case 'graduation-cap':
        return this.renderGraduationCap()
      case 'close-bubble':
        return this.renderCloseBubble()
      case 'weight-large':
        return this.renderWeightLarge()
      case 'sparkle-group':
        return this.renderSparkleGroup()
      case 'sixteen-personalities':
        return this.renderSixteenPersonalities()
      case 'large-disc':
        return this.renderLargeDisc()
      case 'podium_stars':
        return this.renderPodiumStars()
      case 'question-mark-magnifying-glass':
        return this.renderQuestionMarkMagnifyingGlass()
      case 'detailed-cloud-with-lightning':
        return this.renderDetailedCloudWithLightning()
      case 'trophy-with-profile':
        return this.renderTrophyWithProfile()
      case 'language':
        return this.renderLanguage()
      case 'filter':
        return this.renderFilter()
      case 'long-arrow':
        return this.renderLongArrow()
      case 'profile-tree':
        return this.renderProfileTree()
      case 'profiles-group':
        return this.renderProfilesGroup()
      case 'circular-arrows':
        return this.renderCircularArrows()
      case 'empty-bubble':
        return this.renderEmptyBubble()
      case 'warning':
        return this.renderWarning()
      case 'medal':
        return this.renderMedal()
      case 'large-cup':
        return this.renderLargeCup()
      case 'megaphone':
        return this.renderMegaphone()
      case 'large-sun':
        return this.renderLargeSun()
      case 'heart-with-outline':
        return this.renderHeartWithOutline()
      case 'chart-with-lines':
        return this.renderChartWithLines()
      case 'bar-chart-with-line-chart':
        return this.renderBarChartWithLineChart()
      case 'rook':
        return this.renderRook()
      case 'shield':
        return this.renderShield()
      case 'person-question-mark':
        return this.renderPersonQuestionMark()
      case 'paper-with-ruler':
        return this.renderPaperWithRuler()
      case 'checkmark-big':
        return this.renderBigCheckmarkSVG()
      case 'checkmark-bubble-big':
        return this.renderBigCheckmarkBubbleSVG()
      case 'custom-content-eye':
        return this.renderCustomContentEyeSVG()
      case 'custom-content-eye-blind':
        return this.renderCustomContentBlindSVG()
      case 'trashcan-detailed':
        return this.renderTrashcanDetailedSVG()
      case 'trashcan-thick':
        return this.renderTrashcanThickSVG()
      case 'trashcan-bubble':
        return this.renderTrashcanBubbleSVG()
      case 'crystal-icon':
        return this.renderCrystalIconSVG()
      case 'four-squares':
        return this.renderFourSquaresSVG()
      case 'thumbs-up':
        return this.renderThumbsUp()
      case 'yellow-check-bubble':
        return this.renderYellowCheckBubble()
      case 'group-with-check':
        return this.renderGroupWithCheck()
      case 'comparison-report':
        return this.renderComparisonReport()
      case 'presentation-with-person':
        return this.renderPresentationWithPerson()
      case 'journal':
        return this.renderJournal()
      case 'lined-speech-bubble':
        return this.renderLinedSpeechBubble()
      case 'group-with-waving':
        return this.renderGroupWithWaving()
      case 'follow-up':
        return this.renderFollowUp()
      case 'cube':
        return this.renderCube()
      case 'eye-blind':
        return this.renderEyeBlindSVG()
      default:
        tsErrorHelper(icon)
        return null
    }
  }

  renderCube() {
    return <CubeSVG />
  }

  renderFollowUp() {
    return <FollowUpSVG />
  }

  renderYellowCheckBubble() {
    return <YellowCheckBubble />
  }

  renderGroupWithCheck() {
    return <GroupWithCheck />
  }

  renderComparisonReport() {
    return <ComparisonReportSVG />
  }

  renderPresentationWithPerson() {
    return <PresentationWithPersonSVG />
  }

  renderJournal() {
    return <JournalSVG />
  }

  renderLinedSpeechBubble() {
    return <LinedSpeechBubbleSVG />
  }

  renderGroupWithWaving() {
    return <GroupWithWavingSVG />
  }

  renderFourSquaresSVG() {
    const { discType, color } = this.props

    return <FourSquaresSVG discType={discType} color={color} />
  }

  renderCrystalIconSVG() {
    return (
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M8.29732 12.2621L9.01396 13.2347L9.73061 12.2621L9.98656 11.9224L10.4054 11.8061L11.5688 11.485L11.2756 10.3635L11.1965 10.0703L11.2756 9.78177L11.5688 8.65561L10.4054 8.33917L9.98656 8.22283L9.73061 7.87847L9.01396 6.91052L8.29732 7.87847L8.04137 8.22283L7.62255 8.33917L6.45916 8.65561L6.75233 9.78177L6.83144 10.0703L6.75233 10.3635L6.45916 11.485L7.62255 11.8061L8.04137 11.9224L8.29732 12.2621Z"
            fill="#A2E2FC"
          />
          <path
            d="M6.4545 12.7321L4.95605 12.3179L5.3516 10.8102H1.91727L0 14.9612L4.55119 15.9756L6.4545 12.7321Z"
            fill="#0283AA"
          />
          <path
            d="M13.4953 3.97864L11.4943 7.39436L13.0719 7.82248L12.6763 9.33489H15.9897L18 4.98846L13.4953 3.97864Z"
            fill="#1FC3EA"
          />
          <path
            d="M16.0781 10.8102H12.6763L13.0719 12.3179L11.5455 12.7368L13.4488 15.9756L18 14.9612L16.0781 10.8102Z"
            fill="#0283AA"
          />
          <path
            d="M10.2797 13.5882L9.01396 15.3054L7.72958 13.5649L5.86815 16.734L9 19.945L12.1319 16.734L10.2797 13.5882Z"
            fill="#0283AA"
          />
          <path
            d="M5.3516 9.33489L4.95605 7.82248L6.50569 7.39901L4.5 3.97864L0 4.98846L2.00569 9.33489H5.3516Z"
            fill="#1FC3EA"
          />
          <path
            d="M7.76215 6.53826L9.01396 4.83971L10.2518 6.51034L12.1644 3.24819L9 0L5.83092 3.24819L7.76215 6.53826Z"
            fill="#1FC3EA"
          />
        </g>
      </svg>
    )
  }

  renderEyeBlindSVG() {
    const { discType, color } = this.props

    return <EyeBlindSVG discType={discType} color={color} />
  }

  renderCustomContentBlindSVG() {
    const { discType, color } = this.props

    return <CustomContentBlindSVG discType={discType} color={color} />
  }

  renderCustomContentEyeSVG() {
    const { discType, color } = this.props

    return <CustomContentEyeSVG discType={discType} color={color} />
  }

  renderTrashcanDetailedSVG() {
    const { discType, color } = this.props

    return <TrashcanDetailed discType={discType} color={color} />
  }

  renderTrashcanThickSVG() {
    const { discType, color } = this.props

    return <TrashcanThickSVG discType={discType} color={color} />
  }

  renderTrashcanBubbleSVG() {
    const { discType, color } = this.props

    return <TrashcanBubbleSVG discType={discType} color={color} />
  }

  renderCrystalLogo() {
    const { discType, color } = this.props

    return <CrystalLogoSVG discType={discType} color={color} />
  }

  renderCheck() {
    const { discType, color } = this.props

    return <CheckSVG discType={discType} color={color} />
  }

  renderCheckBubble() {
    const { discType, color } = this.props

    return <CheckBubbleSVG discType={discType} color={color} />
  }

  renderCheckCircle() {
    return (
      <svg {...this.getBasicClasses('0 0 24 24')}>
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
      </svg>
    )
  }

  renderCheckBox() {
    return (
      <svg {...this.getBasicClasses()}>
        <polyline points="9 11 12 14 22 4" />
        <path d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" />
      </svg>
    )
  }

  renderCompass() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Convince">
          <circle
            id="Ellipse_310"
            data-name="Ellipse 310"
            cx="14.72"
            cy="14.83"
            r="13"
          />
          <path
            id="Path_2685"
            data-name="Path 2685"
            d="M20.23,9.32l-2.76,8.27L9.21,20.34,12,12.07Z"
          />
        </g>
      </svg>
    )
  }

  renderGlobe() {
    const { discType, color } = this.props

    return <GlobeSVG discType={discType} color={color} />
  }

  renderProfile() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path d="M26.27,28V25.08A5.79,5.79,0,0,0,20.5,19.3H8.94a5.8,5.8,0,0,0-5.78,5.78V28" />
          <circle cx="14.72" cy="7.74" r="5.78" />
        </g>
      </svg>
    )
  }

  renderMotivation() {
    return (
      <svg {...this.getBasicClasses()}>
        <circle cx="12" cy="8" r="7" />
        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
      </svg>
    )
  }

  renderDrains() {
    return (
      <svg {...this.getBasicClasses()}>
        <title>Drains</title>
        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
        <polyline points="17 18 23 18 23 12" />
      </svg>
    )
  }

  renderMic() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="mic">
          <path d="M15.55,1.07a3.78,3.78,0,0,0-3.78,3.78V15a3.79,3.79,0,1,0,7.57,0V4.85A3.79,3.79,0,0,0,15.55,1.07Z" />
          <path d="M24.39,12.42V15A8.84,8.84,0,0,1,6.72,15V12.42" />
          <line x1="15.72" y1="23.83" x2="15.72" y2="28.83" />
          <line x1="10.72" y1="28.83" x2="20.72" y2="28.83" />
        </g>
      </svg>
    )
  }

  renderSmallGlobe() {
    const { discType, color } = this.props

    return <SmallGlobeSVG discType={discType} color={color} />
  }

  renderMeeting() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30 ')}>
        <g id="Meeting">
          <rect x="3.57" y="3.67" width="24.3" height="24.3" rx="2.7" />
          <line x1="21.12" y1="0.97" x2="21.12" y2="6.37" />
          <line x1="10.32" y1="0.97" x2="10.32" y2="6.37" />
          <line x1="3.57" y1="11.77" x2="27.87" y2="11.77" />
        </g>
      </svg>
    )
  }

  renderShareIcon() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <circle cx="22.53" cy="5.22" r="4.19" />
        <circle cx="5.75" cy="15" r="4.19" />
        <circle cx="22.53" cy="24.79" r="4.19" />
        <line x1="9.37" y1="17.11" x2="18.92" y2="22.68" />
        <line x1="18.91" y1="7.33" x2="9.37" y2="12.89" />
      </svg>
    )
  }

  renderConflict() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path
            data-name="Path 2684"
            d="M13.3,4.23,2.56,22.16a2.54,2.54,0,0,0,.92,3.47A2.71,2.71,0,0,0,4.72,26H26.21a2.55,2.55,0,0,0,2.51-2.57,2.59,2.59,0,0,0-.34-1.24L17.64,4.23a2.55,2.55,0,0,0-3.49-.86A2.59,2.59,0,0,0,13.3,4.23Z"
          />
          <line
            data-name="Line 354"
            x1="15.47"
            y1="10.75"
            x2="15.47"
            y2="15.82"
          />
          <line
            data-name="Line 355"
            x1="15.47"
            y1="20.89"
            x2="15.47"
            y2="20.89"
          />
        </g>
      </svg>
    )
  }

  renderComparison() {
    return (
      <svg {...this.getBasicClasses()}>
        <polyline points="8 22 4 18 8 14" />
        <path d="M23,18H4" />
        <polyline points="16 2 20 6 16 10" />
        <path d="M1,6H20" />
      </svg>
    )
  }

  renderLink() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Trust">
          <path
            id="Path_2713"
            data-name="Path 2713"
            d="M12.52,16.81A6.73,6.73,0,0,0,22,18.17a6.22,6.22,0,0,0,.73-.63l4-4a6.74,6.74,0,1,0-9.49-9.57l0,0L14.87,6.27"
          />
          <path
            id="Path_2714"
            data-name="Path 2714"
            d="M17.91,14.11a6.75,6.75,0,0,0-9.44-1.36,7.43,7.43,0,0,0-.73.64l-4,4A6.74,6.74,0,0,0,13.19,27l0,0,2.3-2.3"
          />
        </g>
      </svg>
    )
  }

  renderBlind() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path
            data-name="Path 2699-2"
            d="M26.84,27.83V24.94a5.8,5.8,0,0,0-5.78-5.78H9.5a5.8,5.8,0,0,0-5.78,5.78v2.89"
          />
          <circle data-name="Ellipse 314-2" cx="15.22" cy="8.33" r="5.5" />
        </g>
        <line data-name="Line 380" x1="10.29" y1="8.48" x2="20.44" y2="8.48" />
      </svg>
    )
  }

  renderShare() {
    return (
      <svg {...this.getBasicClasses()}>
        <path d="M4,12v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12" />
        <polyline points="16 6 12 2 8 6" />
        <line x1="12" y1="2" x2="12" y2="15" />
      </svg>
    )
  }

  renderConvince() {
    return (
      <svg {...this.getBasicClasses()}>
        <circle cx="12" cy="12" r="10" />
        <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76" />
      </svg>
    )
  }

  renderProfiles() {
    return (
      <svg {...this.getBasicClasses()}>
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
      </svg>
    )
  }

  renderGraph() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Graph">
          <path d="M19.79,3A13,13,0,0,1,9.65,26.93a13.46,13.46,0,0,1-6.9-6.76" />
          <path d="M14.72,2a13,13,0,0,0-13,13h13Z" />
        </g>
      </svg>
    )
  }

  renderMail() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path
            data-name="Path 2693"
            d="M4.42,4.17H26a2.7,2.7,0,0,1,2.7,2.7v16.2a2.7,2.7,0,0,1-2.7,2.7H4.42a2.7,2.7,0,0,1-2.7-2.7V6.87A2.7,2.7,0,0,1,4.42,4.17Z"
          />
          <path data-name="Path 2694" d="M28.72,6.87l-13.5,9.45L1.72,6.87" />
        </g>
      </svg>
    )
  }

  renderBookOpen() {
    return (
      <svg {...this.getBasicClasses()}>
        <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
        <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
      </svg>
    )
  }

  renderTarget() {
    return (
      <svg {...this.getBasicClasses()}>
        <circle cx="12" cy="12" r="10"></circle>
        <circle cx="12" cy="12" r="6"></circle>
        <circle cx="12" cy="12" r="2"></circle>
      </svg>
    )
  }

  renderPrint() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <polyline points="7.12 10.42 7.12 0.97 23.32 0.97 23.32 10.42" />
        <path d="M7.12,22.57H4.42a2.7,2.7,0,0,1-2.7-2.7V13.12a2.7,2.7,0,0,1,2.7-2.7H26a2.7,2.7,0,0,1,2.7,2.7v6.75a2.7,2.7,0,0,1-2.7,2.7h-2.7" />
        <rect x="7.12" y="17.17" width="16.2" height="10.8" />
      </svg>
    )
  }

  renderEnneagram() {
    return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill={this.getDiscHex()}>
        <g>
          <circle cx="5.64" cy="4.75" r="2.92" />
          <circle cx="15.32" cy="4.75" r="2.92" />
          <circle cx="25.35" cy="4.75" r="2.92" />
          <circle cx="5.64" cy="14.61" r="2.92" />
          <circle cx="15.32" cy="14.61" r="2.92" />
          <circle cx="25.35" cy="14.61" r="2.92" />
          <circle cx="5.64" cy="24.46" r="2.92" />
          <circle cx="15.32" cy="24.46" r="2.92" />
          <circle cx="25.35" cy="24.46" r="2.92" />
        </g>
      </svg>
    )
  }

  renderMyersBriggs() {
    const { a, b } = this.getClasses('renderMyersBriggs')

    return (
      <svg width={'100%'} height={'100%'} viewBox="0 0 30 30">
        <defs>
          <style>{`.${a},.${b}{fill:none;}.${b}{stroke:${this.getDiscHex()};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}</style>
        </defs>
        <rect className={a} x="1.72" y="0.97" width="11" height="11" />
        <rect className={b} x="2.72" y="1.97" width="9" height="9" />
        <rect className={a} x="17.72" y="0.97" width="11" height="11" />
        <rect className={b} x="18.72" y="1.97" width="9" height="9" />
        <rect className={a} x="17.72" y="16.97" width="11" height="11" />
        <rect className={b} x="18.72" y="17.97" width="9" height="9" />
        <rect className={a} x="1.72" y="16.97" width="11" height="11" />
        <rect className={b} x="2.72" y="17.97" width="9" height="9" />
      </svg>
    )
  }

  renderMonochromeDISC() {
    const { discType } = this.props

    return <MonochromeDiscSVG discType={discType} />
  }

  renderDISC() {
    const { discType, color } = this.props

    return <DiscSVG discType={discType} color={color} />
  }

  renderSpeechBubbleThin() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M28.72,19.47a3,3,0,0,1-3,3h-18l-6,6v-24a3,3,0,0,1,3-3h21a3,3,0,0,1,3,3Z" />
      </svg>
    )
  }

  renderSpeechBubble() {
    return (
      <svg {...this.getBasicClasses('0 0 20 20')}>
        <path
          d="M21,15a2,2,0,0,1-2,2H7L3,21V5A2,2,0,0,1,5,3H19a2,2,0,0,1,2,2Z"
          transform="translate(-2 -2)"
        />
      </svg>
    )
  }

  renderUpload() {
    return (
      <svg {...this.getBasicClasses('0 0 18 22')}>
        <g transform="translate(-3 -1)">
          <path d="M4,12v8a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12" />
          <path d="M16,6,12,2,8,6" />
          <line y2="13" transform="translate(12 2)" />
        </g>
      </svg>
    )
  }

  renderDownload() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M28.72,19.16v6.09a3,3,0,0,1-3,3.05H4.36a3.06,3.06,0,0,1-3.05-3.05V19.16" />
        <polyline points="7.4 14.62 15.02 22.24 22.63 14.62" />
        <line x1="15.02" y1="22.24" x2="15.02" y2="1.91" />
      </svg>
    )
  }

  renderSearch() {
    return (
      <svg {...this.getBasicClasses('0 0 20.414 20.414')}>
        <g transform="translate(-2 -2)">
          <circle cx="8" cy="8" r="8" transform="translate(3 3)" />
          <line x1="4.35" y1="4.35" transform="translate(16.65 16.65)" />
        </g>
      </svg>
    )
  }

  renderStrengths() {
    const { discType, color } = this.props

    return <StrengthsSVG discType={discType} color={color} />
  }

  renderBook() {
    return (
      <svg {...this.getBasicClasses()}>
        <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
        <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
      </svg>
    )
  }

  renderPlaybook() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="book">
          <path
            id="Path_1377"
            data-name="Path 1377"
            d="M4.45,25.06A3.4,3.4,0,0,1,7.8,21.71H25.72"
          />
          <path
            id="Path_1378"
            data-name="Path 1378"
            d="M7.67,1.85H25.59V28.41H7.67a3.4,3.4,0,0,1-3.35-3.35v-20A3.38,3.38,0,0,1,7.67,1.85Z"
          />
        </g>
      </svg>
    )
  }

  renderPeople() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill={this.getDiscHex()}
        viewBox="0 0 100 100"
        strokeWidth="0"
        stroke={this.getDiscHex()}
      >
        <path d="M50.08,45.76A11.67,11.67,0,1,1,61.75,34.09,11.68,11.68,0,0,1,50.08,45.76Zm0-21.34a9.67,9.67,0,1,0,9.67,9.67A9.68,9.68,0,0,0,50.08,24.42Z" />
        <path d="M65.42,70.9H34.74a5.5,5.5,0,0,1-4.44-2.22,5.41,5.41,0,0,1-.88-4.82,21.53,21.53,0,0,1,41.32,0,5.41,5.41,0,0,1-.88,4.82A5.51,5.51,0,0,1,65.42,70.9ZM50.08,50.38a19.61,19.61,0,0,0-18.74,14,3.43,3.43,0,0,0,.56,3.06,3.5,3.5,0,0,0,2.84,1.42H65.42a3.5,3.5,0,0,0,2.84-1.42,3.46,3.46,0,0,0,.56-3.06A19.61,19.61,0,0,0,50.08,50.38Z" />
        <path d="M79.51,57.72a9.93,9.93,0,1,1,9.93-9.93A9.94,9.94,0,0,1,79.51,57.72Zm0-17.86a7.93,7.93,0,1,0,7.93,7.93A7.94,7.94,0,0,0,79.51,39.86Z" />
        <path d="M93.66,78.78H65.35a3.76,3.76,0,0,1-2.92-1.38,3.68,3.68,0,0,1-.79-3,18.14,18.14,0,0,1,1.12-3.69,1,1,0,0,1,1.84.78,16.5,16.5,0,0,0-1,3.25A1.67,1.67,0,0,0,64,76.13a1.79,1.79,0,0,0,1.38.65H93.66A1.78,1.78,0,0,0,95,76.13a1.74,1.74,0,0,0,.37-1.44l-.08-.36A16.27,16.27,0,0,0,79.51,61.59a16.09,16.09,0,0,0-9.05,2.77,1,1,0,0,1-1.12-1.66A18.18,18.18,0,0,1,97.28,73.91l.08.4a3.76,3.76,0,0,1-3.7,4.47Z" />
        <path d="M21.05,57.72A9.93,9.93,0,1,1,31,47.79,9.94,9.94,0,0,1,21.05,57.72Zm0-17.86A7.93,7.93,0,1,0,29,47.79,7.93,7.93,0,0,0,21.05,39.86Z" />
        <path d="M35.21,78.78H6.9A3.77,3.77,0,0,1,4,77.41a3.71,3.71,0,0,1-.79-3.1l.08-.4A18.18,18.18,0,0,1,31.22,62.7a1,1,0,0,1-1.12,1.66,16.19,16.19,0,0,0-24.87,10l-.07.36a1.74,1.74,0,0,0,.37,1.44,1.78,1.78,0,0,0,1.37.65H35.21a1.82,1.82,0,0,0,1.38-.65A1.69,1.69,0,0,0,37,74.75a16.19,16.19,0,0,0-1-3.28,1,1,0,1,1,1.84-.78,18.33,18.33,0,0,1,1.11,3.65,3.69,3.69,0,0,1-.78,3.06A3.78,3.78,0,0,1,35.21,78.78Z" />
      </svg>
    )
  }

  renderBubble() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 66.134 66.2"
        opacity={0.1}
        strokeWidth="0"
        stroke="none"
        fill={this.getDiscHex()}
      >
        <g transform="translate(0 0)">
          <path
            d="M60.684,51.246A33.064,33.064,0,1,0,8.52,55.228L1.3,66.2l31.842-.069v0A33.027,33.027,0,0,0,60.684,51.246Z"
            transform="translate(0 0)"
          />
        </g>
      </svg>
    )
  }

  renderPencil() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path
            data-name="Path 2718"
            d="M13.74,3.82H4.32a2.69,2.69,0,0,0-2.69,2.7V25.37a2.68,2.68,0,0,0,2.69,2.69H23.17a2.68,2.68,0,0,0,2.69-2.69V15.94"
          />
          <path
            data-name="Path 2719"
            d="M23.84,1.8a2.86,2.86,0,0,1,4,4L15.09,18.64,9.7,20l1.35-5.38Z"
          />
        </g>
      </svg>
    )
  }

  renderPencilPlain() {
    return (
      <svg {...this.getBasicClasses('0 0 16.838 16.898')}>
        <g transform="translate(-1193.736 -510.488)">
          <g transform="translate(1194.486 511.029)">
            <path
              d="M19.6,2.565c.916-.916,1.69-.382,2.606.534s1.625,1.866.71,2.782l-10.5,10.5L8,17.485l1.105-4.421Z"
              transform="translate(-8 -1.879)"
            />
          </g>
          <line x2="2.269" y2="2.269" transform="translate(1196.009 522.752)" />
        </g>
      </svg>
    )
  }

  renderUnlocked() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill={this.getDiscHex()}
        viewBox="0 0 58.483 68.88"
      >
        <path
          d="M21.818,43.635A21.8,21.8,0,0,1,0,21.818V3.724A3.729,3.729,0,0,1,3.725,0H39.911a3.728,3.728,0,0,1,3.724,3.724V21.818A21.8,21.8,0,0,1,21.818,43.635Zm0-33.417a6.422,6.422,0,0,0-3.41,11.866v5.426a3.411,3.411,0,1,0,6.821,0V22.083a6.422,6.422,0,0,0-3.411-11.866Z"
          transform="translate(14.848 25.245)"
        />
        <path
          d="M29.293,28.97V17.828a14.1,14.1,0,0,0-14.1-14.1h0a14.1,14.1,0,0,0-14.1,14.1V28.97H6.657V19.614c0-5.4,3.82-9.782,8.532-9.782h0c4.712,0,8.532,4.379,8.532,9.782V28.97Z"
          transform="translate(-1.086 -3.725)"
        />
        <path
          d="M29.293,28.97V17.828a14.1,14.1,0,0,0-14.1-14.1h0a14.1,14.1,0,0,0-14.1,14.1V28.97H6.657V19.614c0-5.4,3.82-9.782,8.532-9.782h0c4.712,0,8.532,4.379,8.532,9.782V28.97Z"
          transform="translate(-1.086 -3.725)"
        />
      </svg>
    )
  }

  renderLockOpen() {
    return (
      <svg {...this.getBasicClasses()}>
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
        <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
      </svg>
    )
  }

  renderLockOpenWithBG() {
    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10.87"
          cy="12"
          r="10"
          fill="white"
          fillOpacity="0.25"
        ></circle>
        <path
          d="M5.13662 9.93327H16.4033C17.2699 9.93327 18.0033 10.7333 18.0033 11.7999V18.2666C18.0033 19.2666 17.2699 20.1333 16.4033 20.1333H5.13662C4.26995 20.1333 3.53662 19.3333 3.53662 18.2666V11.7999C3.53662 10.7333 4.26995 9.93327 5.13662 9.93327Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M6.73682 9.93333V6.26666C6.73682 3.73333 8.53682 1.66667 10.7368 1.66667C12.6701 1.66667 14.3368 3.2 14.6701 5.33333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    )
  }

  renderLocked() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 122 122"
        fill={this.getDiscHex()}
      >
        <path
          data-name="Subtraction 50"
          d="M2654,322a60.714,60.714,0,0,1-34.106-10.418,61.18,61.18,0,0,1-22.1-26.838,60.919,60.919,0,0,1,5.624-57.85,61.18,61.18,0,0,1,26.838-22.1,60.919,60.919,0,0,1,57.85,5.624,61.184,61.184,0,0,1,22.1,26.838,60.917,60.917,0,0,1-5.625,57.85,61.179,61.179,0,0,1-26.838,22.1A60.619,60.619,0,0,1,2654,322Zm-24.368-71.655V275.3a24.951,24.951,0,1,0,49.9,0V250.345Zm25.132-24.679a14.513,14.513,0,0,0-9.372,3.268,10.276,10.276,0,0,0-3.883,7.888V247.49h6.309v-7.247c0-4.936,3.285-8.952,7.323-8.952s7.325,4.016,7.325,8.952v7.246h5.551V236.822a10.276,10.276,0,0,0-3.883-7.888A14.514,14.514,0,0,0,2654.764,225.666Zm-.182,60.04a3.905,3.905,0,0,1-3.9-3.9V275.6a7.348,7.348,0,1,1,7.8,0v6.205A3.905,3.905,0,0,1,2654.583,285.706Z"
          transform="translate(-2593 -200)"
        />
      </svg>
    )
  }

  renderLightning() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M14.58,3.07,4.12,17.79H15.67l-2.55,10.7,14.6-17H15.92Z" />
      </svg>
    )
  }

  renderClipboard() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path d="M20.05,4.53h2.46a2.61,2.61,0,0,1,2.8,2.38V26.56a2.61,2.61,0,0,1-2.8,2.38H8.09a2.61,2.61,0,0,1-2.8-2.38V6.91a2.61,2.61,0,0,1,2.8-2.38h2.18" />
          <rect x="11.44" y="1.97" width="8.4" height="4.47" />
        </g>
      </svg>
    )
  }

  renderStar() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M15.22,2l4.17,8.45,9.33,1.37L22,18.39l1.59,9.29-8.34-4.39L6.87,27.68l1.6-9.29L1.72,11.82l9.33-1.37Z" />
      </svg>
    )
  }

  renderTrash() {
    return (
      <svg {...this.getBasicClasses('0 0 24 24')}>
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="10" y1="11" x2="10" y2="17"></line>
        <line x1="14" y1="11" x2="14" y2="17"></line>
      </svg>
    )
  }

  renderArrow() {
    return (
      <svg {...this.getBasicClasses()}>
        <polyline points="9 18 15 12 9 6" />
      </svg>
    )
  }

  renderRibbon() {
    return (
      <svg {...this.getBasicClasses('0 0 70.78 91.162')}>
        <g transform="translate(-1022.964 -1877)">
          <g transform="translate(1024 1878)">
            <circle
              cx="21.543"
              cy="21.543"
              r="21.543"
              transform="translate(12.811 13.02)"
            />
            <path
              d="M54.511,8.6h0A6.194,6.194,0,0,0,63.7,11.948h0a6.194,6.194,0,0,1,9.031,7.579h0a6.194,6.194,0,0,0,4.89,8.468h0a6.194,6.194,0,0,1,2.047,11.611h0a6.194,6.194,0,0,0-1.7,9.63h0a6.194,6.194,0,0,1-5.9,10.21h0a6.194,6.194,0,0,0-7.49,6.286h0A6.194,6.194,0,0,1,53.5,69.764h0a6.194,6.194,0,0,0-9.778,0h0a6.194,6.194,0,0,1-11.079-4.032h0a6.194,6.194,0,0,0-7.49-6.286h0a6.194,6.194,0,0,1-5.9-10.21h0a6.194,6.194,0,0,0-1.7-9.63h0A6.194,6.194,0,0,1,19.61,27.994h0a6.194,6.194,0,0,0,4.89-8.468h0a6.194,6.194,0,0,1,9.031-7.579h0A6.194,6.194,0,0,0,42.72,8.6h0A6.2,6.2,0,0,1,54.511,8.6Z"
              transform="translate(-14.261 -4.311)"
            />
            <path
              d="M67.675,65.2l9.491,25.173-9.368-3.4-4.792,8.738L53.516,70.54"
              transform="translate(-15.782 -6.67)"
            />
            <path
              d="M45.078,71.316,33.989,95.826,29.768,86.8,20.2,89.589,31.29,65.078"
              transform="translate(-14.491 -6.665)"
            />
          </g>
        </g>
      </svg>
    )
  }

  renderPuzzle() {
    const { discType, color } = this.props

    return <PuzzleSVG discType={discType} color={color} />
  }

  renderScale() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <line x1="14.38" y1="7.47" x2="14.38" y2="26.52" />
          <line x1="8.48" y1="27.11" x2="20.29" y2="27.11" />
          <line x1="5.04" y1="9.78" x2="24.45" y2="3.83" />
          <line x1="19.85" y1="13.45" x2="24.53" y2="3.83" />
          <line x1="28.92" y1="13.45" x2="24.53" y2="3.83" />
          <path d="M19.85,13.45a4.54,4.54,0,0,0,9.07,0Z" />
          <line x1="0.72" y1="19.19" x2="5.04" y2="9.78" />
          <line x1="9.67" y1="19.19" x2="5.04" y2="9.78" />
          <path d="M.72,19.18a4.53,4.53,0,0,0,4.41,4.64h0a4.62,4.62,0,0,0,4.53-4.64Z" />
        </g>
      </svg>
    )
  }

  renderInfo() {
    return (
      <svg {...this.getBasicClasses()}>
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="16" x2="12" y2="12" />
        <line x1="12" y1="8" x2="12" y2="8" />
      </svg>
    )
  }

  renderRefresh() {
    return (
      <svg {...this.getBasicClasses('0 0 24 24')}>
        <polyline points="1 4 1 10 7 10"></polyline>
        <polyline points="23 20 23 14 17 14"></polyline>
        <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
      </svg>
    )
  }

  renderBoxGrid() {
    const { discType, color } = this.props

    return <BoxGridSVG discType={discType} color={color} />
  }

  renderHamburgerGrid() {
    return (
      <svg {...this.getBasicClasses('0 0 26.558 21.389')} strokeWidth={'4px'}>
        <g transform="translate(-138.1 -20.648)">
          <line x2="22.558" transform="translate(140.1 22.648)" />
          <line x2="22.558" transform="translate(140.1 31.343)" />
          <line x2="22.558" transform="translate(140.1 40.037)" />
        </g>
      </svg>
    )
  }

  renderSettings() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <circle cx="14.94" cy="15.05" r="3.76" />
        <path d="M24.21,18.81a2.07,2.07,0,0,0,.41,2.28l.08.07a2.51,2.51,0,0,1,0,3.55h0a2.5,2.5,0,0,1-3.54,0h0l-.08-.08a2.08,2.08,0,0,0-2.94,0,2,2,0,0,0-.59,1.44v.21a2.51,2.51,0,1,1-5,0v-.11a2.07,2.07,0,0,0-1.35-1.89,2.07,2.07,0,0,0-2.28.41l-.08.08a2.5,2.5,0,0,1-3.54,0h0a2.5,2.5,0,0,1,0-3.54h0l.07-.07a2.07,2.07,0,0,0,0-2.94,2.05,2.05,0,0,0-1.44-.59H3.67a2.51,2.51,0,0,1,0-5h.12A2.09,2.09,0,0,0,5.68,11.3,2.07,2.07,0,0,0,5.26,9l-.07-.08a2.5,2.5,0,0,1,0-3.54h0a2.49,2.49,0,0,1,3.49,0h0l.08.07A2.07,2.07,0,0,0,11,5.89h.15A2.08,2.08,0,0,0,12.44,4V3.78a2.51,2.51,0,0,1,5,0V3.9A2.08,2.08,0,0,0,21,5.37l.08-.07a2.5,2.5,0,0,1,3.54,0h0a2.5,2.5,0,0,1,0,3.54h0l-.08.08a2.07,2.07,0,0,0-.41,2.28v.1A2.08,2.08,0,0,0,26,12.55h.21a2.51,2.51,0,1,1,0,5H26.1A2.06,2.06,0,0,0,24.21,18.81Z" />
      </svg>
    )
  }
  renderLinkedInIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill={this.getDiscHex()}
      >
        <path d="M17.04,17.044H14.078V12.4c0-1.108-.023-2.531-1.543-2.531-1.544,0-1.78,1.2-1.78,2.45v4.723H7.793V7.5h2.845V8.8h.038a3.122,3.122,0,0,1,2.809-1.541c3,0,3.556,1.975,3.556,4.546V17.04ZM4.448,6.194a1.72,1.72,0,1,1,1.72-1.721A1.719,1.719,0,0,1,4.448,6.194Zm1.485,10.85H2.963V7.5h2.97ZM18.52,0H1.475A1.458,1.458,0,0,0,0,1.441V18.559A1.458,1.458,0,0,0,1.475,20H18.519A1.463,1.463,0,0,0,20,18.559V1.441A1.463,1.463,0,0,0,18.519,0Z" />
      </svg>
    )
  }

  renderGoogleIcon() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={this.getDiscHex()}
      >
        <path
          d="M12.0039 0C5.37491 0 0 5.373 0 12C0 18.627 5.37491 24 12.0039 24C22.0139 24 24.2691 14.707 23.3301 10H22H19.7324H12V14H19.7383C18.8487 17.4483 15.726 20 12 20C7.582 20 4 16.418 4 12C4 7.582 7.582 4 12 4C14.009 4 15.8391 4.74575 17.2441 5.96875L20.0859 3.12891C17.9519 1.18491 15.1169 0 12.0039 0Z"
          fill="#A2E2FC"
        />
      </svg>
    )
  }

  renderOutlookIcon() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={this.getDiscHex()}
      >
        <path
          d="M12 0C11.8654 0.000816118 11.7313 0.015212 11.5996 0.0429688L11.5977 0.0410156L1.62891 2.03516L1.62695 2.03711C1.16976 2.12391 0.757141 2.36743 0.460171 2.72572C0.163201 3.084 0.000467831 3.53464 0 4V20C9.94457e-05 20.466 0.162903 20.9173 0.4603 21.276C0.757698 21.6348 1.17102 21.8784 1.62891 21.9648L11.5977 23.959C11.73 23.9862 11.8648 24 12 24C12.5304 24 13.0391 23.7893 13.4142 23.4142C13.7893 23.0391 14 22.5304 14 22V2C14 1.46957 13.7893 0.960859 13.4142 0.585786C13.0391 0.210714 12.5304 0 12 0ZM16 2V6.3418L18 7.77148L23.9375 3.5293C23.7255 2.6533 22.942 2 22 2H16ZM24 5.94336L18.5801 9.81445C18.4061 9.93845 18.204 10 18 10C17.796 10 17.5939 9.93845 17.4199 9.81445L16 8.80078V15.3438C16.995 13.9288 18.638 13 20.5 13C21.83 13 23.049 13.4728 24 14.2578V5.94336ZM6.58203 7C7.60103 7 8.42764 7.45247 9.05664 8.35547C9.68464 9.25947 10 10.4507 10 11.9297C10 13.4497 9.67348 14.6725 9.02148 15.6035C8.36748 16.5335 7.5158 17 6.4668 17C5.4448 17 4.6108 16.5504 3.9668 15.6484C3.3238 14.7474 3 13.572 3 12.125C3 10.596 3.32742 9.36002 3.98242 8.41602C4.63742 7.47302 5.50203 7 6.58203 7ZM6.53125 8.99609C6.06225 8.99609 5.69006 9.27227 5.41406 9.82227C5.13806 10.3733 5 11.1028 5 12.0098C5 12.9298 5.13806 13.6564 5.41406 14.1934C5.69006 14.7284 6.05105 14.9961 6.49805 14.9961C6.95805 14.9961 7.32375 14.7378 7.59375 14.2168C7.86475 13.6968 8 12.9738 8 12.0488C8 11.0858 7.86847 10.3368 7.60547 9.80078C7.34447 9.26378 6.98625 8.99609 6.53125 8.99609ZM20.5 15C18.57 15 17 16.57 17 18.5C17 20.43 18.57 22 20.5 22C22.43 22 24 20.43 24 18.5C24 16.57 22.43 15 20.5 15ZM20 16H21V18H23V19H21H20V18V16Z"
          fill="#A2E2FC"
        />
      </svg>
    )
  }

  renderTwitter() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20.001"
        viewBox="0 0 20 20.001"
        fill={this.getDiscHex()}
      >
        <g transform="translate(11.999 0.001)">
          <path
            d="M18.749,20H1.249A1.251,1.251,0,0,1,0,18.751V1.25A1.251,1.251,0,0,1,1.249,0h17.5A1.252,1.252,0,0,1,20,1.25v17.5A1.252,1.252,0,0,1,18.749,20Zm-15-6.135h0A7.361,7.361,0,0,0,7.679,15a7.475,7.475,0,0,0,3.128-.653,6.891,6.891,0,0,0,2.285-1.687,7.366,7.366,0,0,0,1.878-4.842c0-.1,0-.206-.008-.325a5.152,5.152,0,0,0,1.279-1.308,5.175,5.175,0,0,1-1.473.4A2.514,2.514,0,0,0,15.9,5.187a5.212,5.212,0,0,1-1.628.611A2.593,2.593,0,0,0,12.4,5,2.547,2.547,0,0,0,9.836,7.524,2.6,2.6,0,0,0,9.9,8.1,7.349,7.349,0,0,1,4.618,5.462a2.5,2.5,0,0,0,.792,3.37,2.6,2.6,0,0,1-1.159-.316.228.228,0,0,0,0,.033,2.538,2.538,0,0,0,2.057,2.474,2.62,2.62,0,0,1-.676.089,2.535,2.535,0,0,1-.482-.045A2.556,2.556,0,0,0,7.543,12.82,5.145,5.145,0,0,1,4.361,13.9,5.469,5.469,0,0,1,3.751,13.866Z"
            transform="translate(-11.999 -0.001)"
          />
        </g>
      </svg>
    )
  }

  renderInstagram() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fillRule={'evenodd'}
        fill={this.getDiscHex()}
      >
        <path d="M10,0C7.284,0,6.944.012,5.878.06A7.374,7.374,0,0,0,3.45.525,4.884,4.884,0,0,0,1.679,1.679,4.858,4.858,0,0,0,.525,3.45,7.342,7.342,0,0,0,.06,5.878C.01,6.944,0,7.284,0,10s.012,3.056.06,4.123A7.367,7.367,0,0,0,.525,16.55a4.884,4.884,0,0,0,1.154,1.771A4.858,4.858,0,0,0,3.45,19.475a7.341,7.341,0,0,0,2.428.465C6.944,19.99,7.284,20,10,20s3.056-.013,4.123-.06a7.367,7.367,0,0,0,2.428-.465,5.117,5.117,0,0,0,2.925-2.925,7.341,7.341,0,0,0,.465-2.427c.05-1.066.06-1.406.06-4.123s-.013-3.056-.06-4.123a7.367,7.367,0,0,0-.465-2.428,4.884,4.884,0,0,0-1.154-1.771A4.858,4.858,0,0,0,16.55.525,7.342,7.342,0,0,0,14.122.06C13.056.01,12.716,0,10,0Zm0,1.8c2.67,0,2.988.012,4.041.06a5.523,5.523,0,0,1,1.856.345,3.09,3.09,0,0,1,1.15.747,3.09,3.09,0,0,1,.747,1.15,5.523,5.523,0,0,1,.345,1.856C18.188,7.014,18.2,7.33,18.2,10s-.013,2.988-.062,4.041a5.62,5.62,0,0,1-.35,1.856,3.174,3.174,0,0,1-.75,1.15,3.13,3.13,0,0,1-1.15.747,5.556,5.556,0,0,1-1.863.345c-1.062.048-1.375.059-4.05.059s-2.988-.013-4.05-.062a5.653,5.653,0,0,1-1.862-.35,3.112,3.112,0,0,1-1.15-.75,3.059,3.059,0,0,1-.75-1.15,5.653,5.653,0,0,1-.35-1.863c-.038-1.05-.05-1.375-.05-4.037s.012-2.988.05-4.05a5.653,5.653,0,0,1,.35-1.863,2.965,2.965,0,0,1,.75-1.15,2.965,2.965,0,0,1,1.15-.75,5.556,5.556,0,0,1,1.85-.35c1.062-.038,1.375-.05,4.05-.05Zm0,3.065A5.135,5.135,0,1,0,15.135,10,5.135,5.135,0,0,0,10,4.865Zm0,8.469A3.334,3.334,0,1,1,13.334,10,3.334,3.334,0,0,1,10,13.334Zm6.537-8.671a1.2,1.2,0,1,1-1.2-1.2A1.2,1.2,0,0,1,16.537,4.662Z" />
      </svg>
    )
  }

  renderMore() {
    return (
      <svg {...this.getBasicClasses()}>
        <circle cx="12" cy="12" r="1"></circle>
        <circle cx="19" cy="12" r="1"></circle>
        <circle cx="5" cy="12" r="1"></circle>
      </svg>
    )
  }

  renderHamburgerMenu() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <line x1="1.72" y1="8.17" x2="28.72" y2="8.17" />
        <line x1="1.72" y1="15.97" x2="28.72" y2="15.97" />
        <line x1="1.72" y1="23.77" x2="28.72" y2="23.77" />
      </svg>
    )
  }

  renderTie() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Group_3943" data-name="Group 3943">
          <path
            id="Path_2659"
            data-name="Path 2659"
            d="M16.45,8.77l4,13.69L14.75,28l-6-5.51,4-13.69"
          />
          <path
            id="Path_2660"
            data-name="Path 2660"
            d="M16.46,8.77H12.74L11.37,2.83h6.27Z"
          />
        </g>
      </svg>
    )
  }

  renderPulse() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M28.72,13.82h-5.4L19.27,26l-8.1-24.3-4,12.15H1.72" />
      </svg>
    )
  }

  renderBreakdown() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Breakdown">
          <line x1="8.43" y1="5.97" x2="27.72" y2="5.97" />
          <line x1="8.43" y1="14.97" x2="27.72" y2="14.97" />
          <line x1="8.43" y1="23.97" x2="27.72" y2="23.97" />
          <line x1="2.72" y1="5.97" x2="2.72" y2="5.97" />
          <line x1="2.72" y1="14.97" x2="2.72" y2="14.97" />
          <line x1="2.72" y1="23.97" x2="2.72" y2="23.97" />
        </g>
      </svg>
    )
  }

  renderHorizontalBars() {
    return (
      <svg {...this.getBasicClasses('0 0 28 20')}>
        <line x1="1.72" y1="10.01" x2="28.72" y2="10.01" />
        <line x1="1.72" y1="15.47" x2="20.84" y2="15.47" />
        <line x1="1.72" y1="4.56" x2="9.59" y2="4.56" />
        <line x1="1.72" y1="26.37" x2="14.09" y2="26.37" />
        <line x1="1.72" y1="20.92" x2="6.22" y2="20.92" />
      </svg>
    )
  }

  renderThinChain() {
    return (
      <svg
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        xmlns="http://www.w3.org/2000/svg"
        width="96.761"
        height="93.646"
        viewBox="0 0 96.761 93.646"
        fill="none"
        stroke={this.getDiscHex()}
      >
        <g transform="translate(-3601.458 -1627.808)">
          <path d="M3647.631,1682.858a15.116,15.116,0,0,1-3.109,18.976l-17,14.877a15.109,15.109,0,0,1-21.319-1.421h0a15.11,15.11,0,0,1,1.42-21.32l17-14.877a15.116,15.116,0,0,1,18.306-1.222" />
          <path d="M3643.01,1687.309a9.6,9.6,0,0,1-2.45,11.115l-15.925,13.934a9.592,9.592,0,0,1-13.534-.9h0a9.592,9.592,0,0,1,.9-13.534l15.925-13.934a9.59,9.59,0,0,1,10.054-1.617" />
          <path d="M3653.311,1667.885a15.115,15.115,0,0,1,2.141-18.677l15.975-15.974a15.107,15.107,0,0,1,21.367,0h0a15.108,15.108,0,0,1,0,21.366l-15.975,15.975a15.114,15.114,0,0,1-18.477,2.264" />
          <path d="M3658,1663.3c-2.735-6.716,2.724-11.077,6.909-15.261,3.364-3.364,6.6-6.961,10.141-10.141,7.536-6.773,19.931.957,15.528,10.724-1.956,4.339-7.156,8.031-10.429,11.305-4.494,4.493-9.478,11.475-16.777,8.648" />
          <path d="M3668.689,1659.2l-.8-.8a2.671,2.671,0,0,0-3.777,0l-30.1,28.461a2.671,2.671,0,0,0-.251,3.769l.748.855a2.671,2.671,0,0,0,3.769.251l30.414-28.755A2.671,2.671,0,0,0,3668.689,1659.2Z" />
        </g>
      </svg>
    )
  }

  renderTag() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="purchased-01">
          <circle cx="8.59" cy="8.69" r="2.54" />
          <path d="M28.77,17.83,17.73,29,1.87,13.13,2.38,2.6,12.91,2Z" />
        </g>
      </svg>
    )
  }

  renderThinPrint() {
    const { discType, color } = this.props

    return <ThinPrintSVG discType={discType} color={color} />
  }

  renderClose() {
    return (
      <svg {...this.getBasicClasses('0 0 21.256 21.256')}>
        <g transform="translate(10.628 -7.799) rotate(45)">
          <line y2="26.06" transform="translate(13.03 0)" />
          <line y2="26.06" transform="translate(26.06 13.03) rotate(90)" />
        </g>
      </svg>
    )
  }

  renderArrowThin() {
    return (
      <svg {...this.getBasicClasses('0 0 101.118 69.335')}>
        <path
          d="M45.765,44.8V99.118H21.4V44.8H0L33.667,0,67.335,44.8Z"
          transform="translate(1 68.335) rotate(-90)"
        />
      </svg>
    )
  }

  renderLeadership() {
    const { discType, color } = this.props

    return <LeadershipSVG discType={discType} color={color} />
  }

  renderManyToOne() {
    const { a, b } = this.getClasses('renderManyToOne')

    return (
      <svg
        viewBox="0 0 30 30"
        stroke={this.getDiscHex()}
        width="100%"
        height="100%"
      >
        <defs>
          <style>{`.${a}{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${this.getDiscHex()};stroke-width:0px;}`}</style>
        </defs>
        <g id="Group_3945" data-name="Group 3945">
          <circle className={b} cx="14.72" cy="1.96" r="1.73" />
          <circle className={b} cx="3.45" cy="27.1" r="1.73" />
          <circle className={b} cx="14.72" cy="27.1" r="1.73" />
          <circle className={b} cx="25.99" cy="27.1" r="1.73" />
          <path className={a} d="M3.58,21.22V15.27h22.5v5.95" />
          <line className={a} x1="14.71" y1="7.6" x2="14.71" y2="21.33" />
        </g>
      </svg>
    )
  }

  renderThinShare() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="84.431"
        height="102.044"
        viewBox="0 0 84.431 102.044"
        fill="none"
        stroke={this.getDiscHex()}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      >
        <g transform="translate(-3497.899 -801.528)">
          <path d="M3581.33,858.549h-12V817.333a2.807,2.807,0,0,0-2.8-2.8H3513.7a2.807,2.807,0,0,0-2.8,2.8v41.216h-12V817.333a14.82,14.82,0,0,1,14.8-14.8h52.825a14.82,14.82,0,0,1,14.8,14.8Z" />
          <path d="M3557.72,870.475l-11.6,11.61V835.723h-12v46.36l-11.609-11.608-8.485,8.485,21.853,21.854a6,6,0,0,0,8.487,0l21.846-21.856Z" />
        </g>
      </svg>
    )
  }

  renderCommunicate() {
    const { discType, color } = this.props

    return <CommunicateSVG discType={discType} color={color} />
  }

  renderTeam() {
    const { discType, color } = this.props

    return <TeamSVG discType={discType} color={color} />
  }

  renderName() {
    return (
      <svg {...this.getBasicClasses('0 0 80.767 52.654')}>
        <g transform="translate(-3373.852 -824.347)">
          <rect
            width="78.767"
            height="50.654"
            rx="7.422"
            transform="translate(3374.852 825.347)"
          />
          <line x2="78.767" transform="translate(3374.852 839.411)" />
          <line x2="77.5" transform="translate(3374.852 871.866)" />
          <path
            d="M3382.542,852.148a1.663,1.663,0,0,1,2.624-.663,4.171,4.171,0,0,1,.85,3.206,68.537,68.537,0,0,1-2.218,13.355,115.642,115.642,0,0,1,7.4-13.864,4.768,4.768,0,0,1,1.481-1.686,1.677,1.677,0,0,1,2.078.177,2.413,2.413,0,0,1,.424,1.435,47.85,47.85,0,0,1-.123,8.057c-.126,1.266-.15,2.847.97,3.45s2.449-.3,3.261-1.273c1.368-1.639,2.415-3.736,4.383-4.561a.974.974,0,0,1,1.434.484c1.4,2.355-.186,7.94-2.6,6.643-2.038-1.094-.033-6.085,1.973-7.235.186-.107,1.388,1.345,1.51,1.548a23.928,23.928,0,0,1,.989,2.638c.389.858,1.258,1.653,2.172,1.427a2.674,2.674,0,0,0,1.357-1.145l1.639-2.19c.656-.878,1.491-1.846,2.586-1.805a2.336,2.336,0,0,1,1.982,1.834,6.855,6.855,0,0,1-.066,2.869c.979-1.473,1.688-4.243,3.442-4.022,1.277.161,1.08,2.366,1.067,3.653a19.652,19.652,0,0,1,3.582-4.323,3.043,3.043,0,0,1,1.892-.976c1.161-.012,1.973,1.176,2.263,2.3a11.771,11.771,0,0,0,.913,3.34,4.035,4.035,0,0,0,4.615,1.414,7.884,7.884,0,0,0,3.907-3.287,4.251,4.251,0,0,0,.858-2.09,1.777,1.777,0,0,0-1.094-1.818,2.642,2.642,0,0,0-2.26.8c-.662.522-1.374,1.209-1.284,2.048.087.807.877,1.331,1.607,1.685a14.338,14.338,0,0,0,9.678,1.241c3.218-.82,4.394-2.151,5.213-4.935"
            transform="translate(-1.218 -4.076)"
          />
        </g>
      </svg>
    )
  }

  renderBriefcase() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Working">
          <rect
            id="Rectangle_588"
            data-name="Rectangle 588"
            x="1.72"
            y="8.47"
            width="26"
            height="18.2"
            rx="2.6"
          />
          <path
            id="Path_2715"
            data-name="Path 2715"
            d="M19.92,26.67V5.87a2.6,2.6,0,0,0-2.6-2.6h-5.2a2.59,2.59,0,0,0-2.6,2.6v20.8"
          />
        </g>
      </svg>
    )
  }

  renderPricetag() {
    const { discType, color } = this.props

    return <PricetagSVG discType={discType} color={color} />
  }

  renderExplosion() {
    return (
      <svg {...this.getBasicClasses('0 0 79.167 79.648')}>
        <g transform="translate(1 1)">
          <path
            d="M3304.238,1388.78l1.942,14.625,21.913-5.224-9.378,16.592,14.873,13.1-22.332,2.964,3.086,16.229-15.749-8.01-13.064,25.163-1.7-28.306-25.318,8.238,17.1-20.9-19.189-11.8,22.331-2.964-5.224-21.913,17.887,13.695Z"
            transform="translate(-3256.42 -1386.568)"
          />
          <path
            d="M3304.01,1406.687l1.015,7.648,11.459-2.732-4.9,8.677,7.778,6.848-11.678,1.55,1.614,8.487-8.236-4.189-6.832,13.159-.89-14.8-13.24,4.308,8.942-10.93L3279,1418.539l11.678-1.55-2.732-11.459,9.354,7.161Z"
            transform="translate(-3260.537 -1390.025)"
          />
        </g>
      </svg>
    )
  }

  renderRelationshipThin() {
    return (
      <svg {...this.getBasicClasses('0 0 78.966 59.11')}>
        <g transform="translate(1.025 0.968)">
          <path
            d="M2545.8,1129.7c.6-9.21,0-12.839,2.833-16.576a27.612,27.612,0,0,1,10.917-4.8,19.328,19.328,0,0,0,27.3.475l.475-.475a27.144,27.144,0,0,1,10.916,4.8c2.641,2.92,2.4,7.2,3.1,16.849"
            transform="translate(-2545.8 -1072.863)"
          />
          <path
            d="M2587.619,1086.3c0,7.676-4.91,18.985-14.272,18.985s-14.256-11.309-14.256-18.985a14.272,14.272,0,0,1,28.495,0"
            transform="translate(-2545.8 -1072.863)"
          />
          <g transform="translate(41.636)">
            <path
              d="M2602.991,1112.4a19.279,19.279,0,0,0,5.227-3.6l.475-.475a27.142,27.142,0,0,1,10.916,4.8c2.64,2.92,2.4,7.2,3.1,16.849"
              transform="translate(-2587.436 -1072.863)"
            />
            <path
              d="M2587.436,1074.854a14.273,14.273,0,0,1,21.519,11.445"
              transform="translate(-2587.436 -1072.863)"
            />
            <path
              d="M2608.987,1086.3c0,7.676-4.91,18.985-14.271,18.985a10.912,10.912,0,0,1-5.521-1.48"
              transform="translate(-2587.436 -1072.863)"
            />
          </g>
        </g>
      </svg>
    )
  }

  renderSigns() {
    return (
      <svg {...this.getBasicClasses('0 0 81.422 78.481')}>
        <g transform="translate(1 1)">
          <line y1="8.075" transform="translate(36.878 27.527)" />
          <path
            d="M4946.7,1457.337v22.991h-6.205v-22.991"
            transform="translate(-4903.612 -1403.847)"
          />
          <line y2="8.075" transform="translate(43.083 27.527)" />
          <path
            d="M4940.49,1402.209v-9.64h6.205v9.64"
            transform="translate(-4903.612 -1392.569)"
          />
          <path
            d="M4966.781,1422.129h-58.94v-17.887h58.94l10.568,8.944Z"
            transform="translate(-4897.927 -1394.602)"
          />
          <path
            d="M4906.4,1435.678h58.941v17.887H4906.4l-10.567-8.943Z"
            transform="translate(-4895.836 -1400.076)"
          />
        </g>
      </svg>
    )
  }

  renderBrain() {
    const { discType, color } = this.props

    return <BrainSVG discType={discType} color={color} />
  }

  renderPersonThin() {
    return (
      <svg {...this.getBasicClasses('0 0 67.804 69.606')}>
        <g transform="translate(-3.975 3.968)">
          <g transform="translate(5 -3)">
            <path
              d="M2545.8,1133.628c.707-10.9,0-15.2,3.354-19.622a32.687,32.687,0,0,1,12.923-5.677,22.88,22.88,0,0,0,32.316.562l.562-.562a32.134,32.134,0,0,1,12.922,5.677c3.126,3.457,2.837,8.526,3.67,19.946"
              transform="translate(-2545.8 -1066.345)"
            />
            <path
              d="M2592.862,1088.768c0,9.087-5.812,22.474-16.9,22.474s-16.876-13.387-16.876-22.474a16.9,16.9,0,0,1,33.732,0"
              transform="translate(-2543.357 -1072.863)"
            />
          </g>
        </g>
      </svg>
    )
  }

  renderComputerGraph() {
    const { discType, color } = this.props

    return <ComputerGraphSVG discType={discType} color={color} />
  }

  renderHandshake() {
    const { discType, color } = this.props

    return <HandshakeSVG discType={discType} color={color} />
  }

  renderMagnifyingGlassPerson() {
    const { discType, color } = this.props

    return <MagnifyingGlassPersonSVG discType={discType} color={color} />
  }

  renderMagnifyingGlassPersonThin() {
    return (
      <svg {...this.getBasicClasses('0 0 97.956 98.24')} strokeWidth={'1.6'}>
        <g transform="translate(-2414.637 -1153.583)">
          <circle
            cx="36.924"
            cy="36.924"
            r="36.924"
            transform="translate(2415.602 1154.547)"
          />
          <path d="M2483.392,1211.745l25.933,25.4a8.215,8.215,0,0,1,0,11.408h0a8.215,8.215,0,0,1-11.408,0l-26.415-25.877" />
          <line
            x2="16.946"
            y2="16.946"
            transform="translate(2483.895 1223.733)"
          />
          <path d="M2424.717,1215.573c.6-9.21,1.995-10.038,2.832-10.626a27.618,27.618,0,0,1,10.917-4.8,19.329,19.329,0,0,0,27.3.475l.474-.475a27.154,27.154,0,0,1,10.917,4.8c.883.584,2.4,1.252,3.1,10.9" />
          <path d="M2466.535,1178.122c0,7.676-4.91,18.985-14.272,18.985s-14.255-11.309-14.255-18.985a14.272,14.272,0,0,1,28.494,0" />
        </g>
      </svg>
    )
  }

  renderMagnifyingGlassWithProfile() {
    return <MagnifyingGlassWithProfileSVG />
  }

  renderContract() {
    const { discType, color } = this.props

    return <ContractSVG discType={discType} color={color} />
  }

  renderHome() {
    return (
      <svg {...this.getBasicClasses('0 0 25.774 28.415')}>
        <g transform="translate(-2 -1)">
          <path d="M3,11.245,14.887,2l11.887,9.245V25.774a2.642,2.642,0,0,1-2.642,2.642H5.642A2.642,2.642,0,0,1,3,25.774Z" />
          <path
            d="M9,25.208V12h7.925V25.208"
            transform="translate(1.925 3.208)"
          />
        </g>
      </svg>
    )
  }

  renderMoneySign() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={this.getDiscHex()}
      >
        <line x1="12" y1="1" x2="12" y2="23"></line>
        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
      </svg>
    )
  }

  renderSun() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <circle cx="15.22" cy="14.97" r="6.14" />
          <line x1="15.22" y1="1.47" x2="15.22" y2="3.92" />
          <line x1="15.22" y1="26.01" x2="15.22" y2="28.47" />
          <line x1="5.67" y1="5.42" x2="7.41" y2="7.16" />
          <line x1="23.02" y1="22.77" x2="24.77" y2="24.51" />
          <line x1="1.72" y1="14.97" x2="4.17" y2="14.97" />
          <line x1="26.26" y1="14.97" x2="28.72" y2="14.97" />
          <line x1="5.67" y1="24.51" x2="7.41" y2="22.77" />
          <line x1="23.02" y1="7.16" x2="24.77" y2="5.42" />
        </g>
      </svg>
    )
  }

  renderShoppingCart() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={this.getDiscHex()}
      >
        <circle cx="9" cy="21" r="1"></circle>
        <circle cx="20" cy="21" r="1"></circle>
        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
      </svg>
    )
  }

  renderThumbsDown() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={this.getDiscHex()}
      >
        <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
      </svg>
    )
  }

  renderThumbsDownThin() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={this.getDiscHex()}
      >
        <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
      </svg>
    )
  }

  renderResume() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={this.getDiscHex()}
      >
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
        <polyline points="14 2 14 8 20 8"></polyline>
        <line x1="16" y1="13" x2="8" y2="13"></line>
        <line x1="16" y1="17" x2="8" y2="17"></line>
        <polyline points="10 9 9 9 8 9"></polyline>
      </svg>
    )
  }

  renderGraphClipboard() {
    const { discType, color } = this.props

    return <GraphClipboardSVG discType={discType} color={color} />
  }

  renderConversationSpeechBubbles() {
    const { discType, color } = this.props

    return <ConversationSpeechBubblesSVG discType={discType} color={color} />
  }

  renderPlaybookSpeech() {
    const { discType, color } = this.props

    return <PlaybookSpeechSVG discType={discType} color={color} />
  }

  renderPlaybookExplosion() {
    const { discType, color } = this.props

    return <PlaybookExplosionSVG discType={discType} color={color} />
  }

  renderPlaybookDocument() {
    const { discType, color } = this.props

    return <PlaybookDocumentSVG discType={discType} color={color} />
  }

  renderPlaybookBriefcase() {
    const { discType, color } = this.props

    return <PlaybookBriefcaseSVG discType={discType} color={color} />
  }

  renderPlaybookChecklist() {
    const { discType, color } = this.props

    return <PlaybookChecklistSVG discType={discType} color={color} />
  }

  renderPlaybookRibbon() {
    const { discType, color } = this.props

    return <PlaybookRibbonSVG discType={discType} color={color} />
  }

  renderPlaybookCommunicate() {
    const { discType, color } = this.props

    return <PlaybookCommunicateSVG discType={discType} color={color} />
  }

  renderPlaybookSign() {
    const { discType, color } = this.props

    return <PlaybookSignSVG discType={discType} color={color} />
  }

  renderPlaybookCalendar() {
    const { discType, color } = this.props

    return <PlaybookCalendarSVG discType={discType} color={color} />
  }

  renderPlaybookPulse() {
    const { discType, color } = this.props

    return <PlaybookPulseSVG discType={discType} color={color} />
  }

  renderPlaybookLetter() {
    const { discType, color } = this.props

    return <PlaybookLetterSVG discType={discType} color={color} />
  }

  renderPlaybookChain() {
    const { discType, color } = this.props

    return <PlaybookChainSVG discType={discType} color={color} />
  }

  renderPlaybookHandshake() {
    const { discType, color } = this.props

    return <PlaybookHandshakeSVG discType={discType} color={color} />
  }

  renderPlaybookLightning() {
    const a = this.getClass('a-renderPlaybookLightning')
    const b = this.getClass('b-renderPlaybookLightning')

    return (
      <svg {...this.getPlaybookBasicClasses('0 0 77.49 134.621')}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${this.getDiscHex()};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${this.getDiscHex()};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-141 -451)">
          <path
            className={a}
            d="M3430.085,1386.568l-36.125,75.636,40.694-5.934-11.424,62.919,42.077-83.058-38.4,4.994Z"
            transform="translate(-3251.959 -934.568)"
          />
          <path
            className={b}
            d="M3430.085,1386.568l-36.125,75.636,40.694-5.934-11.424,62.919,42.077-83.058-38.4,4.994Z"
            transform="translate(-3247.816 -934.568)"
          />
        </g>
      </svg>
    )
  }

  renderPlaybookFeedback() {
    const { discType, color } = this.props

    return <PlaybookFeedbackSVG discType={discType} color={color} />
  }

  renderPlaybookLeader() {
    const { discType, color } = this.props

    return <PlaybookLeaderSVG discType={discType} color={color} />
  }

  renderPlaybookProfile() {
    const { discType, color } = this.props

    return <PlaybookProfileSVG discType={discType} color={color} />
  }

  renderPlaybookRelationship() {
    const { discType, color } = this.props

    return <PlaybookRelationshipSVG discType={discType} color={color} />
  }

  renderPlaybookWeight() {
    const { discType, color } = this.props

    return <PlaybookWeightSVG discType={discType} color={color} />
  }

  renderPlaybookBlind() {
    const { discType, color } = this.props

    return <PlaybookBlindSVG discType={discType} color={color} />
  }

  renderPlaybookHandHeart() {
    const { discType, color } = this.props

    return <PlaybookHandHeartSVG discType={discType} color={color} />
  }

  renderPlaybookRelationshipConversation() {
    const { discType, color } = this.props

    return (
      <PlaybookRelationshipConversationSVG discType={discType} color={color} />
    )
  }

  renderPlaybookScale() {
    const { discType, color } = this.props

    return <PlaybookScaleSVG discType={discType} color={color} />
  }

  renderPlaybookGroup() {
    const { discType, color } = this.props

    return <PlaybookGroupSVG discType={discType} color={color} />
  }

  renderPlaybookFlower() {
    const { discType, color } = this.props

    return <PlaybookFlowerSVG discType={discType} color={color} />
  }

  renderPlaybookSmile() {
    const { discType, color } = this.props

    return <PlaybookSmileSVG discType={discType} color={color} />
  }

  renderPlaybookWater() {
    const { discType, color } = this.props

    return <PlaybookWaterSVG discType={discType} color={color} />
  }

  renderPlaybookHeartFlower() {
    const { discType, color } = this.props

    return <PlaybookHeartFlowerSVG discType={discType} color={color} />
  }

  renderPlaybookDiamond() {
    const { discType, color } = this.props

    return <PlaybookDiamondSVG discType={discType} color={color} />
  }

  renderPlaybookWhistle() {
    const { discType, color } = this.props

    return <PlaybookWhistleSVG discType={discType} color={color} />
  }

  renderPlaybookWarning() {
    const { discType, color } = this.props

    return <PlaybookWarningSVG discType={discType} color={color} />
  }

  renderPlaybookBranch() {
    const { discType, color } = this.props

    return <PlaybookBranchSVG discType={discType} color={color} />
  }

  renderPlaybookGroupThink() {
    const { discType, color } = this.props

    return <PlaybookGroupThinkSVG discType={discType} color={color} />
  }

  renderPlaybookHeadIdea() {
    const { discType, color } = this.props

    return <PlaybookHeadIdeaSVG discType={discType} color={color} />
  }

  renderPlaybookGavel() {
    const { discType, color } = this.props

    return <PlaybookGavelSVG discType={discType} color={color} />
  }

  renderPlaybookBooksApple() {
    const { discType, color } = this.props

    return <PlaybookBooksAppleSVG discType={discType} color={color} />
  }

  renderPlaybookBrain() {
    const { discType, color } = this.props

    return <PlaybookBrainSVG discType={discType} color={color} />
  }

  renderPlaybookLion() {
    const { discType, color } = this.props

    return <PlaybookLionSVG discType={discType} color={color} />
  }

  renderPlaybookMountain() {
    const { discType, color } = this.props

    return <PlaybookMountainSVG discType={discType} color={color} />
  }

  renderPlaybookTwoFingers() {
    const { discType, color } = this.props

    return <PlaybookTwoFingersSVG discType={discType} color={color} />
  }

  renderPlaybookPartyHorn() {
    const { discType, color } = this.props

    return <PlaybookPartyHornSVG discType={discType} color={color} />
  }

  renderPlaybookArrowHeart() {
    const { discType, color } = this.props

    return <PlaybookArrowHeartSVG discType={discType} color={color} />
  }

  renderPlaybookGearCheck() {
    const { discType, color } = this.props

    return <PlaybookGearCheckSVG discType={discType} color={color} />
  }

  renderPlaybookLock() {
    const { discType, color } = this.props

    return <PlaybookLockSVG discType={discType} color={color} />
  }

  renderPlaybookDog() {
    const { discType, color } = this.props

    return <PlaybookDogSVG discType={discType} color={color} />
  }

  renderPlaybookPersonCenter() {
    const { discType, color } = this.props

    return <PlaybookPersonCenterSVG discType={discType} color={color} />
  }

  renderPlaybookHeartHandshake() {
    const { discType, color } = this.props

    return <PlaybookHeartHandshakeSVG discType={discType} color={color} />
  }

  renderPlaybookPaint() {
    const { discType, color } = this.props

    return <PlaybookPaintSVG discType={discType} color={color} />
  }

  renderPlaybookGroupSparkle() {
    const { discType, color } = this.props

    return <PlaybookGroupSparkleSVG discType={discType} color={color} />
  }

  renderPlaybookTrophy() {
    const { discType, color } = this.props

    return <PlaybookTrophySVG discType={discType} color={color} />
  }

  renderPlaybookDollar() {
    const { discType, color } = this.props

    return <PlaybookDollarSVG discType={discType} color={color} />
  }

  renderPlaybookTrees() {
    const { discType, color } = this.props

    return <PlaybookTreesSVG discType={discType} color={color} />
  }

  renderPlaybookTie() {
    const { discType, color } = this.props

    return <PlaybookTieSVG discType={discType} color={color} />
  }

  renderPlaybookManyToOne() {
    const { discType, color } = this.props

    return <PlaybookManyToOneSVG discType={discType} color={color} />
  }

  renderPlaybookResume() {
    const { discType, color } = this.props

    return <PlaybookResumeSVG discType={discType} color={color} />
  }

  renderMagnifyingGlassInfo() {
    const { discType, color } = this.props

    return <MagnifyingGlassInfoSVG discType={discType} color={color} />
  }

  renderProfileWithArrows() {
    const { discType, color } = this.props

    return <ProfileWithArrowsSVG discType={discType} color={color} />
  }

  renderPlaybookDeviceLocationMarker() {
    const { discType, color } = this.props

    return <PlaybookDeviceLocationMarkerSVG discType={discType} color={color} />
  }

  renderHeart() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path
          id="Path_2634"
          data-name="Path 2634"
          d="M21.12,2.59c-2.58,0-4.84,2.1-6.06,4.26C13.84,4.69,11.58,2.59,9,2.59A7.17,7.17,0,0,0,2,9.9C2,18.76,15.06,28,15.06,28S28.15,18.86,28.15,9.9A7.17,7.17,0,0,0,21.12,2.59Z"
        />
      </svg>
    )
  }

  renderBigCheckmarkSVG() {
    const { discType, color } = this.props

    return <CheckmarkBigSVG discType={discType} color={color} />
  }

  renderBigCheckmarkBubbleSVG() {
    const { discType, color } = this.props

    return <CheckmarkBubbleBigSVG discType={discType} color={color} />
  }

  renderArrowSplit() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Group_3966" data-name="Group 3966">
          <path d="M26.72,6c-5,6.07-9.46,14.11-11.18,21.78,0,0-3.92-12.76-10.82-21.78" />
          <path d="M28.54,12.79,27.42,5,19.6,6.09" />
          <path d="M2.59,12.79,3.72,5l7.82,1.12" />
        </g>
      </svg>
    )
  }

  renderArrowSquare() {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.15905 1.3587H1.43462C0.915385 1.3587 0.5 1.77409 0.5 2.29332V8.83563C0.5 9.35486 0.915385 9.77024 1.43462 9.77024H7.94231C8.46154 9.77024 8.87692 9.35486 8.87692 8.83563V6.11119"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.9284 3.89184L8.9284 1.2973L6.33386 1.2973"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.30765 6.23083L8.78154 1.39524"
          stroke="#1E749F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

  renderHollowCheckBubble() {
    const { a, b, c, d } = this.getClasses('renderHollowCheckBubble')

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="-1 0 36.006 31.195"
      >
        <defs>
          <style>{`.${a},.${b},.${d}{fill:none;}.${a}{stroke:${this.getDiscHex()};stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;}.${b}{stroke:${this.getDiscHex()};opacity:0.87;stroke-width:3px;}.${c}{stroke:none;}`}</style>
        </defs>
        <g transform="translate(-5 -3.413)">
          <path
            className={a}
            d="M9,17.338l5.716,5.716L33.771,4"
            transform="translate(4.82 0.827)"
          />
          <g className={b} transform="translate(5 3.607)">
            <circle className={c} cx="15.5" cy="15.5" r="15.5" />
            <circle className={d} cx="15.5" cy="15.5" r="14.75" />
          </g>
        </g>
      </svg>
    )
  }

  renderBox() {
    return (
      <svg {...this.getBasicClasses('0 0 24 24')}>
        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
        <line x1="12" y1="22.08" x2="12" y2="12"></line>
      </svg>
    )
  }

  renderLocationMarker() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Group_3950" data-name="Group 3950">
          <path
            id="Path_2667"
            data-name="Path 2667"
            d="M14.45,2.83a7.85,7.85,0,0,0-7.73,8,1.85,1.85,0,0,0,0,.23c.24,6.87,5.75,15,7.32,17.18a.53.53,0,0,0,.73.12.43.43,0,0,0,.11-.12c1.63-2.18,7.33-10.37,7.33-17.64a7.75,7.75,0,0,0-7.75-7.75Z"
          />
          <circle
            id="Ellipse_303"
            data-name="Ellipse 303"
            cx="14.47"
            cy="11.09"
            r="3.07"
          />
        </g>
      </svg>
    )
  }

  renderDetailedShoppingCart() {
    const { discType, color } = this.props

    return <DetailedShoppingCartSVG discType={discType} color={color} />
  }

  renderHollowPriceTicket() {
    const { discType, color } = this.props

    return <HollowPriceTicketSVG discType={discType} color={color} />
  }

  renderMonitor() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <rect x="1.72" y="1.97" width="27" height="18.9" rx="2.7" />
        <line x1="9.82" y1="26.27" x2="20.62" y2="26.27" />
        <line x1="15.22" y1="20.87" x2="15.22" y2="26.27" />
      </svg>
    )
  }

  renderDetailedMonitor() {
    const { discType, color } = this.props

    return <DetailedMonitorSVG discType={discType} color={color} />
  }

  renderDetailedCoffeeCup() {
    const { discType, color } = this.props

    return <DetailedCoffeeCupSVG discType={discType} color={color} />
  }

  renderCoffeeCup() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M22.29,11.11h1.29a5.15,5.15,0,0,1,0,10.29H22.29" />
        <path d="M1.72,11.11H22.29V22.68a5.14,5.14,0,0,1-5.14,5.14H6.86a5.14,5.14,0,0,1-5.14-5.14Z" />
        <line x1="6.86" y1="2.11" x2="6.86" y2="5.97" />
        <line x1="12" y1="2.11" x2="12" y2="5.97" />
        <line x1="17.15" y1="2.11" x2="17.15" y2="5.97" />
      </svg>
    )
  }

  renderEye() {
    return (
      <svg {...this.getBasicClasses('0 0 24 24')}>
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
      </svg>
    )
  }

  renderPlaybookClipboardChecklist() {
    const { discType, color } = this.props

    return <PlaybookClipboardCheckListSVG discType={discType} color={color} />
  }

  renderPlaybookHire() {
    const { discType, color } = this.props

    return <PlaybookHire discType={discType} color={color} />
  }

  renderThreeSixtyAssessment() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <circle cx="15.22" cy="14.97" r="13.5" />
        <line x1="1.72" y1="14.97" x2="28.72" y2="14.97" />
        <path d="M15.22,1.47A20.64,20.64,0,0,1,20.62,15a20.69,20.69,0,0,1-5.4,13.5A20.63,20.63,0,0,1,9.82,15,20.59,20.59,0,0,1,15.22,1.47Z" />
      </svg>
    )
  }

  renderBrainStorm() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="brainstorming">
          <path
            id="Path_2740"
            data-name="Path 2740"
            d="M23,20.75A5.9,5.9,0,0,0,21.81,9.06H20.32A9.45,9.45,0,1,0,6.6,19.69"
          />
          <path
            id="Path_2741"
            data-name="Path 2741"
            d="M15.9,13.79l-4.72,7.08h7.09L13.54,28"
          />
        </g>
      </svg>
    )
  }

  renderCollaborate() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M24.91,11.53a9.56,9.56,0,1,0-17,6,11.07,11.07,0,0,1,2.35,7V29H20.41v-4.3a11.25,11.25,0,0,1,2.36-7.13A9.46,9.46,0,0,0,24.91,11.53Z" />
        <line x1="10.28" y1="21.22" x2="20.41" y2="21.22" />
        <line x1="10.28" y1="25.59" x2="20.41" y2="25.59" />
        <polyline points="13.09 21.22 13.09 13.34 17.59 13.34 17.59 21.22" />
      </svg>
    )
  }

  renderWhistle() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M22.07,20.58c-.48,4.2-3.42,7.65-7.64,7.65a7.65,7.65,0,0,1-7.65-7.65c0-3.29,2.26-5.7,5-7.17,1.58-.85,13.58-6.61,13.58-6.61a30.36,30.36,0,0,1,3.36,4.76s-4.06,2.65-8,5.15A5.36,5.36,0,0,1,22.07,20.58Z" />
        <path d="M10.14,26.91a26.91,26.91,0,0,1-3.36-1.47A7.88,7.88,0,0,1,2,18.34c0-3.29,2.26-5.7,5-7.17,1.58-.85,13.58-6.6,13.58-6.6,2.61,1.17,4.8,2.23,4.8,2.23" />
        <line x1="12.58" y1="8.38" x2="17.49" y2="10.42" />
        <line x1="13.72" y1="0.97" x2="12.97" y2="5.14" />
        <line x1="7.72" y1="1.97" x2="9.72" y2="6.14" />
        <line x1="2.72" y1="5.97" x2="6.72" y2="8.14" />
      </svg>
    )
  }

  renderGroup() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path d="M17.15,23.58V21.66a3.86,3.86,0,0,0-3.86-3.86H5.57a3.85,3.85,0,0,0-3.85,3.85v1.93" />
          <circle cx="9.16" cy="10.02" r="3.94" />
          <path d="M22.93,23.59V21.66A3.86,3.86,0,0,0,20,17.92" />
          <path d="M28.72,23.59V21.66a3.86,3.86,0,0,0-2.89-3.74" />
          <path d="M16.18,6.35a3.86,3.86,0,0,1,0,7.47" />
          <path d="M22,6.35a3.86,3.86,0,0,1,0,7.47" />
        </g>
      </svg>
    )
  }

  renderMagnifyingGlassPersonSmall() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path d="M17.56,20.84v-1.5a3,3,0,0,0-3-3h-6a3,3,0,0,0-3,3v1.5" />
          <circle cx="11.67" cy="10.3" r="3.32" />
        </g>
        <g>
          <circle cx="12.09" cy="13.2" r="10.37" />
          <line x1="27.93" y1="28.97" x2="19.17" y2="20.21" />
        </g>
      </svg>
    )
  }

  renderSignal() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="wifi">
          <path d="M6.14,13.62a13.6,13.6,0,0,1,17.41,0" />
          <path d="M1.72,9.23a19.78,19.78,0,0,1,26.16,0" />
          <path d="M10.51,18a7.41,7.41,0,0,1,8.59,0" />
          <line x1="14.8" y1="22.83" x2="14.81" y2="22.83" />
        </g>
      </svg>
    )
  }

  renderPersonWithFlag() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Group_3967" data-name="Group 3967">
          <path d="M20.79,26.71V24.33A4.77,4.77,0,0,0,16,19.56H6.49a4.76,4.76,0,0,0-4.77,4.76h0v2.38" />
          <circle cx="11.25" cy="10.02" r="4.77" />
          <line x1="20.7" y1="5.93" x2="20.7" y2="23.13" />
          <rect x="20.7" y="5.26" width="8.04" height="6.76" />
        </g>
      </svg>
    )
  }

  renderSignature() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g id="Group_3969" data-name="Group 3969">
          <g id="Group_3968" data-name="Group 3968">
            <path d="M5,3.91,17.6,21.22l3.32,1.25.19-3.58L8.83,1.27S5.45.56,5,3.91Z" />
          </g>
          <line x1="3.72" y1="27.55" x2="27.17" y2="27.55" />
          <line x1="3.72" y1="17.74" x2="8.97" y2="22.99" />
          <line x1="8.97" y1="17.74" x2="3.72" y2="22.99" />
        </g>
      </svg>
    )
  }

  renderPhone() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M8.42,2H20.34a2.53,2.53,0,0,1,2.38,2.66V25.9a2.53,2.53,0,0,1-2.38,2.66H8.42A2.54,2.54,0,0,1,6,25.9V4.63A2.54,2.54,0,0,1,8.42,2Z" />
        <line x1="14.34" y1="23.24" x2="14.36" y2="23.24" />
      </svg>
    )
  }

  renderPuzzlePiece() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <g>
          <path d="M27.37,8h0a.53.53,0,0,0-.25,0h-6a4,4,0,0,0,.57-2,4.05,4.05,0,1,0-8.1,0h0a4.06,4.06,0,0,0,.57,2h-6a.37.37,0,0,0-.39.32v6.37a4.1,4.1,0,0,0-2-.57,4.05,4.05,0,1,0,0,8.1h0a3.9,3.9,0,0,0,2-.57v6s0,0,0,0c0,.42.17.61.38.61h18.9a.68.68,0,0,0,.52-.26c.3-.05.44-.19.44-.36V8.64A.67.67,0,0,0,27.37,8Z" />
          <g id="Group_3973" data-name="Group 3973">
            <g id="Group_3972" data-name="Group 3972">
              <g id="Path_2752" data-name="Path 2752">
                <path d="M27.73,20.15Zm-3.6-.66a1.54,1.54,0,1,1,0-3.08,1.65,1.65,0,0,1,1.3.62h0a1.67,1.67,0,0,0,1.31.63,1.6,1.6,0,0,0,1-.31h0v1.08a3.32,3.32,0,0,0-1-.21h0a1.66,1.66,0,0,0-1.31.63h0A1.64,1.64,0,0,1,24.13,19.49Z" />
                <path d="M24.13,17.41a.55.55,0,1,0,0,1.08.61.61,0,0,0,.51-.25h0a2.5,2.5,0,0,1,.27-.29,2.55,2.55,0,0,1-.26-.3h0a.66.66,0,0,0-.51-.23m0-2a2.62,2.62,0,0,1,2.09,1,.65.65,0,0,0,.52.25.66.66,0,0,0,.4-.13c.16-.11,1.59-.89,1.59-1.09v4.7c0-.35-1.62-.93-2-.93h0a.65.65,0,0,0-.52.25,2.63,2.63,0,0,1-2.09,1,2.54,2.54,0,1,1,0-5.08Zm4.6,4.74Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  renderTool() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <path d="M18.73,7.72a1.38,1.38,0,0,0,0,1.92l2.19,2.19a1.36,1.36,0,0,0,1.91,0L28,6.67A8.21,8.21,0,0,1,17.13,17.53L7.68,27a2.9,2.9,0,0,1-4.11-4.1h0L13,13.43A8.21,8.21,0,0,1,23.88,2.57L18.74,7.71Z" />
      </svg>
    )
  }

  renderOverlappingBoxes() {
    return (
      <svg {...this.getBasicClasses('0 0 30 30')}>
        <rect x="2" y="10.5" width="18.5" height="18.5" />
        <rect x="9.5" y="2.3" width="18.5" height="18.5" />
        <line x1="14.7" y1="11" x2="9.8" y2="16" />
        <line x1="19.4" y1="11.2" x2="10.6" y2="20.1" />
        <line x1="20" y1="15.5" x2="15.1" y2="20.5" />
      </svg>
    )
  }

  renderBell() {
    return (
      <svg {...this.getBasicClasses()}>
        <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
      </svg>
    )
  }

  renderInterview() {
    const { discType, color } = this.props

    return <InterviewSVG discType={discType} color={color} />
  }

  renderSimpleMagnifyingGlass() {
    return (
      <svg {...this.getBasicClasses('0 0 20.414 20.414')}>
        <g transform="translate(-2 -2)">
          <circle cx="7" cy="7" r="7" transform="translate(3 3)" />
          <line x1="5.5" y1="5.5" transform="translate(15.5 15.5)" />
        </g>
      </svg>
    )
  }

  renderTable() {
    return (
      <svg {...this.getBasicClasses('0 0 29 20')}>
        <g transform="translate(-0.72 -4.97)">
          <path d="M1.72,23.97v-11h27v11" />
          <path d="M28.72,12.97h-27l6-7h14Z" />
          <line y2="5" transform="translate(8.72 13.97)" />
          <line y2="5" transform="translate(21.72 13.97)" />
        </g>
      </svg>
    )
  }

  renderHandsTogether() {
    const { discType, color } = this.props

    return <HandsTogetherSVG discType={discType} color={color} />
  }

  renderHandThumbsDown() {
    const { discType, color } = this.props

    return <HandThumbsDownSVG discType={discType} color={color} />
  }

  renderClassicPhone() {
    const { a, b } = this.getClasses('renderClassicPhone')

    return (
      <svg {...this.getPlaybookBasicClasses('0 0 135.141 135.14')}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${this.getDiscHex()};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;fill-rule:evenodd;}.${b}{fill:${this.getDiscHex()};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-131.854 -6735)">
          <path
            className={a}
            d="M4189.366,1496.3l-25.1-25.1a3.668,3.668,0,0,0-5.187,0l-16.923,16.924c-10.377-.476-23.065-6.6-33.784-17.314s-16.84-23.407-17.314-33.784l16.925-16.923a3.668,3.668,0,0,0,0-5.187l-25.1-25.1a3.665,3.665,0,0,0-5.185,0l-7.744,7.742c-.069.071-.1.16-.165.234-17.156,18.588-6.818,57.646,23.565,88.03s69.443,40.721,88.031,23.566c.074-.062.164-.1.233-.164l7.744-7.744A3.666,3.666,0,0,0,4189.366,1496.3Z"
            transform="translate(-3928.522 5347.256)"
          />
          <path
            className={b}
            d="M4189.366,1496.3l-25.1-25.1a3.668,3.668,0,0,0-5.187,0l-16.923,16.924c-10.377-.476-23.065-6.6-33.784-17.314s-16.84-23.407-17.314-33.784l16.925-16.923a3.668,3.668,0,0,0,0-5.187l-25.1-25.1a3.665,3.665,0,0,0-5.185,0l-7.744,7.742c-.069.071-.1.16-.165.234-17.156,18.588-6.818,57.646,23.565,88.03s69.443,40.721,88.031,23.566c.074-.062.164-.1.233-.164l7.744-7.744A3.666,3.666,0,0,0,4189.366,1496.3Z"
            transform="translate(-3924.446 5351.333)"
          />
        </g>
      </svg>
    )
  }

  renderArrowLoop() {
    const { a, b } = this.getClasses('renderArrowLoop')

    return (
      <svg {...this.getPlaybookBasicClasses('0 0 151.898 168.148')}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${this.getDiscHex()};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${this.getDiscHex()};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-112.373 -6898.606)">
          <path
            className={a}
            d="M134.378,60.7,107.3,32.088a6.812,6.812,0,0,0-9.632-.266L69.058,58.9l9.368,9.9L94.58,53.509A40.482,40.482,0,1,1,65.241,15.2l6.552,1.87L75.532,3.964,68.98,2.095a54.114,54.114,0,1,0,39.241,50.791l16.259,17.18Z"
            transform="translate(113.684 7026.938) rotate(-71)"
          />
          <path
            className={b}
            d="M134.378,60.7,107.3,32.088a6.812,6.812,0,0,0-9.632-.266L69.058,58.9l9.368,9.9L94.58,53.509A40.482,40.482,0,1,1,65.241,15.2l6.552,1.87L75.532,3.964,68.98,2.095a54.114,54.114,0,1,0,39.241,50.791l16.259,17.18Z"
            transform="translate(116.911 7030.165) rotate(-71)"
          />
        </g>
      </svg>
    )
  }

  renderSimpleClassicPhone() {
    return (
      <svg {...this.getBasicClasses('0 0 27.951 28')}>
        <g transform="translate(-58.66 -4093)">
          <path
            d="M28.713,3946.433v3.914a2.61,2.61,0,0,1-2.6,2.62c-.082,0-.164,0-.246-.011a25.82,25.82,0,0,1-11.259-4.005,25.446,25.446,0,0,1-7.828-7.828,25.832,25.832,0,0,1-4.006-11.312,2.609,2.609,0,0,1,2.364-2.833c.078-.007.155-.011.233-.011H9.286a2.608,2.608,0,0,1,2.609,2.244,16.772,16.772,0,0,0,.913,3.666,2.607,2.607,0,0,1-.587,2.753l-1.657,1.657a20.882,20.882,0,0,0,7.828,7.828l1.657-1.657a2.611,2.611,0,0,1,2.753-.587,16.742,16.742,0,0,0,3.666.913A2.611,2.611,0,0,1,28.713,3946.433Z"
            transform="translate(56.896 167.033)"
          />
        </g>
      </svg>
    )
  }

  renderSimpleHandsTogether() {
    return (
      <svg {...this.getBasicClasses('0 0 29.607 29')}>
        <g transform="translate(-57 -4180)">
          <g transform="translate(56.896 167.033)">
            <path
              className="a"
              d="M1.1,4037.581v3.386H9.442l2.78-3.767-5.817-4.868S2.8,4036.607,1.1,4037.581Z"
            />
            <path
              className="a"
              d="M7.347,4033.121a8.3,8.3,0,0,0,1.714-3.159c.339-1.608,3.344-15.11,3.344-15.11s.561-1.637,1.508-.459a4.1,4.1,0,0,1,.862,2.575s.14,15.237,0,16.126c-.111.718-3.579,3.249-3.579,3.249Z"
            />
            <path
              className="a"
              d="M28.711,4037.581v3.386H20.373l-2.781-3.767,5.817-4.868S27.018,4036.607,28.711,4037.581Z"
            />
            <path
              className="a"
              d="M14.719,4032.967c.111.717,3.9,3.376,3.9,3.376l3.85-3.222a8.3,8.3,0,0,1-1.714-3.159c-.338-1.608-3.343-15.11-3.343-15.11s-.561-1.637-1.508-.459a17.564,17.564,0,0,0-1.183,1.574"
            />
          </g>
        </g>
      </svg>
    )
  }

  renderSimpleArrowLoop() {
    return (
      <svg {...this.getBasicClasses('0 0 22.131 27.263')}>
        <g transform="translate(-61.13 -4220.59)">
          <path
            className="a"
            d="M11.6,4060.553a10,10,0,1,1-5.572,5.676"
            transform="translate(56.896 167.033)"
          />
          <path
            className="a"
            d="M16.072,4066.285l-5.657-5.657,5.657-5.657"
            transform="translate(56.896 167.033)"
          />
        </g>
      </svg>
    )
  }

  renderMirror() {
    return (
      <svg {...this.getBasicClasses('0 0 29.136 28.792')}>
        <g transform="translate(-0.151 -4093.175)">
          <circle
            cx="9.227"
            cy="9.227"
            r="9.227"
            transform="translate(5.492 4094.175)"
          />
          <path d="M24.55,4103.4h3.737a13.568,13.568,0,1,1-27.136,0H4.888" />
          <line y2="6.725" transform="translate(14.719 4113.232)" />
          <path d="M21.321,4120.967H8.117" />
          <line x1="4.82" y2="4.82" transform="translate(14.364 4103.024)" />
        </g>
      </svg>
    )
  }

  renderDetailedMirror() {
    const { discType, color } = this.props

    return <DetailedMirrorSVG discType={discType} color={color} />
  }

  renderArrowInGear() {
    return (
      <svg {...this.getBasicClasses('0 0 35.392 32.076')}>
        <g transform="translate(-0.72 -2.36)">
          <path
            d="M7.995,11.758l-.087-.087a2.77,2.77,0,0,1,0-3.913h0a2.8,2.8,0,0,1,3.876,0h0l.075.087a2.311,2.311,0,0,0,2.484.46H14.5a2.286,2.286,0,0,0,1.379-2.1V5.956a2.783,2.783,0,0,1,5.553,0v.124a2.3,2.3,0,0,0,2.36,2.3,2.249,2.249,0,0,0,1.59-.659l.087-.087a2.783,2.783,0,0,1,3.925,0h0a2.783,2.783,0,0,1,0,3.925h0l-.062.05a2.286,2.286,0,0,0-.46,2.484V14.2a2.286,2.286,0,0,0,2.1,1.379h.236a2.783,2.783,0,0,1,0,5.553h-.124a2.3,2.3,0,0,0-1.64,3.9l.087.087a2.783,2.783,0,0,1,0,3.925h0a2.783,2.783,0,0,1-3.925,0h0L25.5,29a2.311,2.311,0,0,0-3.255,0,2.335,2.335,0,0,0-.646,1.6v.236a2.783,2.783,0,0,1-5.553,0v-.124a2.4,2.4,0,0,0-4.012-1.64l-.087.087a2.77,2.77,0,0,1-3.913,0h0a2.783,2.783,0,0,1,0-3.925h0l.075.037"
            transform="translate(1.303 0)"
          />
          <line x2="21.54" transform="translate(1.72 18.441)" />
          <path
            d="M15.11,10.88l5.727,5.727L15.11,22.346"
            transform="translate(3.244 1.822)"
          />
        </g>
      </svg>
    )
  }

  renderEgg() {
    const { a } = this.getClasses('renderEgg')

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="254.744"
        height="206.817"
        viewBox="0 0 254.744 206.817"
      >
        <defs>
          <style>{`.${a}{fill:${this.getDiscHex()};opacity:0.1;}`}</style>
        </defs>
        <path
          className={a}
          d="M139.8-6.052c70.4,0,114.94,30.492,114.94,88.886S179.521,200.765,109.123,200.765,0,164.2,0,105.8,69.406-6.052,139.8-6.052Z"
          transform="translate(0 6.052)"
        />
      </svg>
    )
  }

  renderIntroduce() {
    const { discType, color } = this.props

    return <IntroduceSVG discType={discType} color={color} />
  }

  renderSimpleIntroduce() {
    const { discType, color } = this.props

    return <SimpleIntroduceSVG discType={discType} color={color} />
  }

  renderApologize() {
    const { discType, color } = this.props

    return <ApologizeSVG discType={discType} color={color} />
  }

  renderSimpleApologize() {
    const { discType, color } = this.props

    return <SimpleApologizeSVG discType={discType} color={color} />
  }

  renderWallet() {
    const { discType, color } = this.props

    return <WalletSVG discType={discType} color={color} />
  }

  renderSimpleWallet() {
    const { discType, color } = this.props

    return <SimpleWalletSVG discType={discType} color={color} />
  }

  renderHandTextBubble() {
    const { discType, color } = this.props

    return <HandTextBubbleSVG discType={discType} color={color} />
  }

  renderStarTicket() {
    const { discType, color } = this.props

    return <StarTicketSVG discType={discType} color={color} />
  }

  renderSimpleStarTicket() {
    const { discType, color } = this.props

    return <SimpleStarTicketSVG discType={discType} color={color} />
  }

  renderSimpleHandTextBubble() {
    const { discType, color } = this.props

    return <SimpleHandTextBubbleSVG discType={discType} color={color} />
  }

  renderGift() {
    const { discType, color } = this.props

    return <GiftSVG discType={discType} color={color} />
  }

  renderSimpleGift() {
    const { discType, color } = this.props

    return <SimpleGiftSVG discType={discType} color={color} />
  }

  renderBouquet() {
    const { discType, color } = this.props

    return <BouquetSVG discType={discType} color={color} />
  }

  renderRose() {
    const { discType, color } = this.props

    return <RoseSVG discType={discType} color={color} />
  }

  renderDice() {
    const { discType, color } = this.props

    return <DiceSVG discType={discType} color={color} />
  }

  renderHeadQuestionMark() {
    const { discType, color } = this.props

    return <HeadQuestionMarkSVG discType={discType} color={color} />
  }

  renderMuscle() {
    const { discType, color } = this.props

    return <MuscleSVG discType={discType} color={color} />
  }

  renderTimer() {
    const { discType, color } = this.props

    return <TimerSVG discType={discType} color={color} />
  }

  renderSmilingFrowningMasks() {
    const { discType, color } = this.props

    return <SmilingFrowningMasksSVG discType={discType} color={color} />
  }

  renderChessPiece() {
    const { discType, color } = this.props

    return <ChessPieceSVG discType={discType} color={color} />
  }

  renderArrowGap() {
    const { discType, color } = this.props

    return <ArrowGapSVG discType={discType} color={color} />
  }

  renderEasel() {
    const { discType, color } = this.props

    return <EaselSVG discType={discType} color={color} />
  }

  renderMaximize() {
    const { discType, color } = this.props

    return <MaximizeSVG discType={discType} color={color} />
  }

  renderRibbonCheck() {
    const { discType, color } = this.props

    return <RibbonCheckSVG discType={discType} color={color} />
  }

  renderLighBulb() {
    const { discType, color } = this.props

    return <LightBulbSVG discType={discType} color={color} />
  }

  renderSimpleLock() {
    const { discType, color } = this.props

    return <SimpleLockSVG discType={discType} color={color} />
  }

  renderUploadCloud() {
    const { discType, color } = this.props

    return <UploadCloudSVG discType={discType} color={color} />
  }

  renderGraduationCap() {
    const { discType, color } = this.props

    return <GraduationCapSVG discType={discType} color={color} />
  }

  renderCloseBubble() {
    const { discType, color } = this.props

    return <CloseBubbleSVG discType={discType} color={color} />
  }

  renderWeightLarge() {
    const { discType, color } = this.props

    return <WeightSVG discType={discType} color={color} />
  }

  renderSparkleGroup() {
    const { discType, color } = this.props

    return <SparkleGroupSVG discType={discType} color={color} />
  }

  renderSixteenPersonalities() {
    const { discType, color } = this.props

    return <SixteenPersonalitiesSVG discType={discType} color={color} />
  }

  renderLargeDisc() {
    const { discType, color } = this.props

    return <LargeDiscSVG discType={discType} color={color} />
  }

  renderPodiumStars() {
    const { discType, color } = this.props

    return <PodiumStarsSvg discType={discType} color={color} />
  }

  renderQuestionMarkMagnifyingGlass() {
    const { discType, color } = this.props

    return <QuestionMarkMagnifyingGlassSvg discType={discType} color={color} />
  }

  renderDetailedCloudWithLightning() {
    const { discType, color } = this.props

    return <DetailedCloudWithLightningSVG discType={discType} color={color} />
  }

  renderTrophyWithProfile() {
    const { discType, color } = this.props

    return <TrophyWithProfileSVG discType={discType} color={color} />
  }

  renderLanguage() {
    const { discType, color } = this.props

    return <LanguageSVG discType={discType} color={color} />
  }

  renderFilter() {
    const { discType, color } = this.props

    return <FilterSVG discType={discType} color={color} />
  }

  renderLongArrow() {
    const { discType, color } = this.props

    return <LongArrowSVG discType={discType} color={color} />
  }

  renderProfileTree() {
    const { discType, color } = this.props

    return <ProfileTreeSVG discType={discType} color={color} />
  }

  renderProfilesGroup() {
    const { discType, color } = this.props

    return <ProfilesGroupSVG discType={discType} color={color} />
  }

  renderCircularArrows() {
    const { discType, color } = this.props

    return <CircularArrowsSVG discType={discType} color={color} />
  }

  renderEmptyBubble() {
    const { discType, color } = this.props

    return <EmptyBubbleSVG discType={discType} color={color} />
  }

  renderWarning() {
    const { discType, color } = this.props

    return <WarningSVG discType={discType} color={color} />
  }

  renderMedal() {
    const { discType, color } = this.props

    return <MedalSVG discType={discType} color={color} />
  }

  renderLargeCup() {
    const { discType, color } = this.props

    return <LargeCupSVG discType={discType} color={color} />
  }

  renderMegaphone() {
    const { discType, color } = this.props

    return <MegaphoneSVG discType={discType} color={color} />
  }

  renderLargeSun() {
    const { discType, color } = this.props

    return <LargeSunSVG discType={discType} color={color} />
  }

  renderHeartWithOutline() {
    const { discType, color } = this.props

    return <HeartWithOutlineSVG discType={discType} color={color} />
  }

  renderChartWithLines() {
    const { discType, color } = this.props

    return <ChartWithLinesSVG discType={discType} color={color} />
  }

  renderBarChartWithLineChart() {
    const { discType, color } = this.props

    return <BarChartWithLineChartSVG discType={discType} color={color} />
  }

  renderRook() {
    const { discType, color } = this.props

    return <RookSVG discType={discType} color={color} />
  }

  renderShield() {
    const { discType, color } = this.props

    return <ShieldSVG discType={discType} color={color} />
  }

  renderPersonQuestionMark() {
    return <PersonQuestionMark />
  }

  renderPaperWithRuler() {
    const { discType, color } = this.props

    return <PaperWithRulerSVG discType={discType} color={color} />
  }

  renderThumbsUp() {
    const { discType } = this.props

    return <ThumbsUp discType={discType} />
  }

  // Functions
  getDiscHex() {
    const { discType, color } = this.props

    return getDiscHex(discType, color)
  }

  getClasses = (text: string): Record<Letters, string> => {
    const list = [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
    ].slice(0)
    const object: Record<string, string> = {}

    list.forEach(l => {
      object[l] = this.getClass(`${l}-${text}`)
    })

    return object as Record<Letters, string>
  }

  getClass(text: string) {
    const discHex = this.getDiscHex().replace('#', '')

    return `${text}-${discHex}`
  }

  getPlaybookBasicClasses(viewBox: string) {
    return {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '100%',
      height: '100%',
      viewBox: viewBox,
      stroke: this.getDiscHex(),
    }
  }

  getBasicClasses(viewBox?: string): SVGProps {
    type capTypes = 'round' | 'inherit' | 'butt' | 'undefined'

    const strokeLinecap: capTypes = 'round'
    const strokeLinejoin: capTypes = 'round'

    return {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '100%',
      height: '100%',
      fill: 'none',
      viewBox: viewBox ? viewBox : '0 0 24 24',
      strokeWidth: '2',
      strokeLinecap,
      strokeLinejoin,
      stroke: this.getDiscHex(),
    }
  }

  handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    this.setState({ query: value })
  }
}
