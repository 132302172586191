import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class MedalSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('MedalSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a} {fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 1px;}`}</style>
        </defs>
        <path
          className={a}
          d="M68.38,96.53a1,1,0,0,1-.59-.2L50.12,83.5,32.46,96.33a1,1,0,0,1-1.18,0,1,1,0,0,1-.36-1.11l6.74-20.77L20,61.61a1,1,0,0,1-.36-1.11,1,1,0,0,1,.95-.7H42.42l5.7-17.53a1,1,0,0,1,1.23-.66,2.89,2.89,0,0,0,1.62,0,1,1,0,0,1,1.17.67L57.82,59.8H79.66a1,1,0,0,1,.59,1.81L62.58,74.45l6.75,20.77A1,1,0,0,1,69,96.33,1,1,0,0,1,68.38,96.53ZM23.66,61.8,39.43,73.26a1,1,0,0,1,.36,1.12l-6,18.53L49.53,81.45a1,1,0,0,1,1.18,0L66.47,92.91l-6-18.53a1,1,0,0,1,.37-1.12L76.58,61.8H57.1a1,1,0,0,1-1-.69L50.5,43.74a4.7,4.7,0,0,1-.75,0L44.1,61.11a1,1,0,0,1-1,.69Z"
        />
        <path
          className={a}
          d="M88.37,5.47H11.88a1,1,0,1,1,0-2H88.37a1,1,0,1,1,0,2Z"
        />
        <path
          className={a}
          d="M50.12,35a.93.93,0,0,1-.46-.12L21.42,20.12a1,1,0,0,1-.54-.89V4.47a1,1,0,0,1,1-1h56a1,1,0,0,1,1,1V19.23a1,1,0,0,1-.53.88L50.59,34.87A1,1,0,0,1,50.12,35ZM22.88,18.62,50.12,32.86,76.91,18.63V5.47h-54Zm55,.61h0Z"
        />
        <path
          className={a}
          d="M60.12,29.39a1,1,0,0,1-1-1V4.47a1,1,0,0,1,2,0V28.39A1,1,0,0,1,60.12,29.39Z"
        />
        <path
          className={a}
          d="M39.67,29.39a1,1,0,0,1-1-1V4.47a1,1,0,0,1,2,0V28.39A1,1,0,0,1,39.67,29.39Z"
        />
        <path
          className={a}
          d="M50.12,43.51a5.26,5.26,0,1,1,5.26-5.26A5.27,5.27,0,0,1,50.12,43.51Zm0-8.52a3.26,3.26,0,1,0,3.26,3.26A3.26,3.26,0,0,0,50.12,35Z"
        />
      </svg>
    )
  }
}
