import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookDiamondSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d, e, f, g, h, i } = getSVGClasses(
      'renderPlaybookDiamond',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.186 94.15"
      >
        <defs>
          <style>{`.${a},.${b},.${c},.${d}{fill:none;}.${a},.${b},.${c},.${d},.${f},.${g},.${h},.${i}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${a},.${f}{opacity:0.48;}.${a},.${c},.${d},.${f},.${h},.${i}{isolation:isolate;}.${c},.${h}{opacity:0.52;}.${e}{opacity:0.1;}.${f},.${g},.${h},.${i}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1834.209 -993.179)">
          <g transform="translate(179.114 373.638)">
            <path
              className={a}
              d="M1717.145,647.882h-61.051l47.093,63.808,47.093-63.808Z"
            />
            <path
              className={b}
              d="M1717.145,647.882H1687.5l15.693,63.808,15.693-63.808Z"
            />
            <path
              className={c}
              d="M1717.145,647.882H1687.5l15.693,63.808,15.693-63.808Z"
            />
            <path
              className={b}
              d="M1718.88,647.882l-15.693,63.808,47.093-63.808Z"
            />
            <path
              className={b}
              d="M1687.5,647.882l15.693,63.808-47.093-63.808Z"
            />
            <path className={d} d="M1671.8,620.541l-15.708,27.342h31.4Z" />
            <path className={d} d="M1703.188,620.541,1687.5,647.882h31.385Z" />
            <path className={d} d="M1734.573,620.541l-15.693,27.342h31.4Z" />
            <line
              className={b}
              x2="62.778"
              transform="translate(1671.803 620.541)"
            />
          </g>
          <g className={e} transform="translate(181.114 374.638)">
            <path
              className={f}
              d="M1717.145,647.882h-61.051l47.093,63.808,47.093-63.808Z"
            />
            <path
              className={g}
              d="M1717.145,647.882H1687.5l15.693,63.808,15.693-63.808Z"
            />
            <path
              className={h}
              d="M1717.145,647.882H1687.5l15.693,63.808,15.693-63.808Z"
            />
            <path
              className={g}
              d="M1718.88,647.882l-15.693,63.808,47.093-63.808Z"
            />
            <path
              className={g}
              d="M1687.5,647.882l15.693,63.808-47.093-63.808Z"
            />
            <path
              className={i}
              d="M1671.8,620.541l-15.708,27.342,49.871-25.95Z"
            />
            <path
              className={i}
              d="M1703.188,620.541l-47.321,27.343,92.566-.819Z"
            />
            <path
              className={i}
              d="M1734.573,620.541l-31.819,1.081,47.527,26.261Z"
            />
            <line
              className={b}
              x2="62.778"
              transform="translate(1671.803 620.541)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
