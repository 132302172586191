import React from 'react'

import { getDiscHex, getBasicClasses, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ChartWithLinesSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('ChartWithLinesSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 100 100', discType, color)}>
        <defs>
          <style>{`.${a}{fill:${getDiscHex(
            discType,
            color,
          )}; stroke-width: 0.5px;}`}</style>
        </defs>
        <path
          className={a}
          d="M36.48,79.46H7.28a1,1,0,0,0,0,2H36.46A8.91,8.91,0,0,1,36.48,79.46Z"
        />
        <path
          className={a}
          d="M92.74,79.46H52.85a9.55,9.55,0,0,1,0,2H92.74a1,1,0,0,0,0-2Z"
        />
        <path
          className={a}
          d="M49.45,47.49H7.28a1,1,0,0,0,0,2H49.49A10.27,10.27,0,0,1,49.45,47.49Z"
        />
        <path
          className={a}
          d="M92.74,47.49H65.88a9.54,9.54,0,0,1,0,2h26.9a1,1,0,0,0,0-2Z"
        />
        <path
          className={a}
          d="M41.77,18.34H7.28a1,1,0,0,0,0,2H41.7A10.27,10.27,0,0,1,41.77,18.34Z"
        />
        <path
          className={a}
          d="M92.74,18.34H58.08a10.27,10.27,0,0,1,.07,2H92.74a1,1,0,0,0,0-2Z"
        />
        <path
          className={a}
          d="M44.64,90a9.53,9.53,0,1,1,9.52-9.52A9.53,9.53,0,0,1,44.64,90Zm0-17a7.53,7.53,0,1,0,7.52,7.53A7.54,7.54,0,0,0,44.64,72.93Z"
        />
        <path
          className={a}
          d="M57.16,58a9.53,9.53,0,1,1,9.53-9.52A9.53,9.53,0,0,1,57.16,58Zm0-17a7.53,7.53,0,1,0,7.53,7.53A7.53,7.53,0,0,0,57.16,41Z"
        />
        <path
          className={a}
          d="M49.22,28.87a9.53,9.53,0,1,1,9.52-9.53A9.54,9.54,0,0,1,49.22,28.87Zm0-17a7.53,7.53,0,1,0,7.52,7.52A7.52,7.52,0,0,0,49.22,11.82Z"
        />
      </svg>
    )
  }
}
