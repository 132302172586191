import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookDocumentSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookDocument', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 135.839 131.562', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-112.033 -1503.39)">
          <g transform="translate(113.033 1504.39)">
            <g transform="translate(0 0)">
              <g transform="translate(26.393)">
                <path
                  className={a}
                  d="M5104.6,2182.174v-44.993h77.809l22.28,22.132v107.43H5104.6v-49.5"
                  transform="translate(-5104.599 -2137.182)"
                />
                <line
                  className={a}
                  x2="64.71"
                  transform="translate(19.628 46.441)"
                />
                <line
                  className={a}
                  x2="30.416"
                  transform="translate(19.628 33.795)"
                />
                <line
                  className={a}
                  x2="64.71"
                  transform="translate(19.628 59.907)"
                />
                <line
                  className={a}
                  x2="64.71"
                  transform="translate(19.628 73.037)"
                />
                <line
                  className={a}
                  x2="64.71"
                  transform="translate(19.628 113.334)"
                />
                <path
                  className={a}
                  d="M5180.544,2159.314h-22.132v-22.132Z"
                  transform="translate(-5080.455 -2137.182)"
                />
                <line
                  className={a}
                  x2="7.283"
                  y2="7.283"
                  transform="translate(19.628 96.856)"
                />
                <line
                  className={a}
                  x1="7.283"
                  y2="7.283"
                  transform="translate(19.628 96.856)"
                />
                <path
                  className={a}
                  d="M5127.821,2207.436c3.3-2.539,10.165-3.09,14.477-1.159,1.317.591,2.389,1.365,3.763,1.909a16.816,16.816,0,0,0,11.19-.038c6.648-2.55,8.844,1.437,14.886.917,3.224-.279,6.4-2.026,8.528-.564"
                  transform="translate(-5094.18 -2106.701)"
                />
              </g>
              <g transform="translate(0 6.305)">
                <rect
                  className={a}
                  width="11.432"
                  height="50.355"
                  transform="matrix(-0.943, 0.333, -0.333, -0.943, 39.547, 76.441)"
                />
                <path
                  className={a}
                  d="M5117.019,2194.3l.207,17.752-10.987-13.945"
                  transform="translate(-5077.471 -2117.86)"
                />
                <rect
                  className={a}
                  width="11.432"
                  height="30.713"
                  transform="matrix(-0.943, 0.333, -0.333, -0.943, 22.778, 28.96)"
                />
                <path
                  className={a}
                  d="M5091.332,2150.38l-4.952,1.822,10.869,30.774"
                  transform="translate(-5086.38 -2137.565)"
                />
              </g>
            </g>
            <g className={b} transform="translate(33.75 0)">
              <path
                className={c}
                d="M5104.6,2182.174v-44.993h77.809l22.28,22.132v107.43H5104.6v-49.5"
                transform="translate(-5104.599 -2137.182)"
              />
              <line
                className={a}
                x2="64.71"
                transform="translate(19.628 46.441)"
              />
              <line
                className={a}
                x2="30.416"
                transform="translate(19.628 33.795)"
              />
              <line
                className={a}
                x2="64.71"
                transform="translate(19.628 59.907)"
              />
              <line
                className={a}
                x2="64.71"
                transform="translate(19.628 73.037)"
              />
              <line
                className={a}
                x2="64.71"
                transform="translate(19.628 113.334)"
              />
              <path
                className={c}
                d="M5180.544,2159.314h-22.132v-22.132Z"
                transform="translate(-5080.455 -2137.182)"
              />
              <line
                className={a}
                x2="7.283"
                y2="7.283"
                transform="translate(19.628 96.856)"
              />
              <line
                className={a}
                x1="7.283"
                y2="7.283"
                transform="translate(19.628 96.856)"
              />
              <path
                className={c}
                d="M5127.821,2207.436c3.3-2.539,10.165-3.09,14.477-1.159,1.317.591,2.389,1.365,3.763,1.909a16.816,16.816,0,0,0,11.19-.038c6.648-2.55,8.844,1.437,14.886.917,3.224-.279,6.4-2.026,8.528-.564"
                transform="translate(-5094.18 -2106.701)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
