import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookManyToOneSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookManyToOne',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 125.563 129.824', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1331.981 -4320.5)">
          <g transform="translate(1333 4321.5)">
            <circle
              className={a}
              cx="11.116"
              cy="11.116"
              r="11.116"
              transform="translate(49.087 79.398)"
            />
            <path
              className={a}
              d="M6113.006,676.782h-27.321c-2.675,0-4.632-3-3.881-6.007,2.217-8.884,9.231-15.373,17.542-15.373s15.323,6.49,17.542,15.373C6117.638,673.78,6115.682,676.782,6113.006,676.782Z"
              transform="translate(-6039.102 -548.958)"
            />
            <circle
              className={a}
              cx="11.116"
              cy="11.116"
              r="11.116"
              transform="translate(92.085 79.398)"
            />
            <path
              className={a}
              d="M6145.174,676.782h-27.32c-2.676,0-4.633-3-3.883-6.007,2.218-8.884,9.233-15.373,17.543-15.373s15.324,6.49,17.543,15.373C6149.807,673.78,6147.85,676.782,6145.174,676.782Z"
              transform="translate(-6028.272 -548.958)"
            />
            <circle
              className={a}
              cx="11.116"
              cy="11.116"
              r="11.116"
              transform="translate(6.548 79.398)"
            />
            <path
              className={a}
              d="M6081.18,676.782h-27.319c-2.676,0-4.634-3-3.883-6.007,2.219-8.884,9.232-15.373,17.542-15.373s15.323,6.49,17.542,15.373C6085.814,673.78,6083.856,676.782,6081.18,676.782Z"
              transform="translate(-6049.816 -548.958)"
            />
            <circle
              className={a}
              cx="11.116"
              cy="11.116"
              r="11.116"
              transform="translate(48.442)"
            />
            <path
              className={a}
              d="M6112.522,617.383H6085.2c-2.676,0-4.635-3-3.884-6.007,2.22-8.884,9.233-15.373,17.543-15.373s15.325,6.49,17.543,15.373C6117.156,614.38,6115.2,617.383,6112.522,617.383Z"
              transform="translate(-6039.264 -568.956)"
            />
            <path
              className={a}
              d="M6056.165,631.392v-9.716h98.679v9.716"
              transform="translate(-6047.678 -560.313)"
            />
            <line
              className={a}
              y2="16.272"
              transform="translate(60.203 53.952)"
            />
          </g>
          <g className={b} transform="translate(1335.581 4321.5)">
            <circle
              className={c}
              cx="11.116"
              cy="11.116"
              r="11.116"
              transform="translate(92.085 79.398)"
            />
            <path
              className={c}
              d="M6145.174,676.782h-27.32c-2.676,0-4.633-3-3.883-6.007,2.218-8.884,9.233-15.373,17.543-15.373s15.324,6.49,17.543,15.373C6149.807,673.78,6147.85,676.782,6145.174,676.782Z"
              transform="translate(-6028.272 -548.958)"
            />
            <circle
              className={c}
              cx="11.116"
              cy="11.116"
              r="11.116"
              transform="translate(6.548 79.398)"
            />
            <path
              className={c}
              d="M6081.18,676.782h-27.319c-2.676,0-4.634-3-3.883-6.007,2.219-8.884,9.232-15.373,17.542-15.373s15.323,6.49,17.542,15.373C6085.814,673.78,6083.856,676.782,6081.18,676.782Z"
              transform="translate(-6049.816 -548.958)"
            />
            <circle
              className={c}
              cx="11.116"
              cy="11.116"
              r="11.116"
              transform="translate(48.442)"
            />
            <path
              className={c}
              d="M6112.522,617.383H6085.2c-2.676,0-4.635-3-3.884-6.007,2.22-8.884,9.233-15.373,17.543-15.373s15.325,6.49,17.543,15.373C6117.156,614.38,6115.2,617.383,6112.522,617.383Z"
              transform="translate(-6039.264 -568.956)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
