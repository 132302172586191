import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class StrengthsSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 30 30', discType, color)}>
        <g>
          <rect
            x="4.55"
            y="16.46"
            width="5.59"
            height="12.34"
            transform="translate(-13.85 11.82) rotate(-45)"
          />
          <rect
            x="19.59"
            y="1.32"
            width="5.59"
            height="12.34"
            transform="translate(1.26 18.02) rotate(-45)"
          />
          <line x1="7.72" y1="17.97" x2="18.73" y2="7.8" />
          <line x1="21.72" y1="11.97" x2="11.72" y2="21.97" />
        </g>
      </svg>
    )
  }
}
