import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ChessPieceSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('ChessPieceSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 80.222 138.609', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-117 -9477)">
          <g transform="translate(97.707 9476.247)">
            <path
              className={a}
              d="M46.6,43.329s.713,29.7-5.464,42.289S28.545,98.209,28.545,106.049"
              transform="translate(2.914 14.683)"
            />
            <path
              className={a}
              d="M57.822,43.329s-.713,29.7,5.464,42.289,12.592,12.591,12.592,20.431"
              transform="translate(13.244 14.683)"
            />
            <rect
              className={a}
              width="77.687"
              height="15.442"
              rx="5.706"
              transform="translate(20.294 121.92)"
            />
            <rect
              className={a}
              width="35.049"
              height="15.988"
              rx="3.677"
              transform="translate(41.669 40.486)"
            />
            <path
              className={a}
              d="M42.382,33.838l-5.591-12.71s17.113-6.889,34.1,0l-4.87,12.71"
              transform="translate(5.826 5.761)"
            />
            <line
              className={a}
              y2="22.074"
              transform="translate(59.668 1.753)"
            />
            <line
              className={a}
              x2="14.136"
              transform="translate(52.96 9.784)"
            />
            <line
              className={a}
              x2="58.85"
              transform="translate(30.272 107.19)"
            />
          </g>
          <g className={b} transform="translate(6.472 9488.045)">
            <path
              className={c}
              d="M120.78,101.662c0-7.894,6.458-7.894,12.678-20.572s5.5-42.58,5.5-42.58h21.7s-.718,29.9,5.5,42.58,12.678,12.678,12.678,20.572Z"
              transform="translate(2.991 9.157)"
            />
            <rect
              className={c}
              width="78.223"
              height="15.549"
              rx="5.706"
              transform="translate(112.528 112.015)"
            />
            <rect
              className={c}
              width="35.29"
              height="16.098"
              rx="3.677"
              transform="translate(134.051 30.021)"
            />
            <path
              className={c}
              d="M134.656,29.128l-5.63-12.8s17.231-6.938,34.334,0l-4.9,12.8"
              transform="translate(5.98 0)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
