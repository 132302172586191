import { getBasicClasses } from '@dashboard/lib/svg'
import React from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const QuestionMarkMagnifyingGlassSvg: React.FC<OwnProps> = ({
  discType,
  color,
}: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 29 29', discType, color)}>
      <g clipPath="url(#clip0)">
        <path
          d="M11.7376 22.053C17.4814 22.053 22.1376 17.3968 22.1376 11.6531C22.1376 5.90931 17.4814 1.25317 11.7376 1.25317C5.99388 1.25317 1.33765 5.90931 1.33765 11.6531C1.33765 17.3968 5.99388 22.053 11.7376 22.053Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.5376 27.353L19.6376 19.4531"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.03766 9.05001C9.03766 7.35001 10.4377 5.95001 12.1377 5.95001C13.8377 5.95001 15.2377 7.35001 15.2377 9.05001C15.2377 10.75 13.8377 12.15 12.1377 12.15V14.95"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="12.1658"
          y1="17.6589"
          x2="12.1658"
          y2="17.6522"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="28.2"
            height="28.1"
            fill="white"
            transform="translate(0.337646 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QuestionMarkMagnifyingGlassSvg
