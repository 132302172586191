import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ProfilesGroupSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 28 19', discType, color)}>
        <path
          d="M15.8708 18.0001V16.1543C15.8708 14.1143 14.2399 12.3657 12.1292 12.3657H4.74169C2.72693 12.3657 1 14.0172 1 16.1543V18.0001"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.19482 8.57707C10.2613 8.57707 11.9365 6.88085 11.9365 4.78848C11.9365 2.69611 10.2613 1 8.19482 1C6.12833 1 4.45312 2.69611 4.45312 4.78848C4.45312 6.88085 6.12833 8.57707 8.19482 8.57707Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4346 17.9999V16.1542C21.4346 14.4056 20.2833 12.9484 18.6523 12.5598"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.9991 17.9999V16.1542C26.9991 14.4056 25.8478 12.9484 24.2168 12.5598"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9121 1.19434C16.9269 1.68005 18.0782 3.72 17.5985 5.76C17.3106 7.12 16.2553 8.09148 14.9121 8.48005"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4766 1.19434C22.4913 1.68005 23.6426 3.72 23.1629 5.76C22.8751 7.12 21.8197 8.09148 20.4766 8.48005"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}
