import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookBrainSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookBrain', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 98.722 84.159"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1578.15 -888.489)">
          <g transform="translate(69.619 380.926)">
            <path
              className={a}
              d="M1604.253,540.172a11.966,11.966,0,0,0-7.048-10.892A14.627,14.627,0,0,0,1584.07,517a11.05,11.05,0,0,0-9.931-6.194h0c-.534,0-1.068.107-1.6.107h-.107a10.081,10.081,0,0,0-1.709.427,12.246,12.246,0,0,0-7.582-2.776,10.959,10.959,0,0,0-6.3,1.922,15.447,15.447,0,0,0-6.3-1.282,15.074,15.074,0,0,0-11.426,5.126,12.352,12.352,0,0,0-2.777-.32,10.923,10.923,0,0,0-9.4,5.232h-.961c-.214,0-.428.107-.641.107s-.32.107-.534.107a1.517,1.517,0,0,0-.641.214c-.213,0-.32.106-.534.106-.213.107-.427.107-.534.214-.213.107-.427.107-.534.214s-.32.106-.427.213l-.641.321h0a12.953,12.953,0,0,0-6.62,10.465v.107a17.3,17.3,0,0,0,11.96,29.794,28.089,28.089,0,0,0,2.99-.214,17.058,17.058,0,0,0,4.058.748c2.777,6.834,8.33,10.465,15.912,10.465,7.261,0,16.018,7.369,16.018,16.019v1.6h12.174l-1.388-9.931a17.4,17.4,0,0,0,18.581-13.028,21.329,21.329,0,0,0,.534-4.379,12.924,12.924,0,0,0,8.543-12.067,11.927,11.927,0,0,0-1.068-5.126A13.641,13.641,0,0,0,1604.253,540.172Z"
            />
            <path className={a} d="M1539.817,546.677s-9,5.692-5.938,14.96" />
            <path
              className={a}
              d="M1521.883,550.535s-2.436-4.549,1.107-6.481c0,0-9.6-4.739-8.119-12.745"
            />
            <path
              className={a}
              d="M1525.647,531.309a12.951,12.951,0,0,0,14.17-2.472"
            />
            <path className={a} d="M1538.175,522.356s-.234,6.952,7.737,8.273" />
            <path
              className={a}
              d="M1555.012,560.176s9.712,10.91,23.745,1.374"
            />
            <path
              className={a}
              d="M1552.661,538.716s11.808,13.034,28.6,1.406"
            />
            <path className={a} d="M1563.758,550.535s1.28-5.025-4.314-7.259" />
            <path className={a} d="M1563.455,529.282s-.7-6.261-8.022-6.4" />
            <path className={a} d="M1547.084,556.653s3.767-9.166-3.1-16.986" />
            <path
              className={a}
              d="M1589.728,536.926s-.688-11.752-14.844-11.217"
            />
            <path
              className={a}
              d="M1595.71,562.384s-1.046-11.986-15.2-11.451"
            />
            <path className={a} d="M1563.884,519.382s1.266-6.587,6.837-8.043" />
          </g>
          <g className={b} transform="translate(71.619 381.926)">
            <path
              className={c}
              d="M1604.253,540.172a11.966,11.966,0,0,0-7.048-10.892A14.627,14.627,0,0,0,1584.07,517a11.05,11.05,0,0,0-9.931-6.194h0c-.534,0-1.068.107-1.6.107h-.107a10.081,10.081,0,0,0-1.709.427,12.246,12.246,0,0,0-7.582-2.776,10.959,10.959,0,0,0-6.3,1.922,15.447,15.447,0,0,0-6.3-1.282,15.074,15.074,0,0,0-11.426,5.126,12.352,12.352,0,0,0-2.777-.32,10.923,10.923,0,0,0-9.4,5.232h-.961c-.214,0-.428.107-.641.107s-.32.107-.534.107a1.517,1.517,0,0,0-.641.214c-.213,0-.32.106-.534.106-.213.107-.427.107-.534.214-.213.107-.427.107-.534.214s-.32.106-.427.213l-.641.321h0a12.953,12.953,0,0,0-6.62,10.465v.107a17.3,17.3,0,0,0,11.96,29.794,28.089,28.089,0,0,0,2.99-.214,17.058,17.058,0,0,0,4.058.748c2.777,6.834,8.33,10.465,15.912,10.465,7.261,0,16.018,7.369,16.018,16.019v1.6h12.174l-1.388-9.931a17.4,17.4,0,0,0,18.581-13.028,21.329,21.329,0,0,0,.534-4.379,12.924,12.924,0,0,0,8.543-12.067,11.927,11.927,0,0,0-1.068-5.126A13.641,13.641,0,0,0,1604.253,540.172Z"
            />
            <path className={c} d="M1539.817,546.677s-9,5.692-5.938,14.96" />
            <path
              className={c}
              d="M1521.883,550.535s-2.436-4.549,1.107-6.481c0,0-9.6-4.739-8.119-12.745"
            />
            <path
              className={c}
              d="M1525.647,531.309a12.951,12.951,0,0,0,14.17-2.472"
            />
            <path className={c} d="M1538.175,522.356s-.234,6.952,7.737,8.273" />
            <path
              className={c}
              d="M1555.012,560.176s9.712,10.91,23.745,1.374"
            />
            <path
              className={c}
              d="M1552.661,538.716s11.808,13.034,28.6,1.406"
            />
            <path className={c} d="M1563.758,550.535s1.28-5.025-4.314-7.259" />
            <path className={c} d="M1563.455,529.282s-.7-6.261-8.022-6.4" />
            <path className={c} d="M1547.084,556.653s3.767-9.166-3.1-16.986" />
            <path
              className={c}
              d="M1589.728,536.926s-.688-11.752-14.844-11.217"
            />
            <path
              className={c}
              d="M1595.71,562.384s-1.046-11.986-15.2-11.451"
            />
            <path className={c} d="M1563.884,519.382s1.266-6.587,6.837-8.043" />
          </g>
        </g>
      </svg>
    )
  }
}
