import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookExplosionSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookExplosion', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 129.005 129.076', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-113.349 -2241.146)">
          <g transform="translate(114.349 2242.146)">
            <g transform="translate(0)">
              <path
                className={a}
                d="M3333.987,1390.156l3.149,23.724,35.546-8.474-15.212,26.915,24.125,21.243-36.225,4.808,5.007,26.325L3324.83,1471.7l-21.191,40.818-2.76-45.916-41.069,13.363,27.737-33.9-31.127-19.143,36.225-4.808-8.474-35.546,29.015,22.215Z"
                transform="translate(-3256.42 -1386.568)"
              />
              <path
                className={a}
                d="M3319.566,1407.406l1.647,12.406,18.589-4.432-7.956,14.075,12.616,11.109-18.943,2.515,2.618,13.766-13.36-6.795L3303.7,1471.4l-1.443-24.011-21.477,6.988,14.5-17.729L3279,1426.633l18.943-2.514-4.431-18.589,15.173,11.617Z"
                transform="translate(-3249.048 -1380.379)"
              />
            </g>
            <path
              className={b}
              d="M3333.987,1390.156l3.149,23.724,35.546-8.474-15.212,26.915,24.125,21.243-36.225,4.808,5.007,26.325L3324.83,1471.7l-21.191,40.818-2.76-45.916-41.069,13.363,27.737-33.9-31.127-19.143,36.225-4.808-8.474-35.546,29.015,22.215Z"
              transform="translate(-3253.59 -1384.446)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
