import React from 'react'

import { getDiscHex, getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class WeightSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        {...getBasicClasses('0 0 127 142', discType, color)}
        fill="none"
        stroke={getDiscHex(discType, color)}
      >
        <path
          d="M112.895 27.0365H14.0183C6.73427 27.0365 1 21.1473 1 14.0183C1 6.73423 6.88925 1 14.0183 1H112.895C120.179 1 125.913 6.88921 125.913 14.0183C125.913 21.3023 120.024 27.0365 112.895 27.0365Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M112.895 64.8515H14.0183C6.73427 64.8515 1 58.9622 1 51.8332C1 44.5492 6.88925 38.8149 14.0183 38.8149H112.895C120.179 38.8149 125.913 44.7042 125.913 51.8332C125.913 58.9622 120.024 64.8515 112.895 64.8515Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M112.275 58.3423H69.5007C66.0912 58.3423 63.4565 55.5527 63.4565 52.2981V51.5232C63.4565 48.1137 66.2461 45.479 69.5007 45.479H112.275C115.685 45.479 118.319 48.2687 118.319 51.5232V52.2981C118.319 55.5527 115.685 58.3423 112.275 58.3423Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.2574 20.5274H14.4831C11.0735 20.5274 8.4389 17.7377 8.4389 14.4832V13.7083C8.4389 10.2987 11.2285 7.66409 14.4831 7.66409H57.2574C60.6669 7.66409 63.3015 10.4537 63.3015 13.7083V14.4832C63.4565 17.8927 60.6669 20.5274 57.2574 20.5274Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M112.275 133.817H69.5007C66.0912 133.817 63.4565 131.028 63.4565 127.773V126.998C63.4565 123.589 66.2461 120.954 69.5007 120.954H112.275C115.685 120.954 118.319 123.744 118.319 126.998V127.773C118.319 131.028 115.685 133.817 112.275 133.817Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.2574 96.0023H14.4831C11.0735 96.0023 8.4389 93.2127 8.4389 89.9581V89.1832C8.4389 85.7737 11.2285 83.139 14.4831 83.139H57.2574C60.6669 83.139 63.3015 85.9286 63.3015 89.1832V89.9581C63.4565 93.2127 60.6669 96.0023 57.2574 96.0023Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M112.895 102.666H14.0183C6.73427 102.666 1 96.7772 1 89.6481C1 82.3641 6.88925 76.6299 14.0183 76.6299H112.895C120.179 76.6299 125.913 82.5191 125.913 89.6481C125.913 96.7772 120.024 102.666 112.895 102.666Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M112.895 140.481H14.0183C6.73427 140.481 1 134.592 1 127.463C1 120.179 6.88925 114.445 14.0183 114.445H112.895C120.179 114.445 125.913 120.334 125.913 127.463C125.913 134.592 120.024 140.481 112.895 140.481Z"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}
