import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookPartyHornSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d } = getSVGClasses(
      'renderPlaybookPartyHorn',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 95.217 87.433"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c},.${d}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b},.${d}{opacity:0.1;}.${c},.${d}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-1578.27 -1360.78)">
          <g transform="translate(69.619 380.926)">
            <line
              className={a}
              x2="10.031"
              y2="4.769"
              transform="translate(1515.522 1055.152)"
            />
            <path
              className={a}
              d="M1509.651,1064.286l55.848-15.331a1.427,1.427,0,0,0,.56-2.123l-22.588-31.592a1.425,1.425,0,0,0-2.19-.157Z"
            />
            <path
              className={a}
              d="M1572.75,990.732l2.65,5.152,5.746.737-4.08,4.113,1.075,5.693-5.172-2.61-5.082,2.781.884-5.726-4.216-3.974,5.719-.929Z"
            />
            <path
              className={a}
              d="M1578.325,1032.095l-.446,4.272,3.267,2.789-4.2.9-1.643,3.969-2.151-3.718-4.282-.336,2.872-3.194-1-4.177,3.926,1.744Z"
            />
            <path
              className={a}
              d="M1548.5,1016.366a12.13,12.13,0,0,0,3.176-13.009,5.348,5.348,0,0,0-2.036-2.777,2.6,2.6,0,0,0-3.217.29c-.979,1.117-.317,3.055,1,3.734a4.666,4.666,0,0,0,4.265-.358c3.655-1.99,5.209-6.421,5.78-10.544.23-1.662.423-3.478-1.127-4.65-4.357-3.3-6.484,4.1-3.458,4.506,2.069.28,4.041-1.02,5.522-2.492,2.055-2.042,4.744-5.841,4.239-10.1"
            />
            <path
              className={a}
              d="M1562.456,1029.238a5.813,5.813,0,0,1,1.463-5,15.194,15.194,0,0,1,8.222-4.222c4.012-.377,7.174,3.131,7.115,5.563-.091,3.719-11.28-2.274-.367-7.755a9,9,0,0,1,4.09-.795,7.82,7.82,0,0,1,4.149,1.014,4.126,4.126,0,0,1,2.039,3.585c-.058.932-.724,1.941-1.658,1.912a1.716,1.716,0,0,1-1.476-1.563,4.449,4.449,0,0,1,.487-2.261,17.323,17.323,0,0,1,6.245-7.94,10.323,10.323,0,0,1,9.772-.983"
            />
            <line
              className={a}
              y1="0.419"
              x2="3.416"
              transform="translate(1555.636 1012.897)"
            />
            <line
              className={a}
              y1="0.419"
              x2="3.416"
              transform="translate(1564.406 1012.066)"
            />
            <line
              className={a}
              x2="0.419"
              y2="3.416"
              transform="translate(1561.104 1006.598)"
            />
            <line
              className={a}
              x2="0.419"
              y2="3.416"
              transform="translate(1561.935 1015.368)"
            />
            <line
              className={a}
              x2="2.294"
              y2="2.565"
              transform="translate(1592.042 1024.592)"
            />
            <line
              className={a}
              x2="2.294"
              y2="2.565"
              transform="translate(1597.73 1031.319)"
            />
            <line
              className={a}
              x1="2.565"
              y2="2.294"
              transform="translate(1598.113 1025.248)"
            />
            <line
              className={a}
              x1="2.565"
              y2="2.294"
              transform="translate(1591.387 1030.935)"
            />
            <line
              className={a}
              x2="16.84"
              y2="8.876"
              transform="translate(1520.188 1047.895)"
            />
            <line
              className={a}
              x2="24.113"
              y2="11.463"
              transform="translate(1523.691 1042.445)"
            />
            <line
              className={a}
              x2="30.98"
              y2="14.727"
              transform="translate(1527.776 1036.091)"
            />
            <line
              className={a}
              x2="30.786"
              y2="14.635"
              transform="translate(1533.288 1029.386)"
            />
            <line
              className={a}
              x2="16.277"
              y2="7.738"
              transform="translate(1537.257 1021.948)"
            />
          </g>
          <g className={b} transform="translate(71.619 382.926)">
            <path
              className={c}
              d="M1509.651,1064.286l55.848-15.331a1.427,1.427,0,0,0,.56-2.123l-22.588-31.592a1.425,1.425,0,0,0-2.19-.157Z"
            />
          </g>
          <path
            className={d}
            d="M1572.75,990.732l2.65,5.152,5.746.737-4.08,4.113,1.075,5.693-5.172-2.61-5.082,2.781.884-5.726-4.216-3.974,5.719-.929Z"
            transform="translate(71.619 382.926)"
          />
          <path
            className={d}
            d="M1578.325,1032.095l-.446,4.272,3.267,2.789-4.2.9-1.643,3.969-2.151-3.718-4.282-.336,2.872-3.194-1-4.177,3.926,1.744Z"
            transform="translate(71.619 382.926)"
          />
        </g>
      </svg>
    )
  }
}
