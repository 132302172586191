import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookLockSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookLock', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 62.575 96.465"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-750.338 -1623.326)">
          <path
            className={a}
            d="M0,0H58.576a0,0,0,0,1,0,0V29.288A29.288,29.288,0,0,1,29.288,58.576h0A29.288,29.288,0,0,1,0,29.288V0A0,0,0,0,1,0,0Z"
            transform="translate(751.338 1658.215)"
          />
          <path
            className={a}
            d="M58.933,1268.77a8.627,8.627,0,1,0-13.2,7.3v7.285a4.578,4.578,0,0,0,9.157,0v-7.285A8.612,8.612,0,0,0,58.933,1268.77Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className={a}
            d="M68.932,1246.426v-14.957A18.932,18.932,0,0,0,50,1212.537h0a18.932,18.932,0,0,0-18.932,18.932v14.957h7.478v-12.559c0-7.252,5.128-13.131,11.454-13.131h0c6.326,0,11.454,5.879,11.454,13.131v12.559Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className={b}
            d="M-3647.112-2502.836a29.2,29.2,0,0,1-9.309-6.277,29.173,29.173,0,0,1-6.277-9.31,29.092,29.092,0,0,1-2.3-11.4v-29.288h58.576v29.288a29.112,29.112,0,0,1-2.3,11.4,29.2,29.2,0,0,1-6.277,9.31,29.211,29.211,0,0,1-9.309,6.277,29.113,29.113,0,0,1-11.4,2.3A29.114,29.114,0,0,1-3647.112-2502.836Zm2.774-34.931a8.58,8.58,0,0,0,4.048,7.3v7.285a4.584,4.584,0,0,0,4.578,4.579,4.584,4.584,0,0,0,4.579-4.579v-7.285a8.581,8.581,0,0,0,4.049-7.3,8.637,8.637,0,0,0-8.627-8.627A8.636,8.636,0,0,0-3644.338-2537.767Zm19.774-22.345v-12.559c0-7.251-5.128-13.131-11.453-13.131s-11.454,5.879-11.454,13.131v12.559h-7.478v-14.957A18.932,18.932,0,0,1-3636.017-2594a18.931,18.931,0,0,1,18.931,18.932v14.957Z"
            transform="translate(4418.337 4219.326)"
          />
        </g>
      </svg>
    )
  }
}
