import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class GraduationCapSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a } = getSVGClasses('GraduationCapSVG', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 100 102.71"
      >
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2.48px;}`}</style>
        </defs>
        <polygon
          className={a}
          points="98.64 41.17 50.92 62.26 1.75 41.17 50.92 22.21 98.64 41.17"
        />
        <path
          className={a}
          d="M72.53,71.48a14,14,0,0,0-5.21.93c-8.06,3.24-16.4,14.22-16.4,14.22A28.84,28.84,0,0,0,34.65,72.41c-6.28-2.25-17.14,2.5-17.14,2.5V48.57"
        />
        <path
          className={a}
          d="M84.33,48.57V74.91a24.31,24.31,0,0,0-3.07-1.49"
        />
        <polyline className={a} points="50.19 41.79 76.69 50.87 76.69 66.23" />
        <path
          className={a}
          d="M80.73,78.32H72.65s-.26-6.08,0-8.09a3.88,3.88,0,0,1,4-3.72,3.83,3.83,0,0,1,4,3.72C81,72.24,80.73,78.32,80.73,78.32Z"
        />
      </svg>
    )
  }
}
