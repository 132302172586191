import React from 'react'

import { getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class WarningSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 19 21', discType, color)}>
        <circle
          cx="9.5"
          cy="11"
          r="9"
          fill={getDiscHex(discType, color)}
          fillOpacity="0.3"
          stroke="none"
        />
        <path
          d="M10.5425 0.904499V8.6265C10.5425 9.0315 10.5335 9.4275 10.5155 9.8145C10.4975 10.2015 10.475 10.593 10.448 10.989C10.421 11.376 10.385 11.7765 10.34 12.1905C10.304 12.5955 10.259 13.032 10.205 13.5H8.585C8.531 13.032 8.4815 12.5955 8.4365 12.1905C8.4005 11.7765 8.369 11.376 8.342 10.989C8.315 10.593 8.2925 10.2015 8.2745 9.8145C8.2565 9.4275 8.2475 9.0315 8.2475 8.6265V0.904499H10.5425ZM7.667 18.765C7.667 18.531 7.7075 18.3105 7.7885 18.1035C7.8785 17.8965 8 17.7165 8.153 17.5635C8.306 17.4105 8.4815 17.289 8.6795 17.199C8.8865 17.109 9.1115 17.064 9.3545 17.064C9.5885 17.064 9.8045 17.109 10.0025 17.199C10.2095 17.289 10.3895 17.4105 10.5425 17.5635C10.6955 17.7165 10.817 17.8965 10.907 18.1035C10.997 18.3105 11.042 18.531 11.042 18.765C11.042 19.008 10.997 19.233 10.907 19.44C10.817 19.638 10.6955 19.8135 10.5425 19.9665C10.3895 20.1195 10.2095 20.2365 10.0025 20.3175C9.8045 20.4075 9.5885 20.4525 9.3545 20.4525C9.1115 20.4525 8.8865 20.4075 8.6795 20.3175C8.4815 20.2365 8.306 20.1195 8.153 19.9665C8 19.8135 7.8785 19.638 7.7885 19.44C7.7075 19.233 7.667 19.008 7.667 18.765Z"
          stroke="none"
          fill={getDiscHex(discType, color)}
        />
      </svg>
    )
  }
}
