import React from 'react'

import { getDiscHex, getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class LargeDISCSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        {...getBasicClasses('0 0 150 150', discType, color)}
        fill="none"
        stroke={getDiscHex(discType, color)}
      >
        <path
          d="M148.846 59.7082L137.32 32.6594L116.418 12.0654L89.0623 1L59.7082 1.30737L32.6594 12.6802L12.0654 33.7352L1 60.9377L1.30737 90.2918L12.8339 117.341L33.7352 137.935L60.9377 149L90.2918 148.693L117.341 137.166L137.935 116.265L149 89.0623L148.846 59.7082ZM74.7695 93.0582C64.6262 93.0582 57.0955 84.7591 56.7882 75.0768C56.3271 65.2409 65.3946 56.9418 74.7695 56.9418C84.9128 56.9418 92.4434 65.2409 92.7508 74.9232C93.2118 84.7591 84.298 93.0582 74.7695 93.0582Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.5275 90.2918C96.2855 105.968 106.429 121.951 117.187 137.012"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.8131 12.9876C43.7248 28.6636 53.7145 44.0322 64.4725 59.4009"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.7529 85.3738C99.6667 91.2139 108.734 97.2077 117.648 103.048C123.334 106.736 129.021 110.578 134.707 114.267C135.629 114.882 136.705 115.496 137.627 116.111"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3728 33.8889C28.2025 44.0322 43.7248 54.0218 59.0935 64.6262"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.2119 78.4579C95.2098 79.5338 132.095 86.4496 148.693 89.0623"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.46103 60.9377C19.9034 64.0114 37.5773 67.8536 56.3271 71.3884"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.3676 63.8577C95.2097 61.0914 99.0519 58.7861 102.894 55.7124C115.189 48.028 125.947 41.1121 137.012 32.9668"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9875 117.187C28.2025 107.197 43.8785 95.6708 59.4008 85.6812"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.2201 59.5545C89.0623 54.9439 109.041 23.2845 116.111 12.3728"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.8889 137.627C38.9606 131.019 43.5712 124.103 48.3354 116.418C53.7145 107.197 59.8619 99.513 64.7799 90.7528"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.6116 56.4808C82.9149 38.6532 85.2201 20.2108 89.0623 1.30737"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.9377 148.693C63.5504 130.404 68.161 111.808 71.3884 93.5192"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.7653 93.2118C82.1464 111.808 86.757 130.404 90.2917 148.385"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.8619 1.61475C63.704 20.2108 67.5462 37.8847 71.2347 56.6345"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.2119 71.2347C111.501 67.2389 129.943 64.0114 148.385 59.7082"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.61478 90.2918C19.7498 86.4496 38.1921 82.6075 56.6345 78.919"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71.2346 56.6345L64.4724 59.4008L59.2471 64.6262L56.4807 71.5421L56.6344 78.919L59.4008 85.6812L64.6261 90.7529L71.542 93.5192H78.919L85.6812 90.5992L90.7528 85.3738L93.5192 78.6116V71.2347L90.5991 64.4725L85.3738 59.2471L78.6116 56.4808L71.2346 56.6345Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}
