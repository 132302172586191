import React from 'react'

import { getBasicClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class SimpleWalletSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg {...getBasicClasses('0 0 29.164 23.742', discType, color)}>
        <g transform="translate(-0.555 -4423.967)">
          <path
            className="a"
            d="M3.092,4428.573H26.76a1.959,1.959,0,0,1,1.959,1.959v14.218a1.959,1.959,0,0,1-1.959,1.959H3.514a1.959,1.959,0,0,1-1.959-1.959v-17.715"
          />
          <path
            className="a"
            d="M3.092,4428.573a1.686,1.686,0,0,1-1.537-1.8h0a1.686,1.686,0,0,1,1.537-1.8H25.145a1.77,1.77,0,0,1,1.615,1.893v1.713"
          />
          <circle
            className="a"
            cx="2.585"
            cy="2.585"
            r="2.585"
            transform="translate(18.37 4434.817)"
          />
          <line
            className="a"
            x1="4.052"
            transform="translate(24.299 4437.402)"
          />
        </g>
      </svg>
    )
  }
}
