import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class ProfileWithArrowsSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderProfileWithArrows', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 122.754 132.451', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1292 -6454.43)">
          <g transform="translate(1293 6455.43)">
            <path
              className={a}
              d="M5656.766,737.8c12.825,2.791,21.884,9.6,21.884,17.566,0,10.448-15.6,18.918-34.844,18.918s-34.841-8.47-34.841-18.918c0-7.758,8.6-14.425,20.906-17.345"
              transform="translate(-5585.471 -668.874)"
            />
            <circle
              className={a}
              cx="9.467"
              cy="9.467"
              r="9.467"
              transform="translate(48.87 0)"
            />
            <path
              className={a}
              d="M5646.4,703.832h-15.793a7.173,7.173,0,0,0-7.171,7.348l.377,15.531c.365,2.725,2.953,4.811,6.148,5.112l1.458,34.593h14.406l2.5-34.6c3.158-.329,5.7-2.4,6.065-5.105l.378-15.531a7.174,7.174,0,0,0-7.172-7.348Z"
              transform="translate(-5580.763 -679.924)"
            />
            <g transform="translate(19.25 52.171)">
              <path
                className={a}
                d="M5605.763,733.419v-8.262h8.262"
                transform="translate(-5605.763 -725.158)"
              />
              <line className={a} x1="12.192" y1="12.192" />
            </g>
            <g transform="translate(19.25 106.599)">
              <path
                className={a}
                d="M5605.763,769.19v8.262h8.262"
                transform="translate(-5605.763 -765.26)"
              />
              <line className={a} x1="12.192" y2="12.192" />
            </g>
            <g transform="translate(52.495 113.21)">
              <path
                className={a}
                d="M5630.847,779.814l5.842,5.842,5.842-5.842"
                transform="translate(-5630.847 -768.414)"
              />
              <line className={a} y2="17.242" transform="translate(5.842)" />
            </g>
            <g transform="translate(0 80.649)">
              <path
                className={a}
                d="M5597.079,758.329l-5.842-5.842,5.842-5.842"
                transform="translate(-5591.237 -746.645)"
              />
              <line className={a} x1="17.242" transform="translate(0 5.842)" />
            </g>
            <g transform="translate(89.311 52.171)">
              <path
                className={a}
                d="M5669.852,733.419v-8.262h-8.262"
                transform="translate(-5657.66 -725.158)"
              />
              <line className={a} y1="12.192" x2="12.192" />
            </g>
            <g transform="translate(89.311 106.599)">
              <path
                className={a}
                d="M5669.852,769.19v8.262h-8.262"
                transform="translate(-5657.66 -765.26)"
              />
              <line className={a} x2="12.192" y2="12.192" />
            </g>
            <g transform="translate(103.512 80.649)">
              <path
                className={a}
                d="M5677.941,758.329l5.842-5.842-5.842-5.842"
                transform="translate(-5666.541 -746.645)"
              />
              <line className={a} x2="17.242" transform="translate(0 5.842)" />
            </g>
          </g>
          <circle
            className={b}
            cx="9.467"
            cy="9.467"
            r="9.467"
            transform="translate(1343.432 6457.773)"
          />
          <path
            className={b}
            d="M5646.4,703.832h-15.793a7.173,7.173,0,0,0-7.171,7.348l.377,15.531c.365,2.725,2.953,4.811,6.148,5.112l1.458,34.593h14.406l2.5-34.6c3.158-.329,5.7-2.4,6.065-5.105l.378-15.531a7.174,7.174,0,0,0-7.172-7.348Z"
            transform="translate(-4286.201 5777.85)"
          />
        </g>
      </svg>
    )
  }
}
