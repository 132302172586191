import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookRelationshipConversationSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses(
      'renderPlaybookRelationshipConversation',
      discType,
      color,
    )

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 78.628 97.115"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b},.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}.${c}{opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-740.715 -1740.715)">
          <circle
            className={a}
            cx="11.682"
            cy="11.682"
            r="11.682"
            transform="translate(751.825 1777.178)"
          />
          <path
            className={a}
            d="M49.982,1416.281H16.393a4.954,4.954,0,0,1-4.774-6.313,22.476,22.476,0,0,1,43.137,0A4.954,4.954,0,0,1,49.982,1416.281Z"
            transform="translate(730.319 411.789)"
          />
          <circle
            className={a}
            cx="11.682"
            cy="11.682"
            r="11.682"
            transform="translate(784.526 1785.939)"
          />
          <path
            className={a}
            d="M53.327,1406.41a22.475,22.475,0,0,1,34.519,13.851c.036.164.07.33.1.5a3.614,3.614,0,0,1-3.555,4.284H47.376a3.59,3.59,0,0,1-3.562-4.206l.008-.041a22.276,22.276,0,0,1,1.371-4.514"
            transform="translate(730.319 411.789)"
          />
          <path
            className={a}
            d="M25.64,1354.1h3.877l3.518,7.405,3.775-7.405H53.723a4.755,4.755,0,0,0,4.755-4.755v-14.662a4.755,4.755,0,0,0-4.755-4.755H25.64a4.755,4.755,0,0,0-4.756,4.755v14.662A4.755,4.755,0,0,0,25.64,1354.1Z"
            transform="translate(730.319 411.789)"
          />
          <path
            className={a}
            d="M58.478,1345.715h12.91a3.591,3.591,0,0,1,3.591,3.591v11.072a3.591,3.591,0,0,1-3.591,3.591H68.46L65.8,1369.56l-2.851-5.591H50.18a3.591,3.591,0,0,1-3.591-3.591v-6.28"
            transform="translate(730.319 411.789)"
          />
          <line
            className={a}
            x2="24.133"
            transform="translate(758.062 1749.773)"
          />
          <line
            className={a}
            x2="24.133"
            transform="translate(758.062 1757.504)"
          />
          <circle
            className={b}
            cx="1.816"
            cy="1.816"
            r="1.816"
            transform="translate(783.006 1764.857)"
          />
          <circle
            className={b}
            cx="1.816"
            cy="1.816"
            r="1.816"
            transform="translate(788.798 1764.857)"
          />
          <circle
            className={b}
            cx="1.816"
            cy="1.816"
            r="1.816"
            transform="translate(794.589 1764.857)"
          />
          <circle
            className={c}
            cx="11.682"
            cy="11.682"
            r="11.682"
            transform="translate(784.526 1785.938)"
          />
          <path
            className={c}
            d="M53.087,1416.28c3.585-2.422,1.034-9.682.951-10.012,16.857-7.892,31.617,3.881,33.808,13.993.036.164.07.33.1.5a3.614,3.614,0,0,1-3.555,4.284H47.376a3.59,3.59,0,0,1-3.562-4.206l.008-.041a22.276,22.276,0,0,1,1.371-4.514"
            transform="translate(730.319 411.789)"
          />
          <path
            className={c}
            d="M25.64,1354.1h3.877l3.518,7.405,3.775-7.405H53.723a4.755,4.755,0,0,0,4.755-4.755v-14.662a4.755,4.755,0,0,0-4.755-4.755H25.64a4.755,4.755,0,0,0-4.756,4.755v14.662A4.755,4.755,0,0,0,25.64,1354.1Z"
            transform="translate(730.319 411.789)"
          />
        </g>
      </svg>
    )
  }
}
