import { getBasicClasses } from '@dashboard/lib/svg'
import React, { FC } from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const TrashcanThickSVG: FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 22 22', discType, color)}>
      <path
        d="M4.4 4.40002V17.952C4.4 17.952 4.4 18.568 4.71167 19.184C5.02333 19.8 5.99871 19.8 5.99871 19.8H15.3083C15.3083 19.8 16.2433 19.8 16.555 19.184C16.8667 18.568 16.8667 18.26 16.8667 18.26V4.40002"
        stroke="#6C6C6C"
      />
      <path
        d="M7.64841 2.66151L8.1724 3.90002H13.0943L13.6183 2.66151C13.7577 2.33184 13.5157 1.96669 13.1578 1.96669H8.10889C7.75094 1.96669 7.50894 2.33184 7.64841 2.66151Z"
        stroke="#6C6C6C"
      />
      <line
        x1="2.7"
        y1="3.90002"
        x2="18.5667"
        y2="3.90002"
        stroke="#6C6C6C"
        strokeLinecap="round"
      />
      <line
        x1="7.83334"
        y1="7.83337"
        x2="7.83334"
        y2="16.3667"
        stroke="#6C6C6C"
        strokeLinecap="round"
      />
      <line
        x1="10.7667"
        y1="7.83337"
        x2="10.7667"
        y2="16.3667"
        stroke="#6C6C6C"
        strokeLinecap="round"
      />
      <line
        x1="13.7"
        y1="7.83337"
        x2="13.7"
        y2="16.3667"
        stroke="#6C6C6C"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default TrashcanThickSVG
