import { getBasicClasses } from '@dashboard/lib/svg'
import React from 'react'

interface OwnProps {
  discType?: string
  color?: string
}

const LightBulbSVG: React.FC<OwnProps> = ({ discType, color }: OwnProps) => {
  return (
    <svg {...getBasicClasses('0 0 24 24', discType, color)}>
      <path d="M19.79,8.79A7.79,7.79,0,1,0,6,13.7a9,9,0,0,1,1.91,5.74V23h8.25V19.5a9.16,9.16,0,0,1,1.93-5.81A7.73,7.73,0,0,0,19.79,8.79Z" />
      <line x1="7.87" y1="16.69" x2="16.13" y2="16.69" />
      <line x1="7.87" y1="20.25" x2="16.13" y2="20.25" />
      <polyline points="10.17 16.68 10.17 10.27 13.83 10.27 13.83 16.68" />
    </svg>
  )
}

export default LightBulbSVG
