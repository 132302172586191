import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookHandHeartSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c, d, e, f } = getSVGClasses(
      'PlaybookHireSVG',
      discType,
      color,
    )

    return (
      <svg {...getPlaybookBasicClasses('0 0 192.533 121.935', discType, color)}>
        <defs>
          <style>{`.${a},.${d},.${e},.${f}{fill:${getDiscHex(
            discType,
            color,
          )};}.${a},.${b},.${c},.${d},.${e},.${f}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;}.${a},.${b},.${e}{stroke-width:1.936px;}.${a},.${d}{opacity:0.1;}.${b},.${c}{fill:none;}.${c},.${d},.${f}{stroke-width:2px;}.${e},.${f}{opacity:0.05;}`}</style>
        </defs>
        <g transform="translate(-99.959 -7259.413)">
          <path
            className={a}
            d="M-7255.61,5461.909a22.243,22.243,0,0,1-8.714-1.759,22.311,22.311,0,0,1-7.117-4.8,22.3,22.3,0,0,1-4.8-7.116,22.251,22.251,0,0,1-1.76-8.715,22.248,22.248,0,0,1,1.76-8.713,22.3,22.3,0,0,1,4.8-7.114,22.311,22.311,0,0,1,7.117-4.8,22.267,22.267,0,0,1,8.714-1.758,22.269,22.269,0,0,1,12.516,3.821l1.084,1.354-12.071,17.519-8.34-8.676-8.307,8.307,16.484,10.811,19.227-19.226.266.332a19.452,19.452,0,0,1,1.527,8.139,22.257,22.257,0,0,1-1.759,8.715,22.3,22.3,0,0,1-4.8,7.116,22.3,22.3,0,0,1-7.115,4.8A22.245,22.245,0,0,1-7255.61,5461.909Z"
            transform="translate(7452.042 1856.081)"
          />
          <g transform="translate(100.999 7260.381)">
            <path
              className={b}
              d="M7635.813,955.079a19.421,19.421,0,0,1,1.527,8.139,22.378,22.378,0,1,1-9.871-18.562"
              transform="translate(-7519.521 -927.997)"
            />
            <path
              className={b}
              d="M7608.563,968a22.389,22.389,0,1,1-22.388-22.384A22.387,22.387,0,0,1,7608.563,968Z"
              transform="translate(-7548.139 -923.245)"
            />
            <path
              className={b}
              d="M7665.1,968a22.387,22.387,0,1,1-22.388-22.384A22.386,22.386,0,0,1,7665.1,968Z"
              transform="translate(-7491.916 -923.245)"
            />
            <path
              className={b}
              d="M7648.68,944.391l-35.985,35.984-16.484-10.812,8.307-8.307,8.34,8.677,24.484-35.534Z"
              transform="translate(-7515.895 -934.398)"
            />
            <path
              className={c}
              d="M7654.322,1009.15h-61.2a9.025,9.025,0,0,1-8.7-11.5c4.971-17.008,15.165-29.432,39.295-29.432,21.04,0,34.326,12.423,39.294,29.432A9.023,9.023,0,0,1,7654.322,1009.15Z"
              transform="translate(-7527.972 -900.767)"
            />
            <path
              className={c}
              d="M7629.5,978.906c5.605-3.114,12.724-4.88,21.823-4.88,21.038,0,34.324,12.423,39.294,29.432a9.023,9.023,0,0,1-8.7,11.5h-61.2a9.022,9.022,0,0,1-8.694-11.5"
              transform="translate(-7500.525 -894.991)"
            />
            <path
              className={c}
              d="M7634.894,1003.458a9.026,9.026,0,0,1-8.7,11.5H7565a9.025,9.025,0,0,1-8.7-11.5c4.969-17.009,15.165-29.432,39.3-29.432a42.18,42.18,0,0,1,20.366,4.829"
              transform="translate(-7555.94 -894.991)"
            />
            <path
              className={d}
              d="M7654.322,1009.15h-61.2a9.025,9.025,0,0,1-8.7-11.5c4.971-17.008,15.165-29.432,39.295-29.432,21.04,0,34.326,12.423,39.294,29.432A9.023,9.023,0,0,1,7654.322,1009.15Z"
              transform="translate(-7527.972 -900.767)"
            />
            <path
              className={e}
              d="M7665.1,968a22.387,22.387,0,1,1-22.388-22.384A22.386,22.386,0,0,1,7665.1,968Z"
              transform="translate(-7491.916 -923.245)"
            />
            <path
              className={f}
              d="M7612.028,1003.458a9.022,9.022,0,0,0,8.694,11.5h61.2a9.023,9.023,0,0,0,8.7-11.5c-4.97-17.009-18.256-29.432-39.294-29.432-9.1,0-21.726,4.657-21.823,4.88s9.724,12.155,5.286,19.946S7612.028,1003.458,7612.028,1003.458Z"
              transform="translate(-7500.525 -894.991)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
