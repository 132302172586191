import React from 'react'

import { getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class CheckSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill={getDiscHex(discType, color)}
        viewBox="0 0 24 24"
        strokeWidth="0"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="9.9 18.4 3.9 12.4 5.6 10.8 9.9 15 18.2 6.7 19.9 8.4 9.9 18.4" />
      </svg>
    )
  }
}
