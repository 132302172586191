import React from 'react'

import {
  getDiscHex,
  getPlaybookBasicClasses,
  getSVGClasses,
} from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookChainSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b, c } = getSVGClasses('renderPlaybookChain', discType, color)

    return (
      <svg {...getPlaybookBasicClasses('0 0 139.289 134.627', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${c}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{opacity:0.1;}.${c}{fill:${getDiscHex(
            discType,
            color,
          )};}`}</style>
        </defs>
        <g transform="translate(-127.936 -176)">
          <g transform="translate(129 177)">
            <path
              className={a}
              d="M3671.607,1455.338a21.274,21.274,0,0,1-11.857,24.33l-28.932,13.191a21.267,21.267,0,0,1-28.172-10.527h0a21.265,21.265,0,0,1,10.528-28.172l28.933-13.193a21.275,21.275,0,0,1,25.175,5.747"
              transform="translate(-3600.725 -1363.562)"
            />
            <path
              className={a}
              d="M3661.016,1456.973a13.506,13.506,0,0,1-7.794,14l-27.1,12.356a13.5,13.5,0,0,1-17.884-6.683h0a13.5,13.5,0,0,1,6.683-17.884l27.1-12.356a13.5,13.5,0,0,1,14.209,1.881"
              transform="translate(-3598.16 -1361.062)"
            />
            <path
              className={a}
              d="M3654.241,1440.42a21.275,21.275,0,0,1,3.014-26.288l22.485-22.485a21.267,21.267,0,0,1,30.075,0h0a21.266,21.266,0,0,1,0,30.073l-22.486,22.485a21.273,21.273,0,0,1-26.006,3.187"
              transform="translate(-3580.226 -1385.418)"
            />
            <path
              className={a}
              d="M3658.294,1431.407a13.5,13.5,0,0,1,2.937-14.695l21.061-21.059a13.5,13.5,0,0,1,19.091,0h0a13.5,13.5,0,0,1,0,19.09l-21.061,21.061a13.5,13.5,0,0,1-15.766,2.439"
              transform="translate(-3577.679 -1382.859)"
            />
            <path
              className={a}
              d="M3657,1441.773l6.161-6.159,14.506-14.506a3.758,3.758,0,0,0,0-5.316l-1.13-1.132a3.759,3.759,0,0,0-5.316,0l-14.506,14.506-11.4,11.4Z"
              transform="translate(-3582.556 -1373.95)"
            />
            <path
              className={a}
              d="M3661.248,1442.05l-23.906,10.9a3.759,3.759,0,0,0-1.861,4.98l.663,1.455a3.76,3.76,0,0,0,4.98,1.861l31.206-14.227Z"
              transform="translate(-3586.7 -1362.339)"
            />
            <line
              className={a}
              y1="17.23"
              transform="translate(58.654 32.869)"
            />
            <line
              className={a}
              x1="13.67"
              y1="13.67"
              transform="translate(33.917 42.23)"
            />
            <line
              className={a}
              x1="15.541"
              transform="translate(28.709 67.823)"
            />
            <line
              className={a}
              y2="17.23"
              transform="translate(84.905 98.743)"
            />
            <line
              className={a}
              x2="13.67"
              y2="13.67"
              transform="translate(95.972 92.943)"
            />
            <line
              className={a}
              x2="15.541"
              transform="translate(99.309 81.019)"
            />
          </g>
          <g className={b} transform="translate(130.401 178.408)">
            <path
              className={c}
              d="M21.29,55.734a21.268,21.268,0,0,1-8.835-40.62L41.389,1.922A21.086,21.086,0,0,1,50.2,0,21.319,21.319,0,0,1,66.563,7.668l4.325,8.623A21.285,21.285,0,0,1,59.031,40.622L30.1,53.812A21.1,21.1,0,0,1,21.29,55.734Zm28.168-47.1a13.4,13.4,0,0,0-5.592,1.219l-27.1,12.356a13.5,13.5,0,1,0,11.2,24.568l27.1-12.357a13.381,13.381,0,0,0,6.241-5.8,13.609,13.609,0,0,0,1.553-8.2l-4.786-8.689A13.557,13.557,0,0,0,49.459,8.636Z"
              transform="translate(0 75.485)"
            />
            <path
              className={c}
              d="M21.264,65.017h0a21.228,21.228,0,0,1-10.967-3.043L3.213,55A21.245,21.245,0,0,1,6.227,28.714L28.712,6.229A21.266,21.266,0,1,1,58.786,36.3L36.3,58.788A21.129,21.129,0,0,1,21.264,65.017ZM43.357,8.84a13.411,13.411,0,0,0-9.546,3.954L12.751,33.853a13.509,13.509,0,0,0-2.938,14.7l6.263,6.836A13.546,13.546,0,0,0,22.3,56.9a13.4,13.4,0,0,0,9.544-3.954L52.9,31.885A13.5,13.5,0,0,0,43.357,8.84Z"
              transform="translate(70.808 0)"
            />
            <path
              className={c}
              d="M3657,1441.773l6.161-6.159,14.506-14.506a3.758,3.758,0,0,0,0-5.316l-1.13-1.132a3.759,3.759,0,0,0-5.316,0l-14.506,14.506-11.4,11.4Z"
              transform="translate(-3582.55 -1373.95)"
            />
            <path
              className={c}
              d="M3661.248,1442.05l-23.906,10.9a3.759,3.759,0,0,0-1.861,4.98l.663,1.455a3.76,3.76,0,0,0,4.98,1.861l31.206-14.227Z"
              transform="translate(-3586.694 -1362.339)"
            />
          </g>
        </g>
      </svg>
    )
  }
}
