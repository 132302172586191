import React from 'react'

import { getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class GraphClipboardSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props

    return (
      <svg
        stroke={getDiscHex(discType, color)}
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        width="67.319"
        height="98.683"
        viewBox="0 0 67.319 98.683"
      >
        <g transform="translate(-6175.154 -450.38)">
          <path d="M6232.046,463h9.426v85.067h-65.319V463h13.788" />
          <path d="M6215.887,456.714a5.334,5.334,0,0,0-10.668,0H6189.81v10.574h41.485V456.714Z" />
          <circle
            cx="1.612"
            cy="1.612"
            r="1.612"
            transform="translate(6208.813 455.492)"
          />
          <path d="M6210.718,487.511a20.18,20.18,0,1,1-20.18,20.179" />
          <path d="M6206.909,503.369h-20.18a20.18,20.18,0,0,1,20.18-20.18Z" />
          <path d="M6204.437,526.869l6.281-19.178,20.149-1.1" />
          <line
            y1="13.507"
            x2="14.99"
            transform="translate(6210.718 494.183)"
          />
          <path d="M6210.718,487.511a20.18,20.18,0,1,1-20.18,20.179" />
          <line
            y1="13.507"
            x2="14.99"
            transform="translate(6210.718 494.183)"
          />
        </g>
      </svg>
    )
  }
}
