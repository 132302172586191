import React from 'react'

import { getSVGClasses, getBasicClasses, getDiscHex } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class IntroduceSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('IntroduceSVG', discType, color)

    return (
      <svg {...getBasicClasses('0 0 138.968 87.5', discType, color)}>
        <defs>
          <style>{`.${a}{fill:none;stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.936px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-100.032 -7105.819)">
          <g transform="translate(-7342.931 6167.162)">
            <rect
              className={a}
              width="136"
              height="85.563"
              transform="translate(7443.931 939.625)"
            />
            <line
              className={a}
              x2="136"
              transform="translate(7443.931 964.191)"
            />
            <path
              className={a}
              d="M7456.233,1002.094,7466.6,969.1a196.762,196.762,0,0,0-.778,26.8,36.678,36.678,0,0,1,6.594-8.8l1.862,7.053c.405,1.543,1.092,3.359,2.659,3.657,1.395.265,2.643-.867,3.531-1.972a26.6,26.6,0,0,0,4.449-8.1,4.373,4.373,0,0,0,3.225,6.433c2.363.135,4.34-1.7,5.894-3.485a63.147,63.147,0,0,0,13.663-26.478,32.225,32.225,0,0,0-2.235,20.836c.6,2.578,1.59,5.194,3.584,6.934a3.71,3.71,0,0,0,2.035,1.009c1.879.169,3.2-1.77,3.981-3.484a54.9,54.9,0,0,0,4.956-22.662,58.324,58.324,0,0,0-1.837,16.813c.124,3.379,1.239,7.549,4.563,8.169,2.246.419,4.354-1.082,6.146-2.5l10.081-7.962c.412-.326.939-.67,1.422-.465a1.326,1.326,0,0,1,.6.689,5.987,5.987,0,0,1-.908,6.465,1.781,1.781,0,0,1-.843.592c-1.063.273-1.795-1.312-1.243-2.26a4.268,4.268,0,0,1,2.778-1.589l12.263-3.264"
              transform="translate(5.738 11.465)"
            />
            <line
              className={a}
              x2="82.343"
              transform="translate(7470.817 952.122)"
            />
          </g>
          <rect
            className={b}
            width="136"
            height="25.357"
            transform="translate(103 7108.788)"
          />
        </g>
      </svg>
    )
  }
}
