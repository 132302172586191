import React from 'react'

import { getDiscHex, getSVGClasses } from '@dashboard/lib/svg'

interface OwnProps {
  discType?: string
  color?: string
}

export default class PlaybookWarningSVG extends React.Component<OwnProps> {
  render() {
    const { discType, color } = this.props
    const { a, b } = getSVGClasses('renderPlaybookWarning', discType, color)

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 95.159 77.462"
      >
        <defs>
          <style>{`.${a}{fill:none;}.${a},.${b}{stroke:${getDiscHex(
            discType,
            color,
          )};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.${b}{fill:${getDiscHex(
            discType,
            color,
          )};opacity:0.1;}`}</style>
        </defs>
        <g transform="translate(-1834.726 -757.407)">
          <path
            className={a}
            d="M1749.771,460.23h-93.159l46.579-75.462Z"
            transform="translate(179.114 373.638)"
          />
          <path
            className={a}
            d="M1706.6,441.264h-5.1l-3-35.363h11.1Z"
            transform="translate(179.114 373.638)"
          />
          <circle
            className={a}
            cx="3.669"
            cy="3.669"
            r="3.669"
            transform="translate(1879.502 820.068)"
          />
          <path
            className={b}
            d="M1706.6,441.264h-5.1l-3-35.363h11.1Z"
            transform="translate(179.114 373.638)"
          />
          <circle
            className={b}
            cx="3.669"
            cy="3.669"
            r="3.669"
            transform="translate(1879.502 820.068)"
          />
        </g>
      </svg>
    )
  }
}
